import React, { useContext } from 'react'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import { getMessage } from '../../../../lib/translator'
import { getSession, isExtensionEnabled } from '../../../../lib/auth'
import PlacementDetails from '../Details/PlacementDetails'
import PacklistView from '../Details/PacklistView'
import OrderItemsViewNew from '../Details/OrderItemsView'
import API from '../../../../lib/api'
import Loader from '../../../../components/Loader'
import { Dialog } from '../../../../components/Popup'
import PacklistPackingDetails from '../Details/PackingDetails'
import { SplitContext } from 'containers/SplitContext'
import { BaseForm } from '../../../../components/Form'
import PackingDetailsNoPacklist from '../Details/PackingDetails/NoPacklist'
import _ from 'lodash'

class EditPackedDeliveryOrder extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      packlistData: null,
      loading: true,
      failed: false,
      warning: false,
      isMobileView: false,
      editedPacklists: {},
      originalData: null,
      editedOrder: {},
    }
    this.getMobileView = this.getMobileView.bind(this)
    this.showErrorPopup = this.showErrorPopup.bind(this)
    this.hideErrorPopup = this.hideErrorPopup.bind(this)
    this.getData = this.getData.bind(this)
    this.updatePacklists = this.updatePacklists.bind(this)
    this.updateItemQuantity = this.updateItemQuantity.bind(this)
    this.updateCrates = this.updateCrates.bind(this)
    this.allowEditPacked = this.allowEditPacked.bind(this)
    this.backToDetails = this.backToDetails.bind(this)
    this.updateOrderItem = this.updateOrderItem.bind(this)
    this.updateOrderPackages = this.updateOrderPackages.bind(this)
    this.organization = getSession().organization
    this.currency = this.organization.currency
    this.statusCanEditPacklist = [
      'DELIVERY_ORDER_STATUS_PROCESSING',
      'DELIVERY_ORDER_STATUS_PICKING',
      'DELIVERY_ORDER_STATUS_PACKING',
      'DELIVERY_ORDER_STATUS_PACKED',
    ]
  }

  getMobileView() {
    this.setState({ isMobileView: window.screen.width <= 480 })
  }

  showErrorPopup(error) {
    this.setState({
      failed: true,
      loading: false,
      error: error,
    })
  }

  hideErrorPopup() {
    if (this.state.warning) {
      this.setState({
        failed: false,
        warning: false,
      })
    } else {
      this.setState(
        {
          failed: false,
        },
        this.backToDetails()
      )
    }
  }

  onSubmit() {
    this.updatePacklists()
    this.putOrder()
  }

  backToDetails() {
    const referenceNumber = this.props.match.params.id
    this.props.history.push(`/operations/delivery-orders/${referenceNumber}`)
  }

  allowEditPacked(deliveryOrder, packlists) {
    if (packlists.length > 0) {
      const hasEditablePacklist =
        packlists &&
        packlists.some(
          (packlist) => packlist.status === 'PACKLIST_STATUS_PACKED'
        )
      return (
        hasEditablePacklist &&
        this.statusCanEditPacklist.includes(deliveryOrder.status)
      )
    }
    return this.statusCanEditPacklist.includes(deliveryOrder.status)
  }

  async getData() {
    const promises = []
    const referenceNumber = this.props.match.params.id
    this.orderDetailsApi = new API({
      url: `/order-service/v3/deliveryOrders/${referenceNumber}`,
    })
    this.packlistApi = new API({
      url: `/picking-service/v3/packlists?deliveryOrderReferenceNumber=${referenceNumber}`,
    })
    promises.push(this.orderDetailsApi.get({}), this.packlistApi.get({}))
    await Promise.all(promises)
      .then((response) => {
        if (response.length === 2) {
          const deliveryOrder = response[0].deliveryOrder
          const packlists = response[1].packlists
          if (this.allowEditPacked(deliveryOrder, packlists)) {
            this.setState({
              loading: false,
              data: deliveryOrder,
              packlistData: packlists,
              originalData: deliveryOrder,
            })
          } else {
            this.showErrorPopup(
              getMessage('deliveryOrder.edit-packed.notAllowed.error')
            )
          }
        } else {
          this.showErrorPopup(getMessage('error.server'))
        }
      })
      .catch((error) => {
        this.showErrorPopup(error.message)
      })
  }

  async updatePacklists() {
    const { editedPacklists } = this.state
    // update API call for each packlist in editedPacklists
    for (const packlistId in editedPacklists) {
      this.setState({ loading: true })
      const packlistData = editedPacklists[packlistId]
      const items = packlistData['items'] || []

      const payload = {}

      // insert quantity
      if (items.length !== 0) {
        payload.items = items
      }

      // insert labels
      if (Object.prototype.hasOwnProperty.call(packlistData, 'crates')) {
        payload.cratesNew = { valid: true, cratesList: packlistData['crates'] }
      }

      if (Object.keys(payload).length !== 0) {
        try {
          this.updatePacklistApi = new API({
            url: `/picking-service/v3/packlists/${packlistId}/update`,
          })
          await this.updatePacklistApi.put(payload)
          this.setState({ loading: false })
          this.backToDetails()
        } catch (e) {
          this.showErrorPopup(e.message)
        }
      }
    }
  }

  updateItemQuantity(packlistId, item, itemQuantity, overPicked) {
    // if it is overPicked, show warning message to user and do not update editedPacklists
    // else, update editedPacklists with itemQuantity
    if (overPicked) {
      const soldByWeight =
        item.soldByWeight &&
        item.unitOfMeasurement &&
        item.unitOfMeasurement === 'KGM'
      const overPickingQty = soldByWeight
        ? (item.orderedQuantity * item.overPickingThreshold).toFixed(2)
        : item.orderedQuantity
      const overPickingError = `${getMessage('deliveryOrder.edit-packed.overpicked.error')}: ${itemQuantity} > ${overPickingQty}`
      this.setState(
        {
          warning: true,
        },
        this.showErrorPopup(overPickingError)
      )
    } else {
      const editedPacklists = Object.assign({}, this.state.editedPacklists)
      const packlistItem = {
        id: item.id,
        item_id: item.itemId,
        quantity: itemQuantity,
      }

      if (
        Object.keys(editedPacklists).length > 0 &&
        packlistId in editedPacklists &&
        'items' in editedPacklists[packlistId]
      ) {
        const itemIndex = editedPacklists[packlistId].items.findIndex(
          (plItem) => plItem.id === item.id
        )
        if (itemIndex > -1) {
          editedPacklists[packlistId].items[itemIndex].quantity = itemQuantity
        } else {
          editedPacklists[packlistId].items.push(packlistItem)
        }
      } else {
        editedPacklists[packlistId] = { items: [packlistItem] }
      }

      this.setState({ editedPacklists })
    }
  }

  updateCrates(values, oriValues) {
    const editedPacklists = this.state.editedPacklists || {}
    const modifiedEditedPacklists = Object.assign({}, editedPacklists)
    for (const key in values) {
      if (!_.isEqual(values[key], oriValues[key])) {
        const packlistData = modifiedEditedPacklists[key] || {}
        packlistData['crates'] = values[key]
        modifiedEditedPacklists[key] = packlistData
      }
    }
    if (!_.isEqual(modifiedEditedPacklists, editedPacklists)) {
      this.setState({ editedPacklists: modifiedEditedPacklists })
    }
  }

  updateOrderItem(e, itemID) {
    const newDoItems = JSON.parse(JSON.stringify(this.state.data.items))
    const freeDoItems = JSON.parse(JSON.stringify(this.state.data.freeItems))

    const allDoItems = newDoItems.concat(freeDoItems)
    const updatedItem = allDoItems.find((item) => item.item.id === itemID)

    const updatedQuantity = updatedItem.item.soldByWeight
      ? parseFloat(e || 0)
      : parseInt(e || 0, 10)

    const isOverPicked =
      (updatedItem.item.soldByWeight &&
        updatedItem.unitOfMeasurement &&
        updatedItem.unitOfMeasurement === 'KGM' &&
        updatedQuantity >
          updatedItem.overPickingThreshold * updatedItem.orderedQuantity) ||
      updatedQuantity > updatedItem.orderedQuantity
        ? true
        : false

    if (isOverPicked) {
      const overPickingError = `${getMessage('deliveryOrder.edit-packed.overpicked.error')}: ${updatedQuantity} > ${updatedItem.orderedQuantity}`
      this.setState(
        {
          warning: true,
        },
        this.showErrorPopup(overPickingError)
      )

      return
    }

    const updatedDoItems = allDoItems.find((item) => item.item.id === itemID)
    if (updatedDoItems) {
      updatedDoItems.deliveredQuantity = updatedQuantity
    }

    const newItems = this.getOrderItems(allDoItems)

    const oldItems = this.getOrderItems(
      this.state.originalData.items.concat(this.state.originalData.freeItems)
    )

    const editedItemsArray = newItems.filter((x) =>
      oldItems.filter(
        (y) =>
          y.orderItemId === x.orderItemId &&
          y.deliveredQuantity !== x.deliveredQuantity
      )
    )

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        freeItems: freeDoItems,
        items: newDoItems,
      },
      editedOrder: {
        ...prevState.editedOrder,
        items: editedItemsArray,
      },
    }))
  }

  getOrderItems(items) {
    return items.map((eachItem) => ({
      quantity: parseInt(eachItem.deliveredQuantity, 10),
      orderItemId: parseInt(eachItem.item.id, 10),
    }))
  }

  updateOrderPackages(e) {
    let newOrderPackages = JSON.parse(JSON.stringify(this.state.data.package))

    newOrderPackages = {
      details: {
        crates: e,
      },
    }

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        package: newOrderPackages,
      },
      editedOrder: {
        ...prevState.editedOrder,
        details: {
          crates: e,
        },
        items: prevState.editedOrder.items
          ? prevState.editedOrder.items
          : this.getOrderItems(this.state.originalData.items),
      },
    }))
  }

  async putOrder() {
    const referenceNumber = this.props.match.params.id

    if (Object.keys(this.state.editedOrder).length !== 0) {
      try {
        this.putOrderApi = new API({
          url: `/order-service/order/${referenceNumber}/pack`,
        })
        await this.putOrderApi.put(this.state.editedOrder)
        this.setState({ loading: false })
        this.backToDetails()
      } catch (e) {
        this.showErrorPopup(e.message)
      }
    }
  }

  async componentDidMount() {
    if (isExtensionEnabled('InStoreProcessing')) {
      try {
        this.api = new API({
          url: `/config-service/config/inStoreProcessing.packedOrderEditAllowed`,
        })
        await this.api.get().then((res) => {
          if (
            res &&
            res.data &&
            res.data.inStoreProcessing &&
            res.data.inStoreProcessing.packedOrderEditAllowed
          ) {
            this.getMobileView()
            this.getData()
            window.addEventListener('resize', this.mobileView, false)
          } else {
            this.showErrorPopup(getMessage('error.server.403'))
          }
        })
      } catch (e) {
        this.showErrorPopup(e.message)
      }
    } else {
      this.showErrorPopup(getMessage('error.server.403'))
    }
  }

  componentWillUnmount() {
    this.orderDetailsApi && this.orderDetailsApi.cancel()
    this.packlistApi && this.packlistApi.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  render() {
    const { Form } = this.components
    const { SubmitButton } = this.buttons
    const deliveryOrderDetails = this.state.data
    const packlistDetails = this.state.packlistData
    const referenceNumber = this.props.match.params.id
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        storeDependent
        className="edit-packed-order"
      >
        <div>
          <h1>{getMessage('deliveryOrder.edit-packed.heading')}</h1>
          <h1 className="title heading">
            {getMessage('deliveryOrder.details.heading')}{' '}
            {this.state.data &&
              (this.state.data.clientId
                ? getMessage('order.table.clientId.prefix')
                : getMessage('order.table.referenceNumber.prefix'))}
            <strong className="reference-number">
              {this.state.data && (this.state.data.clientId || referenceNumber)}
            </strong>
          </h1>
          {this.state.loading ? (
            <Loader />
          ) : (
            <div>
              {this.state.failed && (
                <Dialog
                  title={getMessage(
                    'order.details.edit-packed.error.dialog.label'
                  )}
                  show={this.state.failed}
                  information={this.state.error}
                  close={this.hideErrorPopup}
                  closeText={getMessage(
                    'order.details.edit-packed.error.closeDialog'
                  )}
                />
              )}
              {deliveryOrderDetails && packlistDetails && (
                <div>
                  <PlacementDetails
                    data={{
                      status: deliveryOrderDetails.status.replace(
                        'DELIVERY_ORDER_STATUS_',
                        ''
                      ),
                      batchIds: null,
                      creationTime: deliveryOrderDetails.createTime,
                      completionTime: deliveryOrderDetails.completeTime,
                      device: 'DESKTOP_NA_WEB',
                      storeId: deliveryOrderDetails.seller.code,
                      storeName: deliveryOrderDetails.store.name,
                      preorder: deliveryOrderDetails.preorder,
                      salesOrderReferenceNumber:
                        deliveryOrderDetails['salesOrderReferenceNumber'],
                    }}
                  />
                  {packlistDetails.length > 0 ? (
                    <>
                      <PacklistView
                        data={{
                          packlistData: packlistDetails,
                          orderItems: deliveryOrderDetails.items,
                          payment: [],
                          shipping: 0,
                          clickAndCollectCharges: 0,
                          type: deliveryOrderDetails.fulfilmentType,
                          amount: deliveryOrderDetails.amount,
                          pendingAmount: deliveryOrderDetails.pendingAmount,
                          invoiceAmount: deliveryOrderDetails.invoiceAmount,
                        }}
                        discounts={deliveryOrderDetails.discounts}
                        currency={this.currency}
                        status={deliveryOrderDetails.status}
                        updateItemQuantity={this.updateItemQuantity}
                        itemEditMode={true}
                      />
                      <PacklistPackingDetails
                        packlistDetails={packlistDetails}
                        itemEditMode={true}
                        updateCrates={this.updateCrates}
                      />
                    </>
                  ) : (
                    <>
                      <OrderItemsViewNew
                        deliveryOrderData={deliveryOrderDetails}
                        isSplitOrder={
                          deliveryOrderDetails.salesOrderReferenceNumber
                            ? deliveryOrderDetails.referenceNumber !==
                              deliveryOrderDetails.salesOrderReferenceNumber
                            : false
                        }
                        isDfOrder={
                          deliveryOrderDetails.seller?.type ===
                          'SELLER_TYPE_VENDOR'
                        }
                        currency={this.currency}
                        itemEditMode={true}
                        updateOrderItem={this.updateOrderItem}
                      />
                      <PackingDetailsNoPacklist
                        packages={deliveryOrderDetails.package}
                        itemEditMode={true}
                        updateOrderPackages={this.updateOrderPackages}
                      />
                    </>
                  )}
                  <Form>
                    <div className="foot-buttons">
                      <button
                        className="button"
                        type="button"
                        onClick={this.backToDetails}
                      >
                        {getMessage(
                          'order.details.itemsTable.actions.cancelText'
                        )}
                      </button>
                      <SubmitButton
                        disabled={
                          Object.keys(this.state.editedPacklists).length ===
                            0 &&
                          Object.keys(this.state.editedOrder).length === 0
                        }
                      >
                        {getMessage(
                          'order.details.itemsTable.actions.confirmText'
                        )}
                      </SubmitButton>
                    </div>
                  </Form>
                </div>
              )}
            </div>
          )}
        </div>
      </AuthenticatedPage>
    )
  }
}

export default function EditPackedDeliveryOrderWrapper(props) {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <EditPackedDeliveryOrder {...props} splits={splits} />
}

export { EditPackedDeliveryOrder }
