import React from 'react'
import { Row, Cell } from '../../Table'
import Splashscreen from './Splashscreen'
import { getMessage } from 'lib/translator'
import {
  TableAction,
  getStatus,
  ColumnName,
  ColumnDate,
  ColumnStatus,
} from '../utils'
import API from 'lib/api'
import { getImageSize } from 'react-image-size'

const tableProperties = () => {
  return {
    headers: [
      'Splashscreen',
      'Campaign Name',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({ id, name, splash, startTime, endTime, isEnable, onAction }) => {
      const status = isEnable
        ? getStatus(startTime, endTime)
        : getMessage('apphome.disabled')

      const editLink = `/app-home/splash-dressup/edit/${id}`

      return (
        <Row>
          <Cell className="column-splashscreen">
            <Splashscreen splash={splash} />
          </Cell>
          <ColumnName link={editLink} name={name} />
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={id}
              onAction={onAction}
              isEnable={isEnable}
              editLink={editLink}
              type="splash"
            />
          </Cell>
        </Row>
      )
    },
  }
}

const normalizeResponse = (response) => {
  return {
    actualWidth: response.width || response.w,
    actualHeight: response.height || response.h,
  }
}

const getExpectedDimension = (type) => {
  let maxHeight, maxWidth
  let errorMessage = ''

  if (type === 'bottomAnimation' || type === 'topAnimation') {
    maxHeight = 609
    maxWidth = 1080
    errorMessage = getMessage('app.splash.uploadAnimation.error')
  } else if (type === 'background') {
    maxHeight = 1920
    maxWidth = 1080
    errorMessage = getMessage('app.splash.uploadBackground.error')
  }
  return { maxHeight, maxWidth, errorMessage }
}

const getActualDimension = async (url) => {
  const isLottie = url.slice(-4) === 'json'
  const response = isLottie
    ? await new API({ url }).get()
    : await getImageSize(url)
  return normalizeResponse(response)
}

export {
  tableProperties,
  getActualDimension,
  getExpectedDimension,
  normalizeResponse,
}
