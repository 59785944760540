import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SplitContext } from '../../../containers/SplitContext'
import SPLIT_FEATURES from '../../../containers/SplitContext/features'
import { getMessage } from '../../../lib/translator'

export default function HeaderActions() {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  const isEnabledDigitalPaymentOffer =
    splits?.[SPLIT_FEATURES.OFFER_DIGITAL_PAYMENT]?.treatment === 'on'
  return (
    <>
      <Link to="/marketing/offers/add" className="primary button add-action">
        + <span className="text">{getMessage('offer.add.text')}</span>
      </Link>
      {isEnabledDigitalPaymentOffer && (
        <Link
          to="/marketing/offers/pay-via-fp-app/add"
          className="primary button add-action"
          data-testid="offer-add-fp-app-action"
        >
          + <span className="text">{getMessage('offer.addfpapp.text')}</span>
        </Link>
      )}
      <Link
        to="/marketing/offers/pin-wallet/add"
        className="primary button add-action"
      >
        <span className="text">{getMessage('offer.pin.wallet')}</span>
      </Link>
    </>
  )
}
