import React, { createContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TRIGGERS } from 'components/OmniJourney/ScanToWin/utils'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import {
  OMNI_JOURNEY_MENU,
  tableProperties,
} from 'components/OmniJourney/utils'
import Filters from 'components/OmniJourney/Filters'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import ScanToWinForm from './Form'
import './style.scss'
import API from 'lib/api'

const HeaderButtons = ({ setShowPopup }) => {
  return (
    <div className="header-wrap">
      <button className="primary button" onClick={() => setShowPopup(true)}>
        {getMessage('omni-journey.create.trigger')}
      </button>
    </div>
  )
}

export const StoresContext = createContext()

const ManageScanToWin = (props) => {
  const [stores, setStores] = useState([])
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (!props.match.params.action) {
      const productStoresAPI = new API({
        url: `/genie/lms/content?contentType=category&key=products`,
      })
      productStoresAPI.get().then((res) => {
        if (!res.data.items) {
          return
        }
        const data = res.data.items.map(({ text, value }) => {
          return { text, value }
        })
        setStores(data)
      })
    }
  }, [props.match.params.action])

  return (
    <StoresContext.Provider value={{ stores }}>
      <ListingPageWithRoutes
        title={getMessage('omni-journey.manage.scan-to-win')}
        addHeading={`Add ${getMessage(`omni-journey.${props.match.params.type}`)}`}
        editHeading={`Edit ${getMessage(`omni-journey.${props.match.params.type}`)}`}
        menu={props.menu}
        className="scan-to-win-listing"
        api={{
          url: `/genie/lms/content`,
          transform: (res) => {
            return res.data.items
          },
          params: {
            contentType: 'scan',
            key: props.match.params.key
              ? `product#${props.match.params.key}`
              : null,
            sk: props.match.params.sk,
          },
        }}
        headerActions={() => <HeaderButtons setShowPopup={setShowPopup} />}
        emptyState={{ actions: HeaderButtons }}
        form={{
          component: ScanToWinForm,
        }}
        filters={{
          component: Filters,
          forceShow: true,
          showFiltersOnLoad: true,
          transformFilter: () => {
            return {
              contentType: 'scan',
              key: 'product#fp-all-stores',
            }
          },
          options: { type: 'scan-to-win' },
        }}
        tableProperties={tableProperties(OMNI_JOURNEY_MENU[1])}
        viewHeading="Scan to Win Details"
        modalClassName="resend-popup"
        dontSavePagination
        primaryKey="sk"
        tableDynamic
      />
      <Popup
        show={showPopup}
        heading={getMessage('omni-journey.select.trigger')}
        close={() => setShowPopup(false)}
      >
        <div className="select-trigger-section">
          {TRIGGERS.map(({ text, value }) => (
            <Link
              to={`/omni-journey/scan-to-win/${value}/add`}
              className="button trigger-button"
              onClick={() => setShowPopup(false)}
              key={value}
            >
              <span className="text">{text}</span>
            </Link>
          ))}
        </div>
      </Popup>
    </StoresContext.Provider>
  )
}

export default ManageScanToWin
