import React from 'react'
import './style.css'
import { isCatalogueProductReadOnly } from 'pages/catalogue/Products/utils'

function OfferSequence({ details, onEdit, onDelete }) {
  const isDefaultVariant = details?.variant === 'default'
  const startDate = details?.validFrom ? new Date(details?.validFrom) : ''
  const endDate = details?.validTill ? new Date(details?.validTill) : ''
  const data = details?.data.data?.length ? details?.data.data : []
  const isReadOnly = isCatalogueProductReadOnly()

  return (
    <div className="ConfigDetails">
      <div className="Config-header">
        <div className="variant-left">
          <div>
            <span className="Config-ID">
              ID - {details?.id} | User - {details?.updatedBy}
            </span>{' '}
            <span className="Config-V">{details?.variant}</span>
            <span
              className={`Config-Status ${details?.status === 'DISABLED' ? 'variant-disabled' : ''}`}
            >
              {details?.status}
            </span>
          </div>
        </div>
        <div className="Config-Footer">
          {!isReadOnly && (
            <button className="Edit-btn" onClick={() => onEdit(details?.id)}>
              Edit
            </button>
          )}
          {!isDefaultVariant && !isReadOnly && (
            <button
              className="Delete-btn"
              onClick={() => onDelete(details?.id)}
            >
              Delete
            </button>
          )}
        </div>
      </div>

      <div className="Config-Content">
        <div className="ConfigSection">
          <label htmlFor="">Offer Sequence</label>
          <p>
            {data.map((item) => {
              return (
                <span className="ConfigChips" key={item}>
                  {item}
                </span>
              )
            })}
          </p>
        </div>

        <div className="ConfigSection ConfigTimeStamp">
          {startDate && (
            <div>
              <label htmlFor="">Start Date & Time</label>
              <span>{`${startDate?.toLocaleDateString('en-CA')} | ${startDate.toLocaleTimeString()}`}</span>
            </div>
          )}

          {endDate && (
            <div className="ConfigEndDate">
              <label htmlFor="">End Date & Time</label>
              <span>{`${endDate?.toLocaleDateString('en-CA')} | ${endDate.toLocaleTimeString()}`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OfferSequence
