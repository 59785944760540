import React, { useState, useEffect } from 'react'
import { getMessage } from 'lib/translator'
import update from 'immutability-helper'
import Loader from 'components/Loader'
import Draggable from '../Draggable'
import Card from './Card'
import API from 'lib/api'

const Cards = ({
  cards,
  moveCard,
  isSorted,
  closePopup,
  updateCardPosition,
}) => {
  return (
    <>
      {cards.length > 0 && (
        <div className="preview-container">
          <div className="filteredcards-section">
            {cards.map((card, index) => (
              <Draggable
                index={index}
                key={card.sk}
                axis="horizontal"
                moveCard={moveCard}
              >
                <Card src={card.image} texts={card.texts || []} />
              </Draggable>
            ))}
          </div>
          {!isSorted && (
            <p className="text-section">
              {getMessage('app.card.change.position')}
            </p>
          )}
          {isSorted && (
            <div className="buttons-section">
              <button className="button" onClick={closePopup}>
                <span className="text">{getMessage('apphome.cancel')}</span>
              </button>
              <button className="primary button" onClick={updateCardPosition}>
                <span className="text">{getMessage('apphome.enable')}</span>
              </button>
            </div>
          )}
        </div>
      )}
      {cards.length === 0 && (
        <p className="empty text-section">{getMessage('app.card.empty')}</p>
      )}
    </>
  )
}

const PreviewCard = ({ category, closePopup }) => {
  const [cards, setCards] = useState([])
  const [isSorted, setIsSorted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    new API({
      url: `/genie/lms/content`,
    })
      .get({
        contentType: 'content',
        key: `cards#${category}`,
        status: 'active',
        sort: 'rank',
        limit: 1000,
      })
      .then((res) => {
        setCards(res.data.items)
        setIsLoading(false)
      })
  }, [])

  const moveCard = (dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }).reduce((accumulator, item, index) => {
        accumulator[index] = {
          ...item,
        }
        return accumulator
      }, [])
    )
    setIsSorted(true)
  }

  const updateCardPosition = () => {
    setIsLoading(true)
    new API({ url: `/genie/lms/content` })
      .patch({
        updates: cards.map(({ pk, sk }, index) => {
          return {
            pk,
            sk,
            fields: [
              {
                op: 'SET',
                name: 'rank',
                value: index + 1,
              },
            ],
          }
        }),
      })
      .then(() => {
        closePopup()
      })
  }

  return (
    <>
      {isLoading && <Loader size="sm" />}
      {!isLoading && (
        <Cards
          cards={cards}
          moveCard={moveCard}
          isSorted={isSorted}
          closePopup={closePopup}
          updateCardPosition={updateCardPosition}
        />
      )}
    </>
  )
}

export default PreviewCard
