/**
 * The list of all activity type definition, group
 * by modules for easier reading / tracking changes
 */
const ActivityTypes = {
  operations: {
    VIEW_OPERATION_DASHBOARD: 'View_Operation_Dashboard',
    VIEW_ORDERS: 'View_Orders',
    CREATE_ORDER: 'Create_Order',
    CANCEL_ORDER: 'Cancel_Order',
    VIEW_ORDER_DETAIL: 'View_Order_Detail',
    VIEW_ORDER_LOG: 'View_Order_Log',
    EDIT_ORDER_DETAIL: 'Edit_Order_Detail',
    VIEW_STORE_SLOT: 'View_Store_Slots',
    EDIT_STORE_SLOT: 'Edit_Store_Slot',
    VIEW_PICKING_SEQUENCE: 'View_Picking_Sequence',
    EDIT_PICKING_SEQUENCE: 'Edit_Picking_Sequence',
    VIEW_PICKERS: 'View_Pickers',
    CHECKIN_PICKER: 'Checkin_Picker',
    CHECKOUT_PICKER: 'Checkout_Picker',
    EDIT_PICKER: 'Edit_Picker',
    VIEW_CAPACITY_PLANNING: 'View_Capacity_Planning',
    ADD_CAPACITY_PLANNING_RULE: 'Add_Capacity_Planning_Rule',
    DELETE_CAPACITY_PLANNING_RULE: 'Delete_Capacity_Planning_Rule',
    EDIT_CAPACITY_PLANNING_RULE: 'Edit_Capacity_Planning_Rule',
    EDIT_TODAY_CAPACITY_PLANNING_RULE: 'Edit_Today_Capacity_Planning_Rule',
    VIEW_ORDER_SLOT_CHARGES: 'View_Order_Slot_Charges',
    EDIT_BATCH_PICKING_QUEUES: 'Edit_Batch_Picking_Queues',
    VIEW_DELIVERY_ORDERS: 'View_Delivery_Orders',
    /* ORDER ACTIONS ON DELIVERY ORDER PAGE*/
    CANCEL_DELIVERY_ORDER: 'Cancel_Delivery_Order',
    PACK_DELIVERY_ORDER: 'Pack_Delivery_Order',
    EDIT_DELIVERY_ORDER_SLOT: 'Edit_Delivery_Order_Slot',
    COMPLETE_DELIVERY_ORDER: 'Complete_Delivery_Order',
    RETURN_DELIVERY_ORDER: 'Return_Delivery_Order',
    /* ACTIONS ON TIME SLOT BLOCKING PAGE */
    CREATE_TIME_SLOT_BLOCKING_RULE: 'Create_Time_Slot_Blocking_Rule',
    EDIT_TIME_SLOT_BLOCKING_RULE: 'Edit_Time_Slot_Blocking_Rule',
    DELETE_TIME_SLOT_BLOCKING_RULE: 'Delete_Time_Slot_Blocking_Rule',
  },
  catalogue: {
    VIEW_CAT_DASHBOARD: 'View_Catalogue_Dashboard',
    VIEW_BRANDS: 'View_Brands',
    ADD_BRAND: 'Add_Brand',
    EDIT_BRAND: 'Edit_Brand',
    DELETE_BRAND: 'Delete_Brand',
    VIEW_CATEGORIES: 'View_Categories',
    ADD_CATEGORY: 'Add_Category',
    EDIT_CATEGORY: 'Edit_Category',
    DELETE_CATEGORY: 'Delete_Category',
    VIEW_PRODUCTS: 'View_Products',
    ADD_SINGLE_PRODUCT: 'Add_Single_Product',
    HIDE_SINGLE_PRODUCT: 'Hide_Single_Product',
    SHOW_SINGLE_PRPODUCT: 'Show_Single_Product',
    EDIT_SINGLE_PRODUCT: 'Edit_Single_Product',
    DELETE_SINGLE_PRODUCT: 'Delete_Single_Product',
    BULK_UPLOAD: 'Bulk_upload',
    VIEW_BATCH_JOBS: 'View_Batch_jobs',
    DOWNLOAD_BULK_UPLOAD: 'Download_Batch_Job',
    VIEW_TAGS: 'View_Tags',
    ADD_TAG: 'Add_Tag',
    EDIT_TAG: 'Edit_Tag',
    DELETE_TAG: 'Delete_Tag',
    IMPORT_TAGS: 'Import_Tags',
    VIEW_SUBSTITUTION_GROUPS: 'View_Substitution_Groups',
    ADD_SUBSTITUTION_GROUPS: 'Add_Substitution_Groups',
    EDIT_SUBSTITUTION_GROUPS: 'Edit_Substitution_Groups',
    DELETE_SUBSTITUTION_GROUPS: 'Delete_Substitution_Groups',
    VIEW_STOCK_OVERRIDES: 'View_Stock_Overrides',
    VIEW_STOCK_OVERRIDES_PRODUCT: 'View_Stock_Overrides_Product',
    VIEW_STOCK_OVERRIDES_CATEGORY: 'View_Stock_Overrides_Category',
    SUBMIT_STOCK_OVERRIDES: 'Submit_Stock_Overrides',
    EDIT_STOCK_OVR_CONFIG: 'Edit_Stock_Overrides_config',
    DELETE_STOCK_OVR_CONFIG: 'Delete_Stock_Overrides_config',
    VIEW_SKU_META_BULK_UPDATE_JOBS: 'View_SKU_Meta_Bulk_Update_Jobs',
    UPLOAD_SKU_META_JOB: 'Upload_SKU_Meta_Job',
  },
  marketplace: {
    VIEW_SELLERS: 'View_Sellers',
    ADD_SELLER: 'Add_Seller',
    EDIT_SELLER: 'Edit_Seller',
    DOWNLOAD_SELLER_PICKLIST: 'Download_Seller_Picklist',
  },
  logistics: {
    VIEW_VEHICLES: 'View_Vehicles',
    ADD_VEHICLE: 'Add_Vehicle',
    EDIT_VEHICLE: 'Edit_Vehicle',
    VIEW_VEHICLE_VENDORS: 'View_Vehicle_Vendors',
    ADD_VEHICLE_VENDOR: 'Add_Vehicle_Vendor',
    EDIT_VEHICLE_VENDOR: 'Edit_Vehicle_Vendor',
    VIEW_VEHICLE_PLANNING: 'View_Vehicle_Planning',
    ADD_VEHICLE_PLANNING: 'Add_Vehicle_Planning',
    DELETE_VEHICLE_PLANNING: 'Delete_Vehicle_Planning',
    EDIT_VEHICLE_PLANNING: 'Edit_Vehicle_Planning',
    ADD_VEHICLE_TO_SHIFT: 'Add_Vehicle_To_Shift',
    UPDATE_VEHICLE_IN_SHIFT: 'Update_Vehicle_In_Shift',
    VIEW_TRIPS: 'View_Trips',
    ADD_TRIP: 'Add_Trip',
    VIEW_TRIP_DETAIL: 'View_Trip_Detail',
    EDIT_TRIP: 'Edit_Trip',
    VIEW_TRIP_PLANNERS: 'View_Trip_Planners',
  },
  hr: {
    VIEW_EMPLOYEE: 'View_Employee',
    ADD_EMPLOYEE: 'Add_Employee',
    EDIT_EMPLOYEE: 'Edit_Employee',
    DELETE_EMPLOYEE: 'Delete_Employee',
    VIEW_DESIGNATION: 'View_Designation',
    ADD_DESIGNATION: 'Add_Designation',
    EDIT_DESIGNATION: 'Edit_Designation',
    DELETE_DESIGNATION: 'Delete_Designation',
    VIEW_ATTENDANCE_SUMMARY: 'View_Attendance_Summary',
  },
  marketing: {
    VIEW_PAGES: 'View_Pages',
    EDIT_PAGE: 'Edit_Page',
    VIEW_OFFERS: 'View_Offers',
    EDIT_OFFER: 'Edit_Offer',
    ADD_OG_OFFER: 'Add_Og_Offer',
    ADD_PAY_VIA_FP_OFFER: 'Add_Pay_Via_FP_Offer',
    VIEW_BANNERS: 'View_Banners',
    EDIT_BANNER: 'Edit_Banner',
    ADD_BANNER: 'Add_Banner',
    DELETE_BANNER: 'Delete_Banner',
    VIEW_MARKETING_CAMPAIGNS: 'View_Marketing_Campaigns',
    EDIT_MARKETING_CAMPAIGN: 'Edit_Marketing_Campaign',
    ADD_MARKETING_CAMPAIGN: 'Add_Marketing_Campaign',
    DELETE_MARKETING_CAMPAIGN: 'Delete_Marketing_Campaign',
    VIEW_EVOUCHERS: 'View_Evouchers',
    VIEW_EVOUCHER_DETAIL: 'View_Evoucher_Detail',
    EDIT_EVOUCHER: 'Edit_Evoucher',
    RESEND_EVOUCHER_EMAIL: 'Resend_Evoucher_Email',
    ADD_EVOUCHER: 'Add_Evoucher',
    DELETE_EVOUCHER: 'Delete_Evoucher',
    VIEW_LUCKY_DRAWS: 'View_Lucky_Draws',
    VIEW_LUCKY_DRAW_DETAIL: 'View_Lucky_Draw_Detail',
    ADD_LUCKY_DRAW: 'Add_Lucky_Draw',
    EDIT_LUCKY_DRAW: 'Edit_Lucky_Draw',
    VIEW_RECOMMENDERS: 'View_Recommenders',
    ADD_RECOMMENDER: 'Add_Recommender',
    SAVE_RECOMMENDER_CONFIG: 'Save_Recommender_Config',
    LOAD_RECOMMENDER_CONFIG: 'Load_recommender_Config',
  },
  customer_support: {
    SEARCH_CUSTOMER_INFO: 'Search_Customer_Info',
    VIEW_CUSTOMER_DETAIL: 'View_Customer_Detail',
  },
  settings: {
    VIEW_STORES: 'View_Stores',
    ADD_STORES: 'Add_Store',
    EDIT_STORES: 'Edit_Store',
    VIEW_ORDER_PROCESS_SETTING: 'View_Order_Process_Setting',
    EDIT_ORDER_PROCESS_SETTING: 'Edit_Order_Process_Setting',
    VIEW_DELIVERY_AREA: 'View_Delivery_Area',
    ADD_DELIVERY_AREA: 'Add_Delivery_Area',
    EDIT_DELIVERY_AREA: 'Edit_Delivery_Area',
    DELETE_DELIVERY_AREA: 'Delete_Delivery_Area',
    VIEW_LOGISTICS_SETTINGS: 'View_Logistics_Settings',
    EDIT_LOGISTIC_SETTING: 'Edit_Logistic_Setting',
    ADD_DELIVERY_FEE_CONFIG: 'Add_Delivery_Fee_Config',
    EDIT_DELIVERY_FEE_CONFIG: 'Edit_Delivery_Fee_Config',
    DELETE_DELIVERT_FEE_CONFIG: 'Delete_Delivery_Fee_Config',
    VIEW_SEO: 'View_SEO',
    EDIT_SEO: 'Edit_SEO',
    VIEW_CUSTOM_FIELDS: 'View_Custom_Fields',
    EDIT_CUSTOM_FIELDS: 'Edit_Custom_Fields',
    VIEW_ORDER_SLOTS: 'View_Order_Slots',
    ADD_ORDER_SLOTS: 'Add_Order_Slots',
    VIEW_CUSTOMER_TAGS: 'View_Customer_Tags',
    ADD_CUSTOMER_TAGS: 'Add_Customer_Tags',
    EDIT_CUSTOMER_TAG: 'Edit_Customer_Tag',
    DELETE_CUSTOMER_TAG: 'Delete_Customer_Tag',
    VIEW_SEARCH_OVERRIDES: 'View_Search_Overrides',
    EDIT_SEARCH_OVERRIDE: 'Edit_Search_Override',
    DELETE_SEARCH_OVERRIDE: 'Delete_Search_Override',
    ADD_SEARCH_OVERRIDE: 'Add_Search_Override',
    VIEW_THEME_SETTING_HOME: 'View_Theme_Setting_HomePage',
    VIEW_THEME_SETTING_CATEGORY: 'View_Theme_Setting_CategoryPage',
    VIEW_THEME_SETTING_PROMOTION: 'View_Theme_Setting_PromotionPage',
    VIEW_THEME_SETTING_TAG: 'View_Theme_Setting_TagPage',
    VIEW_THEME_SETTING_BRAND: 'View_Theme_Setting_BrandPage',
    VIEW_THEME_SETTING_STORE: 'View_Theme_Setting_StorePage',
    VIEW_THEME_SETTING_SEARCH: 'View_Theme_Setting_SearchPage',
    UPDATE_THEME_SETTING_HOME: 'Update_Theme_Setting_HomePage',
    UPDATE_THEME_SETTING_CATEGORY: 'Update_Theme_Setting_CategoryPage',
    UPDATE_THEME_SETTING_PROMOTION: 'Update_Theme_Setting_PromotionPage',
    UPDATE_THEME_SETTING_TAG: 'Update_Theme_Setting_TagPage',
    UPDATE_THEME_SETTING_BRAND: 'Update_Theme_Setting_BrandPage',
    UPDATE_THEME_SETTING_STORE: 'Update_Theme_Setting_StorePage',
    UPDATE_THEME_SETTING_SEARCH: 'Update_Theme_Setting_SearchPage',
    DELETE_THEME_SETTING_HOME: 'Delete_Theme_Setting_HomePage',
    DELETE_THEME_SETTING_CATEGORY: 'Delete_Theme_Setting_CategoryPage',
    DELETE_THEME_SETTING_PROMOTION: 'Delete_Theme_Setting_PromotionPage',
    DELETE_THEME_SETTING_TAG: 'Delete_Theme_Setting_TagPage',
    DELETE_THEME_SETTING_BRAND: 'Delete_Theme_Setting_BrandPage',
    DELETE_THEME_SETTING_STORE: 'Delete_Theme_Setting_StorePage',
    DELETE_THEME_SETTING_SEARCH: 'Delete_Theme_Setting_SearchPage',
  },
  recipe: {
    VIEW_RECIPES: 'View_Recipes',
    UPLOAD_BULK_RECIPES: 'Upload_Bulk_Recipes',
  },
  qc: {
    VIEW_QC_ORDERS: 'View_QC_Orders',
    VIEW_QV_ORDER_DETAIL: 'View_QC_Order_Detail',
  },
  'retail-media': {},
  rewards: {
    VIEW_CHALLENGES: 'View_Challenges',
    VIEW_CHALLENGE_DETAIL: 'View_Challenge_Detail',
    EDIT_CHALLENGE: 'Edit_Challenge',
    CLONE_CHALLENGE: 'Clone_Challenge',
    ADD_CHALLENGE: 'Add_Challenges',
    DISABLE_CHALLENGE: 'Disable_Challenges',
    VIEW_REWARD_CATEGORIES: 'View_Reward_Categories',
    VIEW_REWARD_CATEGORY_DETAIL: 'View_Reward_Categories_Detail',
    ADD_REWARD_CATEGORY: 'Add_Reward_Category',
    EDIT_REWARD_CATEGORY: 'Edit_Reward_Category',
    VIEW_REWARD_PARTNERS: 'View_Reward_Partners',
    VIEW_REWARD_PARTNER_DETAIL: 'View_Reward_Partner_Detail',
    ADD_REWARD_PARTNER: 'Add_Reward_Partner',
    EDIT_REWARD_PARTNER: 'Edit_Reward_Partner',
    VIEW_REWARD_PROMOTIONS: 'View_Reward_Promotions',
    VIEW_REWARD_PROMOTION_DETAIL: 'View_Reward_Promotion_Detail',
    ADD_REWARD_PROMOTION: 'Add_Reward_Promotion',
    EDIT_REWARD_PROMOTION: 'Edit_Reward_Promotion',
    VIEW_REWARD_MAINTENANCES: 'View_Reward_Maintenance',
    VIEW_REWARD_MAINTENANCE_DETAIL: 'View_Reward_Maintenance_Detail',
    ADD_REWARD_MAINTENANCE: 'Add_Reward_Maintenance',
    EDIT_REWARD_MAINTENANCE: 'Edit_Reward_Maintenance',
    VIEW_REWARD_CAROUSELS: 'View_Reward_Carousels',
    VIEW_REWARD_CAROUSEL_DETAIL: 'View_Reward_Carousel_Detail',
    ADD_REWARD_CAROUSEL: 'Add_Reward_Carousel',
    EDIT_REWARD_CAROUSEL: 'Edit_Reward_Carousel',
    VIEW_REWARD_STORES: 'View_Reward_Stores',
    VIEW_REWARD_STORE_DETAIL: 'View_Reward_Store_Detail',
    ADD_REWARD_STORE: 'Add_Reward_Store',
    BULK_ADD_REWARD_STORE: 'Bulk_Add_Reward_Store',
    EDIT_REWARD_STORE: 'Edit_Reward_Store',
    VIEW_REWARD_CATALOGUES: 'View_Reward_Catalogues',
    VIEW_REWARD_CATALOGUE_DETAIL: 'View_Reward_Catalogue_Detail',
    EDIT_REWARD_CATALOGUE: 'Edit_Reward_Catalogue',
    ADD_REWARD_CATALOGUE: 'Add_Reward_Catalogue',
    VIEW_REWARD_MERCHANTS: 'View_Reward_merchants',
    VIEW_REWARD_MERCHANT_DETAIL: 'View_Reward_merchant_Detail',
    ADD_REWARD_MERCHANT: 'Add_Reward_Merchant',
    EDIT_REWARD_MERCHANT: 'Edit_Reward_Merchant',
    VIEW_REWARD_DEVICES: 'View_Reward_devices',
    VIEW_REWARD_DEVICE_DETAIL: 'View_Reward_device_Detail',
    ADD_REWARD_DEVICE: 'Add_Reward_Device',
    EDIT_REWARD_DEVICE: 'Edit_Reward_Device',
  },
  admin: {
    VIEW_ACTION_TRAIL_LISTING: 'View_Action_Trail_Listing',
  },
  giftcard: {
    VIEW_DESIGN_CODES: 'View_Design_Codes',
  },
  'developer-options': {},
}

/**
 * This mapping use the endpoint path as the key to
 * find the matching Activitytype more faster
 */
const ActivityMaps = {
  '/seller-service/sellers': {
    get: ActivityTypes.marketplace.VIEW_SELLERS,
    post: ActivityTypes.marketplace.ADD_SELLER,
  },
  '/seller-service/sellers/.*': {
    put: ActivityTypes.marketplace.EDIT_SELLER,
  },
  '/seller-order-service/picklists': {
    get: ActivityTypes.DOWNLOAD_SELLER_PICKLIST,
  },
  '/recipe-service/recipe/.*': {
    get: ActivityTypes.recipe.VIEW_RECIPES,
    post: ActivityTypes.recipe.UPLOAD_BULK_RECIPES,
  },
  '/backoffice-9fgcialh.an.gateway.dev/v1/orders': {
    get: ActivityTypes.qc.VIEW_QC_ORDERS,
  },
  '/backoffice-9fgcialh.an.gateway.dev/v1/orders/.*': {
    get: ActivityTypes.qc.VIEW_QV_ORDER_DETAIL,
  },
  '/ef-customer-core/profile/.*': {
    get: ActivityTypes.customer_support.VIEW_CUSTOMER_DETAIL,
  },
  '/ef-customer-core/customers': {
    get: ActivityTypes.customer_support.SEARCH_CUSTOMER_INFO,
  },
  '/order-service/order-count': {
    get: ActivityTypes.operations.VIEW_OPERATION_DASHBOARD,
  },
  '/order-service/order': {
    get: ActivityTypes.operations.VIEW_ORDERS,
    post: ActivityTypes.operations.CREATE_ORDER,
  },
  '/order-service/order/.*': {
    get: ActivityTypes.operations.VIEW_ORDER_DETAIL,
  },
  '/order-service/orders/.*': {
    patch: ActivityTypes.operations.EDIT_ORDER_DETAIL,
    put: ActivityTypes.operations.EDIT_ORDER_DETAIL,
    post: ActivityTypes.operations.EDIT_ORDER_DETAIL,
  },
  '/order-service/order-log': {
    get: ActivityTypes.operations.VIEW_ORDER_LOG,
  },
  '/order-service/store/.*/slot': {
    get: ActivityTypes.operations.VIEW_STORE_SLOT,
    put: ActivityTypes.operations.EDIT_STORE_SLOT,
  },
  '/order-service/picking-sequence': {
    get: ActivityTypes.operations.VIEW_PICKING_SEQUENCE,
  },
  '/order-service/picking-sequence/.*': {
    post: ActivityTypes.operations.EDIT_PICKING_SEQUENCE,
  },
  '/account-service/extension/.*': {
    get: ActivityTypes.settings.VIEW_ORDER_PROCESS_SETTING,
    put: ActivityTypes.settings.EDIT_ORDER_PROCESS_SETTING,
  },
  '/logistics-service/delivery-area': {
    get: ActivityTypes.settings.VIEW_DELIVERY_AREA,
    post: ActivityTypes.settings.ADD_DELIVERY_AREA,
  },
  '/logistics-service/delivery-area/*': {
    put: ActivityTypes.settings.EDIT_DELIVERY_AREA,
    delete: ActivityTypes.settings.DELETE_DELIVERY_AREA,
  },
  '/account-service/config/logistics': {
    get: ActivityTypes.settings.VIEW_LOGISTICS_SETTINGS,
    put: ActivityTypes.settings.EDIT_LOGISTIC_SETTING,
  },
  '/account-service/shipping-tier': {
    post: ActivityTypes.settings.ADD_DELIVERY_FEE_CONFIG,
  },
  '/account-service/shipping-tier/.*': {
    put: ActivityTypes.settings.EDIT_DELIVERY_FEE_CONFIG,
    delete: ActivityTypes.settings.DELETE_DELIVERT_FEE_CONFIG,
  },
  '/ef-customer-core/tags': {
    get: ActivityTypes.settings.VIEW_CUSTOMER_TAGS,
    post: ActivityTypes.settings.ADD_CUSTOMER_TAGS,
  },
  '/ef-customer-core/tags/.*': {
    patch: ActivityTypes.settings.EDIT_CUSTOMER_TAG,
    delete: ActivityTypes.settings.DELETE_CUSTOMER_TAG,
  },
  '/catalogue-service/product-ranking': {
    get: ActivityTypes.settings.VIEW_SEARCH_OVERRIDES,
    post: ActivityTypes.settings.ADD_SEARCH_OVERRIDE,
  },
  '/catalogue-service/product-ranking/.*': {
    put: ActivityTypes.settings.EDIT_SEARCH_OVERRIDE,
    delete: ActivityTypes.settings.DELETE_SEARCH_OVERRIDE,
  },
  '/account-service/seo': {
    get: ActivityTypes.settings.VIEW_SEO,
  },
  '/account-service/seo/.*': {
    put: ActivityTypes.settings.EDIT_SEO,
  },
  '/website-service/layout$': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_STORE,
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_STORE,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_STORE,
  },
  '/website-service/layout/home/.*': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_HOME,
  },
  'website-service/layout/home': {
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_HOME,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_HOME,
  },
  '/website-service/layout/category/.*': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_CATEGORY,
  },
  '/website-service/layout/category': {
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_CATEGORY,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_CATEGORY,
  },
  '/website-service/layout/promotion/.*': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_PROMOTION,
  },
  '/website-service/layout/promotion': {
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_PROMOTION,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_PROMOTION,
  },
  '/website-service/layout/brand/.*': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_BRAND,
  },
  '/website-service/layout/brand': {
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_BRAND,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_BRAND,
  },
  '/website-service/layout/tag/.*': {
    get: ActivityTypes.settings.VIEW_THEME_SETTING_TAG,
  },
  '/website-service/layout/tag': {
    put: ActivityTypes.settings.UPDATE_THEME_SETTING_TAG,
    delete: ActivityTypes.settings.DELETE_THEME_SETTING_TAG,
  },
  '/website-service/layout/search/.*': {
    get: ActivityTypes.VIEW_THEME_SETTING_SEARCH,
  },
  '/website-service/layout/search': {
    put: ActivityTypes.UPDATE_THEME_SETTING_SEARCH,
    delete: ActivityTypes.DELETE_THEME_SETTING_SEARCH,
  },
  '/account/service/employee/.*': {
    put: ActivityTypes.hr.EDIT_EMPLOYEE,
  },
  '/account-service/employee': {
    get: ActivityTypes.operations.VIEW_PICKERS,
    delete: ActivityTypes.hr.DELETE_EMPLOYEE,
  },
  '/account-service/attendance': {
    post: ActivityTypes.operations.CHECKIN_PICKER,
  },
  '/account-service/attendance/.*': {
    put: ActivityTypes.operations.CHECKOUT_PICKER,
  },
  '/account-service/attendance-summary': {
    get: ActivityTypes.hr.VIEW_ATTENDANCE_SUMMARY,
  },
  '/account-service/employee/.*': {
    put: ActivityTypes.operations.EDIT_PICKER,
  },
  '/account-service/designation': {
    post: ActivityTypes.hr.ADD_DESIGNATION,
  },
  '/account-service/designation/.*': {
    put: ActivityTypes.hr.EDIT_DESIGNATION,
    delete: ActivityTypes.hr.DELETE_DESIGNATION,
  },
  '/logistics-service/zone-capacity': {
    get: ActivityTypes.operations.VIEW_CAPACITY_PLANNING,
    post: ActivityTypes.operations.ADD_CAPACITY_PLANNING_RULE,
    put: ActivityTypes.operations.EDIT_TODAY_CAPACITY_PLANNING_RULE,
  },
  '/logistics-service/vehicle': {
    get: ActivityTypes.logistics.VIEW_VEHICLES,
    post: ActivityTypes.logistics.ADD_VEHICLE,
  },
  '/logistics-service/vehicle/.*': {
    put: ActivityTypes.logistics.EDIT_VEHICLE,
  },
  '/logistics-service/vehicle-vendor': {
    get: ActivityTypes.logistics.VIEW_VEHICLE_VENDORS,
    post: ActivityTypes.logistics.ADD_VEHICLE_VENDOR,
  },
  '/logistics-service/vehicle-vendor/.*': {
    put: ActivityTypes.logistics.EDIT_VEHICLE_VENDOR,
  },
  '/logistics-service/vehicle-planner': {
    get: ActivityTypes.logistics.VIEW_VEHICLE_PLANNING,
    post: ActivityTypes.logistics.ADD_VEHICLE_PLANNING,
  },
  '/logistics-service/vehicle-planner/.*': {
    delete: ActivityTypes.logistics.DELETE_VEHICLE_PLANNING,
    put: ActivityTypes.logistics.EDIT_VEHICLE_PLANNING,
  },
  '/logistics-service/vehicle-attendance': {
    get: ActivityTypes.logistics.VIEW_VEHICLE_PLANNING,
    post: ActivityTypes.logistics.ADD_VEHICLE_TO_SHIFT,
    put: ActivityTypes.logistics.UPDATE_VEHICLE_IN_SHIFT,
  },
  '/logistics-service/trip': {
    get: ActivityTypes.logistics.VIEW_TRIPS,
    post: ActivityTypes.logistics.ADD_TRIP,
  },
  '/logistics-service/trip/.*': {
    get: ActivityTypes.logistics.VIEW_TRIP_DETAIL,
    put: ActivityTypes.logistics.EDIT_TRIP,
  },
  '/logistics-service/zone-capacity/.*': {
    delete: ActivityTypes.operations.DELETE_CAPACITY_PLANNING_RULE,
    put: ActivityTypes.operations.EDIT_CAPACITY_PLANNING_RULE,
  },
  'logistics-management-service/v1/rules': {
    post: ActivityTypes.operations.CREATE_TIME_SLOT_BLOCKING_RULE,
  },
  'logistics-management-service/v1/rules/.*': {
    patch: ActivityTypes.operations.EDIT_TIME_SLOT_BLOCKING_RULE,
    delete: ActivityTypes.operations.DELETE_TIME_SLOT_BLOCKING_RULE,
  },
  '/order-service/slot-charge': {
    get: ActivityTypes.operations.VIEW_ORDER_SLOT_CHARGES,
  },
  '/order-service/slot': {
    get: ActivityTypes.settings.VIEW_ORDER_SLOTS,
    post: ActivityTypes.settings.ADD_ORDER_SLOTS,
  },
  '/account-service/store': {
    get: ActivityTypes.settings.VIEW_STORES,
    post: ActivityTypes.settings.ADD_STORES,
  },
  '/account-service/store/.*': {
    put: ActivityTypes.operations.EDIT_BATCH_PICKING_QUEUES,
  },
  '/account-service/static-page': {
    get: ActivityTypes.marketing.VIEW_PAGES,
  },
  '/account-service/static-page/.*': {
    put: ActivityTypes.marketing.EDIT_PAGE,
  },
  '/batches': {
    get: ActivityTypes.marketing.VIEW_EVOUCHERS,
    post: ActivityTypes.marketing.ADD_EVOUCHER,
  },
  '/batches/.*': {
    get: ActivityTypes.marketing.VIEW_EVOUCHER_DETAIL,
    patch: ActivityTypes.marketing.EDIT_EVOUCHER,
    delete: ActivityTypes.marketing.DELETE_EVOUCHER,
  },
  '/batches/.*/issue': {
    post: ActivityTypes.marketing.RESEND_EVOUCHER_EMAIL,
  },
  '/designcode': {
    get: ActivityTypes.giftcard.VIEW_DESIGN_CODES,
  },
  'offer-service/offer': {
    get: ActivityTypes.marketing.VIEW_OFFERS,
    post: ActivityTypes.marketing.ADD_OG_OFFER,
  },
  'offer-service/offer/.*': {
    put: ActivityTypes.marketing.EDIT_OFFER,
  },
  'recommender-service/orchid/v1/contract': {
    get: ActivityTypes.marketing.VIEW_RECOMMENDERS,
  },
  'recommender-service/orchid/v1/recommender': {
    post: ActivityTypes.marketing.ADD_RECOMMENDER,
  },
  'recommender-service/orchid/v2/config/.*': {
    get: ActivityTypes.marketing.LOAD_RECOMMENDER_CONFIG,
    put: ActivityTypes.marketing.SAVE_RECOMMENDER_CONFIG,
  },
  'duck/campaigns': {
    get: ActivityTypes.marketing.VIEW_LUCKY_DRAWS,
    post: ActivityTypes.marketing.ADD_LUCKY_DRAW,
  },
  'duck/campaigns/.*': {
    get: ActivityTypes.marketing.VIEW_LUCKY_DRAW_DETAIL,
    patch: ActivityTypes.marketing.EDIT_LUCKY_DRAW,
  },
  '/order-service/v3/search/deliveryOrders': {
    get: ActivityTypes.operations.VIEW_DELIVERY_ORDERS,
  },
  '^\\/order-service\\/v3\\/deliveryOrders\\/\\d+\\/cancel$': {
    post: ActivityTypes.operations.CANCEL_DELIVERY_ORDER,
  },
  '^\\/order-service\\/v3\\/deliveryOrders\\/\\d+\\/pack$': {
    post: ActivityTypes.operations.PACK_DELIVERY_ORDER,
  },
  '^\\/order-service\\/v3\\/deliveryOrders\\/\\d+$': {
    put: ActivityTypes.operations.EDIT_DELIVERY_ORDER_SLOT,
  },
  '^\\/order-service\\/v3\\/deliveryOrders\\/\\d+\\/complete$': {
    post: ActivityTypes.operations.COMPLETE_DELIVERY_ORDER,
  },
  '^\\/order-service\\/order\\/\\d+\\/returns$': {
    post: ActivityTypes.operations.RETURN_DELIVERY_ORDER,
  },
  '/catalogue-service/stats': {
    get: ActivityTypes.catalogue.VIEW_CAT_DASHBOARD,
  },
  '/catalogue-service/brand': {
    get: ActivityTypes.catalogue.VIEW_BRANDS,
    post: ActivityTypes.catalogue.ADD_BRAND,
  },
  '/catalogue-service/brand/.*': {
    put: ActivityTypes.catalogue.EDIT_BRAND,
    delete: ActivityTypes.catalogue.DELETE_BRAND,
  },
  '/catalogue-service/category': {
    get: ActivityTypes.catalogue.VIEW_CATEGORIES,
    post: ActivityTypes.catalogue.ADD_CATEGORY,
  },
  '/catalogue-service/category/.*': {
    put: ActivityTypes.catalogue.EDIT_CATEGORY,
    delete: ActivityTypes.catalogue.DELETE_CATEGORY,
  },
  '/catalogue-service/product': {
    get: ActivityTypes.catalogue.VIEW_PRODUCTS,
    post: ActivityTypes.catalogue.ADD_SINGLE_PRODUCT,
  },
  '/catalogue-service/product/.*': {
    put: ActivityTypes.catalogue.EDIT_SINGLE_PRODUCT,
    delete: ActivityTypes.catalogue.DELETE_SINGLE_PRODUCT,
  },
  '/catalogue-service/batch-jobs': {
    get: ActivityTypes.catalogue.VIEW_BATCH_JOBS,
  },
  '/catalogue-service/batch-jobs/.*': {
    get: ActivityTypes.catalogue.DOWNLOAD_BULK_UPLOAD,
  },
  '/catalogue-service/tag': {
    get: ActivityTypes.catalogue.VIEW_TAGS,
    post: ActivityTypes.catalogue.ADD_TAG,
  },
  '/catalogue-service/tag/.*': {
    put: ActivityTypes.catalogue.EDIT_TAG,
    delete: ActivityTypes.catalogue.DELETE_TAG,
  },
  '/catalogue-service/product-tag-file/': {
    post: ActivityTypes.catalogue.IMPORT_TAGS,
  },
  '/catalogue-service/substitution-group': {
    get: ActivityTypes.catalogue.VIEW_SUBSTITUTION_GROUPS,
    post: ActivityTypes.catalogue.ADD_SUBSTITUTION_GROUPS,
  },
  '/catalogue-service/substitution-group/.*': {
    put: ActivityTypes.catalogue.EDIT_SUBSTITUTION_GROUPS,
    delete: ActivityTypes.catalogue.DELETE_SUBSTITUTION_GROUPS,
  },
  '/catalogue-service/stock-override': {
    get: ActivityTypes.catalogue.VIEW_STOCK_OVERRIDES,
    put: ActivityTypes.catalogue.SUBMIT_STOCK_OVERRIDES,
  },
  '/catalogue-service/stock-override/X': {
    put: ActivityTypes.catalogue.EDIT_STOCK_OVR_CONFIG,
    delete: ActivityTypes.catalogue.DELETE_STOCK_OVR_CONFIG,
  },
  '/chendol/challenges': {
    get: ActivityTypes.rewards.VIEW_CHALLENGES,
    post: ActivityTypes.rewards.ADD_CHALLENGE,
  },
  '/chendol/challenges/.*': {
    get: ActivityTypes.rewards.VIEW_CHALLENGE_DETAIL,
    patch: ActivityTypes.rewards.EDIT_CHALLENGE,
  },
  'fp-search-service/campaigns': {
    get: ActivityTypes.marketing.VIEW_MARKETING_CAMPAIGNS,
    post: ActivityTypes.marketing.ADD_MARKETING_CAMPAIGN,
  },
  'fp-search-service/campaigns/.*': {
    put: ActivityTypes.marketing.EDIT_MARKETING_CAMPAIGN,
    delete: ActivityTypes.marketing.DELETE_MARKETING_CAMPAIGN,
  },
  'search-service/campaigns': {
    get: ActivityTypes.marketing.VIEW_MARKETING_CAMPAIGNS,
    post: ActivityTypes.marketing.ADD_MARKETING_CAMPAIGN,
  },
  'search-service/campaigns/.*': {
    put: ActivityTypes.marketing.EDIT_MARKETING_CAMPAIGN,
    delete: ActivityTypes.marketing.DELETE_MARKETING_CAMPAIGN,
  },
  'promo-service/banner': {
    get: ActivityTypes.marketing.VIEW_BANNERS,
    post: ActivityTypes.marketing.ADD_BANNER,
  },
  'promo-service/banner/.*': {
    put: ActivityTypes.marketing.EDIT_BANNER,
    delete: ActivityTypes.marketing.DELETE_BANNER,
  },
  'rms/categories': {
    get: ActivityTypes.rewards.VIEW_REWARD_CATEGORIES,
    post: ActivityTypes.rewards.ADD_REWARD_CATEGORY,
  },
  'rms/categories/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_CATEGORY_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_CATEGORY,
  },
  'rms/partners': {
    get: ActivityTypes.rewards.VIEW_REWARD_PARTNERS,
    post: ActivityTypes.rewards.ADD_REWARD_PARTNER,
  },
  'rms/partners/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_PARTNER_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_PARTNER,
  },
  'rms/promotions': {
    get: ActivityTypes.rewards.VIEW_REWARD_PROMOTIONS,
    post: ActivityTypes.rewards.ADD_REWARD_PROMOTION,
  },
  'rms/promotions/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_PROMOTION_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_PROMOTION,
  },
  'rms/maintenance': {
    get: ActivityTypes.rewards.VIEW_REWARD_MAINTENANCES,
    post: ActivityTypes.rewards.ADD_REWARD_MAINTENANCE,
  },
  'rms/maintenance/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_MAINTENANCE_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_MAINTENANCE,
  },
  'rms/carousels': {
    get: ActivityTypes.rewards.VIEW_REWARD_CAROUSELS,
    post: ActivityTypes.rewards.ADD_REWARD_CAROUSEL,
  },
  'rms/carousels/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_CAROUSEL_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_CAROUSEL,
  },
  'rms/stores': {
    get: ActivityTypes.rewards.VIEW_REWARD_STORES,
    post: ActivityTypes.rewards.ADD_REWARD_STORE,
  },
  'rms/stores/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_STORE_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_STORE,
  },
  'rms/v2/rewards': {
    get: ActivityTypes.rewards.VIEW_REWARD_CATALOGUES,
    post: ActivityTypes.rewards.ADD_REWARD_CATALOGUE,
  },
  'rms/v2/rewards/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_CATALOGUE_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_CATALOGUE,
  },
  'admin/merchants': {
    get: ActivityTypes.rewards.VIEW_REWARD_MERCHANTS,
    post: ActivityTypes.rewards.ADD_REWARD_MERCHANT,
  },
  'admin/merchants/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_MERCHANT_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_MERCHANT,
  },
  'admin/devices': {
    get: ActivityTypes.rewards.VIEW_REWARD_DEVICES,
    post: ActivityTypes.rewards.ADD_REWARD_DEVICE,
  },
  'admin/devices/.*': {
    get: ActivityTypes.rewards.VIEW_REWARD_DEVICE_DETAIL,
    patch: ActivityTypes.rewards.EDIT_REWARD_DEVICE,
  },
  '/inventory-service/product-meta-update': {
    get: ActivityTypes.catalogue.VIEW_SKU_META_BULK_UPDATE_JOBS,
    put: ActivityTypes.catalogue.UPLOAD_SKU_META_JOB,
  },
  '/actions-trail': {
    get: ActivityTypes.admin.VIEW_ACTION_TRAIL_LISTING,
  },
}

export { ActivityTypes, ActivityMaps }
