import React, { Component } from 'react'
import { Popup } from '../../../Popup'
import API from '../../../../lib/api'
import imageCompression from 'browser-image-compression'
import './style.css'

function getValidationObj(props, image) {
  const valueMissing = props.required && !image
  return {
    valueMissing,
    valid: !valueMissing,
  }
}

class Upload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showImagePreviewModal: false,
      isJSONFile: false,
      fileUploading: false,
      file: '',
      fileBlob: '',
      showError: false,
      errorMessage: '',
    }
    this.readURL = this.readURL.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.confirmFileUpload = this.confirmFileUpload.bind(this)
    this.runValidation = this.runValidation.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.generateState = this.generateState.bind(this)
  }

  runValidation(image) {
    this.props.onValidation &&
      this.props.onValidation(getValidationObj(this.props, image))
  }

  componentDidMount() {
    this.runValidation(this.props.value)
  }

  componentDidUpdate(prevProps) {
    prevProps.required !== this.props.required &&
      this.runValidation(this.inputRef)
  }

  handleBlur(e) {
    e.preventDefault()
    this.props.onBlur && this.props.onBlur()
  }

  async generateState(e, file) {
    const isJSONFile = file.type === 'application/json'
    const compressedFile = isJSONFile
      ? file
      : await imageCompression(file, { maxSizeMB: 1 })
    const state = {
      size: `${Math.ceil(compressedFile.size / 1024)} KB`,
      showImagePreviewModal: true,
      fileBlob: compressedFile,
      file: isJSONFile ? null : file,
      compressedDisplay: isJSONFile
        ? null
        : URL.createObjectURL(compressedFile),
      isJSONFile: isJSONFile ? true : false,
    }
    return { state }
  }

  async readURL(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      try {
        const { state } = await this.generateState(e, file)
        if (
          file.size > this.props.maxFileSize ||
          !this.props.accept.split(',').includes(file.type)
        ) {
          state['showError'] = true
          state['errorMessage'] =
            file.size > this.props.maxFileSize
              ? this.props.validationStrings.fileSizeExeeded
              : this.props.validationStrings.invalidFileType
        } else {
          state['showError'] = false
        }
        this.setState(state)
      } catch (err) {
        console.log('Failed to compress file', err)
        const state = {}
        state['showError'] = true
        state['errorMessage'] =
          file.size > this.props.maxFileSize
            ? this.props.validationStrings.fileSizeExeeded
            : this.props.validationStrings.invalidFileType
        this.setState(state)
      }
    }
  }

  handleClick() {
    this.uploadInputRef.value = null
  }

  handleModalClose() {
    this.setState({
      showImagePreviewModal: false,
      showError: false,
    })
  }

  confirmFileUpload(e) {
    e.preventDefault()
    this.setState({
      fileUploading: true,
    })
    const file = this.state.fileBlob
    const data = new window.FormData()
    data.append('imageUpload', file, file.name)
    data.append('cache', '1y')
    if (this.props.resize === false) {
      data.append('resize', false)
    }
    const api = new API({ url: '/media-service/v2/image-upload' })
    api.post(data).then(
      (response) => {
        const url = this.state.isJSONFile
          ? response.data.file.url
          : response.data.imageUpload.url
        this.props.onChange(url)
        this.setState(
          {
            showImagePreviewModal: false,
            showError: false,
            fileUploading: false,
          },
          this.props.verifyDimension
        )
        this.runValidation(url)
      },
      (error) => {
        if (error.code === 401) {
          throw error
        }
        this.setState({
          showError: true,
          errorMessage: error.message,
          fileUploading: false,
        })
      }
    )
  }

  render() {
    const {
      name,
      required,
      placeholder,
      value,
      value: imageUrl,
      accept,
      disabled
    } = this.props
    const { compressedDisplay } = this.state
    return (
      <div className="Upload">
        <Popup
          heading="Confirm Optimise Image"
          show={this.state.showImagePreviewModal}
          close={this.handleModalClose}
        >
          {this.state.showError && (
            <div className="imageModal form-error">
              {this.state.errorMessage}
            </div>
          )}
          <img
            className="image-preview-popup"
            id={name}
            src={compressedDisplay}
            alt=""
          />
          <div className="actions">
            <button
              className="button"
              onClick={this.handleModalClose}
              disabled={this.state.fileUploading}
            >
              Close
            </button>
            <div>
              Size: <b>{this.state.size}</b>
            </div>
            <button
              className="primary button"
              onClick={this.confirmFileUpload}
              disabled={this.state.fileUploading || this.state.showError}
            >
              {this.state.fileUploading ? '...' : 'Confirm'}
            </button>
          </div>
        </Popup>
        <div className="input">
          <input
            title={value}
            type="file"
            id={name}
            name={name}
            required={required}
            onChange={this.readURL}
            accept={accept}
            disabled={disabled}
            ref={(node) => {
              this.uploadInputRef = node
            }}
            onClick={this.handleClick}
          />
          <div className="image-upload-div">
            {imageUrl ? (
              <img id={name} src={imageUrl + '?w=60%60'} alt="" />
            ) : (
              <div className="file-label">
                {placeholder || 'Click here to upload or drag your logo'}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Upload.defaultProps = {
  accept: 'image/jpeg,image/jpg,image/png',
  maxFileSize: '20971520',
}

export default Upload
