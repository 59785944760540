import React from 'react'
import { Select } from 'components/Form'
import { StoresContext } from 'pages/omni-journey/ManageScanToWin'
import { getMessage } from 'lib/translator'

const FilterStore = ({ generateStateMappers }) => {
  const { stores } = React.useContext(StoresContext)
  return (
    <Select
      name="store-filter"
      className="store-filter"
      label={getMessage('omni-journey.filter.store.label')}
      placeholder={getMessage('omni-journey.filter.store.placeholder')}
      options={stores}
      {...generateStateMappers({
        stateKeys: ['key'],
        loseEmphasisOnFill: true,
      })}
    />
  )
}

export default FilterStore
