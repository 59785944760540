import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../../../components/Loader'
import { getMessage } from '../../../../lib/translator'
import {
  getSession
} from '../../../../lib/auth'
import { filterAddressByOrderType, getAmendmentItemsFromPacklists } from './helpers.utils';
import API from '../../../../lib/api'
import AuthenticatedPage from '../../../../containers/AuthenticatedPage'
import ShowDeliveryOrderDetails from './ShowDeliveryOrderDetails'
import OrderActions from './OrderActions'
import Dialog from 'components/Popup/Dialog'
import './style.css'

function getMobileView() {
  return window.screen.width <= 480
}

export default class DeliveryOrderDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: false,
      failed: false,
      isMobileView: getMobileView(),
      packlistData: null,
      deliveryNoteData: null,
      communicationConfig: null,
      orderConfig: null,
      error: null,
      salesOrderType: null,
      errorDialog: null
    }
    this.getMobileView = this.getMobileView.bind(this)
    this.organization = getSession().organization
    this.getData = this.getData.bind(this)
    this.getPacklistData = this.getPacklistData.bind(this)
  }

  getMobileView() {
    this.setState({ isMobileView:  window.screen.width <= 480 })
  }

  onError(error) {
    if (error && error.message) {
      this.setState({
        error: error.message
          .split(':')
          .slice(1)
          .join(':'),
      })
    }
    throw error
  }

  getOrderDetailsApi() {
    return this.orderDetailsApi
    .get({})
    .then(
      response => {
        const deliveryNote = {
          referenceNumber: response.deliveryOrder.referenceNumber,
          customerNote: response.deliveryOrder.customerInstruction,
          dispatcherNote: response.deliveryOrder.dispatcherInstruction,
          deliveryNotes: response.deliveryOrder.deliveryNotes
        }
        const deliveryOrder = filterAddressByOrderType(response);
        this.setState({
          failed: false,
          data: deliveryOrder,
          deliveryNoteData: deliveryNote,
          salesOrderType: response.salesOrder?.type
        })
      },
      error => {
        this.setState({ failed: true })
        if (error.code === 401) {
          throw error
        }
      }
    )
  }

  getPacklistAPI() {
    return this.packlistApi
    .get({})
    .then(
      response => {
        const packlistsWithAmendmentItems = getAmendmentItemsFromPacklists(response.packlists);
        this.setState({
          failed: false,
          packlistData: packlistsWithAmendmentItems,
        })
      }).catch(error => {
        this.setState({
          errorDialog: {
            title: error?.message || getMessage('order.details.packlist.fetch.error.title'),
            message: getMessage('order.details.packlist.fetch.error.message')
          }
        })
      })
  }

  getData() {
    const referenceNumber = this.props.router.match.params.id
    this.orderDetailsApi = new API({
      url: `/order-service/v3/deliveryOrders/${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getOrderDetailsApi()
          .then(() => {
            this.setState({ loading: false })
          })
      }
    )
  }

  getPacklistData() {
    const referenceNumber = this.props.router.match.params.id
    this.packlistApi = new API({
      url: `/picking-service/v3/packlists?deliveryOrderReferenceNumber=${referenceNumber}`,
    })
    this.setState(
      {
        loading: true,
        failed: false,
      },
      () => {
        this.getPacklistAPI()
          .then(() => {
            this.setState({ loading: false })
          })
      }
    )
  }

  getCommunicationConfig() {
    this.communicationApi = new API({ url: `/account-service/config` })
    this.communicationApi
      .get()
      .then(response => {
        this.setState({
          communicationConfig: response.data.config.communication,
          orderConfig: response.data.config.order,
        })
      })
      .catch(error => {
        throw error
      })
  }

  async componentDidMount() {
    this.getData()
    this.getPacklistData()
    this.getCommunicationConfig()
    window.addEventListener('resize', this.mobileView, false)
  }

  componentWillUnmount() {
    this.orderDetailsApi && this.orderDetailsApi.cancel()
    this.communicationApi && this.communicationApi.cancel()
    window.removeEventListener('resize', this.mobileView, false)
  }

  render() {
    const {isMobileView, isOrderViewItemCollapsed, isDeliveryNoteCollapsed} = this.state
    const referenceNumber = this.props.router.match.params.id
    const contentLoaded = !this.state.loading && !this.state.failed &&
                          this.state.data && this.state.packlistData

    return (
      <AuthenticatedPage
        storeDependent={this.props.url === '/operations'}
        className="delivery-order-details"
        menu={this.props.menu}
      >
        <div className="flex-around">
          <h1 className="title heading">
            { ( this.state.salesOrderType === 'SALES_ORDER_TYPE_SCAN_AND_GO' || this.state.salesOrderType === 'SALES_ORDER_TYPE_RB_PREORDER' )
              ? getMessage('order.details.heading')
              : getMessage('deliveryOrder.details.heading')
            }
            &nbsp;
            {getMessage('order.table.referenceNumber.prefix')}
            <Link to={`/operations/delivery-orders/${referenceNumber}`}>
              <strong className="reference-number">
                {referenceNumber}
              </strong>
            </Link>
          </h1>
          {contentLoaded && (
            <OrderActions
              data={this.state.data}
              packlistData={this.state.packlistData}
              salesOrderType={this.state.salesOrderType}
              reloadDetails={() => window.location.reload()}
              referenceNumber={referenceNumber} />
          )}
        </div>

        {this.state.loading ? <Loader /> : null}
        {this.state.failed ? (
          <div className="retry-message text-muted" onClick={this.getData}>
            {getMessage('order.details.errors.onLoad')}
          </div>
        ) : null}

        {contentLoaded ? (
          <ShowDeliveryOrderDetails
            deliveryOrderData = {this.state.data}
            packlistData = {this.state.packlistData}
            deliveryNoteData = {this.state.deliveryNoteData}
            url={this.props.url}
            isMobileView = {isMobileView}
            isOrderViewItemCollapsed ={isOrderViewItemCollapsed}
            isDeliveryNoteCollapsed ={isDeliveryNoteCollapsed}
            currency = {this.organization.currency}
            communicationConfig={this.state.communicationConfig}
            orderConfig={this.state.orderConfig}
            error={this.state.error}
            router={this.props.router}
            salesOrderType={this.state.salesOrderType}
            onError={() => this.onError()}
            getOrderDetailsApi={() => this.getOrderDetailsApi()}
             >
          </ShowDeliveryOrderDetails>
        ) : null}

      {this.state.errorDialog && (
        <Dialog
          show={!!this.state.errorDialog}
          title={this.state.errorDialog.title}
          information={this.state.errorDialog.message}
          close={() => this.setState({ errorDialog: null })}
          closeText={getMessage('dialog.okText')}
        />
      )}
      </AuthenticatedPage>
    )
  }
}
