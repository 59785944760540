import React, { useState } from 'react'
import moment from 'moment'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { DateTime } from 'components/Form'
import Dialog from 'components/Popup/Dialog'
import FileProcessor from 'components/FileUpload/FileProcessor'
import UploadIcon from 'icons/upload-container.svg'
import API from 'lib/api'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'
import { getUser } from 'lib/auth'
import CsvParser from 'papaparse'
import { getMessage } from 'lib/translator'
import tableProperties from './TableProperties'
import emptyIcon from 'icons/empty.svg'
import './style.scss'

function downloadCsv(data, fileName) {
  const val = CsvParser.unparse(data)
  var hiddenElement = document.createElement('a')
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(val)
  hiddenElement.target = '_blank'
  hiddenElement.download = fileName.endsWith('.csv')
    ? fileName
    : `${fileName}.csv`
  hiddenElement.click()
}

function OfferSKULimit({ menu }) {
  const [scheduledDateTime, setScheduledDateTime] = useState(null)
  const [errorDialog, setErrorDialog] = useState(null)
  const [successDialog, setSuccessDialog] = useState(null)

  const handleFileUpload = async (file) => {
    if (!scheduledDateTime || scheduledDateTime?.length < 12) {
      setErrorDialog({
        title: getMessage('catalogue.offerSKULimit.uploadFile.missingDateTime.title'),
        message: getMessage('catalogue.offerSKULimit.uploadFile.missingDateTime.message'),
      })
    } else if (file.type !== 'text/csv') {
      setErrorDialog({
        title: getMessage('catalogue.offerSKULimit.uploadFile.wrongFormat.title'),
        message: getMessage('catalogue.offerSKULimit.uploadFile.wrongFormat.message'),
      })
    } else {
      await handleReadCSVAndUpload(file)
    }
  }

  // Method to convert csv file to JSON
  const handleReadCSVAndUpload = (file) => {
    try {
      const reader = new FileReader()
      reader.readAsText(file)

      reader.onload = function (e) {
        const data = e.target.result.replace(/[\r ]+/g, '') // remove \r and all white spaces
        CsvParser.parse(data, {
          header: true,
          skipEmptyLines: true,
          newline: '\n',
          complete: function (results) {
            const rowsInJson = results.data
            uploadSKULimitJobs(file.name, rowsInJson)
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadSKULimitJobs = async (name, jobs) => {
    try {
      const currentUser = JSON.parse(getUser())

      // check if time selected is after current time
      const scheduledTime = moment(scheduledDateTime);
      const currentTime = moment();
      if (scheduledTime.isBefore(currentTime)) {
        setErrorDialog({
          title: getMessage('catalogue.offerSKULimit.uploadFile.earlierTimeSelected.title'),
          message: getMessage('catalogue.offerSKULimit.uploadFile.earlierTimeSelected.message'),
        })
      } else {
        const momentDate = moment(scheduledDateTime, 'YYYY-MM-DD HH:mm:ss')
        const formattedScheduledDateTime = momentDate.toISOString()

        const jobsPayload = jobs.map((job) => {
          const payload = {
            sku: job.SKU,
            action: job.Action
          }
          if (job.Customer_Redemption_Limit) {
            payload.customerRedemptionLimit = Number(job.Customer_Redemption_Limit);
          }

          if (job.Offer_ID) {
            payload.offerId = Number(job.Offer_ID)
          }
          return payload
        })
        const offerJobsApi = new API({ url: '/offer-service/jobs' })
        const res = await offerJobsApi.post({
          name: name,
          data: jobsPayload,
          scheduledAt: formattedScheduledDateTime,
          userId: currentUser.id,
          status: 'PENDING',
        })
        if (res) {
          setSuccessDialog({
            title: getMessage('catalogue.offerSKULimit.jobCreated.title'),
            message: getMessage('catalogue.offerSKULimit.jobCreated.message'),
            type: 'success',
          })
        }
      }

    } catch (e) {
      console.log(e)
      if (e.message) {
        setErrorDialog({
          title: 'Error',
          message: e.message,
        })
      }
    }
  }

  const handleCancel = async(id) => {
    try {
      const cancelJobApi = new API({ url: `/offer-service/jobs/${id}` })
      const res = await cancelJobApi.patch({ status: 'DISABLED'})

      if (res) {
        setSuccessDialog({
          title: getMessage('catalogue.offerSKULimit.jobCancelled.title'),
          message: getMessage('catalogue.offerSKULimit.jobCancelled.message'),
          type: 'success',
        })
      }
    } catch(e) {
      setErrorDialog({
        title: 'Error',
        message: e.message,
      })
    }
  }

  const handleDownload = (fileName, data) => {
    /* prepare data for CSV download */
    const dataForCSV = data.map(d => ({
      SKU: d.sku,
      Customer_Redemption_Limit: d.customerRedemptionLimit,
      Offer_ID: d.offerId,
      Action: d.action,
    }))
    downloadCsv(dataForCSV, fileName)
  }

  return (
    <AuthenticatedPage className="offer-sku-limit-page" menu={menu}>
      <div className="header-container">
        <h2>{getMessage('catalogue.offerSKULimit.heading')}</h2>
      </div>
      <div className="scheduled-date-time-container">
        <DateTime
          name="scheduledDateTime"
          label={getMessage('catalogue.offerSKULimit.scheduledDateTime')}
          testid="scheduled-date-time-select"
          required
          enableToday
          value={scheduledDateTime}
          onChange={(value) => {
            setScheduledDateTime(value)
          }}
        />
      </div>
      {/* File Processor */}
      <div className="file-upload-wrapper">
        <div className="text-center">
          <img src={UploadIcon} width="140" alt="" />
          <FileProcessor
            name="csvFileUploaderBulkDispatchOrders"
            fileType=".csv"
            dataTestId="csv-file-input"
            title={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel'
            )}
            secondaryTitle={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel.or'
            )}
            buttonText={getMessage(
              'customer.orderCleanup.bulkDispatch.csvUploadLabel.chooseFileFromPC'
            )}
            onFileUpload={handleFileUpload}
          />
        </div>
      </div>

      <br />
      <br />
      <ListingPageWithRoutes
        className="scheduled-jobs-page"
        addHeading="Scheduled Upload Jobs"
        title="Scheduled Jobs"
        api={{
          url: '/offer-service/jobs',
          params: { limit: 200 },
          transform: (response) => response?.data?.job || [],
        }}
        showLanguageDropDown
        emptyState={{
          icon: emptyIcon,
          message: getMessage('catalogue.offerSKULimit.noScheduledJob'),
        }}
        tableProperties={tableProperties(handleCancel, handleDownload)}
        primaryKey="id"
        form={{}}
      />

      {errorDialog && (
        <Dialog
          className="error"
          show={!!errorDialog}
          title={errorDialog.title}
          information={errorDialog.message}
          close={() => setErrorDialog(null)}
          closeText={getMessage('dialog.okText')}
        />
      )}
      {successDialog && (
        <Dialog
          className={successDialog.type}
          show={!!successDialog}
          title={successDialog.title}
          information={successDialog.message}
          close={() => window.location.reload()}
          closeText={getMessage('dialog.okText')}
        />
      )}
    </AuthenticatedPage>
  )
}

export default OfferSKULimit
