import React from 'react'
import { withRouter } from 'react-router-dom'
import PrintOrdersDetails from './Details'
import PrintOrderTable from './Table'
import './style.css'

const PrintOrders = (props) => {
  const { router } = props
  const details = { ...props, url: '/print-orders' }
  if (router.match.params.distinctDesignCodes) {
    return <PrintOrdersDetails {...details} />
  }
  return <PrintOrderTable {...details} />
}

export default withRouter(({ match, ...props }) => (
  <PrintOrders router={{ match }} {...props} />
))
