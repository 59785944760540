import React from 'react'
import { Popup } from 'components/Popup'
import { BaseForm, Upload } from 'components/Form'
import PreviewSection from 'components/AppHome/PreviewSection'
import {
  SCRATCH_CARD_COMPONENTS,
  FormActionButtons,
  getComponents,
  ErrorDialog,
} from 'components/OmniJourney/utils'
import {
  contentAPIRequestHandler,
  formatValidity,
} from 'components/AppHome/utils'
import Screen from 'components/OmniJourney/Screen'
import Validity from 'components/AppHome/Validity'
import { getMessage } from 'lib/translator'
import Loader from 'components/Loader'
import './style.scss'

class ScratchCardForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showErrorDialog: false,
      showUploadPopup: false,
      uploadType: null,
      errorMessage: null,
      errorField: false,
      values: {
        startTime: '00:00:00',
        endTime: '00:00:00',
        imageCenter:
          'https://preprod-media.nedigital.sg/fairprice/images/579e5f38-27d6-4cd2-8c03-9849b3f8d28c/Default%20-%20Before.png',
        imageCenterBackground:
          'https://preprod-media.nedigital.sg/fairprice/images/d4db1f8b-0e99-4c04-8b1f-43d77cb30f51/background.png',
        imageTopBackground:
          'https://preprod-media.nedigital.sg/fairprice/images/6966267b-d447-4b5f-b33e-0ba7ce8f2d58/top.png',
      },
    }

    this.previewButtonHandler = this.previewButtonHandler.bind(this)
  }

  async componentDidMount() {
    const { method } = this.props
    if (method === 'edit') {
      this.setState({ loading: true })
      const apiData = this.props.apiData.data.items[0]
      const validity = await formatValidity('api', apiData)
      const values = {
        ...apiData,
        ...validity,
      }
      this.setState({ values, loading: false })
    }
  }

  async _submitHandler(e, saveAsDraft = false) {
    e && e.preventDefault()
    this.setState({ pressedSubmitWithCurrentData: true })
    if (this.isFormValid()) {
      this.setState({ loading: true })
      const { values } = this.state
      const { method, onCancel } = this.props
      const validity = await formatValidity('form', values)
      const data = {
        animation: values?.animation,
        imageBottomBackground: values?.imageBottomBackground,
        imageCenter: values?.imageCenter,
        imageCenterBackground: values?.imageCenterBackground,
        imageTopBackground: values?.imageTopBackground,
        isDisabled: saveAsDraft,
        pk: values?.pk,
        sk: values?.sk,
        ...validity,
      }
      const req = method !== 'add' ? 'PUT' : 'POST'
      const payload = {
        contentType: 'scan',
        key: 'preload-asset',
        data,
      }
      const callbacks = {
        onCancel: () => this.setState({ loading: false }),
        onError: ({ message }) =>
          this.setState({
            errorMessage: message,
            showErrorDialog: true,
          }),
        onSuccess: onCancel,
      }

      contentAPIRequestHandler(req, payload, callbacks)
    }
  }

  previewButtonHandler(type) {
    if (type === 'reset') {
      const values = Object.assign({}, this.state.values)
      SCRATCH_CARD_COMPONENTS.forEach((component) => (values[component] = null))
      this.setState({ values })
    } else {
      this.setState({ uploadType: type, showUploadPopup: true })
    }
  }

  render() {
    const { Form } = this.components
    const { values, loading, uploadType, showUploadPopup } = this.state
    const actionButtons = [
      [
        {
          type: 'imageTopBackground',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.upload.top.background'),
        },
        {
          type: 'imageCenterBackground',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.upload.center.background'),
        },
        {
          type: 'imageCenter',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.upload.center'),
        },
        {
          type: 'imageBottomBackground',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.upload.bottom.background'),
        },
      ],
      [
        {
          type: 'animation',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.upload.animation'),
        },
        {
          type: 'reset',
          handler: this.previewButtonHandler,
          name: getMessage('omni-journey.reset-button'),
        },
      ],
    ]
    const componentsBefore = SCRATCH_CARD_COMPONENTS.filter(
      (component) => component !== 'animation'
    )

    return (
      <div className="scratch-card-form">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <PreviewSection actionButtons={actionButtons}>
              <div className="screen-wrapper">
                <h5>{getMessage('omni-journey.before.scratch')}</h5>
                <Screen components={getComponents(componentsBefore, values)} />
              </div>
              <div className="screen-wrapper">
                <h5>{getMessage('omni-journey.after.scratch')}</h5>
                <Screen
                  components={getComponents(SCRATCH_CARD_COMPONENTS, {
                    ...values,
                    imageCenter:
                      'https://preprod-media.nedigital.sg/fairprice/images/2f4e5dd9-b61d-40d9-ae8c-fde94bbf22b5/Modal%20element.png',
                  })}
                />
              </div>
            </PreviewSection>

            <Validity generateStateMappers={this.generateStateMappers} />

            <div className="form-buttons-section">
              <FormActionButtons
                buttons={this.buttons}
                onSave={(e) => this._submitHandler(e, true)}
              />
            </div>

            <Popup
              show={showUploadPopup}
              heading={getMessage('omni-journey.upload.content')}
              close={() => this.setState({ showUploadPopup: false })}
              className="uploadPopup"
            >
              <Upload
                name="uploadImage"
                accept={'image/jpeg,image/jpg,image/png,application/json'}
                placeholder={getMessage(
                  'omni-journey.upload.content.placeholder'
                )}
                {...this.generateStateMappers({
                  stateKeys: [uploadType],
                  loseEmphasisOnFill: true,
                })}
              />
              <button
                className="button"
                type="button"
                onClick={() => this.setState({ showUploadPopup: false })}
              >
                {getMessage('omni-journey.done-button')}
              </button>
            </Popup>

            <ErrorDialog
              message={this.state.errorMessage}
              show={this.state.showErrorDialog}
              onClose={() =>
                this.setState({
                  showErrorDialog: false,
                  errorMessage: null,
                })
              }
            />
          </Form>
        )}
      </div>
    )
  }
}

export default ScratchCardForm
