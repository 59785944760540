import React from 'react'
import { Input, Upload } from 'components/Form'
import { getMessage } from 'lib/translator'

const ContentForm = ({ trigger, generateStateMappers }) => {
  return (
    <>
      <Upload
        placeholder={getMessage('omni-journey.upload.content.placeholder')}
        {...generateStateMappers({
          stateKeys: ['image'],
          loseEmphasisOnFill: true,
        })}
        required
      />
      <Input
        type="text"
        name="text1"
        disabled={trigger === 'scan-check-in'}
        placeholder={getMessage(
          `omni-journey.scan-to-win.card.text-1.field.placeholder`
        )}
        {...generateStateMappers({
          stateKeys: ['texts', `text1`],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="text"
        name="text2"
        placeholder={getMessage(
          `omni-journey.scan-to-win.card.text-2.field.placeholder`
        )}
        {...generateStateMappers({
          stateKeys: ['texts', `text2`],
          loseEmphasisOnFill: true,
        })}
      />
    </>
  )
}

export default ContentForm
