import React, { Component } from 'react'
import AuthenticatedPage from '../../../containers/AuthenticatedPage/index'
import API from '../../../lib/api'
import Dialog from '../../../components/Popup/Dialog'
import './style.css'

export default class ProductCacheClear extends Component {
  constructor(props) {
    super(props)
    this.clearCache = this.clearCache.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.toggleDialog = this.toggleDialog.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleStoreChange = this.handleStoreChange.bind(this)
    this.state = {
      textAreaValue: '',
      storeId: '',
      selectType: 'product',
      showDialog: false,
      dialogMessage: '',
      status: 'success',
    }
  }

  clearCache() {
    const { selectType, textAreaValue,storeId } = this.state
    const result = textAreaValue.split('\n').map(Number)
    const uniqueArray = [...new Set(result)]
    if (uniqueArray.length > 100) {
      alert(
        `More than 100 id shared, ids starting from ${uniqueArray[100]} will be ignored`
      )
    }
    const api = new API({
      url: `/catalogue-service/cache/remove?rand=${Date.now()}`,
    })
    const data =
      {
        "type": selectType,
        "ids": uniqueArray
      }
    /* istanbul ignore next */
    if(storeId){
      data["store_id"] = Number(storeId)
    }
    api.delete(data).then((response) => {
      this.setState({
        showDialog: true,
        textAreaValue:'',
        status: 'success',
        dialogMessage: response.data,
      })
    },
    error => {
      this.setState({
        showDialog: true,
        textAreaValue:'',
        status: 'error',
        dialogMessage: error.message,
      })
    })
  }

  handleValueChange(e) {
    this.setState({
      textAreaValue: e.target.value,
    })
  }

  toggleDialog() {
    this.setState({
      showDialog: false,
      dialogMessage: '',
    })
  }
  handleTypeChange(e) {
    this.setState({
      selectType: e.target.value,
    })
  }

  handleStoreChange(e){
    this.setState({
      storeId: e.target.value,
    })
  }

  render() {
    const { status, showDialog, dialogMessage, textAreaValue, selectType,storeId } =
      this.state
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
      >
        <h1>Cache Clear for Product, Category, Tag and Brand</h1>
        <p>Storeid(optional)</p>
        <input type="number" id="storeId" value={storeId} onChange={this.handleStoreChange} />
        <p>Please select type of cache to clear</p>
        <select
          id="selectType"
          value={selectType}
          onChange={this.handleTypeChange}
        >
          <option value="product">Product</option>
          <option value="category">Category</option>
          <option value="tag">Tag</option>
          <option value="brand">brand</option>
        </select>
        <p>Maximum 100 {selectType} ids, rest will be ignored</p>
        <textarea
          className="productIds"
          placeholder={`Please enter ${selectType} ids`}
          rows={8}
          cols={40}
          value={textAreaValue}
          onChange={this.handleValueChange}
        />
        <button
          disabled={!this.state.textAreaValue}
          type="click"
          className="primary button"
          onClick={this.clearCache}
        >
          Apply
        </button>
        <Dialog
          className={status}
          show={showDialog}
          close={this.toggleDialog}
          message={dialogMessage}
          closeText="OK"
        />
      </AuthenticatedPage>
    )
  }
}
