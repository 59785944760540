import { Input } from 'components/Form'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import React, { useState } from 'react'
import deleteSvg from '../../../../icons/delete.svg'

const AddPrintOrder = (props) => {
  const [vendorEmail, setVendorEmail] = useState('')
  const emptyCodeQty = {
    id: 0,
    designCode: '',
    quantity: '',
  }
  const [codeQtyArray, setCodeQtyArray] = useState([{ ...emptyCodeQty }])

  const handleAddPrintOrder = () => {
    // Proceed with the actual implementation once the API becomes available.
    console.log('Submitting order:', { vendorEmail, codeQtyArray })
  }

  return (
    <AuthenticatedPage menu={props.menu} title="order">
      <h1>{getMessage('gift.card.print.orders.add')}</h1>
      <div className="print-order-details">
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.vendors.email')}
              value={vendorEmail}
              testid="vendor-email"
              type="text"
              onChange={(e) => setVendorEmail(e)}
            />
          </div>
        </div>
        {codeQtyArray.map((item, index) => (
          <div className="row" key={item.id}>
            <div className="col-6">
              <Input
                label={getMessage('gift.card.print.orders.design.code')}
                value={item.designCode}
                testid="design-code"
                type="text"
                onChange={(e) =>
                  setCodeQtyArray((prev) =>
                    prev.map((obj, idx) =>
                      idx === index ? { ...obj, designCode: e } : obj
                    )
                  )
                }
              />
            </div>
            <div className="col-6">
              <Input
                label={getMessage('gift.card.print.orders.quantity')}
                value={item.quantity}
                testid="quantity"
                type="text"
                onChange={(e) =>
                  setCodeQtyArray((prev) =>
                    prev.map((obj, idx) =>
                      idx === index ? { ...obj, quantity: e } : obj
                    )
                  )
                }
              />
            </div>
            <div className="col-6 mt-2">
              {codeQtyArray.length > 1 && index !== 0 && (
                <img
                  className="delete-code-qty"
                  src={deleteSvg}
                  onClick={() =>
                    setCodeQtyArray((prev) =>
                      prev.filter((_, idx) => idx !== index)
                    )
                  }
                  alt="Delete Code Quantity"
                />
              )}
              {index === codeQtyArray.length - 1 && (
                <button
                  type="button"
                  className="primary"
                  onClick={() =>
                    setCodeQtyArray((prev) => [
                      ...prev,
                      { ...emptyCodeQty, id: prev.length },
                    ])
                  }
                >
                  {getMessage('gift.card.print.orders.add.row')}
                </button>
              )}
            </div>
          </div>
        ))}
        <div className="row flex-end">
          <div className="form-actions">
            <button
              type="button"
              className="primary"
              data-testid="submit"
              onClick={handleAddPrintOrder}
            >
              {getMessage('gift.card.print.orders.submit')}
            </button>
          </div>
        </div>
      </div>
    </AuthenticatedPage>
  )
}

export default AddPrintOrder
