import React from 'react'
import {
  ColumnDate,
  ColumnName,
  ColumnStatus,
  TableAction,
  getStatus,
} from '../utils'
import { getMessage } from 'lib/translator'
import { Row, Cell } from '../../Table'
import Card from './Card'

const tableProperties = () => {
  return {
    headers: [
      'Card',
      'Name',
      'Category',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({
      sk,
      pk,
      name,
      image,
      texts,
      category,
      startTime,
      endTime,
      isDisabled,
      onAction,
    }) => {
      const status = isDisabled
        ? getMessage('apphome.disabled')
        : getStatus(startTime, endTime)
      return (
        <Row>
          <Cell className="column-card">
            <Card src={image} texts={texts} />
          </Cell>
          <ColumnName
            type="cards"
            id={sk}
            name={name}
            category={category.replace('cards#', '')}
          />
          <Cell className="column-category">
            {category.replace('cards#', '')}
          </Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={sk}
              pk={pk}
              onAction={onAction}
              isEnable={!isDisabled}
              type="cards"
              category={category.replace('cards#', '')}
            />
          </Cell>
        </Row>
      )
    },
  }
}

export { tableProperties }
