import React, { useContext, Component } from 'react'
import { Link } from 'react-router-dom'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import ProductUpload from './ProductUpload'
import ShowHideUpload from './ShowHideUpload'
import { Row, Cell } from '../../../components/Table'
import Image from '../../../components/Image'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import ProductForm, { STOCK_STRATEGIES } from './Form'
import ProductFilters from './Filters'
import { Popup, Dialog } from '../../../components/Popup'
import { getMessage } from '../../../lib/translator'
import { hasPermissions, isExtensionEnabled } from '../../../lib/auth'
import API from '../../../lib/api'
import find from 'lodash.find'
import Toggle from '../../../components/Form/Inputs/Toggle'

import downloadIcon from './download.svg'
import emptyIcon from './products-empty.svg'

import './style.css'
import { When } from 'components/When'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { SplitContext } from 'containers/SplitContext'
import { isCatalogueProductReadOnly } from './utils'

const emptyState = {
  icon: emptyIcon,
  message: getMessage('product.helpItems.message'),
  submessage: getMessage('product.helpItems.submessage'),
  additionalViews: [ProductUpload],
}

class HeaderActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productModalShown: false,
      showHideModalShown: false,
      showDownloadComplete: false,
    }
    this.handleProductModal = this.handleProductModal.bind(this)
    this.hideProductModal = this.hideProductModal.bind(this)
    this.handleShowHideModal = this.handleShowHideModal.bind(this)
    this.hideShowHideModal = this.hideShowHideModal.bind(this)
  }

  handleProductModal() {
    this.setState({
      productModalShown: true,
    })
  }
  hideProductModal() {
    this.setState({
      productModalShown: false,
    })
  }
  handleShowHideModal() {
    this.setState({
      showHideModalShown: true,
    })
  }
  hideShowHideModal() {
    this.setState({
      showHideModalShown: false,
    })
  }
  render() {
    const isReadOnly = isCatalogueProductReadOnly()
    return (
      <div className="flex">
        {this.state.productModalShown && (
          <Popup
            heading={getMessage('product.add.bulk.text')}
            show={this.state.productModalShown}
            close={this.hideProductModal}
          >
            <ProductUpload hideAddButton />
          </Popup>
        )}
        {this.state.showHideModalShown && (
          <Popup
            heading={getMessage('product.add.showhide.bulk.text')}
            show={this.state.showHideModalShown}
            close={this.hideShowHideModal}
            className="show-hide-popup"
          >
            <ShowHideUpload hideAddButton />
          </Popup>
        )}
        <Dialog
          className="success"
          show={this.state.showDownloadComplete}
          title={getMessage('product.downloadCatalogueTitle')}
          information={this.state.downloadSuccessMessage}
          closeText={getMessage('product.downloadDialogClose')}
          close={() =>
            this.setState({
              showDownloadComplete: false,
            })
          }
        />
        <a
          className="download-button"
          onClick={() => {
            const api = new API({
              url: '/catalogue-service/product-upload?type=file',
            })
            api.get().then(response => {
              this.setState({
                showDownloadComplete: true,
                downloadSuccessMessage: response.message,
              })
            })
          }}
        >
          <img src={downloadIcon} alt={getMessage('product.add.text')} />
        </a>
        {!isReadOnly && (
          <DropDown
            className="bulk-upload-dropdown"
            icon={
              <button className="button primary add-product-dropdown-button">
                <span className="text">
                  {getMessage('product.manage.text')}
                </span>
              </button>
            }
          >
            <DropDownItem>
              <Link to="/catalogue/products/add">
                {getMessage('product.add.single.text')}
              </Link>
            </DropDownItem>
            <DropDownItem onClick={this.handleShowHideModal}>
              {getMessage('product.add.assignment.text')}
            </DropDownItem>
          </DropDown>
        )}
      </div>
    )
  }
}

const tableProperties = (isReadOnly) => {
  const hasEditPermission = Boolean(
    hasPermissions('catalogue', 'product', 'put')
  )
  const hasDeletePermission = Boolean(
    hasPermissions('catalogue', 'product', 'delete')
  )
  return {
    headers: [
      getMessage('product.header.image'),
      getMessage('product.header.sku'),
      getMessage('product.header.id'),
      getMessage('product.header.name'),
      getMessage('product.header.primaryCategory'),
      getMessage('product.header.status'),
      getMessage('product.header.actions'),
    ],
    row: ({
      id,
      images,
      brand,
      name,
      clientItemId,
      primaryCategory,
      status,
      variants,
      onAction,
    }) => {
      let image = images && images.length && images[0]
      if (!image && variants && variants.length) {
        const varImage = variants.filter(variant => variant.images)[0]
        if (varImage && varImage.images) {
          image = varImage.images[0]
        }
      }
      const isEnabled = status === 'ENABLED'
      const onChangeStatusHandle = () => {
        onAction(
          TABLE_ACTIONS.UPDATE,
          { id },
          { status: isEnabled ? 'HIDDEN' : 'ENABLED' }
        )
      }
      return (
        <Row>
          <Cell>
            <Image src={image} size="sm" bordered />
          </Cell>
          <Cell>
            {clientItemId}
          </Cell>
          <Cell>
            {id}
          </Cell>
          <Cell>
            <Link
              to={`/catalogue/products/edit/${id}`}
              className="product-name"
            >
              <div>
                {brand ? (
                  <span className="brand-name">{brand.name} </span>
                ) : null}
                {name}
              </div>
            </Link>
          </Cell>
          <Cell className="primary-category">
            <small className="text-muted not-mobile">
              {primaryCategory ? primaryCategory.name : null}
            </small>
            <span className="text-muted only-mobile">
              {`${getMessage('product.header.primaryCategory')}: `}
              <small className="product-text-muted">
                {primaryCategory ? primaryCategory.name : null}
              </small>
            </span>
          </Cell>
          <Cell className="product-status">
            <small className="text-muted product-status not-mobile">
              {status ? status.toLowerCase() : null}
            </small>
            <Toggle
              className={`${status} only-mobile`}
              name={status}
              value={isEnabled}
              icons={false}
              onChange={onChangeStatusHandle}
            />
          </Cell>
          <Cell className="product-actions">
            <DropDown
              className="product-action-dropdown"
              icon={<img src={ICONS.VELLIP} alt="⋮" />}
            >
              <DropDownItem>
                <Link to={`/catalogue/products/edit/${id}`}>
                  {getMessage(
                    isReadOnly ? 'product.action.show' : 'product.action.edit'
                  )}
                </Link>
              </DropDownItem>
              {!isReadOnly && (
                <>
                  <When condition={hasDeletePermission}>
                    <DropDownItem
                      onClick={() => {
                        onAction(TABLE_ACTIONS.DELETE, { id })
                      }}
                    >
                      {getMessage('product.action.delete')}
                    </DropDownItem>
                  </When>
                  <When condition={hasEditPermission}>
                    <DropDownItem
                      onClick={onChangeStatusHandle}
                      className="hide-action"
                    >
                      {getMessage(
                        `product.action.${isEnabled ? 'disable' : 'enable'}`
                      )}
                    </DropDownItem>
                  </When>
                </>
              )}
            </DropDown>
          </Cell>
        </Row>
      )
    },
  }
}
class Products extends Component {
  render() {
    const { menu, splits } = this.props
    const productMetadataMigrateEnabled = splits?.[SPLIT_FEATURES.PRDOUCT_METADATA_MIGRATION_ENABLED]?.treatment === 'on'
    const apiUrl = productMetadataMigrateEnabled ? `/v1/meta-data` : `/config-service/meta-data`
    const variantsEnabled = isExtensionEnabled('MultiVariantSupport')
    const brandsEnabled = isExtensionEnabled('MultiBrandSupport')
    const storesEnabled = isExtensionEnabled('MultiStoreSupport')
    const stockStrategy = isExtensionEnabled('StockManagement')
      ? STOCK_STRATEGIES.NUMERIC
      : STOCK_STRATEGIES.BOOLEAN
    const tagsEnabled = isExtensionEnabled('ProductTagSupport')
    const inStoreProcessing = isExtensionEnabled('InStoreProcessing')
    const soldByWeightEnabled = isExtensionEnabled('SoldByWeightSupport')
    const entityMetaDataEnabled = isExtensionEnabled('EntityMetaData')
    const preOrderSupportEnabled = isExtensionEnabled('PreOrderSupport')
    const bulkOrderEnabled = isExtensionEnabled('BulkOrderSupport')

    const isReadOnly = isCatalogueProductReadOnly()

    return (
      <ListingPageWithRoutes
        menu={menu}
        className="products-page"
        title={getMessage('product.table.heading')}
        addHeading={getMessage('product.form.add.heading')}
        editHeading={getMessage('product.form.edit.heading')}
        api={{
          url: '/catalogue-service/product',
          transform: response => {
            const products = response.data.product || []
            Array.isArray(products) &&
              products.forEach(item => {
                if (!item.images && item.hasVariants) {
                  const variantWithImages = find(
                    item.variants,
                    v => v.images !== null
                  )
                  item.images = variantWithImages && variantWithImages.images
                }
              })
            return products
          },
        }}
        showLanguageDropDown
        emptyState={emptyState}
        headerActions={HeaderActions}
        form={{
          component: ProductForm,
          options: {
            tags: tagsEnabled,
            noOfTagsToLoad: 200,
            variants: variantsEnabled,
            brands: brandsEnabled,
            stockStrategy: stockStrategy,
            multipleStores: storesEnabled,
            inStoreProcessing: inStoreProcessing,
            soldByWeight: soldByWeightEnabled,
            entityMetaData: entityMetaDataEnabled,
            preOrderSupport: preOrderSupportEnabled,
            bulkOrderSuppport: bulkOrderEnabled,
            productMetadataMigrateEnabled: productMetadataMigrateEnabled,
            getStores: function() {
              return new Promise((resolve, reject) => {
                const api = new API({ url: '/account-service/store' })
                api.get({ paginate: 'false' }).then(response => {
                  resolve(response.data.store)
                }, reject)
              })
            },
            getProductMetaData: () => {
              if (isExtensionEnabled('EntityMetaData')) {
                return new Promise((resolve, reject) => {
                  const api = new API({ url: apiUrl })
                  api.get().then((response) => {
                    resolve(productMetadataMigrateEnabled ? response.data.entityMetaData.product : response.data.config.entityMetaData.product)
                  }, reject)
                })
              }
              return null
            },
            getInventoryData: (storeId, ids) => {
              return new Promise((resolve, reject) => {
                const api = new API({
                  url: `/inventory-service/item?storeId=${storeId}&productId=${ids.join(
                    ','
                  )}`,
                })
                api.get().then(response => {
                  resolve(response)
                }, reject)
              })
            },
            getProductLocationData: (storeIds, skus) => {
              return new Promise((resolve, reject) => {
                const api = new API({
                  url: `/pls/v1/admin/locations?productSkus=${skus.join(',')}&storeIds=${storeIds.join(',')}`,
                })
                api.get().then(response => {
                  resolve(response)
                }, reject)
              })
            },
          },
          transformResponse: (response) => {
            const product = response.data.product
            // For each product, assume single variant, single store (at least)
            // Depending on organization settings, transform submit data correspondingly
            const modifiedProduct = Object.assign({}, product)
            if (
              !product.variants ||
              (Array.isArray(product.variants) && product.variants.length === 0)
            ) {
              modifiedProduct.variants = [{}]
              if (product.images) {
                modifiedProduct.variants[0].images = product.images
                // delete modifiedProduct.images
              }
              if (product.barcodes) {
                modifiedProduct.variants[0].barcodes = product.barcodes
                // delete product.barcodes
              }
              if (product.clientItemId) {
                modifiedProduct.variants[0].clientItemId = product.clientItemId
                modifiedProduct.madeUp = true
                // delete product.clientItemId
              }
              modifiedProduct.variants[0].id = product.id
            }
            delete modifiedProduct.categories
            return modifiedProduct
          },
        }}
        filters={{
          component: ProductFilters,
          options: {
            brands: brandsEnabled,
            tags: tagsEnabled,
            entityMetaData: entityMetaDataEnabled,
          },
          transformSubmit: formData => {
            const filters = Object.assign({}, formData)
            if (formData.category) {
              filters.slugCategory = formData.category.slug
              delete filters.category
            }
            if (formData.brand) {
              filters.slugBrand = formData.brand.slug
              delete filters.brand
            } else {
              filters.slugBrand = ''
              delete filters.brand
            }
            if (formData.tag) {
              filters.tagId = formData.tag.id
              delete filters.tag
            } else {
              filters.tagId = ''
              delete filters.tag
            }
            return filters
          },
        }}
        tableProperties={tableProperties(isReadOnly)}
      />
    )
  }
}

const ProductsWrapper = props => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <Products splits={splits} {...props} />
}

export default ProductsWrapper
