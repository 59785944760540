import React from 'react'
import { getMessage } from 'lib/translator'
import './style.scss'

const Content = ({ preview, setPreview, children }) => {
  return (
    <div className="block content">
      <h3>{getMessage('omni-journey.upload.content.header')}</h3>
      <div className="preview">{children}</div>
      <button
        type="button"
        className="button preview-button"
        onClick={setPreview}
      >
        {!preview
          ? getMessage('omni-journey.preview-button')
          : getMessage('omni-journey.back-button')}
      </button>
    </div>
  )
}

export default Content
