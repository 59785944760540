import QueryString from 'query-string'
import { getAuthToken, isExtensionEnabled } from '../auth'
import {
  GO_HOST,
  STORE_TECH_HOST,
  LINK_HOST,
  CUSTOMER_SEGMENTATION_SERVICE,
  TERMINAL_LINK_HOST,
  QC_BE_GATEWAY,
  MARTECH_AD_HOST,
  ACTION_TRAIL_API,
  PREORDER_API,
  P13N_HOST,
  CONFIG_API,
  GO_HOST_PUBLIC,
  FULFILMENT_API,
  FE_GATEWAY_API_URL,
  CAMPAIGN_LABELS_API,
  LINKPASS_ADMIN_URL,
  APG_GATEWAY_HOST,
} from '../../config/app.js'
import { get, set } from '../storage'
import axios from 'axios'
import { getCurrentTimeStamp } from '../datetime'
import _get from 'lodash.get'
import { captureActionViaAxios } from 'lib/actiontrail/actiontrail'
import { datadogRum } from '@datadog/browser-rum'
import apigeeMigration from 'lib/apigeeMigration'

export const UNAUTHORISED_STATUS = 401

export const ServiceHostMapping = {
  'account-service': GO_HOST,
  'billing-service': GO_HOST,
  'blog-service': GO_HOST,
  'catalogue-service': GO_HOST,
  'recipe-service': GO_HOST,
  'communication-service': GO_HOST,
  'customer-service': GO_HOST,
  'ef-customer-core': GO_HOST,
  'logistics-service': GO_HOST,
  'media-service': GO_HOST,
  'order-service': GO_HOST,
  'picking-service': GO_HOST,
  'promo-service': GO_HOST,
  'ticket-service': GO_HOST,
  'website-service': GO_HOST,
  'public-service': GO_HOST,
  'offer-service': GO_HOST,
  'inventory-service': GO_HOST,
  'config-service': GO_HOST,
  'seller-service': GO_HOST,
  'seller-order-service': GO_HOST,
  'banner-service': GO_HOST,
  'fp-search-service': GO_HOST,
  'search-service': GO_HOST,
  'invoice-service': GO_HOST,
  'dp-middleware-connector': GO_HOST,
  'recommender-service': GO_HOST,
  address: GO_HOST_PUBLIC,
  'logistics-management-service': GO_HOST,
  'martech-ad-service': MARTECH_AD_HOST,
  'martech-creative-service': MARTECH_AD_HOST,
  pls: FULFILMENT_API,
  batches: LINK_HOST,
  duck: LINK_HOST,
  chendol: LINK_HOST,
  genie: P13N_HOST,
  entities: LINK_HOST,
  vouchers: LINK_HOST,
  rms: LINK_HOST,
  admin: TERMINAL_LINK_HOST,
  brands: QC_BE_GATEWAY,
  locations: QC_BE_GATEWAY,
  foodsuppliers: QC_BE_GATEWAY,
  orders: QC_BE_GATEWAY,
  'gifting-admin-service': GO_HOST,
  'actions-trail': ACTION_TRAIL_API,
  campaigns: PREORDER_API,
  'meta-data': CONFIG_API,
  'gt-catelogue': CONFIG_API,
  'campaign-labels': CAMPAIGN_LABELS_API,
  segments: CUSTOMER_SEGMENTATION_SERVICE,
  customers: CUSTOMER_SEGMENTATION_SERVICE,
  product: FE_GATEWAY_API_URL,
  'linkpass-admin-service': LINKPASS_ADMIN_URL,
  designcode: LINK_HOST,
}

const VARNISH_CACHE_BYPASS_ENDPOINTS = {
  PRODUCT: '/catalogue-service/product',
  CATEGORY: '/catalogue-service/category',
  TAG: '/catalogue-service/tag',
  BRAND: '/catalogue-service/brand',
  ITEM: `/inventory-service/item`,
  OFFER: `/offer-service/offer`,
}

/**
 * @actiontrail
 */
if (process.env.NODE_ENV !== 'test') {
  axios.interceptors.request.use(function (config) {
    captureActionViaAxios(config)
    return config
  })
}

// const possibleExceptions = ['validation exception', 'auth exception', 'model exception', 'resource not found', 'invalid request', 'server error']
class API {
  constructor(data) {
    const { url } = data
    this.signal = axios.CancelToken.source()
    this.guid = new URL(window.location).searchParams
      ? new URL(window.location).searchParams.get('guid')
      : null
    if (url && url[0] === '/') {
      // Get the host based on the service
      const urlSplit = url.split('/')
      const service = urlSplit[1] === 'v1' ? urlSplit[2] : urlSplit[1]

      /*
        This method will hold the logic to call split feature flag and return the host value based on two conditions.

        A) If true it will return the host url based on config and if service not found it will return the url from ServiceHostMapping.

        B) Else if falg is off it will only refer the url from ServiceHostMapping.
      */
      const api = apigeeMigration(service)
      this.url = api + url

      if (service === 'martech-ad-service') {
        const suburl = url.replace('/martech-ad-service', '')
        this.url = api + suburl
      }
      if (service === 'gt-catelogue') {
        const suburl = url.replace('/gt-catelogue', '')
        this.url = api + suburl
      }
      if (service === 'martech-creative-service') {
        const suburl = url.replace(
          '/martech-creative-service',
          '/creative-service'
        )
        this.url = api + suburl
      }
      if (service === 'genie') {
        let suburl = url.replace('/genie', '')
        this.url = api + suburl
      }
    } else {
      this.url = url
    }
  }
  get(params = {}, headers = {}) {
    if (this.guid) {
      params.guid = this.guid
    }
    return call(this.url, 'GET', params, this.signal.token, headers)
  }
  post(params = {}, headers = {}) {
    return call(this.url, 'POST', params, this.signal.token, headers)
  }
  put(params = {}, headers = {}) {
    return call(this.url, 'PUT', params, this.signal.token, headers)
  }
  patch(params = {}, headers = {}) {
    return call(this.url, 'PATCH', params, this.signal.token, headers)
  }
  delete(params = {}, headers = {}) {
    return call(this.url, 'DELETE', params, this.signal.token, headers)
  }
  fileFetch(params = {}, headers = {}) {
    return callFileMethod(
      this.url,
      'GET',
      params,
      this.signal && this.signal.token,
      headers
    )
  }
  cancel() {
    return this.signal.cancel('API call cancelling')
  }
}

function call(api, method, params, signal, headers) {
  const options = {
    method: method,
    headers: {},
  }
  if (api.includes('/logistics-service/delivery-area')) {
    options.headers['X-API-VERSION'] = 2
  }
  if (
    api &&
    ['/catalogue-service/', '/account-service/store'].some((item) =>
      api.includes(item)
    ) &&
    isExtensionEnabled('MultiLingualSupport')
  ) {
    options.headers['Accept-Language'] = get('dataLang') || 'en'
  }

  options.headers = { ...options.headers, ...headers }
  const timeInSeconds = getCurrentTimeStamp()
  const varnishEndPoints = Object.values(VARNISH_CACHE_BYPASS_ENDPOINTS).join(
    '|'
  )
  const regex1 = new RegExp(`(${varnishEndPoints})/`)
  const regex2 = new RegExp(`(${varnishEndPoints})$`)
  const regex3 = new RegExp(`(${varnishEndPoints})\\?`) //eslint-disable-line
  let querystring = ''
  switch (method) {
    case 'GET':
      if (api.match(regex1) || api.match(regex2) || api.match(regex3)) {
        const { url, query } = QueryString.parseUrl(api)
        querystring += QueryString.stringify({
          ...query,
          byPassCache: timeInSeconds,
        })
        api = url
      }
      if (
        api.split('?')[1] &&
        api.split('?')[1].toLowerCase() === 'status=enabled'
      ) {
        params.status = params.status
          ? params.status.toLowerCase() === 'all'
            ? ''
            : params.status
          : 'enabled'
        api = api.split('?')[0]
      }
      if (params) {
        for (const key in params) {
          const value = params[key]
          if (value) {
            // { clientId: [122, 2323, 232,223, 23232]} ==> clientId=122&clientId=2323...
            if (value instanceof Array && api.includes(QC_BE_GATEWAY)) {
              querystring +=
                (querystring.length ? '&' : '') +
                key +
                '=' +
                encodeURIComponent(
                  value.reduce((str, v) => {
                    str += (str.length ? ',' : '') + v
                    return str
                  }, '')
                )
            } else if (value instanceof Array) {
              querystring +=
                (querystring.length ? '&' : '') +
                value.reduce((str, v) => {
                  str +=
                    (str.length ? '&' : '') + key + '=' + encodeURIComponent(v)
                  return str
                }, '')
            } else {
              querystring +=
                (querystring.length ? '&' : '') +
                key +
                '=' +
                encodeURIComponent(value)
            }
          }
        }
      }
      options.headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
      api = api + (querystring ? '?' + querystring : '')
      break
    case 'PATCH':
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(params)
      if (params instanceof FormData) {
        delete options.headers['Content-Type']
        options.body = params
      }
      break
    case 'PUT':
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(params)
      if (params instanceof FormData) {
        delete options.headers['Content-Type']
        options.body = params
      }
      break
    case 'POST':
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(params)
      if (params instanceof FormData) {
        delete options.headers['Content-Type']
        options.body = params
      }
      break
    case 'DELETE':
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(params)
      break
    default:
      break
  }

  const host = api.split('/', 3).join('/')
  const sendAccessToken =
    host === GO_HOST ||
    host === STORE_TECH_HOST ||
    host === LINK_HOST ||
    host === CUSTOMER_SEGMENTATION_SERVICE ||
    host === TERMINAL_LINK_HOST ||
    host === P13N_HOST ||
    host === MARTECH_AD_HOST ||
    host === PREORDER_API ||
    host === CONFIG_API ||
    host === CAMPAIGN_LABELS_API ||
    host === FULFILMENT_API ||
    host === LINKPASS_ADMIN_URL ||
    APG_GATEWAY_HOST.includes(host) ||
    ACTION_TRAIL_API.includes(host) ||
    api.includes(QC_BE_GATEWAY) ||
    (api.endsWith('/api/order') && method === 'POST') ||
    (api.endsWith('/api/checkout') && method === 'POST') ||
    (api.includes('/api/cart') && method === 'GET')

  const accessToken = getAuthToken()
  if (api.includes('/logistics-service/delivery-area')) {
    options.headers['X-API-VERSION'] = 2
  }
  if (params.guid) {
    options.headers['Authorization'] = 'Bearer ' + params.guid
    set('token', params.guid)
  } else if (sendAccessToken && accessToken) {
    if ([LINK_HOST, TERMINAL_LINK_HOST].includes(host)) {
      options.headers['X-User-Source'] = 'fpon_admin'
    }
    options.headers['Authorization'] = 'Bearer ' + accessToken
  }
  return axios({
    url: api,
    method: method.toLowerCase(),
    headers: options.headers,
    data: options.body,
    validateStatus: (status) => {
      return status === 200 || status === 201 || status === 204
    },
    responseType: 'json',
    cancelToken: signal,
  })
    .then((response) => {
      return Promise.resolve(response.data ? response.data : response)
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return Promise.reject({
          message: 'cancelled',
        })
      }

      let sendErrorToDatadog = true

      if (
        api.includes('/actions-trail') &&
        method === 'POST' &&
        error.message.includes('Network Error')
      ) {
        // Do not send network errors to Datadog for action trail
        sendErrorToDatadog = false
      }

      if (sendErrorToDatadog) {
        datadogRum.addError(error)
      }

      if (
        _get(error, 'response.status') === 429 &&
        host === LINKPASS_ADMIN_URL
      ) {
        return Promise.reject({
          code: _get(error, 'response.status'),
          message: _get(error, 'response.data.message'),
        })
      }
      if (
        (error.code === 'ERR_NETWORK' && host === LINKPASS_ADMIN_URL) ||
        (_get(error, 'response.data.code') === 401 && host === LINK_HOST)
      ) {
        return Promise.reject({
          code: 403,
          message:
            'Unable to perform operation. You either have no permissions for this action or did not connect to vpn. Please contact linkpass team for assistance.',
        })
      }

      return Promise.reject({
        code: _get(
          error,
          'response.data.error.code',
          _get(error, 'response.data.code', 500)
        ),
        message: _get(
          error,
          'response.data.error.message',
          _get(error, 'response.data.message', 'An error has occured')
        ),
        url: api,
        method: method,
      })
    })
}

function callFileMethod(api, method, params, signal, headers) {
  const options = {
    method: method,
    headers: {},
  }
  options.headers['Accept-Language'] = 'en'

  options.headers = { ...options.headers, ...headers }

  const accessToken = getAuthToken()
  options.headers['Authorization'] = 'Bearer ' + accessToken

  options.headers['Content-Type'] = 'application/pdf'
  let querystring = ''
  if (params) {
    for (const key in params) {
      const value = params[key]
      if (value) {
        // { clientId: [122, 2323, 232,223, 23232]} ==> clientId=122&clientId=2323...
        if (value instanceof Array) {
          querystring +=
            (querystring.length ? '&' : '') +
            value.reduce((str, v) => {
              str += (str.length ? '&' : '') + key + '=' + encodeURIComponent(v)
              return str
            }, '')
        } else {
          querystring +=
            (querystring.length ? '&' : '') +
            key +
            '=' +
            encodeURIComponent(value)
        }
      }
    }
  }

  return axios({
    url: api,
    method: method.toLowerCase(),
    headers: options.headers,
    validateStatus: (status) => {
      return status === 200 || status === 201
    },
    responseType: 'blob',
    cancelToken: signal,
  })
    .then((response) => {
      return Promise.resolve(response.data ? response.data : response)
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return Promise.reject({
          message: 'cancelled',
        })
      }
      datadogRum.addError(error)
      return Promise.reject({
        code: error.response && error.response.data && error.response.data.code,
        message:
          error.response && error.response.data && error.response.data.message,
        url: api,
        method: method,
      })
    })
}
export default API
