const data = {
  'gift.card.design.codes.title': 'Design Codes',
  'gift.card.print.orders.details': 'Print Orders Detail',
  'gift.card.print.orders': 'Print Orders',
  'gift.card.print.orders.add': 'Add Print Order',
  'gift.card.print.orders.status': 'Status',
  'gift.card.print.orders.status.placeholder': 'Select Status',
  'gift.card.print.orders.design.codes': 'Design Codes',
  'gift.card.print.orders.design.code': 'Design Code',
  'gift.card.print.orders.quantity': 'Quantity',
  'gift.card.print.orders.vendors.email': 'Vendor Email',
  'gift.card.print.orders.distinct.design.codes': 'Distinct Design Codes',
  'gift.card.print.orders.created.by': 'Created By',
  'gift.card.print.orders.create.time': 'Create Time',
  'gift.card.print.orders.create.time.placeholder': 'Select Date',
  'gift.card.print.orders.update.time': 'Update Time',
  'gift.card.print.orders.actions': 'Actions',
  'gift.card.print.orders.actions.resend.order': 'Resend Order',
  'gift.card.print.orders.actions.mark.as.printed': 'Mark as Printed',
  'gift.card.print.orders.add.row': 'Add Row',
  'gift.card.print.orders.search': 'Search',
  'gift.card.print.orders.submit': 'Submit',
}
export default data
