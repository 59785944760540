import { Textarea } from 'components/Form'
import { getMessage } from 'lib/translator'
import React from 'react'
import './style.css'

const Analytic = ({
  type,
  header,
  stateKeys,
  placeholder,
  errorFields,
  dismissErrors,
  generateStateMappers,
}) => {
  const showError = Object.keys(errorFields).includes(type)
  return (
    <>
      {header && <h3>{header}</h3>}
      <div className="analytics-field">
        <Textarea
          type="text"
          label={getMessage('apphome.analytics')}
          name={`${type}-analytics`}
          className={`${showError ? 'errorField' : ''}`}
          placeholder={placeholder}
          {...generateStateMappers({
            stateKeys: stateKeys,
            loseEmphasisOnFill: true,
            onChange: dismissErrors,
          })}
        />
        {showError && (
          <div className="errorField-msg">
            {`${getMessage('apphome.errorMessage.json-format')} - ${errorFields[type]}.`}
          </div>
        )}
      </div>
    </>
  )
}

export default Analytic
