import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import VoucherCenterCampaignForm from './Form';
import VoucherCenterCampaignsTable from './Table';

class VoucherCenterCampaignsRouter extends Component {
  render() {
    const { router } = this.props;
    if (router.match.params.id) {
      return <VoucherCenterCampaignForm {...this.props} isEdit />
    }
    return <VoucherCenterCampaignsTable {...this.props} />
  }
}

export default withRouter(({ match, ...props }) => (
  <VoucherCenterCampaignsRouter router={{ match }} {...props} />
))
