import React from 'react'
import { Checkbox } from '../../../../components/Form'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { get } from '../../../../lib/storage'
import CategorizedStoreSelector from '../../../../components.new/store-selector/CategorizedStoreSelector'
import AddProductSKU from './AddProductSKU'

const SpecificProductDetails = ({
  stores,
  updateState,
  getState,
  isVoucher,
}) => {
  const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
  const isDYFeatureEnable =
    flags[SPLIT_FEATURES.DIS_DY_SWIMLANE]?.treatment === 'on'
  return (
    <div>
      {!isVoucher && (
        <div
          className="form-sections"
          style={{
            padding: '0.5rem 1rem',
            display: 'flex',
          }}
        >
          <AddProductSKU
            onChange={(value) => {
              updateState(['productIds'], value.join(','))
            }}
            values={
              getState(['productIds'])
                ?.split(',')
                .filter((e) => !!e) || []
            }
          />
          {isDYFeatureEnable && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                margin: '2rem 0 0 2rem',
              }}
            >
              <Checkbox
                inlineLabel="use Dynamic Yield"
                name="hasDYSegment"
                value={getState(['useDY']) || false}
                onChange={(e) => {
                  updateState(['useDY'], e)
                }}
              />
            </div>
          )}
        </div>
      )}
      <div
        className="form-sections"
        style={{
          padding: !isVoucher ? '0.5rem 1rem' : 0,
        }}
      >
        <label htmlFor="specific-store">Specific Store</label>
        <div id="specific-store">
          <CategorizedStoreSelector
            onUpdate={(value) => {
              updateState(['storeIds'], value.join(','))
            }}
            stores={stores}
            values={
              getState(['storeIds'])
                ?.split(',')
                .filter((e) => !!e)
                .map((e) => Number(e)) || []
            }
          />
        </div>
      </div>
    </div>
  )
}

export default SpecificProductDetails
