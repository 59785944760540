import React, { useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { getMessage } from 'lib/translator'
import { TABLE_ACTIONS } from 'containers/ListingPage'
import { SingleDatePicker, TimePicker } from 'components/Form'
import { Dialog } from 'components/Popup'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import { Cell } from 'components/Table'
import { Validator } from 'jsonschema'
import API from 'lib/api'

const DATE_FORMAT = 'D MMM YYYY'

const EDIT_PAGE_LINK = {
  banners: '/app-home/banner-upload/edit',
  tiles: '/app-home/tiles-dressup/edit',
  splash: '/app-home/splash-dressup/edit',
  popup: '/app-home/popup/edit',
  cards: '/app-home/card-content/edit',
}

const STATUS_OPTIONS = [
  {
    value: true,
    text: getMessage(`apphome.enabled`),
  },
  {
    value: false,
    text: getMessage(`apphome.disabled`),
  },
]

const CONTENT_API_CONSUMER = ['popup', 'cards', 'scratch-cards', 'scan-to-win']

const TableDropdown = ({
  id,
  type,
  isEnable,
  category,
  onAction,
  setAction,
  setStatusToggleConfirmation,
}) => {
  const link = !category
    ? `${EDIT_PAGE_LINK[type]}/${id}`
    : `${EDIT_PAGE_LINK[type]}/${category}/${id}`

  return (
    <div>
      <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
        {!['tiles'].includes(type) && (
          <DropDownItem
            onClick={() => {
              setAction('status')
              setStatusToggleConfirmation(true)
            }}
          >
            {isEnable
              ? getMessage('apphome.disable')
              : getMessage('apphome.enable')}
          </DropDownItem>
        )}
        <DropDownItem>
          <Link to={link}>{getMessage('apphome.edit')}</Link>
        </DropDownItem>
        {id !== `${type}-Default` && (
          <DropDownItem
            onClick={() => {
              if (CONTENT_API_CONSUMER.includes(type)) {
                setAction('delete')
                setStatusToggleConfirmation(true)
              } else {
                onAction(TABLE_ACTIONS.DELETE, { id })
              }
            }}
          >
            {getMessage('apphome.delete')}
          </DropDownItem>
        )}
      </DropDown>
    </div>
  )
}

const TableAction = ({ isEnable, onAction, id, pk, type, category = '' }) => {
  const [statusToggleConfirmation, setStatusToggleConfirmation] =
    useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [action, setAction] = useState('')

  const handleStatus = () => {
    setAction('')
    setStatusToggleConfirmation(false)

    let url
    let payload = { isEnable: !isEnable }

    switch (type) {
      case 'splash':
        url = `/genie/lms/splashscreen/${id}/status`
        break
      case 'cards':
      case 'popup':
        url = '/genie/lms/content'
        payload = {
          updates: [
            {
              pk: pk,
              sk: id,
              fields: [
                {
                  op: 'SET',
                  name: 'isDisabled',
                  value: isEnable,
                },
              ],
            },
          ],
        }
        break
      default:
        url = `/genie/lms/${type}/${id}/status`
    }

    return new API({
      url,
    })
      .patch(payload)
      .then(() => {
        if (CONTENT_API_CONSUMER.includes(type)) {
          onAction(TABLE_ACTIONS.EDIT, { sk: id }, { isDisabled: isEnable })
        } else {
          onAction(TABLE_ACTIONS.EDIT, { id }, { isEnable: !isEnable })
        }
      })
      .catch((error) => {
        if (error) {
          setErrorMessage(getMessage('apphome.errorMessage.contact-team'))
          setShowErrorDialog(true)
        }
      })
  }

  const handleDelete = () => {
    setAction('')
    setStatusToggleConfirmation(false)
    const api = new API({
      url: `/genie/lms/content`,
    })
    return api.delete({ pk, sk: id }).then(() => {
      window.location.reload()
    })
  }

  return (
    <div>
      <Dialog
        show={showErrorDialog}
        information={errorMessage}
        close={() => setShowErrorDialog(false)}
        closeText={getMessage('apphome.okay')}
      />
      <Dialog
        show={statusToggleConfirmation}
        title={getMessage('apphome.confirmation')}
        onOk={action === 'status' ? handleStatus : handleDelete}
        close={() => setStatusToggleConfirmation(false)}
        closeText={getMessage('apphome.cancel')}
        okText={getMessage('apphome.yes')}
      />
      <TableDropdown
        id={id}
        type={type}
        isEnable={isEnable}
        category={category}
        setStatusToggleConfirmation={setStatusToggleConfirmation}
        onAction={onAction}
        setAction={setAction}
      />
    </div>
  )
}

const ColumnName = ({ type, id, name, category = '' }) => {
  const link =
    type !== 'cards'
      ? `${EDIT_PAGE_LINK[type]}/${id}`
      : `${EDIT_PAGE_LINK[type]}/${category}/${id}`
  return (
    <Cell className="column-campaign">
      <Link to={link}>{name}</Link>
    </Cell>
  )
}

const ColumnDate = ({ type, time }) => {
  return (
    <Cell className={`column-${type}-date`}>
      {time === '' ? '-' : moment(time).format(DATE_FORMAT)}
    </Cell>
  )
}

const ColumnStatus = ({ status }) => {
  return <Cell className={`column-${status}`}>{status}</Cell>
}

const getStatus = (startAt, endAt) => {
  let status
  if (moment().isAfter(endAt)) {
    status = getMessage('apphome.expired')
  } else if (moment().isBefore(startAt)) {
    status = getMessage('apphome.scheduled')
  } else {
    status = getMessage('apphome.live')
  }
  return status
}

const filterAppHomeListing = (params) => {
  if (params.status) {
    if (params.status === 'Live') {
      delete params.status
      params.preview = true
    } else {
      delete params.preview
    }
  }
  return params
}

const DatePickerField = ({
  label,
  type,
  placeholder,
  readOnlyField,
  generateStateMappers,
}) => {
  return (
    <SingleDatePicker
      enableToday
      className="w-100"
      required={type !== 'tiles-Default'}
      label={getMessage(`apphome.${label}`)}
      placeholder={getMessage(`apphome.${placeholder}`)}
      {...generateStateMappers({
        stateKeys: [label === 'validFrom' ? 'startDate' : 'endDate'],
        loseEmphasisOnFill: true,
      })}
      disabled={readOnlyField}
    />
  )
}

const TimePickerField = ({
  label,
  readOnlyField,
  generateStateMappers,
  placeholder = label,
}) => {
  return (
    <TimePicker
      required
      className="w-100"
      label={getMessage(`apphome.${label}`)}
      placeholder={getMessage(`apphome.${placeholder}`)}
      {...generateStateMappers({
        stateKeys: [label === 'startTime' ? 'startTime' : 'endTime'],
        loseEmphasisOnFill: true,
      })}
      disabled={readOnlyField}
    />
  )
}

const closeErrorMsgPopup = (_this) => {
  _this.setState({
    showErrorDialog: false,
    errorMessage: null,
  })
}

const formatDateTime = (date, time) => {
  return `${moment(date, 'DD MM YYYY')._i}T${
    moment(time || '00:00:00', 'HH:mm:ss')._i
  }+08:00`
}

const formatValidity = async (source, data) => {
  let result
  let hasValidity

  if (source === 'api') {
    hasValidity = data.startTime && data.endTime
    result = {
      startTime: hasValidity
        ? moment(data.startTime).format('HH:mm:ss')
        : '00:00:00',
      endTime: hasValidity
        ? moment(data.endTime).format('HH:mm:ss')
        : '00:00:00',
      startDate: hasValidity
        ? moment(data.startTime).format('YYYY-MM-DD')
        : null,
      endDate: hasValidity ? moment(data.endTime).format('YYYY-MM-DD') : null,
    }
  }

  if (source === 'form') {
    hasValidity = data.startDate && data.endDate
    result = {
      startTime: hasValidity
        ? formatDateTime(data.startDate, data.startTime)
        : null,
      endTime: hasValidity ? formatDateTime(data.endDate, data.endTime) : null,
    }
  }

  return result
}

const jsonParser = (analytic) => {
  try {
    return JSON.parse(analytic)
  } catch (error) {
    return null
  }
}

const validateAnalytics = (data, schema) => {
  const parsedAnalytic = jsonParser(data)
  const validator = new Validator()
  const result = validator.validate(parsedAnalytic, schema)

  return {
    isValid: result.valid,
    errors: result.errors,
    parsedAnalytic,
  }
}

const contentAPIRequestHandler = async (
  req,
  payload,
  callbacks,
  query = ''
) => {
  let response
  const api = new API({ url: `/genie/lms/content` + query })

  try {
    if (req === 'GET') {
      response = await api.get()
    }

    if (req === 'POST') {
      response = await api.post(payload)
    }

    if (req === 'PUT') {
      response = await api.put(payload.data)
    }

    if (req === 'PATCH') {
      response = await api.patch(payload)
    }

    if (req === 'DELETE') {
      response = await api.delete(payload)
    }

    if (response) {
      callbacks.onSuccess(response)
    }
  } catch (error) {
    callbacks.onError(error)
  } finally {
    callbacks.onCancel()
  }
}

export {
  ColumnName,
  ColumnDate,
  ColumnStatus,
  getStatus,
  formatDateTime,
  filterAppHomeListing,
  DatePickerField,
  TimePickerField,
  closeErrorMsgPopup,
  TableDropdown,
  TableAction,
  formatValidity,
  validateAnalytics,
  contentAPIRequestHandler,
  DATE_FORMAT,
  STATUS_OPTIONS,
}
