import './style.css'
import React from 'react'
import Module from './Module'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import { Motion, spring } from 'react-motion'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ModulesContext } from './ModulesProvider'
import Loader from 'components/Loader'
import Draggable from '../Draggable'
import { getMessage } from 'lib/translator'

const Container = ({
  items,
  setPopUp,
  moduleValues,
  setModuleValues,
  setIsDraft,
}) => {
  const moveCard = (dragIndex, hoverIndex) => {
    setIsDraft(true)
    setModuleValues((prev) =>
      update(Object.values(prev), {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, Object.values(prev)[dragIndex]],
        ],
      }).reduce((accumulator, item, index) => {
        accumulator[index] = {
          ...item,
          mPosition: moduleValues[index].mPosition,
        }
        return accumulator
      }, {})
    )
  }

  return (
    <div className="draggable-modules">
      {items.map((item, index) => (
        <Motion
          key={moduleValues[item].id}
          style={{
            y: spring(index, { stiffness: 500, damping: 10 }),
          }}
        >
          {({ y }) => (
            <Draggable
              index={index}
              axis="vertical"
              moveCard={moveCard}
              style={{
                transform: `translate3d(0, ${y}px, 0)`,
              }}
            >
              <Module
                index={index}
                setPopUp={setPopUp}
                module={moduleValues[item]}
              />
            </Draggable>
          )}
        </Motion>
      ))}
    </div>
  )
}

const ModuleList = ({ setPopUp, isDraft, setIsDraft }) => {
  const { isLoading, moduleValues, setModuleValues } =
    React.useContext(ModulesContext)

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && Object.keys(moduleValues).length > 0 && (
        <DndProvider backend={HTML5Backend}>
          <Container
            setPopUp={setPopUp}
            moduleValues={moduleValues}
            setModuleValues={setModuleValues}
            items={Object.keys(moduleValues)}
            setIsDraft={setIsDraft}
          />
          {!isDraft && (
            <div className="add-module-section">
              <button
                type="button"
                className="button applayout-hover-button"
                data-testid="add-module-button"
                onClick={() => setPopUp('module-type')}
              >
                {getMessage('app.layout.module.add')}
              </button>
            </div>
          )}
        </DndProvider>
      )}
    </>
  )
}

export default ModuleList
