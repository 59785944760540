import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// plop: append import here

import AccountCreated from '../../pages/others/AccountCreated'
import Login from '../../pages/others/Login'
import Logout from '../../pages/others/Logout'
import MissingPage from '../../pages/others/MissingPage'
import PaymentResponse from '../../pages/others/PaymentResponse'
import ResetPassword from '../../pages/others/ResetPassword'
import SignUp from '../../pages/others/SignUp'
import Verify from '../../pages/others/Verify'

// Catalogue
import BatchUploadJobs from '../../pages/catalogue/BatchUploadJobs/index'
import BatchUploadStatusTracker from '../../pages/catalogue/BatchUploadStatus/index'
import ProductCacheClear from '../../pages/catalogue/ProductCacheClear/index'
import Brands from '../../pages/catalogue/Brands/index'
import SKUMetaUpload from '../../pages/catalogue/BulkUploadSKUMeta'
import Categories from '../../pages/catalogue/Categories/index'
import CategoryTree from '../../pages/catalogue/Categories/TreeView'
import CatalogueDashboard from '../../pages/catalogue/Dashboard'
import Products from '../../pages/catalogue/Products/index'
import ScheduledBatchUploads from '../../pages/catalogue/ScheduledBatchUploads/index'
import StockOverride from '../../pages/catalogue/StockOverride'
import SubstitutionGroups from '../../pages/catalogue/SubstitutionGroups'
import Tags from '../../pages/catalogue/Tags'
import RecipeBatchUploadJobs from '../../pages/recipe/BatchUploadJobs/index'
import FeaturedRecipes from '../../pages/recipe/FeaturedRecipes/index'
import OfferSKULimit from '../../pages/catalogue/OfferSKULimit'
import GiftCards from '../../pages/gift-card/index'
import PrintOrders from '../../pages/gift-card/PrintOrders'
import AddPrintOrder from '../../pages/gift-card/PrintOrders/AddPrintOrder'

// Orchid Recommender Sandbox
import Recommender from '../../pages/marketing/Recommender'

// Marketplace
import Sellers from '../../pages/marketplace/Sellers'

import CsCustomers from '../../pages/customer-support/Customers'
import CsDashboard from '../../pages/customer-support/Dashboard'
import EGiftingOrders from '../../pages/customer-support/EGiftingOrders'
import EGiftingOrdersPendingBankApproval from '../../pages/customer-support/EGiftingOrdersPendingApproval'
import EGiftingEntities from '../../pages/customer-support/EGiftingEntities'
import CsOrderCleanup from '../../pages/customer-support/OrderCleanup'
import CsOrders from '../../pages/customer-support/Orders'
import CsPayments from '../../pages/customer-support/Payments'
import Link from '../../pages/customer-support/Link'
import LinkOperations from 'pages/customer-support/Link/vc/[vc]'
// import CsCalls from '../../pages/customer-support/Calls'
// import Customers from '../../pages/operations/Customers'
import { AuthenticatedCustomerDetails } from 'pages/operations/Customers/Details'
import SaleOrders from 'pages/operations/SaleOrders'
import DeliveryOrderLog from '../../components/DeliveryOrderLog'
import OrderLog from '../../components/OrderLog'
import UserShifts from '../../pages/hr/UserShifts'
import DeliveryOrders from '../../pages/operations/DeliveryOrders'
import Orders from '../../pages/operations/Orders'
import OrderAddForm from '../../pages/operations/Orders/POS'
import Pickers from '../../pages/operations/Pickers'
import PickersToday from '../../pages/operations/PickersToday'
import FileReturn from '../../pages/operations/Returns/Form/FileForReturn'

import LogisticsConfig from '../../pages/logistics/LogisticsConfig'

import ManageBanners from '../../pages/marketing/BannerManagement'
import Blogs from '../../pages/marketing/Blog/index'
import EVouchers from '../../pages/marketing/Evouchers'
import EvoucherDetails from '../../pages/marketing/Evouchers/BatchDetails'
import Offers from '../../pages/marketing/Offers'
import Pages from '../../pages/marketing/Pages/index'
import SearchCampaigns from '../../pages/marketing/SearchCampaigns'
import VoucherCenterCampaigns from '../../pages/marketing/VoucherCenterCampaigns'
import VoucherCenterCampaignForm from '../../pages/marketing/VoucherCenterCampaigns/Form'

import SearchEvoucher from '../../pages/marketing/Evouchers/SearchEvoucher'
import LuckyDraw from '../../pages/marketing/LuckyDraw'
import CapacityPlanning from '../../pages/operations/CapacityPlanning'
import Today from '../../pages/operations/CapacityPlanning/Today'
import OperationsDashboard from '../../pages/operations/Dashboard'
import EditPackedDeliveryOrder from '../../pages/operations/DeliveryOrders/EditPackedDeliveryOrder'
import Disputes from '../../pages/operations/Disputes'
import FileDispute from '../../pages/operations/Disputes/FileDispute'
import EditPackedOrder from '../../pages/operations/Orders/EditPackedOrder/EditPackedOrder'
import OrderRegisterPayments from '../../pages/operations/Orders/RegisterPayments'
import Returns from '../../pages/operations/Returns'
import SlotCharges from '../../pages/operations/SlotCharges'
import StoresV2 from '../../pages/settings/Store'
import Stores from '../../pages/operations/Stores'
import ZonePickingQueue from '../../pages/operations/ZonePickingQueue'
import OmniChallenges from '../../pages/rewards/OmniChallenges'
import TimeslotBlocking from 'pages/operations/TimeslotBlocking'
import BulkOperations from 'pages/operations/BulkOperations'

import AttendanceSummary from '../../pages/hr/AttendanceSummary'
import Designations from '../../pages/hr/Designations'
import DesignationRoles from '../../pages/hr/DesignationRoles'

//Rewards
import RewardsCarousels from '../../pages/rewards/Carousels'
import RewardsCatalogue from '../../pages/rewards/Catalogue'
import RewardsCategories from '../../pages/rewards/Categories'
import RewardsMaintenance from '../../pages/rewards/Maintenance'
import RewardsPartners from '../../pages/rewards/Partners'
import RewardsPromotions from '../../pages/rewards/Promotions'
import RewardsStores from '../../pages/rewards/Stores'
import RewardsMerchants from '../../pages/rewards/Merchants'
import RewardsDevices from '../../pages/rewards/Devices'

//media monetization
import MediaAssetManagement from '../../pages/media-monetization/MediaAssetManagement'
import MediaAssetDetail from '../../pages/media-monetization/MediaAssetManagement/AssetDetail'
import MediaAllCampaigns from '../../pages/media-monetization/MediaCampaigns'
import MediaCampaignDetail from '../../pages/media-monetization/MediaCampaigns/CampaignDetail'
import MediaLineItem from '../../pages/media-monetization/MediaLineItem'
import MediaLineItemDetail from '../../pages/media-monetization/MediaLineItem/LineItemDetail'
import MediaLineItemOffsite from '../../pages/media-monetization/MediaLineItem/Offsite'
import MediaPackageManagement from '../../pages/media-monetization/MediaPackageManagement'
import MediaPackageDetail from '../../pages/media-monetization/MediaPackageManagement/PackageDetail'
import MediaPackageInventory from '../../pages/media-monetization/MediaPackageManagement/PackageInventory'
import MediaAssetList from '../../pages/media-monetization/MediaSpotAllocation/assetList'
import MediaSpot from '../../pages/media-monetization/MediaSpotAllocation/spots'
import MediaStoreList from '../../pages/media-monetization/MediaSpotAllocation/storelist'
import MediaCreativeList from '../../pages/media-monetization/MediaCreativeManagement'
import MediaCreativeDetail from '../../pages/media-monetization/MediaCreativeManagement/MediaCreativeDetail'
import MediaCreativeGroupList from '../../pages/media-monetization/MediaCreativeManagement/MediaCreativeGroupList'
import MediaCreativeAdList from '../../pages/media-monetization/MediaCreativeManagement/MediaCreativeAdList'
import MediaAddCreative from '../../pages/media-monetization/MediaCreativeManagement/MediaAddCreative'

import Analytics from '../../pages/settings/Analytics/index'
import BasicInformation from '../../pages/settings/BasicInformation/index'
import CommunicationConfig from '../../pages/settings/Communication'
import CustomerTags from '../../pages/settings/CustomerTags'
import DeliveryArea from '../../pages/settings/DeliveryArea/index'
import Extensions from '../../pages/settings/Extensions'
import ExtensionDetailsForm from '../../pages/settings/Extensions/ExtensionDetailsForms'
import MobileApps from '../../pages/settings/MobileApps'
import Notifications from '../../pages/settings/Notifications'
import PaymentConfiguration from '../../pages/settings/PaymentConfiguration'
import RackManagement from '../../pages/settings/RackManagement'
import SearchConfiguration from '../../pages/settings/SearchConfigurations'
import SEO from '../../pages/settings/SEO'
import SocialMedia from '../../pages/settings/SocialMedia/index'
import Themes from '../../pages/settings/Themes/index'
import Users from '../../pages/settings/Users'

import AccountBalance from '../../pages/others/AccountBalance'
import ChangePassword from '../../pages/others/ChangePassword'
import LeaveManagement from '../../pages/others/LeaveManagement/CancelableLeaves'
import MyLeaves from '../../pages/others/Leaves'
import LeaveSummary from '../../pages/others/LeaveSummary'
import StaffConnection from '../../pages/customer-support/StaffConnection'

import _get from 'lodash.get'
import {
  getExtensionDetails,
  getPermissions,
  isExtensionEnabled,
  modifyMenuForEnterprise,
} from '../../lib/auth'
import QCOrderListing from '../../pages/qc/Orders'
import OrderManagement from '../../pages/settings/OrderManagement'
import Slots from '../../pages/stores/slots/EditSlots'

// New Admin - Action Trail
import ActionTrail from '../../pages/admin/ActionTrail/ActionTrail'

import { PrivateRoute } from './PrivateRoute'
import {
  getDisplayedMenuFromRole,
  getDefaultLink,
  getMenuFromPermission,
  getNewSessionOnRefresh,
  RootDataContext,
  submenuRedirect,
} from './utils'
import PreorderCamapignForm from 'pages/pre-order/PreOrderCampaign'
import DefineFestiveCampaign from 'pages/pre-order/DefineFestiveCampaign'

// Engagement
import BannerUpload from 'pages/app-home/BannerUpload'
import ManageTiles from 'pages/app-home/ManageTiles'
import ManageSplashscreen from 'pages/app-home/ManageSplashscreen'
import ManageLayout from 'pages/app-home/ManageLayout'
import ManageCard from 'pages/app-home/ManageCard'
import ManagePopup from 'pages/app-home/ManagePopup'
import ManageVouchers from 'pages/omni-journey/ManageVouchers'
import ManageScratchCards from 'pages/omni-journey/ManageScratchCards'
import ManageScanToWin from 'pages/omni-journey/ManageScanToWin'

import { SngActions } from '../../pages/admin'
import SPLIT_FEATURES from '../SplitContext/features'
import { Preorder } from 'pages/admin/Preorder'
import CampaignLabels from 'pages/marketing/CampaignLabels'
import ConfigurationSetup from 'pages/catalogue/ConfigurationSetup'

export default class NewRouter extends Component {
  constructor(props) {
    super(props)
    const url = new URL(window.location)
    if (url.searchParams.get('guid')) {
      this.state = {
        refresh: true,
      }
    } else {
      this.state = {
        refresh: false,
      }
    }
  }

  renderOrderRelatedPages() {
    return (
      <>
        {/* operations */}
        <PrivateRoute
          path="/operations/orders/add"
          isInMenu
          component={OrderAddForm}
          exact
        />
        <PrivateRoute path="/operations/orders/:id?" component={Orders} exact />
        <PrivateRoute
          path="/operations/orders/order-log/:id"
          component={OrderLog}
          exact
        />
        <PrivateRoute
          path="/operations/orders/edit-packed/:id"
          component={EditPackedOrder}
          exact
        />
        <PrivateRoute
          path="/operations/orders/dispute/:id"
          component={FileDispute}
          exact
        />
        <PrivateRoute
          path="/operations/orders/file-for-return/:id"
          component={FileReturn}
          exact
        />
        <PrivateRoute
          path="/operations/orders/register-payment/:id"
          component={OrderRegisterPayments}
          exact
        />
        {/* customer-support */}
        <PrivateRoute
          path="/customer-support/orders/:id?"
          component={CsOrders}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/order-log/:id"
          component={OrderLog}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/file-for-return/:id"
          component={FileReturn}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/dispute/:id"
          component={FileDispute}
          exact
        />
        <PrivateRoute
          path="/customer-support/orders/register-payment/:id"
          component={OrderRegisterPayments}
          exact
        />
      </>
    )
  }

  render() {
    const { isLoggedIn, splits } = this.props

    let menu = getMenuFromPermission()

    const location = new URL(window.location)

    this.state.refresh &&
      getNewSessionOnRefresh({
        url: location,
        callback: () => {
          this.setState({
            refresh: false,
          })
        },
      })

    const hasPermissionsInfo = Boolean(getPermissions())
    let redirectToLogin = !isLoggedIn || !hasPermissionsInfo
    if (location.searchParams.get('guid') && hasPermissionsInfo) {
      redirectToLogin = false
    }

    menu = modifyMenuForEnterprise(menu)

    const displayedMenu = getDisplayedMenuFromRole(menu, splits)
    const isSnGActionEnable =
      splits[SPLIT_FEATURES.SNG_ACTION]?.treatment === 'on'
    const isBatchUploadStatusEnabled =
      splits[SPLIT_FEATURES.BATCH_UPLOAD_STATUS]?.treatment === 'on'
    const storeConfigurationV2Enabled =
      splits[SPLIT_FEATURES.STORE_CONFIGURATION]?.treatment === 'on'

    const firstMenuLink = getDefaultLink(displayedMenu)

    const contextData = {
      redirectToLogin,
      isLoggedIn,
      displayedMenu,
      refresh: this.state.refresh,
    }

    const hideOrderRelatedRoutes =
      splits?.[SPLIT_FEATURES.HIDE_ORDER_ROUTES]?.treatment === 'on'

    return (
      <RootDataContext.Provider value={contextData}>
        <Switch>
          <Redirect exact from="/" to={firstMenuLink} />
          <Redirect
            exact
            from="/in-store-preorder"
            to={submenuRedirect(
              'in-store-preorder',
              _get(displayedMenu, 'in-store-preorder[0]', '')
            )}
          />
          <Redirect
            exact
            from="/marketing"
            to={submenuRedirect(
              'marketing',
              _get(displayedMenu, 'marketing[0]', '')
            )}
          />
          <Redirect
            exact
            from="/operations"
            to={submenuRedirect(
              'operations',
              _get(displayedMenu, 'operations[0]', '')
            )}
          />
          <Redirect
            exact
            from="/qc"
            to={submenuRedirect('qc', _get(displayedMenu, 'qc[0]', ''))}
          />
          <Redirect
            exact
            from="/recipe"
            to={submenuRedirect('recipe', _get(displayedMenu, 'recipe[0]', ''))}
          />
          <Redirect
            exact
            from="/logistics"
            to={submenuRedirect(
              'logistics',
              _get(displayedMenu, 'logistics[0]', '')
            )}
          />
          <Redirect
            exact
            from="/settings"
            to={submenuRedirect(
              'settings',
              _get(displayedMenu, 'settings[0]', '')
            )}
          />
          <Redirect
            exact
            from="/customer-support"
            to={submenuRedirect(
              'customer-support',
              _get(displayedMenu, 'customer-support[0]', '')
            )}
          />
          <Redirect
            exact
            from="/catalogue"
            to={submenuRedirect(
              'catalogue',
              _get(displayedMenu, 'catalogue[0]', '')
            )}
          />
          <Redirect
            exact
            from="/marketplace"
            to={submenuRedirect(
              'marketplace',
              _get(displayedMenu, 'marketplace[0]', '')
            )}
          />
          <Redirect
            exact
            from="/media-monetization"
            to={submenuRedirect(
              'media-monetization',
              _get(displayedMenu, 'media-monetization[0]', '')
            )}
          />
          <Redirect
            exact
            from="/user"
            to={submenuRedirect('user', _get(displayedMenu, 'user[0]', ''))}
          />
          <Redirect
            exact
            from="/hr"
            to={submenuRedirect('hr', _get(displayedMenu, 'hr[0]', ''))}
          />
          <Redirect
            exact
            from="/rewards"
            to={submenuRedirect(
              'rewards',
              _get(displayedMenu, 'rewards[0]', '')
            )}
          />
          <Redirect
            exact
            from="/app-home"
            to={submenuRedirect(
              'app-home',
              _get(displayedMenu, 'app-home[0]', '')
            )}
          />
          <Redirect
            exact
            from="/omni-journey"
            to={submenuRedirect(
              'omni-journey',
              _get(displayedMenu, 'omni-journey[0]', '')
            )}
          />
          <Redirect
            exact
            from="/operations/customers"
            to="/customer-support/customers"
          />
          <Redirect
            exact
            from="/operations/customers/view/:id"
            to="/customer-support/customers/view/:id"
          />
          <Redirect
            exact
            from="/admin"
            to={submenuRedirect('admin', _get(displayedMenu, 'admin[0]', ''))}
          />
          {/* plop: append private redirect route here */}
          {isExtensionEnabled('InStoreProcessing') && (
            <Redirect
              exact
              from="/settings/instore"
              to={`/settings/extensions/${
                getExtensionDetails('InStoreProcessing').id
              }`}
            />
          )}
          {isExtensionEnabled('TawkToLiveChat') && (
            <Redirect
              exact
              from="/settings/tawk"
              to={`/settings/extensions/${
                getExtensionDetails('TawkToLiveChat').id
              }`}
            />
          )}
          {isExtensionEnabled('EntityMetaData') && (
            <Redirect
              from="/settings/metadata"
              to={`/settings/extensions/${
                getExtensionDetails('EntityMetaData').id
              }`}
            />
          )}
          <Redirect
            exact
            from="/preorders"
            to="/preorders/preorder-campaigns"
          />
          <PrivateRoute
            path="/preorders/define-festive-campaigns/:action?/:id?"
            component={DefineFestiveCampaign}
            exact
          />
          <Route path="/signUp" component={SignUp} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ResetPassword} />
          <Route path="/user/logout" component={Logout} />
          <Route path="/verify" component={Verify} />
          <Route path="/account-created" component={AccountCreated} />
          {/* For each route, we should have exactly one component */}
          <PrivateRoute
            path="/preorders/define-festive-campaigns"
            component={DefineFestiveCampaign}
            exact
          />
          <PrivateRoute
            path="/preorders/preorder-campaigns"
            isInMenu
            component={PreorderCamapignForm}
          />
          <PrivateRoute
            path="/payment-response"
            isInMenu
            component={PaymentResponse}
          />
          <PrivateRoute
            path="/catalogue/dashboard"
            isInMenu
            component={CatalogueDashboard}
          />
          {/* <PrivateRoute
            exact
            from="/gift-card"
            to={submenuRedirect(
              'gift-card',
              _get(displayedMenu, 'gift-card[0]', '')
            )}
          /> */}
          <PrivateRoute
            path="/gift-card/design-codes"
            isInMenu
            component={GiftCards}
          />
          <PrivateRoute
            path="/gift-card/print-orders/add"
            component={AddPrintOrder}
            exact
          />
          <PrivateRoute
            path="/gift-card/print-orders/:distinctDesignCodes?"
            component={PrintOrders}
            exact
          />

          <PrivateRoute
            path="/catalogue/categories/tree-view"
            component={CategoryTree}
          />
          <PrivateRoute
            path="/catalogue/categories/:action?/:id?"
            component={Categories}
            exact
          />
          <PrivateRoute path="/catalogue/brands" isInMenu component={Brands} />
          <PrivateRoute
            path="/catalogue/products/:action?/:id?"
            component={Products}
            exact
          />
          <PrivateRoute
            path="/catalogue/configuration"
            isInMenu
            component={ConfigurationSetup}
          />
          <PrivateRoute
            path="/catalogue/batch-upload-jobs"
            isInMenu
            component={BatchUploadJobs}
            exact
          />
          <PrivateRoute
            path="/catalogue/product-cache-clear"
            isInMenu
            component={ProductCacheClear}
            exact
          />
          {isBatchUploadStatusEnabled && (
            <PrivateRoute
              path="/catalogue/batch-uploads-status-tracker/:action?/:id?"
              isInMenu
              component={BatchUploadStatusTracker}
              exact
            />
          )}
          <PrivateRoute
            path="/catalogue/scheduled-batch-uploads"
            isInMenu
            component={ScheduledBatchUploads}
            exact
          />
          <PrivateRoute
            path="/catalogue/sku-meta-upload"
            isInMenu
            component={SKUMetaUpload}
            exact
          />
          <PrivateRoute
            path="/catalogue/offer-sku-limit"
            isInMenu
            component={OfferSKULimit}
            exact
          />
          <PrivateRoute path="/catalogue/tags" component={Tags} exact />
          <PrivateRoute
            path="/catalogue/substitution-groups/:action?/:id?"
            component={SubstitutionGroups}
          />
          <PrivateRoute
            path="/operations/dashboard"
            isInMenu
            component={OperationsDashboard}
          />
          <PrivateRoute
            path="/catalogue/stock-override"
            isInMenu
            component={StockOverride}
          />
          <PrivateRoute
            path="/marketplace/sellers/:action?/:id?"
            component={Sellers}
            exact
          />
          <PrivateRoute
            path="/operations/zone-picking-queue"
            isInMenu
            component={ZonePickingQueue}
          />
          <PrivateRoute
            path="/operations/delivery-orders/order-log/:id"
            component={DeliveryOrderLog}
            exact
          />
          <PrivateRoute
            path="/operations/delivery-orders/edit-packed/:id"
            component={EditPackedDeliveryOrder}
            exact
          />
          <PrivateRoute
            path="/operations/delivery-orders/:id?"
            isInMenu
            component={DeliveryOrders}
            exact
          />
          <PrivateRoute
            path="/operations/rack-management"
            isInMenu
            component={RackManagement}
          />
          <PrivateRoute
            path="/operations/disputes"
            isInMenu
            component={Disputes}
          />
          <PrivateRoute
            path="/operations/returns"
            isInMenu
            component={Returns}
          />
          <PrivateRoute
            path="/operations/pickers"
            isInMenu
            component={Pickers}
          />
          <PrivateRoute
            path="/operations/pickers-today"
            isInMenu
            component={PickersToday}
          />
          <PrivateRoute
            path="/operations/timeslot-blocking"
            isInMenu
            component={TimeslotBlocking}
          />
          <PrivateRoute
            path="/operations/bulk-operations"
            isInMenu
            component={BulkOperations}
            exact
          />
          <PrivateRoute
            path="/operations/delivery-orders/file-for-return/:id"
            component={FileReturn}
            exact
          />
          <PrivateRoute
            path="/operations/capacity-planning"
            isInMenu
            component={CapacityPlanning}
            exact
          />
          <PrivateRoute
            path="/operations/capacity-planning/today"
            isInMenu
            component={Today}
            exact
          />
          <PrivateRoute
            path="/operations/slot-charges"
            isInMenu
            component={SlotCharges}
          />
          <PrivateRoute
            path="/customer-support/dashboard"
            isInMenu
            component={CsDashboard}
          />
          <PrivateRoute
            path="/customer-support/customers/view/:id"
            component={AuthenticatedCustomerDetails}
          />
          <PrivateRoute
            path="/customer-support/customers/:action?/:id?"
            component={CsCustomers}
          />
          <PrivateRoute
            path="/customer-support/delivery-orders/:id?"
            isInMenu
            component={DeliveryOrders}
            exact
          />
          <PrivateRoute
            path="/customer-support/delivery-orders/order-log/:id?"
            component={DeliveryOrderLog}
            exact
          />
          <PrivateRoute
            path="/customer-support/sale-orders/:id?"
            isInMenu
            component={SaleOrders}
            exact
          />
          <PrivateRoute
            path="/customer-support/delivery-orders/file-for-return/:id"
            component={FileReturn}
            exact
          />
          <PrivateRoute
            path="/customer-support/order/order-return/:id"
            component={FileReturn}
            exact
          />
          <PrivateRoute
            path="/customer-support/cleanup"
            isInMenu
            component={CsOrderCleanup}
            exact
          />
          {/* <PrivateRoute path='/customer-support/calls' component={CsCalls} /> */}
          <PrivateRoute
            path="/customer-support/egifting-orders/:id?"
            component={EGiftingOrders}
            exact
          />
          <PrivateRoute
            path="/customer-support/egifting-orders-pending-bank-approval/:id?"
            component={EGiftingOrdersPendingBankApproval}
            exact
          />
          <PrivateRoute
            path="/customer-support/gifting-entities/:action?/:id?"
            component={EGiftingEntities}
            exact
          />
          <PrivateRoute
            path="/customer-support/link"
            isInMenu
            component={Link}
            exact
          />
          <PrivateRoute
            path="/customer-support/link/vc/:vc"
            isInMenu
            component={LinkOperations}
            exact
          />
          <PrivateRoute
            path="/customer-support/fpg-staff"
            isInMenu
            component={StaffConnection}
            exact
          />
          <PrivateRoute
            path="/customer-support/payments"
            isInMenu
            component={CsPayments}
            exact
          />
          <PrivateRoute
            path="/rewards/categories/:action?/:id?"
            component={RewardsCategories}
            exact
          />
          <PrivateRoute
            path="/rewards/carousels/:action?/:id?"
            component={RewardsCarousels}
            exact
          />
          <PrivateRoute
            path="/rewards/maintenance/:action?/:id?"
            component={RewardsMaintenance}
            exact
          />
          <PrivateRoute
            path="/rewards/partners/:action?/:id?"
            component={RewardsPartners}
            exact
          />
          <PrivateRoute
            path="/rewards/stores/:action?/:id?"
            component={RewardsStores}
            exact
          />
          <PrivateRoute
            path="/rewards/promotions/:action?/:id?"
            component={RewardsPromotions}
            exact
          />
          <PrivateRoute
            path="/rewards/catalogue/:action?/:id?"
            component={RewardsCatalogue}
            exact
          />
          <PrivateRoute
            path="/rewards/merchants/:action?/:id?"
            component={RewardsMerchants}
            exact
          />
          <PrivateRoute
            path="/rewards/devices/:action?/:id?"
            component={RewardsDevices}
            exact
          />
          <PrivateRoute
            path="/marketing/offers/pay-via-fp-app/:action?/:id?"
            component={Offers}
          />
          <PrivateRoute
            path="/marketing/offers/pin-wallet/:action?/:id?"
            component={Offers}
          />
          <PrivateRoute
            path="/marketing/offers/:action?/:id?"
            component={Offers}
          />
          <PrivateRoute
            path="/marketing/blog/:action?/:id?"
            component={Blogs}
          />
          <PrivateRoute
            path="/marketing/pages/:action?/:id?"
            component={Pages}
          />
          <PrivateRoute
            path="/marketing/web-banners"
            isInMenu
            component={ManageBanners}
          />
          <PrivateRoute
            path="/marketing/search-campaigns"
            isInMenu
            component={SearchCampaigns}
          />
          <PrivateRoute
            path="/marketing/voucher-center-campaigns"
            isInMenu
            exact
            component={VoucherCenterCampaigns}
          />
          <PrivateRoute
            path="/marketing/voucher-center-campaigns/new"
            exact
            component={VoucherCenterCampaignForm}
          />
          <PrivateRoute
            path="/marketing/voucher-center-campaigns/:id?"
            component={VoucherCenterCampaignForm}
          />
          <PrivateRoute
            path="/marketing/campaign-labels"
            isInMenu
            component={CampaignLabels}
          />
          <PrivateRoute
            path="/marketing/evouchers/:action?/:id?"
            component={EVouchers}
          />
          <PrivateRoute
            path="/marketing/search-evoucher"
            component={SearchEvoucher}
            exact
          />
          <PrivateRoute
            path="/marketing/recommender"
            isInMenu
            component={Recommender}
          />
          <PrivateRoute
            path="/marketing/lucky-draws/:action?/:id?"
            component={LuckyDraw}
          />
          <PrivateRoute
            path="/rewards/challenges/:action?/:id?"
            component={OmniChallenges}
          />
          <PrivateRoute
            path="/app-home/banner-upload/:action?/:id?"
            component={BannerUpload}
          />
          <PrivateRoute
            path="/app-home/tiles-dressup/:action?/:id?"
            component={ManageTiles}
          />
          <PrivateRoute
            path="/app-home/splash-dressup/:action?/:id?"
            component={ManageSplashscreen}
          />
          <PrivateRoute
            path="/app-home/dynamic-layout/:action?/:id?"
            component={ManageLayout}
          />
          <PrivateRoute
            path="/app-home/card-content/:action?/:key?/:sk?"
            component={ManageCard}
          />
          <PrivateRoute
            path="/app-home/popup/:action?/:sk?"
            component={ManagePopup}
          />
          <PrivateRoute
            path="/omni-journey/vouchers"
            component={ManageVouchers}
          />
          <PrivateRoute
            path="/omni-journey/scratch-cards/:action?/:sk?"
            component={ManageScratchCards}
          />
          <PrivateRoute
            path="/omni-journey/scan-to-win/:type?/:action?/:key?/:sk?"
            component={ManageScanToWin}
          />
          <PrivateRoute
            path="/qc/order-management"
            component={QCOrderListing}
          />
          <PrivateRoute
            path="/qc/orders/:customerId/:reference"
            component={QCOrderListing}
            exact
          />
          <PrivateRoute
            path="/recipe/featured-recipes"
            component={FeaturedRecipes}
          />
          <PrivateRoute
            path="/recipe/batch-upload"
            component={RecipeBatchUploadJobs}
          />
          <PrivateRoute path="/marketing/evouchers" component={EVouchers} />
          <PrivateRoute
            path="/media-monetization/media-allcampaigns"
            component={MediaAllCampaigns}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-allcampaigns/media-campaign-detail/:id?"
            component={MediaCampaignDetail}
          />
          <PrivateRoute
            path="/media-monetization/media-allcampaigns/media-adset/:id?"
            component={MediaLineItem}
          />
          <PrivateRoute
            path="/media-monetization/media-allcampaigns/media-adset-detail/:action?/:campaignId/:id?"
            component={MediaLineItemDetail}
          />
          <PrivateRoute
            path="/media-monetization/media-allcampaigns/media-adset-offsite/:campaignId/:id?"
            component={MediaLineItemOffsite}
          />
          <PrivateRoute
            path="/media-monetization/media-storelist"
            component={MediaStoreList}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-storelist/media-assetlist/:id?"
            component={MediaAssetList}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-storelist/media-spotallocation/:id?"
            component={MediaSpot}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-packagemanagement"
            component={MediaPackageManagement}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-packagemanagement/media-package-inventory"
            component={MediaPackageInventory}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-packagemanagement/media-package-detail/:id?"
            component={MediaPackageDetail}
          />
          <PrivateRoute
            path="/media-monetization/media-assetmanagement"
            component={MediaAssetManagement}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-assetmanagement/media-asset-detail/:id?"
            component={MediaAssetDetail}
          />
          <PrivateRoute
            path="/media-monetization/media-creativemanagement"
            component={MediaCreativeList}
            exact
          />
          <PrivateRoute
            path="/media-monetization/media-creativemanagement/media-creative-detail/:id?"
            component={MediaCreativeDetail}
          />
          <PrivateRoute
            path="/media-monetization/media-creativemanagement/media-creative-group-list/:id?"
            component={MediaCreativeGroupList}
          />
          <PrivateRoute
            path="/media-monetization/media-creativemanagement/media-creative-group-ad-list/:creativeId?/:groupId?/:editable?"
            component={MediaCreativeAdList}
          />
          <PrivateRoute
            path="/media-monetization/media-creativemanagement/media-add-creative/:lineItemId/:packageId"
            component={MediaAddCreative}
          />
          <PrivateRoute path="/hr/designations" component={Designations} />
          <PrivateRoute
            path="/hr/designation-roles"
            exact
            component={DesignationRoles}
          />
          <PrivateRoute
            path="/hr/employees/:action?/:id?"
            component={UserShifts}
            exact
          />
          <PrivateRoute
            path="/hr/attendance-summary"
            isInMenu
            component={AttendanceSummary}
          />
          <PrivateRoute
            exact
            path="/settings/extensions/:id"
            isInMenu
            component={ExtensionDetailsForm}
          />
          <PrivateRoute
            path="/settings/users/:action?/:id?"
            component={Users}
          />
          <PrivateRoute
            path="/settings/customer-tags/:action?/:id?"
            component={CustomerTags}
          />
          <PrivateRoute
            path="/settings/stores/:action?/:id?"
            component={storeConfigurationV2Enabled ? StoresV2 : Stores}
          />
          <PrivateRoute
            path="/settings/delivery-area"
            isInMenu
            component={DeliveryArea}
          />
          <PrivateRoute
            path="/settings/analytics"
            isInMenu
            component={Analytics}
          />
          <PrivateRoute
            path="/settings/themes/:action?"
            component={Themes}
            exact
          />
          <PrivateRoute path="/settings/mobile-apps" component={MobileApps} />
          <PrivateRoute
            path="/settings/payment-configuration"
            isInMenu
            component={PaymentConfiguration}
          />
          <PrivateRoute path="/settings/slots" component={Slots} />
          <PrivateRoute path="/settings/seo" component={SEO} />
          <PrivateRoute
            path="/settings/logistics-configuration"
            isInMenu
            component={LogisticsConfig}
          />
          <PrivateRoute
            path="/settings/search-configuration"
            isInMenu
            component={SearchConfiguration}
          />
          <PrivateRoute
            path="/settings/communication-configuration"
            isInMenu
            component={CommunicationConfig}
          />
          <PrivateRoute
            path="/settings/order-config"
            isInMenu
            component={OrderManagement}
          />
          <PrivateRoute
            path="/user/change-password"
            component={ChangePassword}
          />
          <PrivateRoute
            path="/user/account-balance"
            component={AccountBalance}
          />
          <PrivateRoute
            path="/user/leave-management"
            component={LeaveManagement}
          />
          <PrivateRoute path="/user/my-leaves" component={MyLeaves} />
          <PrivateRoute path="/user/leave-summary" component={LeaveSummary} />
          <PrivateRoute
            path="/admin/action-trail"
            exact
            component={ActionTrail}
          />
          <PrivateRoute path="/admin/preorder" exact component={Preorder} />
          <PrivateRoute
            path="/in-store-preorder/offline"
            exact
            component={Preorder}
          />
          {isSnGActionEnable && (
            <PrivateRoute
              path="/admin/sng-actions/:customerId"
              exact
              component={SngActions}
            />
          )}
          <PrivateRoute
            path="/marketing/evoucher-details/:id?"
            component={EvoucherDetails}
            exact
          />
          <PrivateRoute
            path="/settings/basic-information"
            isInMenu
            component={BasicInformation}
            isEnterpriseRoute
          />
          <PrivateRoute
            path="/settings/contact-details"
            isInMenu
            component={SocialMedia}
            isEnterpriseRoute
          />
          <PrivateRoute
            path="/settings/extensions"
            isInMenu
            component={Extensions}
            isEnterpriseRoute
          />
          <PrivateRoute
            path="/settings/notifications"
            component={Notifications}
            isEnterpriseRoute
          />
          {!hideOrderRelatedRoutes && this.renderOrderRelatedPages()}
          {/* plop: append private route here */}
          <Route component={MissingPage} />
        </Switch>
      </RootDataContext.Provider>
    )
  }
}
