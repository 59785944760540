import React from 'react'
import { Row, Cell } from '../../Table'
import Tiles from './Tiles'
import {
  ColumnDate,
  ColumnName,
  ColumnStatus,
  TableDropdown,
  getStatus,
} from '../utils'

const tableProperties = () => {
  return {
    headers: [
      'Tiles',
      'Campaign Name',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({ id, name, tiles, startTime, endTime, onAction }) => {
      const status = getStatus(startTime, endTime)
      const editLink = `/app-home/tiles-dressup/edit/${id}`
      return (
        <Row>
          <Cell className="column-img-url">
            {tiles.upperTiles?.length > 0 && (
              <Tiles type="upper" items={tiles.upperTiles} />
            )}
            {tiles.lowerTiles?.length > 0 && (
              <Tiles type="lower" items={tiles.lowerTiles} />
            )}
          </Cell>
          <ColumnName link={editLink} name={name} />
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableDropdown
              id={id}
              type="tiles"
              onAction={onAction}
              editLink={editLink}
            />
          </Cell>
        </Row>
      )
    },
  }
}

const formatTiles = (source, data) => {
  let result

  if (source === 'api') {
    result = {
      upperTiles:
        data.tiles.upperTiles?.length > 0
          ? data.tiles.upperTiles.reduce((acc, curr, index) => {
              acc[`tile${index + 1}`] = curr
              return acc
            }, [])
          : {},
      lowerTiles:
        data.tiles.lowerTiles?.length > 0
          ? data.tiles.lowerTiles.reduce((acc, curr, index) => {
              acc[`tile${index + 3}`] = curr
              return acc
            }, [])
          : {},
    }
  }

  if (source === 'form') {
    result = {
      upperTiles:
        Object.keys(data.upperTiles).length > 0
          ? Object.values(data.upperTiles)
          : null,
      lowerTiles:
        Object.keys(data.lowerTiles).length > 0
          ? Object.values(data.lowerTiles)
          : null,
    }
  }

  return result
}

export { formatTiles, tableProperties }
