import PopupModal from 'components/Popup/Modal'
import React from 'react'

function InfoModal({ show, close, onConfirm, modalInfo }) {
  const { body, title, confirmBtnText, cancelBtnText } = modalInfo
  return (
    <PopupModal show={show} close={close}>
      <div className="confirmation-popup">
        <div className="body-wrapper">
          <p className="title">{title}</p>
          <p className="body">{body}</p>
          <div className="buttons-wrapper">
            <button className="button secondary" onClick={close}>
              {cancelBtnText}
            </button>
            <button className="button primary" onClick={onConfirm}>
              {confirmBtnText}
            </button>
          </div>
        </div>
      </div>
    </PopupModal>
  )
}

export default InfoModal
