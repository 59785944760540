import { SingleDatePicker, Select, Input } from 'components/Form'
import { getMessage } from 'lib/translator'
import React, { useState } from 'react'

const PrintFilter = () => {
  const [status, setStatus] = useState('')
  const [createTime, setCreateTime] = useState()
  const [vendorEmail, setVendorEmail] = useState('')

  const handlePrintFilter = () => {
    // Proceed with the actual implementation once the API becomes available.
    console.log('Submitting filter:', { status, createTime, vendorEmail })
  }

  const STATUS_OPTIONS = [
    { text: 'Placed', value: 'Placed' },
    { text: 'Printed', value: 'Printed' },
  ]

  return (
    <div className="print-order-details">
      <div className="form-fields">
        <Select
          name="select-status"
          placeholder={getMessage('gift.card.print.orders.status.placeholder')}
          label={getMessage('gift.card.print.orders.status')}
          options={STATUS_OPTIONS}
          value={status}
          testid="status"
          type="text"
          onChange={(e) => setStatus(e)}
        />
        <SingleDatePicker
          className="w-100"
          label={getMessage('gift.card.print.orders.create.time')}
          placeholder={getMessage(
            'gift.card.print.orders.create.time.placeholder'
          )}
          name="created-date"
          value={createTime}
          isOutsideRange
          onChange={(e) => setCreateTime(e)}
        />
        <Input
          label={getMessage('gift.card.print.orders.vendors.email')}
          value={vendorEmail}
          testid="vendor-email"
          type="text"
          onChange={(e) => setVendorEmail(e)}
        />
      </div>
      <div className="row flex-end">
        <div className="form-actions">
          <button
            type="button"
            className="primary"
            data-testid="submit"
            onClick={handlePrintFilter}
          >
            {getMessage('gift.card.print.orders.search')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PrintFilter
