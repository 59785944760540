import React from 'react'
import { ColumnDate, ColumnName, ColumnStatus, getStatus } from '../utils'
import { TableAction } from 'components/OmniJourney/utils'
import { getMessage } from 'lib/translator'
import { Row, Cell } from '../../Table'
import Card from './Card'

const tableProperties = () => {
  return {
    headers: [
      'Card',
      'Name',
      'Category',
      'Start Date',
      'End Date',
      'Status',
      'Actions',
    ],
    row: ({
      sk,
      pk,
      name,
      image,
      texts,
      category,
      startTime,
      endTime,
      isDisabled,
      onAction,
    }) => {
      const status = isDisabled
        ? getMessage('apphome.disabled')
        : getStatus(startTime, endTime)

      const editLink = `/app-home/card-content/edit/${category}/${sk}`

      return (
        <Row>
          <Cell className="column-card">
            <Card src={image} texts={texts} />
          </Cell>
          <ColumnName name={name} link={editLink} />
          <Cell className="column-category">
            {category.replace('cards#', '')}
          </Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={sk}
              pk={pk}
              onAction={onAction}
              isEnable={!isDisabled}
              editLink={editLink}
            />
          </Cell>
        </Row>
      )
    },
  }
}

export { tableProperties }
