import React from 'react'
import { BaseForm, Select, VALIDATION_TYPES } from 'components/Form'
import {
  ANALYTIC_SCHEMA,
  ANALYTIC_PLACEHOLDER,
  formatFormData,
  getConfig,
  isDsConfigurable,
  isCardSwimlane,
} from './utils'
import Loader from 'components/Loader'
import Recommender from './Recommender'
import { getMessage } from 'lib/translator'
import ModuleMetadata from './ModuleMetadata'
import { validateAnalytics } from '../utils'
import Analytic from '../Analytic'
import _unset from 'lodash/unset'
import _set from 'lodash/set'
import _get from 'lodash/get'
import API from 'lib/api'
import './style.css'

class ModuleForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      submitAsDraft: false,
      errorFields: {},
      datasource: [],
      isLoading: false,
      values: {
        mConfig: {
          default: {},
        },
      },
    }

    this.getModuleType = this.getModuleType.bind(this)
    this.addUrlParams = this.addUrlParams.bind(this)
    this.deleteUrlParams = this.deleteUrlParams.bind(this)
    this.handleDatasource = this.handleDatasource.bind(this)
    this.formatDatasource = this.formatDatasource.bind(this)
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    const { router } = this.props
    const { id, action } = router.match.params
    const ID = id.split('_')
    const layout = ID[0]
    const type = ID[1]

    const fetchDataSourceAPI = new API({
      url: `/genie/lms/datasource?mType=${type}`,
    })

    const fetchModuleAPI = new API({
      url: `/genie/lms/profile/module?omni_profile=${layout}`,
    })

    if (action === 'edit') {
      Promise.all([fetchModuleAPI.get(), fetchDataSourceAPI.get()]).then(
        (res) => {
          const index = ID[2]
          const values = formatFormData(res[0][index])
          const datasource = this.formatDatasource(type, res[1])
          this.setState({
            values,
            datasource,
            isLoading: false,
          })
        }
      )
    }
    if (action === 'add') {
      fetchDataSourceAPI.get().then((res) => {
        const datasource = this.formatDatasource(type, res)
        this.setState({ datasource, isLoading: false })
      })
    }
  }

  onSubmit(_data) {
    const hasAnalytics = _data.mConfig.default.analytic?.content
    if (hasAnalytics) {
      const { isValid, parsedAnalytic, errors } = validateAnalytics(
        _data.mConfig.default.analytic.content,
        ANALYTIC_SCHEMA
      )
      if (isValid) {
        _set(_data, 'mConfig.default.analytic.content', parsedAnalytic)
      } else {
        this.setState({
          errorFields: { swimlane: errors[0].stack },
        })
        return
      }
    } else {
      _unset(_data, 'mConfig.default.analytic')
    }

    this.setState({ isLoading: true })
    let payload
    let apiUrl
    let method
    const { id, action } = this.props.router.match.params
    const { mName, mDataSource } = _data
    const sharedPayload = {
      mConfig: getConfig(_data),
      mIsEnable: !this.state.submitAsDraft,
    }

    if (action === 'edit') {
      payload = {
        mName,
        mDataSource,
        ...sharedPayload,
      }
      apiUrl = `/genie/lms/module/config?module_pk=${_data.id}`
      method = 'patch'
    }

    if (action === 'add') {
      payload = {
        ..._data,
        omniProfile: id.split('_')[0],
        mType: id.split('_')[1],
        ...sharedPayload,
      }
      apiUrl = '/genie/lms/module'
      method = 'post'
    }

    new API({ url: apiUrl })[method](payload).then(() => {
      this.props.setLayout({ name: id.split('_')[0] })
      this.props.router.history.push(
        this.props.router.location.pathname.replace(
          /(\/add\/.*)|(\/edit\/.*)$/,
          ''
        )
      )
    })
  }

  getModuleType(id) {
    return id ? this.state.values.mType : this.props.moduleType
  }

  addUrlParams() {
    const values = Object.assign({}, this.state.values)
    const index = Object.values(
      values.mConfig.default.apiConfig.urlParams
    ).length
    values.mConfig.default.apiConfig.urlParams[index] = {
      id: crypto.randomUUID(),
    }
    this.setState({ values })
  }

  deleteUrlParams(id, index) {
    const values = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    values.mConfig.default.apiConfig.urlParams = Object.values(
      values.mConfig.default.apiConfig.urlParams
    )
      .filter((param) => param.id !== id)
      .reduce((accumulator, item, idx) => {
        accumulator[idx] = item
        return accumulator
      }, {})
    validations.mConfig.default.apiConfig.urlParams =
      validations.mConfig.default.apiConfig.urlParams.filter(
        (_, idx) => idx !== index
      )

    this.setState({ values, validations })
  }

  handleDatasource(datasource, type) {
    const newValues = Object.assign({}, this.state.values)
    const validations = Object.assign({}, this.state.validations)
    const hasUrlParams = newValues.mConfig.default.apiConfig?.urlParams
    const hasAnalytics = newValues.mConfig.default.analytic?.content
    if (isDsConfigurable(datasource) && !hasUrlParams) {
      this.setState({
        values: {
          ...newValues,
          mConfig: {
            default: {
              ...newValues.mConfig.default,
              apiConfig: {
                urlParams: {
                  0: { id: crypto.randomUUID() },
                },
              },
            },
          },
        },
      })
    }

    if (!isDsConfigurable(datasource) && hasUrlParams) {
      delete newValues.mConfig.default.apiConfig
      delete validations.mConfig.default.apiConfig
      this.setState({ values: newValues, validations })
    }

    if (
      type === 'product-swimlane' &&
      !isDsConfigurable(datasource) &&
      hasAnalytics
    ) {
      delete newValues.mConfig.default.analytic
      this.setState({ values: newValues, validations })
    }
  }

  formatDatasource(type, res) {
    return res.map((item) => ({
      text: isCardSwimlane(type)
        ? item.api
        : getMessage(`app.layout.module.datasource.${item.api}`),
      value: item.api,
    }))
  }

  render() {
    const { isLoading, errorFields, datasource, values } = this.state
    const { Form } = this.components
    const { router } = this.props
    const { action, id } = router.match.params
    const mType = id.split('_')[1]

    return (
      <>
        <h1>{getMessage(`app.layout.module.${action}`)}</h1>
        {isLoading && <Loader />}
        {!isLoading && (
          <Form className="module-form-section">
            <ModuleMetadata
              generateStateMappers={this.generateStateMappers}
              values={values}
            />
            {mType !== 'placeholder' && (
              <div className="datasource-section">
                <h3>{getMessage('apphome.configurations')}</h3>
                <Select
                  name="Datasource"
                  label={getMessage('app.layout.module.datasource')}
                  placeholder={getMessage(
                    'app.layout.module.datasource.placeholder'
                  )}
                  options={datasource}
                  {...this.generateStateMappers({
                    stateKeys: ['mDataSource'],
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                    loseEmphasisOnFill: true,
                    onChange: (value) => {
                      this.handleDatasource(value, mType)
                    },
                  })}
                />
              </div>
            )}
            {isDsConfigurable(values.mDataSource) && (
              // input for multiple key-value pairs
              <Recommender
                addUrlParams={this.addUrlParams}
                deleteUrlParams={this.deleteUrlParams}
                urlParams={_get(
                  values,
                  'mConfig.default.apiConfig.urlParams',
                  {}
                )}
                generateStateMappers={this.generateStateMappers}
              />
            )}
            {(isDsConfigurable(values.mDataSource) ||
              isCardSwimlane(mType)) && (
              <div className="analytics-section">
                <h3>{getMessage('apphome.analytics')}</h3>
                <Analytic
                  type="swimlane"
                  errorFields={errorFields}
                  dismissErrors={() => this.setState({ errorFields: {} })}
                  placeholder={ANALYTIC_PLACEHOLDER}
                  generateStateMappers={this.generateStateMappers}
                  stateKeys={['mConfig', 'default', 'analytic', 'content']}
                />
              </div>
            )}
            <div className="buttons-section">
              <button
                className="button"
                type="button"
                onClick={() => {
                  router.history.push(
                    router.location.pathname.replace(
                      /(\/add\/.*)|(\/edit\/.*)$/,
                      ''
                    )
                  )
                }}
              >
                {getMessage('apphome.cancel')}
              </button>
              {action === 'add' && (
                <button
                  type="button"
                  className="button"
                  data-testid="draft-button"
                  onClick={() => {
                    this.setState({ submitAsDraft: true }, this._submitHandler)
                  }}
                >
                  {getMessage('apphome.saveasdraft')}
                </button>
              )}
              <button
                className="button primary"
                type="button"
                onClick={() => {
                  this.setState({ submitAsDraft: false }, this._submitHandler)
                }}
              >
                {getMessage('apphome.enable')}
              </button>
            </div>
          </Form>
        )}
      </>
    )
  }
}

export default ModuleForm
