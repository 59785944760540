import React from 'react'
import ActionButton from './AppSplash/ActionButton'
import './style.css'

const PreviewSection = ({ actionButtons = [[], []], children }) => {
  const renderActionButtons = (buttons) => (
    <div className="column-flex">
      {buttons.map(({ type, handler, name, isErrorField = false }) => (
        <ActionButton
          key={type}
          name={name}
          type={type}
          isErrorField={isErrorField}
          handler={handler}
        />
      ))}
    </div>
  )
  return (
    <div className="preview-section">
      {renderActionButtons(actionButtons[0])}
      {children}
      {renderActionButtons(actionButtons[1])}
    </div>
  )
}

export default PreviewSection
