export const CONTENT_API_PAGES = [
  'appcard-listingpage',
  'app-popup-listingpage',
  'scratch-card-listing',
  'scan-to-win-listing',
]

export const APP_HOME_PAGES = [
  'appbanner-listingpage',
  'apptile-listingpage',
  'appsplash-listingpage',
  ...CONTENT_API_PAGES,
]
