const ANALYTICS_PLACEHOLDER = `Enter analytics in JSON format. Example:\n{\n  "ga": {},\n  "amp": {},\n}`

const TRIGGERS = [
  { text: 'Scan Product', value: 'scan-product' },
  { text: 'Scan Check-in', value: 'scan-check-in' },
]

const PRODUCT_TYPES = [
  { text: 'Product', value: 'product' },
  { text: 'Category', value: 'category' },
  { text: 'Brands', value: 'brands' },
]

const ANALYTIC_SCHEMA = {
  id: '/AnalyticSchema',
  type: 'object',
  additionalProperties: true,
  properties: {
    ga: { type: ['object', 'null'] },
    amp: { type: ['object', 'null'] },
  },
}

export { ANALYTICS_PLACEHOLDER, ANALYTIC_SCHEMA, TRIGGERS, PRODUCT_TYPES }
