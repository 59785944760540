import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Cell } from '../../../components/Table'
import { DropDown, DropDownItem, ICONS } from '../../../components/DropDown'
import { getMessage } from '../../../lib/translator'
import Toggle from '../../../components/Form/Inputs/Toggle'
import { OfferTypes } from './settings'
import { TABLE_ACTIONS } from '../../../containers/ListingPage'

const OfferActions = ({ id, onAction, status, editLink }) => (
  <React.Fragment>
    <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
      <DropDownItem>
        <Link to={editLink} data-testid="order-edit-link">
          {getMessage('offer.edit')}
        </Link>
      </DropDownItem>
      {status !== 'ENABLED' ? (
        <DropDownItem
          onClick={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { id },
              { status: 'ENABLED', tableAction: true }
            )
          }}
        >
          {getMessage('offer.action.enable')}
        </DropDownItem>
      ) : (
        <DropDownItem
          onClick={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { id },
              { status: 'DISABLED', tableAction: true }
            )
          }}
        >
          {getMessage('offer.action.disable')}
        </DropDownItem>
      )}
    </DropDown>
  </React.Fragment>
)

export const TableRow = ({
  id,
  metaData,
  offerType,
  offerValidFrom,
  offerValidTill,
  status,
  orderType,
  description,
  onAction,
}) => {
  const editLink = `/marketing/offers/edit/${id}`
  return (
    <Row>
      <Cell className="offer-table-name">
        <span className="desktop-only">
          {metaData ? metaData.sapReference : ''}
        </span>
      </Cell>
      <Cell className="offer-table-description">
        <div>
          <Link to={editLink} data-testid="order-detail-link">
            <div className="name">
              <span>{description}</span>
            </div>
          </Link>
        </div>
      </Cell>
      <Cell className="offer-table-type">
        <span className="desktop-only">{OfferTypes[offerType]}</span>
        <div className="mobile-only">
          <span className="text-muted">{`${getMessage('offer.type')} : `}</span>
          <span>{OfferTypes[offerType]}</span>
        </div>
      </Cell>
      <Cell className="offer-table-status">
        <span className="desktop-only">{status}</span>
        <Toggle
          className={`${status} mobile-only`}
          name={status}
          value={status === 'ENABLED'}
          icons={false}
          onChange={() => {
            onAction(
              TABLE_ACTIONS.UPDATE,
              { id },
              {
                status: status === 'ENABLED' ? 'DISABLED' : 'ENABLED',
                tableAction: true,
              }
            )
          }}
        />
      </Cell>
      <Cell className="offer-table-status">
        <span className="desktop-only">{orderType?.join(', ')}</span>
      </Cell>
      <Cell className="offer-table-time">
        <span className="desktop-only">{offerValidFrom}</span>
      </Cell>
      <Cell className="offer-table-time">
        <span className="desktop-only">{offerValidTill}</span>
      </Cell>
      <Cell className="offer-table-actions">
        <OfferActions
          onAction={onAction}
          id={id}
          status={status}
          editLink={editLink}
        />
      </Cell>
    </Row>
  )
}

export const tableProperties = () => {
  return {
    headers: [
      getMessage('offer.name'),
      getMessage('offer.description'),
      getMessage('offer.type'),
      getMessage('offer.status'),
      getMessage('offer.order.types'),
      getMessage('offer.validFrom'),
      getMessage('offer.validTill'),
      getMessage('offer.actions'),
    ],
    row: (props) => <TableRow {...props} />,
  }
}
