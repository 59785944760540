import React, { useState } from 'react'
import { getMessage } from 'lib/translator'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import arrow from 'components/Form/Inputs/Select/dropdown arrow.svg'
import Table, { Cell, Row, Header } from 'components/Table'
import { LayoutContext } from 'pages/app-home/ManageLayout'
import { ModulesContext } from './ModulesProvider'
import { IMG_PLACEHOLDER } from './utils'
import { Link } from 'react-router-dom'
import './style.css'

const TableProperties = ({ module, index, setPopUp }) => {
  const { layout } = React.useContext(LayoutContext)
  const EDIT_MODULE_URL = `/app-home/dynamic-layout/edit/${layout.name}_${module.mType}_${index}`

  return (
    <Table className="applayout-module-table">
      <Header>
        <Cell>{getMessage('app.layout.module.name')}</Cell>
        <Cell>{getMessage('app.layout.module.title')}</Cell>
        <Cell>{getMessage('app.layout.module.status')}</Cell>
        <Cell>{getMessage('app.layout.module.action')}</Cell>
      </Header>

      <Row>
        <Cell className={`module-${index}-name`}>
          <Link to={EDIT_MODULE_URL}>{module.mName}</Link>
        </Cell>
        <Cell>{module.mConfig.default.title}</Cell>
        <Cell
          className={`module-${index}-status module-status-${module.mIsEnable}`}
        >
          {module.mIsEnable
            ? getMessage('app.layout.enabled')
            : getMessage('app.layout.disabled')}
        </Cell>
        <Cell className="module-action">
          <ModuleDropdown
            index={index}
            module={module}
            setPopUp={setPopUp}
            editModuleURL={EDIT_MODULE_URL}
          />
        </Cell>
      </Row>
    </Table>
  )
}

const ModuleDropdown = ({ index, module, setPopUp, editModuleURL }) => {
  const { toggleModuleStatus, setCurrentModule } =
    React.useContext(ModulesContext)

  return (
    <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
      <DropDownItem>
        <Link to={editModuleURL}>{getMessage('apphome.edit')}</Link>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          toggleModuleStatus(index)
        }}
      >
        {module.mIsEnable
          ? getMessage('app.layout.disable')
          : getMessage('app.layout.enable')}
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          setPopUp('delete-module')
          setCurrentModule(module)
        }}
      >
        {getMessage('apphome.delete')}
      </DropDownItem>
    </DropDown>
  )
}

const Module = ({ index, module, setPopUp }) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div className="draggable-module">
      <div
        className="module-header"
        onClick={() => setShowDetails(!showDetails)}
      >
        <div
          className={`${
            module.mIsEnable ? 'enabled-module' : 'disabled-module'
          }`}
        >
          <h4>{module.mName}</h4>
        </div>
        <span className={`${!showDetails && `up-arrow`}`}>
          <img src={arrow} />
        </span>
      </div>
      {showDetails && (
        <div className="module-section">
          <div className="module-placeholder">
            <img
              src={IMG_PLACEHOLDER[module.mType] || IMG_PLACEHOLDER['default']}
              alt="module image"
            />
          </div>
          <TableProperties setPopUp={setPopUp} module={module} index={index} />
        </div>
      )}
    </div>
  )
}

export default Module
