const data = {
  'productUpload.csvDailyPresetDownloadText':
    'Download Daily Preset/Variance Sample CSV',
  'productUpload.csvPackInfoDownloadText':
    'Download Pack Info Update Sample CSV',
  'productUpload.csvPLUDownloadText': 'Download PLU Update Sample CSV',
  'productUpload.sampleBulkProductLocationDownload':
    'Download Bulk Product Location Update Sample',
  'productUpload.csvPickingThresholdDownloadText':
    'Download PLU Picking Threshold Sample CSV',
  'productUpload.csvBulkRoutingThresholdDownloadText':
    'Download Bulk Routing Threshold Sample CSV',
  'productUpload.csvDownloadText': 'Download sample CSV',
  'productUpload.uploadFileText': 'Click here to upload the file',
  'productUpload.addProduct': 'Add Product',
  'productUpload.orText': 'OR',
  'fileUpload.importCsv.heading': 'Import CSV file',
  'fileUpload.importedCsv.heading': 'Upload Now',
  'fileUpload.uploadedCsv.heading':
    'CSV File Uploaded Successfully! Products will appear after 15 minutes',
  'fileUpload.uploadedCsv.success': 'CSV File Uploaded Successfully!',
  'fileUpload.uploadedCsv.parsed': 'CSV File Parsed Successfully!',
  'fileUpload.importKML.heading': 'Import KML file',
  'fileUpload.importedKML.heading': 'Upload Now',
  'fileUpload.uploadedKML.heading': 'KML File Uploaded Successfully!',
  'category.heading': 'Categories',
  'category.form.name.heading': 'Name',
  'category.form.name.placeholder': 'Category Name',
  'category.form.tagline.heading': 'tag line',
  'category.form.tagline.placeholder': 'Enter Tag Line',
  'category.form.review': 'Review',
  'category.form.upload': 'Upload',
  'category.form.cancelText': 'Cancel',
  'category.form.goBackText': 'Go Back',
  'category.form.submitText': 'Submit',
  'category.form.parentCategory.heading': 'Parent Category',
  'category.form.parentCategory.placeholder': 'Select Parent Category',
  'category.form.description.heading': 'Description',
  'category.form.description.placeholder': 'Type Category Description',
  'category.form.edit.heading': 'Edit Category',
  'category.form.add.heading': 'Add Category',
  'category.form.uploadImage.heading': 'Upload Image',
  'category.form.hideText': 'Hide',
  'category.form.enableText': 'Enable',
  'category.form.deleteText': 'Delete',
  'category.header.id': 'Id',
  'category.header.name': 'Name',
  'category.header.parentCategory': 'Parent Category',
  'category.header.productCount': 'Product Count',
  'category.header.products': 'Products',
  'category.header.status': 'Status',
  'category.header.image': 'Image',
  'category.header.actions': 'Actions',
  'category.action.edit': 'Edit',
  'category.action.delete': 'Delete',
  'category.action.seeProducts': 'See Products',
  'category.helpItems.title': 'From the blog',
  'category.helpItems.ppo.title': 'Principles of product categorization',
  'category.helpItems.ppo.description':
    'Websites with helpful description get free visitors from search engines like Google.',
  'category.helpItems.poc.title': 'Pitfalls of over-categorization',
  'category.helpItems.poc.description':
    'Images improves your landing page appeal which directly correlates with better conversion.',
  'category.helpItems.seo.title': 'SEO impact of product categorization',
  'category.helpItems.seo.description':
    'Content marketing is a form of marketing focused on creating, publishing and distributing content for a targeted audience online.',
  'category.add.text': 'Add Category',
  'category.bulk.text': 'Bulk Assign Products',
  'category.tree.title': 'Category Tree',
  'category.icons.tree-title': 'Tree view',
  'category.icons.table-title': 'Table view',
  'category.helpItems.message': 'Your categories will be shown here.',
  'category.helpItems.submessage':
    'Proper categorization improves customer’s experience.',
  'category.filters.name.heading': 'Name',
  'category.filters.status.heading': 'Status',
  'category.filters.name.placeholder': 'Search by Name',
  'category.filters.submitText': 'Search',
  'category.filters.clearFiltersText': 'Clear All',
  'category.filters.status.label.all': 'All',
  'category.filters.status.label.enabled': 'Enabled',
  'category.filters.status.label.disabled': 'Disabled',
  'category.delete': 'Are you sure?',
  'category.delete.confirm': 'The category will be deleted',
  'category.delete.close': 'Cancel',
  'category.delete.okay': 'Okay',
  'category.add': 'Add',
  'category.view': 'View categories',
  'category.bulk-upload': 'Bulk Upload',
  'category.bulk-upload.columns.sku': 'SKU',
  'category.bulk-upload.columns.productName': 'Product Name',
  'category.bulk-upload.columns.category.primary': 'Primary Category',
  'category.bulk-upload.columns.category.secondary': 'Secondary Category',
  'category.bulk-upload.columns.category.secondary.action':
    'Secondary Category Action',
  'category.bulk-upload.error': 'Some rows have invalid/missing column values.',
  'navigation.sequencing.heading': 'Navigation Sequencing',
  'navigation.add.button': '+ Add',
  'navigation.nosequence.display': 'No Sequence to display',
  'navigation.dropdown.item.addLink': 'Add Link',
  'navigation.dropdown.item.addTag': 'Add Tag',

  'brand.heading': 'Brands',
  'brand.add.text': 'Add Brand',
  'brand.edit.text': 'Edit Brand',
  'brand.helpItems.message': 'Your brands will be shown here.',
  'brand.header.logo': 'Logo',
  'brand.header.brand': 'Brand',
  'brand.header.productCount': 'Product Count',
  'brand.header.status': 'Status',
  'brand.header.actions': 'Actions',
  'brand.status.enabled': 'Enabled',
  'brand.status.disabled': 'Disabled',
  'brand.status.hidden': 'Hidden',
  'brand.action.edit': 'Edit',
  'brand.action.delete': 'Delete',
  'brand.action.seeProducts': 'See products',
  'brand.filters.name.heading': 'Name',
  'brand.filters.status.heading': 'Status',
  'brand.filters.name.placeholder': 'Search by Name',
  'brand.filters.submitText': 'Search',
  'brand.filters.clearFiltersText': 'Clear All',
  'brand.filters.status.label.all': 'All',
  'brand.filters.status.label.enabled': 'Enabled',
  'brand.filters.status.label.hidden': 'Hidden',
  'brand.form.name.heading': 'Name',
  'brand.form.name.placeholder': 'Enter brand name',
  'brand.form.tagline.heading': 'tagline',
  'brand.form.tagline.placeholder': 'Enter Tag Line',
  'brand.form.image.heading': 'Image',
  'brand.form.image.placeholder': 'Click here to upload, or drag your image',
  'brand.form.hideText': 'Hide',
  'brand.form.enableText': 'Enable',
  'brand.form.deleteText': 'Delete',
  'brand.form.submitText': 'Save',
  'brand.form.cancelText': 'Cancel',

  'product.table.heading': 'Products',
  'product.form.add.heading': 'Add Product',
  'product.form.edit.heading': 'Edit Product',
  'product.filters.name.heading': 'Name',
  'product.filters.name.placeholder': 'Search by Name',
  'product.filters.clientId.heading': 'Client Id',
  'product.filters.clientId.placeholder': 'Search by Id',
  'product.filters.category.heading': 'Category',
  'product.filters.category.placeholder': 'Search by Category',
  'product.filters.brand.heading': 'Brand',
  'product.filters.brand.placeholder': 'Search by Brand',
  'product.filters.tag.heading': 'Tag',
  'product.filters.tag.placeholder': 'Search by Tag',
  'product.filters.link.placeholder': 'enter link text',
  'product.filters.status.heading': 'Status',
  'product.filters.status.label.all': 'All',
  'product.filters.status.label.enabled': 'Enabled',
  'product.filters.status.label.disabled': 'Disabled',
  'product.filters.submitText': 'Search',
  'product.filters.clearFiltersText': 'Clear All',
  'product.helpItems.message':
    'You have to create a catalogue of products for customers to buy from.',
  'product.helpItems.submessage':
    'Here you can provide all information about the products including pricing and upload some nice images.',
  'product.add.text': 'Add Product',
  'product.manage.text': 'Manage',
  'product.add.single.text': 'Single product',
  'product.add.bulk.text': 'Bulk upload',
  'product.add.assignment.text': 'Bulk Hide/Show',
  'product.add.showhide.bulk.text': 'Bulk show/hide upload',
  'product.add.showhide.column.displayUnit': 'Display Unit',
  'product.add.showhide.column.storeid': 'Store Id',
  'product.add.showhide.column.storeName': 'Store Name',
  'product.add.showhide.column.channel': 'Channel',
  'product.add.showhide.column.status': 'Status',
  'product.add.store.column.maxPurchaseQty': 'Max Purchase Qty',
  'product.add.store.column.storeBulk': 'Store Bulk',
  'product.add.store.column.unlimitedCheck': 'Unlimited Check',
  'product.add.store.column.stockBuffer': 'Stock Buffer',
  'product.global.modal.header': 'Global Attributes',
  'product.global.dropdown.placeholder': 'Select columns',
  'product.global.column.productName': 'Product Name',
  'product.global.column.name': 'Name',
  'product.global.column.brandId': 'Brand ID',
  'product.global.column.displayUnit': 'Display Unit',
  'product.global.column.primaryCategoryId': 'Primary Category ID',
  'product.global.column.secondaryCategoryIds': 'Secondary Category IDs',
  'product.global.column.images': 'Images',
  'product.global.column.imagesAction': 'Images Action',
  'product.global.column.tagId': 'Tag ID',
  'product.global.column.tagName': 'Tag Name',
  'product.global.column.tagAction': 'Tag Action',
  'product.global.column.keyInformation': 'Key Information',
  'product.global.column.keyInformationAction': 'Key Information Action',
  'product.global.column.additionalInformation': 'Additional Information',
  'product.global.column.additionalInformationAction':
    'Addititional Information Action',
  'product.global.column.ingredients': 'Ingredients',
  'product.global.column.ingredientsAction': 'Ingredients Action',
  'product.global.column.nutritionalData': 'Nutritional Data',
  'product.global.column.storageInformation': 'Storage Information',
  'product.global.column.preparation': 'Preparation',
  'product.global.column.dietaryAttributes': 'Dietary Attributes',
  'product.global.column.dietaryAttributesAction': 'Dietary Attributes Action',
  'product.global.column.globalBulk': 'Bulk Order Threshold',
  'product.global.column.countryOfOrigin': 'Country of Origin',
  'product.global.column.vendorCode': 'Vendor Code',
  'product.global.column.vendorCodeAction': 'Vendor Code Action',
  'product.global.column.barcodes': 'Barcodes',
  'product.global.column.barcodeAction': 'Barcode Action',
  'product.global.column.sku': 'SKU',
  'product.global.column.sellerSku': 'Seller SKU',
  'product.global.column.handlingDays': 'Handling Days',
  'product.global.column.isPreorderOnly': 'Is Preorder Only',
  'product.global.column.packType': 'Pack Type',
  'product.global.column.packTypeAction': 'Pack Type Action',
  'product.global.column.productType': 'Product Type',
  'product.global.column.productTypeAction': 'Product Type Action',
  'product.global.column.productVolume': 'Product Volume',
  'product.global.column.productVolumeAction': 'Product Volume Action',
  'product.global.column.hpbHealthierChoice': 'HPB Healthier Choice',
  'product.global.column.isOgNonComplaint': 'isOGNonComplaint',
  'scheduled.batch.jobs.column.jobName': 'Job Name',
  'scheduled.batch.jobs.column.jobType': 'Job Type',
  'scheduled.batch.jobs.column.jobId': 'Job Id',
  'scheduled.batch.jobs.column.taskId': 'Task Id',
  'scheduled.batch.jobs.column.payload': 'Tsk Payload',
  'scheduled.batch.jobs.column.sku': 'SKU',
  'scheduled.batch.jobs.column.status': 'Status',
  'scheduled.batch.jobs.column.createdBy': 'Created By',
  'scheduled.batch.jobs.column.jobExecutionTime': 'Job Execution Time',
  'scheduled.batch.jobs.column.avgJobExecutionTime': 'Avg Job Execution Time',
  'scheduled.batch.jobs.column.userID': 'User ID',
  'scheduled.batch.jobs.column.total': 'Total',
  'scheduled.batch.jobs.column.success': 'Success',
  'scheduled.batch.jobs.column.failure': 'Failure',
  'scheduled.batch.jobs.column.pending': 'Pending',
  'scheduled.batch.jobs.column.scheduledAt': 'Scheduled At',
  'scheduled.batch.jobs.column.uploadedAt': 'Uploaded At',
  'scheduled.batch.jobs.column.createdAt': 'Created At',
  'scheduled.batch.jobs.column.completedAt': 'Completed At',

  'deliveryArea.kml.upload.text': 'KML upload',
  'product.downloadCatalogueTitle': 'Download Catalogue',
  'product.downloadDialogClose': 'Okay',
  'product.header.image': 'Image',
  'product.header.name': 'Name',
  'product.header.sku': 'SKU',
  'product.header.id': 'ID',
  'product.header.primaryCategory': 'Primary category',
  'product.header.status': 'Status',
  'product.header.actions': 'Actions',
  'product.action.edit': 'Edit',
  'product.action.show': 'See Details',
  'product.action.delete': 'Delete',
  'product.action.enable': 'Enable',
  'product.action.disable': 'Hide',
  'product.action.view': 'View',
  'product.heading': 'Products',
  'product.form.name.heading': 'Name',
  'product.form.name.placeholder': 'Enter product name',
  'product.form.clientId.placeholder': 'Enter product clientId',
  'product.form.name.description':
    "Enter the name in the format 'product-name space number space kg/g/gm'",
  'product.form.brand.placeholder': 'Brand name',
  'product.form.category.heading': 'Category',
  'product.form.category.placeholder': 'Enter category name',
  'product.form.category.requiredMessage': 'This is not a valid category',
  'product.form.tag.heading': 'Tag',
  'product.form.tag.placeholder': 'Enter tag name',
  'product.form.tag.addButton': 'Add new tag',
  'product.form.description.heading': 'Description',
  'product.form.description.placeholder': 'Enter product description',
  'product.form.variant.addButtonText': 'Add Variant',
  'product.form.variant.editButtonText': 'Edit',
  'product.form.variant.saveButton': 'Save',
  'product.form.variant.cancelButton': 'Cancel',
  'product.form.variant.name.heading': 'Variant name',
  'product.form.variant.name.placeholder': 'Variant',
  'product.form.variant.heading': 'Variants',
  'product.form.variant.image.heading': 'Upload product images',
  'product.form.variant.image.placeholder':
    'Click to upload, or drag images here',
  'product.form.variant.image.description': '',
  'product.form.variant.price.heading': 'Price',
  'product.form.variant.price.placeholder': '0.00',
  'product.form.variant.discount.heading': 'Discount',
  'product.form.variant.discount.placeholder': '0.00',
  'product.form.variant.cgst.heading': 'CGST',
  'product.form.variant.cgst.placeholder': '0',
  'product.form.variant.sgst.heading': 'SGST',
  'product.form.variant.sgst.placeholder': '0',
  'product.form.variant.igst.heading': 'IGST',
  'product.form.variant.igst.placeholder': '0',
  'product.form.variant.barcodes.heading': 'Barcodes',
  'product.form.variant.barcodes.placeholder': 'Add Barcodes',
  'product.form.variant.availability.heading': 'Availability',
  'product.form.variant.unlimitedAvailable.label': 'Unlimited',
  'product.form.variant.stock.quantity.heading': 'Quantity',
  'product.form.variant.availability.placeholder': '0',
  'product.form.variant.availability.label': 'Available',
  'product.form.deleteText': 'Delete',
  'product.form.submitText': 'Save',
  'product.form.cancelText': 'Cancel',
  'product.form.delete.title': '',
  'product.form.delete.message':
    'Are you sure you want to delete this product?',
  'product.form.delete.cancelText': 'Cancel',
  'product.form.delete.confirmText': 'Confirm',
  'product.form.variant.delete.title': '',
  'product.form.variant.delete.message':
    'Are you sure you want to remove this variant?',
  'product.form.all.variants.delete.message':
    'Are you sure you want to remove all variants?',
  'product.form.variant.delete.cancelText': 'Cancel',
  'product.form.variant.delete.confirmText': 'Confirm',
  'product.form.stores.fetchError':
    'Failed to load some stores. Click here to try again.',
  'product.form.variant.rack.heading': 'Rack',
  'product.form.variant.aisle.heading': 'Aisle',
  'product.form.variant.shelf.heading': 'Shelf',
  'product.form.variant.position.placeholder': 'Position',
  'product.form.variant.rack.placeholder': 'Rack',
  'product.form.variant.aisle.placeholder': 'Aisle',
  'product.form.variant.primary.heading': 'Primary',
  'product.form.variant.secondary.heading': 'Secondary (optional)',
  'product.form.variant.secondaryExpiry.heading':
    'Secondary Expiry Date (optional)',
  'product.form.variant.primary.placeholder': 'Primary',
  'product.form.variant.secondary.placeholder': 'Secondary',
  'product.form.variant.secondaryExpiry.placeholder': 'Expiry',
  'product.form.variant.shelf.placeholder': 'Shelf',
  'product.form.soldByWeight': 'Sold by weight',
  'product.form.soldByWeight.enable': 'Enable',
  'product.form.handlingDays.placeholder': 'Enter pre-order handling days',
  'product.form.handlingDays.label': 'pre-order handling days',
  'product.form.bulkThreshold.placeholder': 'Enter bulk order threshold',
  'product.form.bulkThreshold.label': 'bulk order threshold',
  'product.form.soldByWeight.pattern': 'Incorrect name pattern',
  'product.form.variant.pattern':
    "Enter name in the format 'number space kg/g/gm' ",
  'product.form.brand.addButton': 'Add new brand',
  'product.form.enter': 'Enter',
  'product.form.clientItemId': 'Client Item Id',
  'product.form.clientItemId.placeholder': 'Enter client item id',
  'product.form.select': 'Select',
  'product.form.details.header': 'Product Details',
  'product.form.other.details.heading': 'Other Details',
  'product.form.variant.price.header': 'Pricing',
  'product.form.variant.tax.header': 'Tax',
  'product.form.variant.location.header': 'Location',
  'product.form.variant.stock.header': 'Stock',
  'product.form.imageUpload.uploading': 'Uploading...',
  'product.form.imageUploade.error': 'Error in uploading',
  'product.form.secondarycategory.heading': 'Secondary categories',
  'product.form.secondarycategory.placeholder': 'Enter secondary categories',

  'tag.heading': 'Tags',
  'tag.add.text': 'Add Tag',
  'tag.generateCSV.text': 'Generate CSV',
  'tag.startDate.text': 'Start Date',
  'tag.startTime.text': 'Start Time',
  'tag.endDate.text': 'End Date',
  'tag.endTime.text': 'End Time',
  'tag.startTime.placeholder': 'Start date',
  'tag.endTime.placeholder': 'End date',
  'tag.productSku.text': 'Product SKU',
  'tag.productSku.placeholder': 'Enter productSku with commas seperate',
  'tag.edit.text': 'Edit Tag',
  'tag.helpItems.message': 'Your product tags will appear here',
  'tag.form.name.heading': 'Name',
  'tag.form.name.placeholder': 'Enter tag name',
  'tag.form.tagline.heading': 'tagline',
  'tag.form.tagline.placeholder': 'Enter Tag Line',
  'tag.form.submitText': 'Submit',
  'tag.form.cancelText': 'Cancel',
  'tag.header.image': 'Image',
  'tag.form.image.heading': 'Image',
  'tag.form.image.placeholder': 'Click here to upload, or drag your image',
  'tag.header.tagId': 'ID',
  'tag.header.tag': 'Tag',
  'tag.header.productCount': 'Product Count',
  'tag.header.actions': 'Actions',
  'tag.action.edit': 'Edit',
  'tag.action.delete': 'Delete',
  'tag.action.linkToProduct': 'Link to products',
  'tag.action.seeProducts': 'See products',
  'tag.action.viewOnWebsite': 'View on Website',
  'tag.action.submit': 'Submit',
  'tag.filters.name.heading': 'Name',
  'tag.filters.name.placeholder': 'Search by Name',
  'tag.filters.submitText': 'Search',
  'tag.action.linkToProduct.heading': "Add '{name}' tag",
  'tag.linkToProduct.product.heading': 'Product',
  'tag.linkToProduct.product.placeholder': 'Enter a product name',
  'tag.linkToProduct.submitText': 'Submit',
  'tag.linkToProduct.product.action.remove': 'Remove',
  'tag.linkToProduct.product.action.retry': 'Retry',
  'tag.filter.clear': 'Clear All ',
  'tag.import': 'Import Tags',

  'substitutionGroup.heading': 'Substitution Groups',
  'substitutionGroup.add.text': 'Add group',
  'substitutionGroup.helpItems.message':
    'A substitution group includes products that are similar to each other and may act as replacements when required.',
  'substitutionGroup.header.name': 'Name',
  'substitutionGroup.header.productCount': 'Product count',
  'substitutionGroup.header.status': 'Status',
  'substitutionGroup.header.actions': 'Actions',
  'substitutionGroup.status.enabled': 'Enabled',
  'substitutionGroup.status.disabled': 'Disabled',
  'substitutionGroup.action.edit': 'Edit',
  'substitutionGroup.action.delete': 'Delete',
  'substitutionGroup.form.name.heading': 'Name',
  'substitutionGroup.form.name.placeholder': 'Name this substitution group',
  'substitutionGroup.form.percentageDeviation.heading': 'Percentage Deviation',
  'substitutionGroup.form.percentageDeviation.placeholder':
    'Enter a value between 0 and 100',
  'substitutionGroup.form.product.heading': 'Product',
  'substitutionGroup.form.product.placeholder': 'Enter name of a product',
  'substitutionGroup.form.product.requiredMessage':
    'A substitution group must have at least one product',
  'substitutionGroup.form.deleteText': 'Delete',
  'substitutionGroup.form.submitText': 'Submit',
  'substitutionGroup.form.cancelText': 'Cancel',
  'substitutionGroup.filters.name.heading': 'Name',
  'substitutionGroup.filters.name.placeholder': 'Search by name',
  'substitutionGroup.filters.status.heading': 'Name',
  'substitutionGroup.filters.status.label.all': 'All',
  'substitutionGroup.filters.status.label.enabled': 'Enabled',
  'substitutionGroup.filters.status.label.disabled': 'Disabled',
  'substitutionGroup.filters.submitText': 'Search',
  'substitutionGroup.filters.clearFiltersText': 'Clear All',
  'substitutionGroup.action.disable': 'Disable',
  'substitutionGroup.action.enable': 'Enable',
  'substitutionGroup.dialogs.disable.title': 'Are you sure?',
  'substitutionGroup.dialogs.enable.title': 'Are you sure?',
  'substitutionGroup.dialogs.disable.message':
    'This action will disable the substitution group',
  'substitutionGroup.dialogs.enable.message':
    'This action will enable the substitution group',
  'substitutionGroup.dialogs.confirmText': 'Confirm',
  'substitutionGroup.dialogs.cancelText': 'Cancel',

  'catalogue.dashboard.heading': 'Dashboard',
  'catalogue.dashboard.helpItems.title': 'From the blog',
  'catalogue.dashboard.helpItems.gp.title':
    'Good Product description saves money',
  'catalogue.dashboard.helpItems.gp.description':
    'Websites with helpful description get free visitors from search engines like Google.',
  'catalogue.dashboard.helpItems.uI.title':
    'Why uploading images are important?',
  'catalogue.dashboard.helpItems.uI.description':
    'Images improves your landing page appeal which directly correlates with better conversion. ',
  'catalogue.dashboard.helpItems.cm.title':
    'Defining a content marketing stategy',
  'catalogue.dashboard.helpItems.cm.description':
    'Content marketing is a form of marketing focused on creating, publishing and distributing content for a targeted audience online.',
  'catalogue.dashboard.productsWithoutImages':
    '{count, plural, one{Product} other{Products} } without images',
  'catalogue.dashboard.productsWithoutDescription':
    '{count, plural, one{Product} other{Products} } without description',
  'catalogue.dashboard.categories':
    '{count, plural, one{Category} other{Categories} }',
  'catalogue.dashboard.products':
    '{count, plural, one{Product} other{Products} }',
  'catalogue.dashboard.brands': '{count, plural, one{Brand} other{Brands} }',
  'catalogue.batch-jobs.heading': 'Batch Upload Jobs',
  'catalogue.batch-jobs.upload.schedule': 'Schedule Upload',

  // Offer SKU Limit Resources
  'catalogue.offerSKULimit.heading': 'Offer SKU Limit',
  'catalogue.offerSKULimit.scheduledDateTime': 'Scheduled Date & Time',
  'catalogue.offerSKULimit.noScheduledJob': 'There are no scheduled upload jobs',
  'catalogue.offerSKULimit.jobCancelled.title': 'Job Cancelled',
  'catalogue.offerSKULimit.jobCancelled.message': 'Offer SKU Limit Job Cancelled Successfully.',
  'catalogue.offerSKULimit.jobCreated.title': 'Job Created',
  'catalogue.offerSKULimit.jobCreated.message': 'Offer SKU Limit Job Created Successfully.',
  'catalogue.offerSKULimit.uploadFile.wrongFormat.title': 'Wrong file format',
  'catalogue.offerSKULimit.uploadFile.wrongFormat.message': 'Uploaded file is not in CSV format.',
  'catalogue.offerSKULimit.uploadFile.missingDateTime.title': 'Missing Scheduled Date Time',
  'catalogue.offerSKULimit.uploadFile.missingDateTime.message': 'Please select scheduled date time and try again.',
  'catalogue.offerSKULimit.uploadFile.earlierTimeSelected.title': 'Time selection error',
  'catalogue.offerSKULimit.uploadFile.earlierTimeSelected.message': 'You cannot schedule the time in the past.'
}

export default data
