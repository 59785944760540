import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Popup } from 'components/Popup'
import { Input } from 'components/Form'
import { format, parse } from 'libphonenumber-js'
import { STORE_TECH_BASE_URL } from '../../pages/customer-support/constants'
import Loader from '../Loader'
import './style.scss'
import API from '../../lib/api'
import { getMessage } from 'lib/translator'
import { LINKPASS_ADMIN_URL, LINK_HOST } from 'config/app'
import { hasPermissions } from 'lib/auth'

const ShowUpdatePersonalInfoInputButton = ({
  hasUpdatePersonalInfoPermissions,
  showBackOfficePhase2Feat,
  openInput,
  isButtonDisabled,
}) => {
  return (
    hasUpdatePersonalInfoPermissions &&
    showBackOfficePhase2Feat && (
      <button
        className="show-linkpass-email-input-button"
        onClick={openInput}
        disabled={isButtonDisabled}
      >
        &#x2710;
      </button>
    )
  )
}

const UpdatePersonalInfoInputField = ({
  placeholder,
  inputName,
  value,
  onChange,
  onClickUpdateInfo,
  disabled,
  loading,
  handleClose,
}) => {
  return (
    <div className="update-email-input-group">
      <Input
        type="text"
        placeholder={placeholder}
        name={inputName}
        value={value}
        onChange={onChange}
      />
      <button
        className="update-email-button"
        onClick={onClickUpdateInfo}
        disabled={disabled}
      >
        {loading ? '...' : '✓'}
      </button>
      <button className="close-update-email-button" onClick={handleClose}>
        &#x2716;
      </button>
    </div>
  )
}

class CustomerDetailsCard extends Component {
  constructor() {
    super()
    this.state = {
      errorMessage: '',
      loading: false,
      success: false,
      showDeleteLinkpassPopup: false,
      deleteReason: '',
      vc: '',
      hasGetLinkAccPermissions: hasPermissions(
        'loyalty',
        'link/search-users',
        'post',
        true
      ),
      hasResetPasswordPermissions: hasPermissions(
        'linkpass-admin',
        'reset-password',
        'post'
      ),
      hasUpdatePersonalInfoPermissions: hasPermissions(
        'linkpass-admin',
        'update-profile',
        'patch'
      ),
      newDisplayEmail: '',
      newDisplayName: '',
    }
    this.handleUnblockCustomer = this.handleUnblockCustomer.bind(this)
    this.handleDeleteLinkpassUser = this.handleDeleteLinkpassUser.bind(this)
    this.handleChangeReason = this.handleChangeReason.bind(this)
    this.handleSendResetPasswordLink =
      this.handleSendResetPasswordLink.bind(this)
    this.handleUpdateLinkpassEmail = this.handleUpdateLinkpassEmail.bind(this)
    this.handleUpdateLinkpassName = this.handleUpdateLinkpassName.bind(this)
    this.handleChangeLinkpassEmail = this.handleChangeLinkpassEmail.bind(this)
    this.handleChangeLinkpassName = this.handleChangeLinkpassName.bind(this)
    this.handleLogoutUser = this.handleLogoutUser.bind(this)
  }
  handleUnblockCustomer(customerID) {
    const api = new API({
      url: `${STORE_TECH_BASE_URL}/store/v0.2/customers/unblock/${customerID}`,
    })
    this.setState({
      loading: true,
      sucess: false,
    })
    api
      .put()
      .then(
        () => {
          // window.location.reload()
          this.setState({
            errorMessage: '',
            success: true,
          })
        },
        (error) => {
          if (error.code === 401) {
            throw error
          }
          this.setState({
            errorMessage: 'Unable to verify',
            success: false,
          })
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  handleDeleteLinkpassUser() {
    this.setState({ loading: true })
    const deleteLinkpassUserApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${this.props.uid}/permanent?reason=${this.state.deleteReason}`,
    })
    deleteLinkpassUserApi
      .delete()
      .then(() => {
        this.setState({
          showDeleteLinkpassPopup: false,
          loading: false,
          errorMessage: '',
        })
        this.props.router.history.push('/customer-support/customers')
      })
      .catch((err) => {
        this.setState({ loading: false, errorMessage: err.message })
      })
  }

  handleSendResetPasswordLink() {
    this.setState({ resetPasswordloading: true })
    const resetPasswordApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/internal/user/auth0/tickets/password-change`,
    })
    resetPasswordApi
      .post({ email: this.props.email })
      .then((res) => {
        if (res.ticket) {
          this.setState({
            changePasswordTicket: res.ticket,
            showPopup: true,
            resetPasswordloading: false,
          })
        }
      })
      .catch((err) => {
        this.setState({
          errorMessage: err.message,
          showPopup: true,
          resetPasswordloading: false,
        })
      })
  }

  handleChangeReason(e) {
    this.setState({ deleteReason: e })
  }

  handleChangeLinkpassEmail(e) {
    this.setState({ newEmail: e })
  }

  handleChangeLinkpassName(e) {
    this.setState({ newName: e })
  }

  handleUpdateLinkpassEmail() {
    this.setState({ updateEmailLoading: true })
    const updateEmailApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${this.props.uid}/personal-info`,
    })
    updateEmailApi
      .patch({ email: this.state.newEmail })
      .then((res) => {
        if (res.email) {
          this.setState({ newDisplayEmail: res.email })
        }
      })
      .catch((err) => {
        let errorMessage = ''
        if (err.message.includes('validEmail')) {
          errorMessage =
            'Email is not valid. Please check if your email has the correct format.'
        } else {
          errorMessage = err.message
        }
        this.setState({ errorMessage })
      })
      .finally(() => {
        this.setState({
          showPopup: true,
          showUpdateLinkpassInput: false,
          updateEmailLoading: false,
          newEmail: '',
        })
      })
  }

  handleUpdateLinkpassName() {
    this.setState({ updateNameLoading: true })
    const updateNameApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/uid/${this.props.uid}/personal-info`,
    })
    updateNameApi
      .patch({ name: this.state.newName })
      .then((res) => {
        if (res.name) {
          this.setState({ newDisplayName: res.name })
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: err.message })
      })
      .finally(() => {
        this.setState({
          showPopup: true,
          showUpdateLinkpassNameInput: false,
          updateNameLoading: false,
          newName: '',
        })
      })
  }

  handleLogoutUser() {
    const logoutUrl = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/user/logout`,
    })
    logoutUrl
      .post({ email: this.props.email })
      .then(() => {
        this.setState({ forceLogoutSuccess: true })
      })
      .catch((err) => {
        this.setState({ errorMessage: err.message })
      })
      .finally(() => {
        this.setState({
          showPopup: true,
        })
      })
  }

  componentDidMount() {
    const linkAccByUidApi = new API({
      url: `${LINK_HOST}/users/search`,
    })
    this.state.hasGetLinkAccPermissions &&
      linkAccByUidApi
        .post({ uid: [this.props.uid] })
        .then((res) => {
          this.setState({ vc: res[0]?.vc })
        })
        .catch(() => {
          // show default backoffice error
        })
  }

  render() {
    const { name, phone, email, uid, id, editHandler } = this.props
    return (
      <div className="CustomerDetailsCard">
        <div className="customer-details">
          <div className="detail">
            {!this.state.showUpdateLinkpassNameInput && (
              <div>
                {this.state.newDisplayName.length > 0
                  ? this.state.newDisplayName
                  : name}
                <ShowUpdatePersonalInfoInputButton
                  hasUpdatePersonalInfoPermissions={
                    this.state.hasUpdatePersonalInfoPermissions
                  }
                  showBackOfficePhase2Feat={this.props.showBackOfficePhase2Feat}
                  openInput={() =>
                    this.setState({ showUpdateLinkpassNameInput: true })
                  }
                  isButtonDisabled={this.state.showUpdateLinkpassInput}
                />
              </div>
            )}
            {this.state.showUpdateLinkpassNameInput && (
              <div>
                <UpdatePersonalInfoInputField
                  placeholder={getMessage(
                    'customerSupport.customer.changeLinkpassName.placeholder'
                  )}
                  inputName={'updateLinkpassName'}
                  value={this.state.newName}
                  onChange={this.handleChangeLinkpassName}
                  onClickUpdateInfo={this.handleUpdateLinkpassName}
                  disabled={
                    !this.state.newName || this.state.newName.length === 0
                  }
                  loading={this.state.updateNameLoading}
                  handleClose={() =>
                    this.setState({
                      showUpdateLinkpassNameInput: false,
                      newName: '',
                    })
                  }
                />
              </div>
            )}
            {this.props.isEntity ? (
              <>
                <div style={{ textAlign: 'left' }}>
                  UEN: <small>{this.props.uen}</small>
                </div>
                <div className="button-container">
                  <button
                    data-testid="button-edit"
                    className="button edit-btn"
                    onClick={() => editHandler()}
                  >
                    Edit
                  </button>
                  {this.props.isSaved && (
                    <div className="changes-saved">
                      {getMessage('eVoucher.entities.saved')}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {!this.state.showUpdateLinkpassInput && (
                  <div>
                    {this.state.newDisplayEmail.length > 0
                      ? this.state.newDisplayEmail
                      : email}{' '}
                    {
                      <ShowUpdatePersonalInfoInputButton
                        hasUpdatePersonalInfoPermissions={
                          this.state.hasUpdatePersonalInfoPermissions
                        }
                        showBackOfficePhase2Feat={
                          this.props.showBackOfficePhase2Feat
                        }
                        openInput={() =>
                          this.setState({ showUpdateLinkpassInput: true })
                        }
                        isButtonDisabled={
                          this.state.showUpdateLinkpassNameInput
                        }
                      />
                    }
                  </div>
                )}
                {this.state.showUpdateLinkpassInput && (
                  <UpdatePersonalInfoInputField
                    placeholder={getMessage(
                      'customerSupport.customer.changeLinkpassEmail.placeholder'
                    )}
                    inputName={'updateLinkpassEmail'}
                    value={this.state.newEmail}
                    onChange={this.handleChangeLinkpassEmail}
                    onClickUpdateInfo={this.handleUpdateLinkpassEmail}
                    disabled={
                      !this.state.newEmail || this.state.newEmail.length === 0
                    }
                    loading={this.state.updateEmailLoading}
                    handleClose={() =>
                      this.setState({
                        showUpdateLinkpassInput: false,
                        newEmail: '',
                      })
                    }
                  />
                )}
                <div>
                  {phone && phone.length > 0
                    ? format(parse(phone), 'International')
                    : phone}
                </div>
                <br />
                <div
                  style={{
                    color: 'rgb(128, 149, 157)',
                    fontWeight: 400,
                    fontSize: 11,
                  }}
                >
                  UID: {uid}
                </div>
                {this.state.hasGetLinkAccPermissions ? (
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: 11,
                    }}
                  >
                    {' '}
                    VC (Link):&nbsp;
                    {this.state.vc ? (
                      <a href={`/customer-support/link/vc/${this.state.vc}`}>
                        {this.state.vc}
                      </a>
                    ) : (
                      'none'
                    )}
                  </div>
                ) : null}
                {this.state.success ? (
                  <span className="success">Unblocked successfully!</span>
                ) : (
                  <div className="button-container">
                    {this.state.loading ? (
                      <Loader size="sm" />
                    ) : (
                      <button
                        className="button"
                        onClick={() => this.handleUnblockCustomer(id)}
                      >
                        Unblock Scan & Go
                      </button>
                    )}
                    {this.state.errorMessage && (
                      <span className="error">{this.state.errorMessage}</span>
                    )}
                  </div>
                )}
                {this.props.showDeleteLinkpassButton && (
                  <button
                    className="delete-linkpass-button"
                    onClick={() =>
                      this.setState({ showDeleteLinkpassPopup: true })
                    }
                  >
                    <b>Delete Linkpass Profile</b>
                  </button>
                )}
                {this.state.hasResetPasswordPermissions &&
                  this.props.showBackOfficePhase2Feat && (
                    <button
                      className="password-reset-button"
                      onClick={this.handleSendResetPasswordLink}
                    >
                      <b>
                        {this.state.resetPasswordloading
                          ? '...'
                          : 'Password reset link'}
                      </b>
                    </button>
                  )}
              </>
            )}
            {this.props.showBackOfficePhase2Feat && (
              <button
                className="force-logout-button"
                onClick={this.handleLogoutUser}
              >
                <b>
                  {this.state.forceLogoutLoading ? '...' : 'Force logout user'}
                </b>
              </button>
            )}
          </div>
        </div>
        <Popup
          show={this.state.showDeleteLinkpassPopup}
          heading={'Delete Linkpass'}
          close={() =>
            this.setState({ showDeleteLinkpassPopup: false, errorMessage: '' })
          }
        >
          <div className="delete-linkpass-popup">
            <p>Are you sure you want to delete linkpass profile?</p>
            {this.state.errorMessage.length > 0 ? (
              <p>Error: {this.state.errorMessage}</p>
            ) : (
              <>
                <Input
                  type="text"
                  label={getMessage(
                    'customerSupport.customer.deleteLinkpass.label'
                  )}
                  placeholder={getMessage(
                    'customerSupport.customer.deleteLinkpass.placeholder'
                  )}
                  name="deleteReason"
                  value={this.state.deleteReason}
                  onChange={this.handleChangeReason}
                />
                <button
                  onClick={this.handleDeleteLinkpassUser}
                  disabled={this.state.deleteReason.length === 0}
                >
                  {this.state.loading ? <Loader size="sm" /> : <b>Delete</b>}
                </button>
              </>
            )}
          </div>
        </Popup>
        <Popup
          show={this.state.showPopup}
          heading={this.state.errorMessage.length > 0 ? 'Error' : 'Success'}
          close={() =>
            this.setState({
              showPopup: false,
              errorMessage: '',
              changePasswordTicket: '',
              newEmail: '',
              newName: '',
              newDisplayEmail: '',
              newDisplayName: '',
              forceLogoutSuccess: false,
            })
          }
        >
          {this.state.errorMessage?.length > 0 && (
            <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
          )}
          {this.state.changePasswordTicket?.length > 0 && (
            <div>{this.state.changePasswordTicket}</div>
          )}
          {this.state.newDisplayEmail.length > 0 && (
            <div>
              <div>Change email success</div>
              <br />
              <div>New Email: {this.state.newDisplayEmail}</div>
            </div>
          )}
          {this.state.newDisplayName.length > 0 && (
            <div>
              <div>Change name success</div>
              <br />
              <div>New name: {this.state.newDisplayName}</div>
            </div>
          )}
          {this.state.forceLogoutSuccess && (
            <div>Force user logout success</div>
          )}
        </Popup>
      </div>
    )
  }
}

export default withRouter(({ location, history, match, ...props }) => (
  <CustomerDetailsCard router={{ location, history, match }} {...props} />
))
