import React from 'react'
import { PRODUCT_TYPES } from './utils'
import { getMessage } from 'lib/translator'
import { Input, Searchable, Select } from 'components/Form'

const SegmentRankProduct = ({
  productType,
  generateStateMappers,
  changeProductTypeHandler,
}) => {
  return (
    <>
      <Searchable
        label={getMessage('omni-journey.segment-field.label')}
        placeholder={getMessage('omni-journey.segment-field.placeholder')}
        transformResponse={(response) => response.data}
        searchUrl="/segments"
        searchKey="name"
        nameKey="name"
        valueKey="name"
        multiple={true}
        {...generateStateMappers({
          stateKeys: ['segments'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        type="number"
        name="rank"
        step="1"
        min={1}
        max={10000000}
        required
        label={getMessage('omni-journey.rank-field.label')}
        placeholder={getMessage('omni-journey.rank-field.placeholder')}
        {...generateStateMappers({
          stateKeys: ['rank'],
          loseEmphasisOnFill: true,
        })}
      />
      <Select
        name="productType"
        label={getMessage('omni-journey.product-type-field.label')}
        placeholder={getMessage('omni-journey.product-type-field.placeholder')}
        options={PRODUCT_TYPES}
        {...generateStateMappers({
          stateKeys: ['productType'],
          loseEmphasisOnFill: true,
          onChange: (e) => changeProductTypeHandler(e),
        })}
      />
      {productType === 'product' && (
        <Input
          name="product_id"
          label={getMessage('omni-journey.product-id-field.label')}
          placeholder={getMessage('omni-journey.product-id-field.placeholder')}
          {...generateStateMappers({
            stateKeys: ['productId'],
            loseEmphasisOnFill: true,
          })}
        />
      )}
    </>
  )
}

export default SegmentRankProduct
