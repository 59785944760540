import React, { useState } from 'react'
import { Row, Cell } from 'components/Table'
import { getMessage } from 'lib/translator'
import {
  ColumnDate,
  ColumnStatus,
  getStatus,
  contentAPIRequestHandler,
} from 'components/AppHome/utils'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import { TABLE_ACTIONS } from 'containers/ListingPage'
import PreviewTrigger from './ScanToWin/PreviewTrigger'
import { Dialog } from 'components/Popup'
import { Link } from 'react-router-dom'
import Screen from './Screen'

const OMNI_JOURNEY_MENU = ['scratch-cards', 'scan-to-win']

const SCRATCH_CARD_COMPONENTS = [
  'imageTopBackground',
  'imageCenterBackground',
  'imageCenter',
  'imageBottomBackground',
  'animation',
]

const DIALOG_TYPES = ['error', 'confirmation']

const getComponents = (components, values) => {
  return components.map((component) => {
    return {
      key: component,
      url: values[component] || null,
      position: component.replace(/image|Background/g, '').toLowerCase(),
    }
  })
}

const tableProperties = (menuItem) => {
  return {
    headers: ['', 'ID', 'Start Date', 'End Date', 'Status', 'Actions'],
    row: ({
      sk,
      pk,
      startTime,
      endTime,
      isDisabled,
      onAction,
      image,
      texts,
      type,
      ...rest
    }) => {
      const status = isDisabled
        ? getMessage('omni-journey.disabled-state')
        : getStatus(startTime, endTime)

      const components = SCRATCH_CARD_COMPONENTS.filter(
        (component) => component !== 'animation'
      )

      const getEditLink = (item) => {
        let link = ''
        if (item === OMNI_JOURNEY_MENU[0]) {
          link = `/omni-journey/${item}/edit/${sk}`
        }
        if (item === OMNI_JOURNEY_MENU[1]) {
          link = `/omni-journey/${item}/${type}/edit/${pk.replace('scan#product#', '')}/${sk}`
        }
        return link
      }

      return (
        <Row>
          <Cell>
            {menuItem === OMNI_JOURNEY_MENU[0] && (
              <Screen components={getComponents(components, rest)} />
            )}
            {menuItem === OMNI_JOURNEY_MENU[1] && (
              <PreviewTrigger
                src={image}
                text1={texts?.text1}
                text2={texts?.text2}
              />
            )}
          </Cell>
          <Cell className="column-campaign">
            <Link to={getEditLink(menuItem)}>{sk}</Link>
          </Cell>
          <ColumnDate type="start" time={startTime} />
          <ColumnDate type="end" time={endTime} />
          <ColumnStatus status={status} />
          <Cell className="column-actions">
            <TableAction
              id={sk}
              pk={pk}
              onAction={onAction}
              isEnable={!isDisabled}
              editLink={getEditLink(menuItem)}
            />
          </Cell>
        </Row>
      )
    },
  }
}

const TableDropdown = ({ isEnable, setAction, setDialogType, editLink }) => {
  return (
    <div>
      <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
        <DropDownItem
          onClick={() => {
            setAction('status')
            setDialogType(DIALOG_TYPES[1])
          }}
        >
          {isEnable
            ? getMessage('omni-journey.disable-button')
            : getMessage('omni-journey.enable-button')}
        </DropDownItem>

        <DropDownItem>
          <Link to={editLink}>{getMessage('omni-journey.edit-button')}</Link>
        </DropDownItem>

        <DropDownItem
          onClick={() => {
            setAction('delete')
            setDialogType(DIALOG_TYPES[1])
          }}
        >
          {getMessage('omni-journey.delete-button')}
        </DropDownItem>
      </DropDown>
    </div>
  )
}

const TableAction = ({ isEnable, onAction, id, pk, editLink }) => {
  const [dialogType, setDialogType] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [action, setAction] = useState(null)

  const callbacks = {
    onError: () => {
      setErrorMessage(getMessage('omni-journey.contact-team-msg'))
      setDialogType(DIALOG_TYPES[0])
    },
    onCancel: () => {},
  }

  const handleStatus = () => {
    setAction(null)
    setDialogType(null)
    const payload = {
      updates: [
        {
          pk: pk,
          sk: id,
          fields: [
            {
              op: 'SET',
              name: 'isDisabled',
              value: isEnable,
            },
          ],
        },
      ],
    }
    callbacks.onSuccess = () =>
      onAction(TABLE_ACTIONS.EDIT, { sk: id }, { isDisabled: isEnable })

    contentAPIRequestHandler('PATCH', payload, callbacks)
  }

  const handleDelete = () => {
    setAction(null)
    setDialogType(null)
    callbacks.onSuccess = () => window.location.reload()
    contentAPIRequestHandler('DELETE', { pk, sk: id }, callbacks)
  }

  const CONFIRMATION_ACTIONS = {
    status: handleStatus,
    delete: handleDelete,
  }

  return (
    <div>
      <Dialog
        show={dialogType === DIALOG_TYPES[0]}
        closeText={getMessage('omni-journey.okay-button')}
        close={() => setDialogType(null)}
        message={errorMessage}
      />
      <Dialog
        show={dialogType === DIALOG_TYPES[1]}
        onOk={CONFIRMATION_ACTIONS[action]}
        title={getMessage('omni-journey.confirmation-msg')}
        okText={getMessage('omni-journey.yes-button')}
        closeText={getMessage('omni-journey.cancel-button')}
        close={() => setDialogType(null)}
      />
      <TableDropdown
        isEnable={isEnable}
        setDialogType={setDialogType}
        setAction={setAction}
        editLink={editLink}
      />
    </div>
  )
}

const FormActionButtons = ({ buttons, onSave }) => {
  const { CancelButton, SubmitButton } = buttons
  return (
    <>
      <CancelButton>{getMessage('omni-journey.cancel-button')}</CancelButton>
      <button
        type="button"
        className="primary"
        data-testid="save-button"
        onClick={onSave}
      >
        {getMessage('omni-journey.save-button')}
      </button>
      <SubmitButton>{getMessage('omni-journey.enable-button')}</SubmitButton>
    </>
  )
}

const ErrorDialog = ({ show, message, onClose }) => {
  return (
    <Dialog
      show={show}
      information={message}
      close={onClose}
      closeText={getMessage('omni-journey.okay-button')}
    />
  )
}

export {
  SCRATCH_CARD_COMPONENTS,
  OMNI_JOURNEY_MENU,
  FormActionButtons,
  tableProperties,
  getComponents,
  ErrorDialog,
}
