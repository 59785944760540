import React from 'react'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'
import { ModulesContext } from './ModulesProvider'
import { LayoutContext } from 'pages/app-home/ManageLayout'
import API from 'lib/api'

const DeleteModule = ({ setPopUp }) => {
  const { currentModule, setCurrentModule } = React.useContext(ModulesContext)
  const { layout, setLayout } = React.useContext(LayoutContext)

  const deleteModule = () => {
    setPopUp(null)
    new API({
      url: `/genie/lms/module?module_pk=${currentModule.id}`,
    })
      .delete()
      .then(() => {
        setLayout({ ...layout })
      })
  }

  return (
    <Dialog
      show
      information={getMessage('apphome.delete.confirmation')}
      title={getMessage('apphome.confirmation')}
      close={() => {
        setPopUp(null)
        setCurrentModule(null)
      }}
      closeText={getMessage('apphome.cancel')}
      okText={getMessage('apphome.yes')}
      onOk={deleteModule}
    />
  )
}

export default DeleteModule
