import React, { useContext } from 'react'
import { Text, View } from '../ui'
import moment from 'moment'
import { SplitContext } from 'containers/SplitContext'
import features from 'containers/SplitContext/features'
import { When } from 'components/When'

export const PreorderInvoice = ({
  order,
  cartItems,
  qrSources,
  otherCartDetails,
}) => {
  const { splits } = useContext(SplitContext)

  return (
    <div id="preorderInvoice">
      <View
        direction="column"
        gap={16}
        margin="0 0 16px"
        style={{ fontFamily: 'Courier Prime' }}
      >
        <View direction="column" justifyContent="center">
          NTUC FAIRPRICE
          <div data-testid="store-name">{otherCartDetails.storeName}</div>
          <Text textAlign="center">{otherCartDetails.storeAddress}</Text>
          <div>TEL: {otherCartDetails.storePhone}</div>
        </View>
        <View direction="column" alignItems="start">
          <Text data-testid="cust-name">{otherCartDetails.customerName}</Text>
          <Text data-testid="cust-mobile">
            Contact: {otherCartDetails.customerPhone}
          </Text>
          <Text data-testid="collection-date">
            Collection Date:{' '}
            {moment(otherCartDetails.pickupDate).format('DD-MMM-YYYY')}
          </Text>
          <Text data-testid="collection-time">
            Collection Time: {otherCartDetails.pickupTime}
          </Text>
        </View>
        <div className="dotted__line" />
        <View justifyContent="space-between">
          <Text>ARTICLE</Text>
          <Text>DESCRIPTION</Text>
          <Text>QTY</Text>
          <Text>AMT</Text>
        </View>
        <div className="dotted__line" />
        {cartItems.map((item) => {
          const itemDetail = item.product || item.item
          const itemQuantity = Number(item.q || item.orderedQuantity)
          const sku = itemDetail.clientItemId
          return (
            <View key={sku} justifyContent="space-between">
              <Text data-testid={`sku-${sku}`} textAlign="left" minWidth={80}>
                {sku}
              </Text>
              <Text
                data-testid={`item-name-${sku}`}
                textAlign="left"
                minWidth={125}
              >
                {itemDetail.name}
              </Text>
              <Text
                data-testid={`item-qty-${sku}`}
                minWidth={30}
                textAlign="right"
              >
                {item.q || item.orderedQuantity}
              </Text>
              <Text
                data-testid={`item-amount-${sku}`}
                minWidth={70}
                textAlign="right"
              >
                {((item.mrp - item.discount) * itemQuantity).toFixed(2)}
              </Text>
            </View>
          )
        })}
        <div className="dotted__line" />
        <View justifyContent="space-between">
          <Text>TOTAL</Text>
          <Text>Qty</Text>
          <Text data-testid="total-qty" minWidth={120} textAlign="right">
            {cartItems.reduce(
              (t, item) => t + Number(item.q || item.orderedQuantity),
              0
            )}
          </Text>
        </View>
        <View justifyContent="space-between">
          <Text>TOTAL</Text>
          <Text>Amt</Text>
          <Text data-testid="total-amount" minWidth={120} textAlign="right">
            {/* order.invoiceAmount is from create order API POST /order (1st print) */}
            {/* deliveryOrder.payments[0].amount is from order details API order-service/v3/deliveryOrders (re-print) */}
            {parseFloat(
              order.invoiceAmount || order.payments[0].amount
            ).toFixed(2)}
          </Text>
        </View>
        <div className="dotted__line" />
        <View direction="column" alignItems="start">
          <div>Free Gift Redemption</div>
          <div data-testid="order-ref">Order Ref: {order.referenceNumber}</div>
          <div data-testid="order-time">
            Order Time: {moment(order.createTime).format('DD-MMM-YYYY HH:mm')}
          </div>
          <div data-testid="staff-id">Taken By: {otherCartDetails.staffId}</div>
          <div>Receipt No:</div>
          <div>Received by Customer:</div>
          <div>Date of Collection:</div>
        </View>
        <View>
          <Text fontWeight={700}>
            xxx This receipt does not serve as valid proof of payment. ***
          </Text>
        </View>
        <View direction="column" alignItems="start">
          <div>Terms & Conditions:</div>
          <div>1. All orders must be paid in FULL.</div>
          <div>2. No changes are allowed once order has been placed.</div>
          <div>3. Payment will be forfeited if order is cancelled.</div>
          <div>
            4. Order and collection of items must be from the same outlet.
          </div>
          <div>
            5. Collection of items is available only between 12noon to 10pm.
          </div>
          <div>
            6. No replacement/refund for order not collected on the stated
            collection day.
          </div>
          <div>
            7. All weights shown are net weight after cooking unless otherwise
            stated.
          </div>
          <div>8. Trimmings and props are not included for all items.</div>
          <div>
            9. All items are cooked and chilled to maintain freshness unless
            otherwise stated.
          </div>
          <div>10. Pre-heating should only be done before serving.</div>
          <div>
            11. No cutting service will be provided at our store for all items
            ordered.
          </div>
          <div>
            12. We reserve the right to limit quantities. Traders not allowed.
          </div>
          <div>
            13. All early bird gift with purchase items are to redeem during
            order collections.
          </div>
          <When
            condition={
              splits?.[features.NEW_TC_PREORDER_OFFLINE]?.treatment === 'on'
            }
          >
            <div>
              14. Early Bird Exclusive (Gift with Purchase - Brand Vouchers) is
              only available for eligible customers who pre-order via the
              FairPrice Group app.
            </div>
          </When>
        </View>
        <View direction="column" alignItems="start">
          <div>
            All personal data collected in this form may be used to facilitate
            your purchase of our goods, or shared with a third party delivery
            service provider for purposes of fulfilling your delivery request
          </div>
          <div style={{ wordWrap: 'break-word', width: '100%' }}>
            To learn more, please see our privacy policy here:
            https://help.fairprice.com.sg/hc/en-us/articles/4464676591129-Privacy-Notice
          </div>
          <div data-testid="printed-date">
            Printed: {moment().format('DD-MMM-YYYY HH:mm')}
          </div>
        </View>
        {qrSources.map((src, i) => {
          return (
            <View key={i} margin="0 0 8px">
              <img
                data-testid={`qr-image-${i}`}
                src={src}
                width={300}
                height={300}
              />
            </View>
          )
        })}
      </View>
    </div>
  )
}
