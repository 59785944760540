import React from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import API from 'lib/api'
import { Popup } from 'components/Popup'

import { LINKPASS_ADMIN_URL } from 'config/app'

import './styles.scss'

const StaffConnection = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [showErrorPopup, setShowErrorPopup] = React.useState(false)
  const [error, setError] = React.useState('')

  const exportStaffConnDetails = () => {
    setLoading(true)
    const exportApi = new API({
      url: `${LINKPASS_ADMIN_URL}/linkpass-admin-service/internal/user/external-party/fpg_staff/export`,
    })

    exportApi
      .get()
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'staffConnectionData.csv')
        document.body.appendChild(link)
        link.click()
        link.remove()
        setLoading(false)
      })
      .catch((err) => {
        setError(err.message)
        setShowErrorPopup(true)
        setLoading(false)
      })
  }

  return (
    <AuthenticatedPage
      menu={props.menu}
      from={props.location && props.location.pathname}
    >
      <div className="staff-connection-wrapper">
        <div>
          <h1>{getMessage('customerSupport.staffconnection.title')}</h1>
        </div>
        <div className="export-button">
          <button onClick={exportStaffConnDetails}>
            {loading ? '...' : 'Export'}
          </button>
        </div>
        <Popup
          show={showErrorPopup}
          heading={'Error'}
          close={() => {
            setShowErrorPopup(false)
            setError('')
          }}
        >
          <div style={{ color: 'red' }}>{error}</div>
        </Popup>
      </div>
    </AuthenticatedPage>
  )
}

export default StaffConnection
