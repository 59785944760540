import React from 'react'
import { withRouter } from 'react-router-dom'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import ModuleForm from 'components/AppHome/AppLayout/ModuleForm'
import Layout from 'components/AppHome/AppLayout/Layout'

export const LayoutContext = React.createContext()

const ManageLayout = (props) => {
  const [layout, setLayout] = React.useState({ name: 'Default' })
  const { menu, router } = props
  const showForm = router.match.params.action

  return (
    <AuthenticatedPage menu={menu}>
      <LayoutContext.Provider value={{ layout, setLayout }}>
        <div className="applayout-container">
          {!showForm && <Layout />}
          {showForm && <ModuleForm router={router} setLayout={setLayout} />}
        </div>
      </LayoutContext.Provider>
    </AuthenticatedPage>
  )
}

export default withRouter(({ location, history, match, ...props }) => (
  <ManageLayout router={{ location, history, match }} {...props} />
))
