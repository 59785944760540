import React, { useEffect, useState } from 'react'
import './style.css'

function InfoBanner({ message }) {
  const [valid, setValid] = useState(false)

  useEffect(() => {
    setValid(true)
    setTimeout(() => {
      setValid(false)
    }, 3000)
  }, [message])

  return (
    <>
      {valid && message.message ? (
        <div
          className={`InfoBanner ${message.status === 'error' ? 'InfoError' : ''}`}
        >
          {message.message}
        </div>
      ) : null}
    </>
  )
}

export default InfoBanner
