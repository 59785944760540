import React, { useEffect, useState } from 'react'
import API from 'lib/api'
import { CAMPAIGN_LABELS_API } from 'config/app'
import ConfigCard from './OfferSequence'
import './style.css'
import AddNewConfig from './AddNewConfig'
import InfoModal from './InfoModal'
import InfoBanner from './InfoBanner'

const CONFIGURATION_TYPE = {
  'offer-label-sequence': ConfigCard,
}

const Loader = () => {
  const template = (index) => (
    <div key={index} className="wrapper">
      <div className="card">
        <div className="card__img skeleton"></div>
        <div className="card__body">
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
          <div className="card__text skeleton"></div>
        </div>
      </div>
    </div>
  )
  return (
    <div>
      {[1, 2, 3, 4, 5].map((_, index) => {
        return template(index)
      })}
    </div>
  )
}

function ConfigCardList({ selectedOption }) {
  const [list, setList] = useState([])
  const [deleteId, setDeleteId] = useState([])
  const [isEditMode, setIsEditMode] = useState()
  const [editData, setEditData] = useState()
  const [loading, setloading] = useState(true)
  const [isDeleteButtonPressed, setIsDeleteButtonPressed] = useState(false)
  const [modalInfo, setModalInfo] = useState({})
  const [message, setMessage] = useState({})

  const ConfigType = CONFIGURATION_TYPE[selectedOption]

  const callAPI = async () => {
    try {
      setloading(true)
      const api = new API({
        url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}`,
      })

      const response = await api.get()
      const configurations = response.data.configurations
      setList(configurations)
    } catch (error) {
      /* istanbul ignore next */
      setMessage({ message: error.message, status: 'error' })
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    callAPI()
  }, [selectedOption])

  const onEdit = async (id) => {
    if (id) {
      setloading(true)
      try {
        const api = new API({
          url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}/${id}`,
        })

        const response = await api.get()
        setloading(false)
        setIsEditMode(true)

        const configurations = response.data
        setEditData(configurations)
      } catch (error) {
        /* istanbul ignore next */
        setMessage({ message: error.message, status: 'error' })
        /* istanbul ignore next */
        setloading(false)
      }
    }
  }

  const onDelete = (id) => {
    setDeleteId(id)
    setModalInfo({
      body: 'Are you sure you want to delete the config?',
      title: 'Delete Config',
      confirmBtnText: 'Delete',
      cancelBtnText: 'Cancel',
      onConfirm: onDeleteConfirm,
    })
    setIsDeleteButtonPressed(true)
  }

  const onDeleteConfirm = async () => {
    if (deleteId) {
      try {
        setloading(true)
        setIsDeleteButtonPressed(false)
        const api = new API({
          url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}/${deleteId}`,
        })

        const response = await api.delete()
        setMessage({ message: response.data, status: 'success' })
        setIsEditMode(false)
        callAPI()
      } catch (error) {
        /* istanbul ignore next */
        setMessage({ message: error.message, status: 'error' })
      } finally {
        setloading(false)
      }
    }
  }

  const onCancel = () => {
    setIsEditMode(false)
  }

  const onSave = async (data, id) => {
    try {
      setloading(true)
      const api = new API({
        url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}/${id}`,
      })

      const response = await api.put(data)
      setMessage({ message: response.data, status: 'success' })
      setIsEditMode(false)
      callAPI()
    } catch (error) {
      /* istanbul ignore next */
      setMessage({ message: error.message, status: 'error' })
    } finally {
      setloading(false)
    }
  }

  const hideModal = () => {
    setModalInfo({})
    setIsDeleteButtonPressed(false)
  }

  return (
    <div>
      {loading && Loader()}
      <InfoBanner message={message} />
      <InfoModal
        show={isDeleteButtonPressed}
        close={hideModal}
        modalInfo={modalInfo}
        onConfirm={onDeleteConfirm}
      />
      {isEditMode ? (
        <div>
          <AddNewConfig
            editData={editData}
            onCancel={onCancel}
            onSave={onSave}
          />
        </div>
      ) : (
        ''
      )}
      <div>
        {list.length && !isEditMode
          ? list.map((item) => {
              return (
                <ConfigType
                  key={item.id}
                  details={item}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              )
            })
          : ''}
      </div>
    </div>
  )
}

export default ConfigCardList
