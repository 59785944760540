import React from 'react'
import { getMessage } from 'lib/translator'
import { LayoutContext } from 'pages/app-home/ManageLayout'
import { ModulesContext } from './ModulesProvider'
import { Select } from 'components/Form'
import './style.css'

const LAYOUT_OPTIONS = [
  { text: 'Default', value: 'Default' },
  { text: 'ScanFairprice', value: 'ScanFairprice' },
  { text: 'ScanKopitiam', value: 'ScanKopitiam' },
]

const Navigator = ({ isDraft, setIsDraft }) => {
  const { layout, setLayout } = React.useContext(LayoutContext)
  const { updateModulePosition } = React.useContext(ModulesContext)
  const buttonsConfig = [
    {
      type: 'primary',
      label: getMessage('apphome.enable'),
      onClick: updateModulePosition,
      testId: 'publish-button',
    },
    {
      type: 'button',
      label: getMessage('apphome.cancel.draft'),
      onClick: () => {
        setLayout({ ...layout })
        setIsDraft(false)
      },
      testId: 'cancel-button',
    },
  ]

  return (
    <div className="selector-buttons-section">
      <div className="selector-section">
        <Select
          name="select-layout"
          placeholder={getMessage('app.layout.module.selectLayout.placeholder')}
          options={LAYOUT_OPTIONS}
          value={layout.name}
          onChange={(e) => {
            setLayout({ name: e })
          }}
        />
      </div>
      {isDraft && (
        <div className="buttons-section">
          {buttonsConfig.map((button) => (
            <button
              key={button.testId}
              type="button"
              className={button.type}
              onClick={button.onClick}
              data-testid={button.testId}
            >
              {button.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Navigator
