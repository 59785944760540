import React, { Component } from 'react'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import ConfigSetup from './ConfigSetup/index'

export default class ConfigurationSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <AuthenticatedPage
        menu={this.props.menu}
        from={this.props.location && this.props.location.pathname}
        className="ExtensionForms"
      >
        <ConfigSetup />
      </AuthenticatedPage>
    )
  }
}
