import API from 'lib/api'
import { LayoutContext } from 'pages/app-home/ManageLayout'
import React, { useState, useContext, useEffect } from 'react'

export const ModulesContext = React.createContext()

const ModulesProvider = ({ setIsDraft, children }) => {
  const [currentModule, setCurrentModule] = useState(null)
  const [moduleValues, setModuleValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { layout, setLayout } = useContext(LayoutContext)

  useEffect(() => {
    setIsLoading(true)
    new API({ url: `/genie/lms/profile/module?omni_profile=${layout.name}` })
      .get()
      .then((response) => {
        const values = response.reduce((accumulator, item, index) => {
          accumulator[index] = item
          return accumulator
        }, {})
        setModuleValues(values)
        setIsLoading(false)
      })
  }, [layout])

  const updateModulePosition = () => {
    const data = Object.values(moduleValues).map((val) => {
      return {
        ModulePK: val.id,
        'm-Position': val.mPosition,
      }
    })
    new API({ url: `/genie/lms/module/position` }).patch(data).then(() => {
      setLayout({ ...layout })
      setIsDraft(false)
    })
  }

  const toggleModuleStatus = (key) => {
    new API({
      url: `/genie/lms/module/enable?enable=${!moduleValues[key]
        .mIsEnable}&module_pk=${moduleValues[key].id}`,
    })
      .patch()
      .then(() => {
        setLayout({ ...layout })
      })
  }

  return (
    <ModulesContext.Provider
      value={{
        currentModule,
        setCurrentModule,
        moduleValues,
        setModuleValues,
        updateModulePosition,
        toggleModuleStatus,
        isLoading,
      }}
    >
      {children}
    </ModulesContext.Provider>
  )
}

export default ModulesProvider
