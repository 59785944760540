import React from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'

const DesignCodes = (props) => {
  return (
    <ListingPageWithRoutes
      {...props}
      testid="design-codes-listing"
      title="Design Codes"
      menu={props.menu}
      className="design-codes"
      form={{
        component: <>Test</>,
      }}
      viewHeading={'Test'}
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default DesignCodes
