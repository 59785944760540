import React from 'react'
import { LayoutContext } from 'pages/app-home/ManageLayout'
import { getMessage } from 'lib/translator'
import { Popup } from 'components/Popup'
import { Link } from 'react-router-dom'
import { MODULE_TYPES } from './utils'
import './style.css'

const SelectModuleType = ({ setPopUp }) => {
  const { layout } = React.useContext(LayoutContext)
  return (
    <Popup
      show
      heading="Select Module Type"
      close={() => setPopUp(null)}
      className="module-popup"
    >
      <div className="module-type-section">
        {MODULE_TYPES.map(({ type, text }) => (
          <Link
            to={`/app-home/dynamic-layout/add/${layout.name}_${type}`}
            key={type}
          >
            <button className="button applayout-hover-button" type="button">
              {getMessage(text)}
            </button>
          </Link>
        ))}
      </div>
    </Popup>
  )
}

export default SelectModuleType
