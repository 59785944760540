import React, { useState } from 'react'
import { getMessage } from 'lib/translator'
import SelectModuleType from './SelectModuleType'
import ModulesProvider from './ModulesProvider'
import DeleteModule from './DeleteModule'
import ModuleList from './ModuleList'
import Navigator from './Navigator'

const Layout = () => {
  const [popUp, setPopUp] = useState(null)
  const [isDraft, setIsDraft] = useState(false)

  return (
    <ModulesProvider setIsDraft={setIsDraft}>
      <h1>{getMessage('app.layout.header')}</h1>
      <div className="layout-wrapper">
        <Navigator isDraft={isDraft} setIsDraft={setIsDraft} />
        <ModuleList
          isDraft={isDraft}
          setPopUp={setPopUp}
          setIsDraft={setIsDraft}
        />
        {popUp === 'module-type' && <SelectModuleType setPopUp={setPopUp} />}
        {popUp === 'delete-module' && <DeleteModule setPopUp={setPopUp} />}
      </div>
    </ModulesProvider>
  )
}

export default Layout
