const data = {
  'omni-journey.error-msg.incomplete-form': 'Form is incomplete.',
  'omni-journey.error-msg.encounter-issue':
    'Oops, we encountered a little issue...',
  'omni-journey.success-msg.upload-success': 'Upload successful!',
  'omni-journey.confirmation-msg': 'Are you sure?',
  'omni-journey.contact-team-msg':
    'Unable to process request. Please check with Engagement tribe - Journey team.',
  'omni-journey.okay-button': 'Okay',
  'omni-journey.yes-button': 'Yes',
  'omni-journey.try-again-button': 'Try again?',
  'omni-journey.submit-button': 'Submit',
  'omni-journey.close-button': 'Close',
  'omni-journey.cancel-button': 'Cancel',
  'omni-journey.upload-button': 'Upload',
  'omni-journey.reset-button': 'Reset',
  'omni-journey.clear-all-button': 'Clear All',
  'omni-journey.save-button': 'Save as Draft',
  'omni-journey.enable-button': 'Publish',
  'omni-journey.done-button': 'Done',
  'omni-journey.back-button': 'Back',
  'omni-journey.preview-button': 'Preview',
  'omni-journey.disable-button': 'Unpublish',
  'omni-journey.edit-button': 'Edit',
  'omni-journey.delete-button': 'Delete',
  'omni-journey.disabled-state': 'Unpublished',

  'omni-journey.rank-field.label': 'Rank',
  'omni-journey.rank-field.placeholder': 'Set rank',
  'omni-journey.segment-field.label': 'Segment',
  'omni-journey.segment-field.placeholder': 'Enter segment',
  'omni-journey.product-type-field.label': 'Product Type',
  'omni-journey.product-type-field.placeholder': 'Select type',
  'omni-journey.scan-type-field.label': 'Scan Type',
  'omni-journey.scan-type-field.placeholder': 'Select type',
  'omni-journey.product-id-field.label': 'Product ID',
  'omni-journey.product-id-field.placeholder': 'Enter product ID',
  'omni-journey.title-field.label': 'Title',
  'omni-journey.title-field.placeholder': 'Enter title',
  'omni-journey.delay-field.label': 'Delay',
  'omni-journey.delay-field.placeholder': 'Set delay',
  'omni-journey.message-field.label': 'Message',
  'omni-journey.message-field.placeholder': 'Enter message',
  'omni-journey.start-date-field.label': 'Start date',
  'omni-journey.start-time-field.label': 'Start time',
  'omni-journey.date-field.placeholder': 'Pick date',
  'omni-journey.time-field.placeholder': 'Select time',
  'omni-journey.category-field.label': 'Category',
  'omni-journey.category-field.placeholder': 'Select Category',
  'omni-journey.store-field.label': 'Store',
  'omni-journey.store-field.placeholder': 'Enter store',
  'omni-journey.storeId-field.label': 'Store ID',
  'omni-journey.storeId-field.placeholder': 'Enter store ID',
  'omni-journey.storeType-field.label': 'Store Type',
  'omni-journey.storeType-field.placeholder': 'Enter store type',
  'omni-journey.filter.store.label': 'Filter by store',
  'omni-journey.filter.store.placeholder': 'Select store',
  'omni-journey.fileUpload-field.placeholder': 'Click here to upload file',
  'omni-journey.upload.content': 'Upload Content',
  'omni-journey.upload.content.placeholder': 'Click here to upload content',
  'omni-journey.upload.top.background': 'Top Background',
  'omni-journey.upload.center.background': 'Center Background',
  'omni-journey.upload.bottom.background': 'Bottom Background',
  'omni-journey.upload.animation': 'Animation',
  'omni-journey.upload.center': 'Center Image',
  'omni-journey.button.text.label': 'Button Text',
  'omni-journey.button.text.placeholder': 'Enter button text',

  'omni-journey.popup.header': 'Popup',
  'omni-journey.validity.header': 'Validity',
  'omni-journey.voucher.header': 'Manage Vouchers',
  'omni-journey.upload.content.header': 'Content',
  'omni-journey.voucher.upload-sequence': 'Upload Voucher Sequence',

  'omni-journey.scratch-card': 'Scratch Card',
  'omni-journey.create.scratch-card': 'Create Scratch Card',
  'omni-journey.before.scratch': 'Before scratch',
  'omni-journey.after.scratch': 'After scratch',

  'omni-journey.manage.scan-to-win': 'Manage Scan to Win',
  'omni-journey.create.trigger': 'Create Trigger',
  'omni-journey.select.trigger': 'Select Trigger',
  'omni-journey.scan-product': ' Scan Product',
  'omni-journey.scan-check-in': 'Scan Check-in',
  'omni-journey.scan-to-win.card.text-1.field.placeholder': 'Enter text 1',
  'omni-journey.scan-to-win.card.text-2.field.placeholder': 'Enter text 2',
}

export default data
