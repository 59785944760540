import { Input } from 'components/Form'
import AuthenticatedPage from 'containers/AuthenticatedPage'
import { getMessage } from 'lib/translator'
import React from 'react'

const Detail = (props) => {
  return (
    <AuthenticatedPage storeDependent menu={props.menu} title="order">
      <h1>{getMessage('gift.card.print.orders.details')}</h1>
      <div className="print-order-details">
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.status')}
              value="Printed"
              testid="detail-status"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.vendors.email')}
              value="abc@email.com"
              testid="detail-email"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.design.code')}
              value={props.router.match.params.distinctDesignCodes}
              testid="detail-design-code"
              type="text"
              readOnly
            />
          </div>
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.quantity')}
              testid="detail-quantity"
              value="3"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.created.by')}
              testid="detail-created-by"
              value="admin@email.com"
              type="text"
              readOnly
            />
          </div>

          <div className="col-6">
            <Input
              label={getMessage('gift.card.print.orders.create.time')}
              testid="detail-create-time"
              value="2025-01-01T00:00:00+08:00"
              type="text"
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Input
              className="updated-at"
              testid="detail-update-time"
              label={getMessage('gift.card.print.orders.update.time')}
              value="2025-01-01T00:00:00+08:00"
              type="text"
              readOnly
            />
          </div>
        </div>
      </div>
    </AuthenticatedPage>
  )
}

export default Detail
