import React from 'react'
import {
  Input,
  VALIDATION_TYPES,
  DateTime,
  Upload,
  Textarea,
} from 'components/Form'
import { getMessage } from 'lib/translator';
import './style.scss'

function PrimaryInputs({ generateStateMappers, isExpiredCampaign }) {
  return (
    <>
      <Input
        type="text"
        placeholder={getMessage("offer.campaigns.form.campaignName.placeholder")}
        name="campaignName"
        label={getMessage("offer.campaigns.form.campaignName")}
        required
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['name'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <Upload
        name="image"
        className="bannerImage"
        placeholder={getMessage("offer.campaigns.form.campaignBanner.placeholder")}
        label={getMessage("offer.campaigns.form.campaignBanner")}
        accept={'image/jpeg,image/jpg,image/png,image/gif'}
        required
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['image'],
          loseEmphasisOnFill: true,
        })}
      />
      <div className="datetime-picker-container">
        <DateTime
          name="startDateTime"
          label={getMessage("offer.campaigns.form.startDateTime")}
          allowAllDates
          required
          disabled={isExpiredCampaign}
          useTimePicker
          {...generateStateMappers({
            stateKeys: ['validFrom'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
        <div> - to - </div>
        <DateTime
          name="endDateTime"
          label={getMessage("offer.campaigns.form.endDateTime")}
          allowAllDates
          required
          disabled={isExpiredCampaign}
          useTimePicker
          {...generateStateMappers({
            stateKeys: ['validTill'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
      </div>
      <Input
        type="text"
        placeholder={getMessage("offer.campaigns.form.campaignTitle.placeholder")}
        name="campaignTitle"
        label={getMessage("offer.campaigns.form.campaignTitle")}
        required
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['title'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <Textarea
        placeholder={getMessage("offer.campaigns.form.campaignDescription.placeholder")}
        name="campaignDescription"
        label={getMessage("offer.campaigns.form.campaignDescription")}
        disabled={isExpiredCampaign}
        {...generateStateMappers({
          stateKeys: ['description'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
      />
      <div className="primary-cta-container">
        <Input
          type="text"
          placeholder={getMessage("offer.campaigns.form.primaryCTA.placeholder")}
          className="primary-cta"
          label={getMessage("offer.campaigns.form.primaryCTA")}
          required
          disabled={isExpiredCampaign}
          {...generateStateMappers({
            stateKeys: ['cta'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
        <Input
          type="text"
          placeholder={getMessage('offer.campaigns.form.redirectLink.placeholder')}
          className="link"
          label={getMessage('offer.campaigns.form.redirectLink')}
          disabled={isExpiredCampaign}
          {...generateStateMappers({
            stateKeys: ['link'],
            loseEmphasisOnFill: true,
            validationType: VALIDATION_TYPES.ONSUBMIT,
          })}
        />
      </div>
    </>
  )
}

export default PrimaryInputs
