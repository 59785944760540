import React from 'react'
import {
  BaseForm,
  Input,
  Searchable,
  Upload,
  VALIDATION_TYPES,
} from 'components/Form'
import _unset from 'lodash/unset'
import Loader from 'components/Loader'
import { Dialog } from 'components/Popup'
import { getMessage } from 'lib/translator'
import Validity from 'components/AppHome/Validity'
import Frequency from 'components/AppHome/AppPopup/Frequency'
import DailySchedule from 'components/AppHome/AppPopup/DailySchedule'
import ClickToAction from 'components/AppHome/AppPopup/ClickToAction'
import ClickToDismiss from 'components/AppHome/AppPopup/ClickToDismiss'
import {
  contentAPIRequestHandler,
  formatValidity,
  validateAnalytics,
} from 'components/AppHome/utils'
import { ANALYTIC_SCHEMA, DAYS } from 'components/AppHome/AppPopup/utils'
import './style.css'

class PopupForm extends BaseForm {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showErrorDialog: false,
      errorFields: {},
      errorMessage: null,
      values: {
        layout: 'Omni Home',
        displaySchedule: {
          mon: null,
          tue: null,
          wed: null,
          thu: null,
          fri: null,
          sat: null,
          sun: null,
        },
        view: {
          frequencyPerDay: 1,
          intervalInMinutes: 60,
        },
        seeBtn: {},
        closeBtn: {},
      },
    }

    this.normalizeSchedule = this.normalizeSchedule.bind(this)
    this.dismissErrors = this.dismissErrors.bind(this)
  }

  async componentDidMount() {
    if (this.props.method === 'edit') {
      this.setState({ loading: true })
      const apiData = this.props.apiData.data.items[0]
      const validity = await formatValidity('api', apiData)
      const orderedSchedule = DAYS.reduce((acc, day) => {
        if (
          Object.prototype.hasOwnProperty.call(apiData.displaySchedule, day)
        ) {
          acc[day] = apiData.displaySchedule[day]
        }
        return acc
      }, {})
      const segments =
        apiData.segments.length > 0
          ? {
              name: apiData.segments[0],
            }
          : null
      const values = {
        ...this.state.values,
        ...apiData,
        ...validity,
        segments,
        seeBtn: {
          ...apiData.seeBtn,
          analytic: JSON.stringify(apiData.seeBtn.analytic, null, 2),
        },
        view: {
          ...apiData.view,
          analytic: JSON.stringify(apiData.view.analytic, null, 2),
        },
        closeBtn: {
          ...apiData.closeBtn,
          analytic: JSON.stringify(apiData.closeBtn.analytic, null, 2),
        },
        displaySchedule: orderedSchedule,
      }
      this.setState({ values, loading: false })
    }
  }

  normalizeSchedule(schedule) {
    for (const day in schedule) {
      const times = schedule[day]
      if (times && times.startTime === null && times.endTime === null) {
        schedule[day] = null
      }
    }
    return schedule
  }

  async _submitHandler(e, saveAsDraft = false) {
    e && e.preventDefault()
    const { values } = this.state
    const analytics = ['seeBtn', 'view', 'closeBtn']
    const parsedAnalytics = {}
    const invalidAnalytics = {}
    // Validate analytics fields without setting them yet
    for (const type of analytics) {
      if (values[type]?.analytic) {
        const { isValid, parsedAnalytic, errors } = validateAnalytics(
          values[type].analytic,
          ANALYTIC_SCHEMA
        )
        if (isValid) {
          parsedAnalytics[type] = parsedAnalytic
        } else {
          invalidAnalytics[type] = errors[0].stack
        }
      } else {
        _unset(values, `${type}.analytic`)
      }
    }
    if (Object.keys(invalidAnalytics).length > 0) {
      this.setState({
        errorFields: invalidAnalytics,
      })
    } else {
      this.setState({ pressedSubmitWithCurrentData: true })
      if (this.isFormValid()) {
        this.setState({ loading: true })
        const { method } = this.props
        for (const field in parsedAnalytics) {
          values[field].analytic = parsedAnalytics[field]
        }
        const validity = await formatValidity('form', values)
        const data = {
          ...values,
          ...validity,
          segments: values.segments ? [values.segments.name] : [],
          displaySchedule: this.normalizeSchedule(values.displaySchedule),
          isDisabled: saveAsDraft,
        }
        const req = method === 'add' ? 'POST' : 'PUT'
        const payload = {
          contentType: 'popup',
          key: 'flash-vouchers',
          data,
        }
        const callbacks = {
          onError: (error) =>
            this.setState({
              showErrorDialog: true,
              errorMessage: error.message,
            }),
          onSuccess: this.props.onCancel,
          onCancel: () => this.setState({ loading: false }),
        }

        contentAPIRequestHandler(req, payload, callbacks)
      }
    }
  }

  dismissErrors() {
    this.setState({
      showErrorDialog: false,
      errorMessage: null,
      errorFields: {},
    })
  }

  render() {
    const { Form } = this.components
    const { loading } = this.state
    const { CancelButton, SubmitButton } = this.buttons

    return (
      <div className="apphome-popup-form">
        {loading && <Loader size="sm" />}
        {!loading && (
          <Form>
            {this.state.showErrorDialog && (
              <Dialog
                show={this.state.showErrorDialog}
                information={this.state.errorMessage}
                close={this.dismissErrors}
                closeText={getMessage('apphome.okay')}
              />
            )}
            <div className="grid-2-col">
              <Input
                readOnly
                type="text"
                label={getMessage('app.layout')}
                placeholder={getMessage('app.layout.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['layout'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Searchable
                label={getMessage('apphome.segment')}
                placeholder={getMessage('apphome.segment.placeholder')}
                searchUrl="/segments"
                valueKey="id"
                nameKey="name"
                searchKey="name"
                transformResponse={(response) => response.data}
                {...this.generateStateMappers({
                  stateKeys: ['segments'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                required
                type="text"
                label={getMessage('app.popup.title')}
                placeholder={getMessage('app.popup.title.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['title'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Input
                required
                type="text"
                label={getMessage('app.popup.description')}
                placeholder={getMessage('app.popup.description.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['desc'],
                  validationType: VALIDATION_TYPES.ONSUBMIT,
                  loseEmphasisOnFill: true,
                })}
              />
              <Upload
                required
                name="image"
                className="two-col"
                label={getMessage('app.popup.image')}
                placeholder={getMessage('app.popup.image.placeholder')}
                {...this.generateStateMappers({
                  stateKeys: ['imageUrl'],
                  loseEmphasisOnFill: true,
                })}
              />
              <Frequency
                frequencyPerDay={this.state.values.view.frequencyPerDay}
                generateStateMappers={this.generateStateMappers}
                errorFields={this.state.errorFields}
                dismissErrors={this.dismissErrors}
              />
              <ClickToAction
                generateStateMappers={this.generateStateMappers}
                errorFields={this.state.errorFields}
                dismissErrors={this.dismissErrors}
              />
              <ClickToDismiss
                generateStateMappers={this.generateStateMappers}
                errorFields={this.state.errorFields}
                dismissErrors={this.dismissErrors}
              />
              <h3 className="two-col">{getMessage('apphome.validity')}</h3>
              <Validity generateStateMappers={this.generateStateMappers} />
              <DailySchedule
                generateStateMappers={this.generateStateMappers}
                schedule={this.state.values.displaySchedule}
              />
              <div className="appFormButtons">
                <CancelButton>{getMessage('apphome.cancel')}</CancelButton>
                <button
                  type="button"
                  className="primary"
                  data-testid="save-button"
                  onClick={(e) => this._submitHandler(e, true)}
                >
                  {getMessage('apphome.saveasdraft')}
                </button>
                <SubmitButton>{getMessage('apphome.enable')}</SubmitButton>
              </div>
            </div>
          </Form>
        )}
      </div>
    )
  }
}

export default PopupForm
