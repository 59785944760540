import ImageSlideShow from './ImageSlideShow'
import ImageWithTextLayout from './ImageWithText'
import ProductCollectionLayout from './ProductCollection'
import ProductCollectionWithBannerLayout from './ProductCollectionWithBanner'
import BannerWithTextLayout from './BannerWithText'
import BannerWithMultipleButtons from './BannerWithMultipleButtons'
import VideoCarouselLayout from './VideoCarousel'
import CategoryCollection from './CategoryCollection'
import BrandCollection from './BrandCollection'
import SubCategoryCollection from './SubCategoryCollection'
import VoucherSwimLane from './VoucherSwimLane'
import './style.css'

/**
 *Object of all layouts and their respective jsx components
 */
const layouts = {
  ImageSlideShow: ImageSlideShow,
  ImageWithText: ImageWithTextLayout,
  ProductCollection: ProductCollectionLayout,
  BannerWithText: BannerWithTextLayout,
  BannerWithButton: BannerWithTextLayout,
  BannerWithMultipleButtons: BannerWithMultipleButtons,
  VideoCardCarousel: VideoCarouselLayout,
  CategoryCollection: CategoryCollection,
  BrandCollection: BrandCollection,
  SearchResults: ProductCollectionLayout,
  SubCategoryCollection: SubCategoryCollection,
  VoucherSwimlane: VoucherSwimLane,
  ImageVoucherSwimlane: VoucherSwimLane,
  ProductCollectionWithBanner: ProductCollectionWithBannerLayout,
}

/**
 * @param {*} pageIndex
 * @param {*} layoutName
 * @param {*} pagesArray
 */
export const getLayout = (pageIndex, layoutName, pagesArray, selectOptions) => {
  const defaultOption =
    pagesArray[pageIndex] !== 'home' && pagesArray[pageIndex] !== 'search'
      ? 'CURRENT'
      : 'ALL'
  if (
    layoutName === 'ProductCollection' ||
    layoutName === 'SearchResults' ||
    layoutName === 'ProductCollectionWithBanner'
  ) {
    return layouts[layoutName](selectOptions, defaultOption)
  } else {
    return layouts[layoutName]()
  }
}
export default getLayout
