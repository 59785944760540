import React from 'react'
import Form, {
  BaseForm,
  Input,
  Select,
  SingleDatePicker,
} from 'components/Form'
import moment from 'moment'
import './style.css'

const ConfigTypes = [
  {
    text: 'Standard',
    value: 'standard',
  },
]

const Status = [
  {
    text: 'Enabled',
    value: 'ENABLED',
  },
  {
    text: 'Disabled',
    value: 'DISABLED',
  },
]

const getTimes = () => {
  const times = []
  for (let i = 0; i <= 23; i++) {
    if (i < 10) {
      times.push(`0${i}:00`)
      times.push(`0${i}:30`)
    } else {
      times.push(`${i}:00`)
      times.push(`${i}:30`)
    }
  }
  return times
}

class AddNewConfig extends BaseForm {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      status: 'DISABLED',
      variant: '',
      offerSequence: '',
      startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      startTime: '',
      endDate: '',
      endTime: '',
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.validateFields = this.validateFields.bind(this)
  }

  // Function to validate state properties
  validateFields = () => {
    const { status, variant, offerSequence, startDate, startTime } = this.state
    let isValid = true

    if (
      !status ||
      !variant ||
      !offerSequence ||
      !startDate ||
      !startTime ||
      offerSequence.trim() === ''
    ) {
      isValid = false
    }

    return isValid
  }

  componentDidMount() {
    if (this.props.editData) {
      const { id, status, data, variant, validFrom, validTill } =
        this.props.editData

      // assuming this value always available
      const fromDate = new Date(validFrom).toLocaleDateString('en-CA')
      const fromTime = new Date(validFrom).toLocaleTimeString()

      const tillDate = validTill
        ? new Date(validTill).toLocaleDateString('en-CA')
        : undefined
      const tillTime = validTill
        ? new Date(validTill).toLocaleTimeString()
        : undefined

      this.setState({
        id: id,
        status: status,
        variant: variant,
        offerSequence: data?.data.join(','),
        startDate: fromDate,
        startTime: moment(fromTime, 'HH:mm:ss').format('HH:mm'),
        endDate: tillDate ? tillDate : '',
        endTime: tillTime ? moment(tillTime, 'HH:mm:ss').format('HH:mm') : '',
      })
    }
  }

  onSubmit(evt) {
    evt.preventDefault()
    if (this.validateFields()) {
      const data = {
        status: this.state.status,
        variant: this.state.variant,
        data: {
          data: this.state.offerSequence.split(','),
        },
        validFrom: moment(
          `${this.state.startDate?.split(' ')[0]} ${this.state.startTime}`,
          'YYYY-MM-DD HH:mm'
        )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        validTill:
          this.state?.endDate && this.state?.endTime
            ? moment(
                `${this.state.endDate.split(' ')[0]} ${this.state.endTime}`,
                'YYYY-MM-DD HH:mm'
              )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss[Z]')
            : null,
      }

      if (this.props.editData) {
        this.props.onSave(data, this.state.id)
      } else {
        this.props.onSave(data)
      }
    } else {
      return
    }
  }

  render() {
    return (
      <div className="ConfigAddNew">
        <div className="AddHeader">
          <h3>
            {this.props.editData ? 'Edit Configuration' : '+ Add Configuration'}
          </h3>

          <div className="ConfigBottom">
            <button
              className={`ConfigSubmit${!this.validateFields() ? ' SaveDisabled' : ''}`}
              onClick={this.onSubmit}
            >
              Save
            </button>
            <button className="ConfigCancel" onClick={this.props.onCancel}>
              Cancel
            </button>
          </div>
        </div>
        <Form>
          <div className="FirstBox">
            <Select
              name="status"
              placeholder="Please select the status"
              label="Offer Status"
              options={Status}
              value={this.state.status}
              onChange={(value) => {
                this.setState({ status: value })
              }}
            />
            <Select
              name="variant"
              placeholder="Please select offer variant"
              label="Type of Variant"
              options={ConfigTypes}
              value={this.state.variant}
              onChange={(value) => {
                this.setState({ variant: value })
              }}
            />
          </div>
          <Input
            type="text"
            name="sequence"
            label="Offer Sequence"
            placeholder="Enter the offer sequence comma separated - JWC, DC"
            value={this.state.offerSequence}
            onChange={(value) => {
              this.setState({ offerSequence: value })
            }}
          />

          <div className="ConfigTime">
            <div className="ConfigStartTime">
              <SingleDatePicker
                label="Validity from"
                placeholder="Start Date"
                displayFormat="YYYY-MM-DD"
                name="startDate"
                className="date-section"
                enableToday
                openDirection={'up'}
                value={this.state.startDate}
                onChange={(value) => {
                  this.setState({ startDate: value })
                }}
              />
              <Select
                label="Start Time"
                name="configStartTime"
                type="text"
                className="time-section"
                options={getTimes()}
                value={this.state.startTime}
                onChange={(value) => {
                  this.setState({ startTime: value })
                }}
              />
            </div>
            {this.props?.editData?.variant !== 'default' ? (
              <div className="ConfigEndTime">
                <SingleDatePicker
                  label="Validity To"
                  placeholder="End Date"
                  displayFormat="YYYY-MM-DD"
                  name="endDate"
                  className="date-section"
                  allowToday
                  enableToday
                  showError={this.state.endDate < this.state.startDate}
                  openDirection={'up'}
                  value={this.state.endDate}
                  onChange={(value) => {
                    this.setState({ endDate: value })
                  }}
                />
                <Select
                  label="End Time"
                  name="configEndTime"
                  type="text"
                  className="time-section"
                  placeholder={this.state.endTime}
                  options={getTimes()}
                  value={this.state.endTime}
                  onChange={(value) => {
                    this.setState({ endTime: value })
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Form>
      </div>
    )
  }
}

export default AddNewConfig
