import React from 'react'
import { getMessage } from 'lib/translator'
import { Row, Cell } from 'components/Table'
import moment from 'moment'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'

const tableProperties = (handleCancel, handleDownload) => {
  return {
    headers: [
      getMessage('scheduled.batch.jobs.column.jobName'),
      getMessage('scheduled.batch.jobs.column.userID'),
      getMessage('scheduled.batch.jobs.column.uploadedAt'),
      getMessage('scheduled.batch.jobs.column.scheduledAt'),
      'Status',
      'Download',
    ],
    row: ({ name, scheduledAt, updatedAt, status, userId, id, data }) => (
      <Row>
        <Cell className="jobname">
          <span>{name}</span>
        </Cell>
        <Cell className="userID">{userId}</Cell>
        <Cell className="uploadedAt">
          <div>{moment(updatedAt).format('DD MMM YYYY')}</div>
          <small className="text-muted">
            {moment(updatedAt).format('HH:mm:ss')}
          </small>
        </Cell>
        <Cell className="scheduledAt">
          <div>{moment(scheduledAt).format('DD MMM YYYY')}</div>
          <small className="text-muted">
            {moment(scheduledAt).format('HH:mm:ss')}
          </small>
        </Cell>
        <Cell className="scheduledAt">{status}</Cell>
        <Cell className="scheduledAt">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem onClick={() => handleDownload(name, data)} dataTestid={`download-btn-${id}`}>
              Download
            </DropDownItem>
            {status === 'PENDING' && (
              <DropDownItem onClick={() => handleCancel(id)} dataTestid={`cancel-btn-${id}`}>
                Cancel
              </DropDownItem>
            )}
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}

export default tableProperties
