import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ListingPageWithRoutes from '../../../containers/ListingPage/listingRouter'
import { getMessage } from '../../../lib/translator'
import API from '../../../lib/api'
import {
  isExtensionEnabled,
  hasPermissions,
} from '../../../lib/auth'
import OfferForm from './Form'
import BANYATP from './Form/BANYATP'
import BMINXATP from './Form/BMINXATP'
import BANYGYD from './Form/BANYGYD'
import BXATP from './Form/BXATP'
import BXGYD from './Form/BXGYD'
import SF from './Form/SF'
import SFXGSD from './Form/SFXGSD'
import BMIN from './Form/BMIN'
import PWP from './Form/PWP'
import BFXATP from './Form/BFXATP'
import BMINXGFG from './Form/BMINXGFG'
import OfferFilter from './Filters'
import EmptyIcon from './coupons-empty.svg'
import { formatDataBeforeSubmit } from '../../../components/SegmentsForm'
import { getStoreName } from '../../../containers/StoreSelector'
import './style.css'
import {
  OfferTypes,
  OfferMapping,
  ProductOnlyOffers,
  EntityIds,
  DiscountTypes,
  StoreOptions,
  ItemDiscountTypes,
  linkPayOffers,
  getEntityIds,
} from './settings'
import { tableProperties } from './tableProperties'
import HeaderActions from './HeaderActions'

export const BANYGLED = 'BANYGLED' // Unity Offer

function AddButton() {
  return (
    <Link to="/marketing/offers/add" className="primary button">
      + <span className="text">{getMessage('offer.add.text')}</span>
    </Link>
  )
}


const EmptyState = {
  icon: EmptyIcon,
  message: getMessage('offer.emptyMessage'),
  additionalViews: [AddButton],
}

class Offer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
    }
    this.changeTab = this.changeTab.bind(this)
  }

  changeTab(activeIndex) {
    this.setState({ activeIndex })
  }

  UNSAFE_componentWillMount() {
    if (this.props.match.url === '/marketing/offers/pay-via-fp-app') {
      this.props.history.push('/marketing/offers')
    }
  }
  componentDidMount() {
    this.offerForm = {
      BANYATP: BANYATP,
      BMINXATP: BMINXATP,
      BANYGYD: BANYGYD,
      SFXGSD: SFXGSD,
      SFXGSFD: SFXGSD,
      SFXGCD: SFXGSD,
      BXATP: BXATP,
      BXGYD: BXGYD,
      SF: SF,
      PWP: PWP,
      BMIN: BMIN,
      BFXATP: BFXATP,
      BMINXGFG: BMINXGFG,
    }
    this.buyKey = 'buy'
    if (isExtensionEnabled('MultiStoreSupport')) {
      const api = new API({ url: '/account-service/store' })
      api.get({ paginate: 'false' }).then((response) => {
        const stores = (response.data.store || []).map(store => ({
          ...store,
          name: getStoreName(store),
        })).filter((store) => store.hasPicking || store.hasSelfCheckout)
      this.setState({ stores})
      })
    }
    if (
      (isExtensionEnabled('ProductTagSupport') &&
      hasPermissions('catalogue', 'tag', 'get')) || this.props.hasPwpAccess
    ) {
      this.pwpwApi = new API({ url: '/catalogue-service/tag' })
      this.pwpwApi.get({ slug: 'pwp' })?.then(response => {
        const tag = response.data.tag || []
        const pwpTag = tag.find((t) => t.slug === 'pwp')
        this.offerForm.PWP = PWP // PWP is an offer that can be added
        this.setState({
          pwpTagId: pwpTag?.id,
        })
      })
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  render() {
    const { props, state } = this
    const isPayViaFpApp = props.match.path.includes('pay-via-fp-app')
    const isPinWallet = props.match.path.includes('pin-wallet')
    const pwpTagId = state && state.pwpTagId
    const defaultStatus = 'ENABLED'
    const formEntityIds = getEntityIds()
    return (
      <ListingPageWithRoutes
        className="offers-page"
        menu={props.menu}
        title={getMessage('offers.title')}
        api={{
          url: `/offer-service/offer`,
          params: { includeCount: 'false' },
          transform: response =>
            Array.isArray(response.data.offers)
              ? response.data.offers.filter(offer => offer.offerType !== 'PT')
              : response.data.offers,
        }}
        emptyState={EmptyState}
        addHeading={
          isPinWallet
            ? getMessage('offer.pin.wallet')
            : getMessage('offer.add.heading')
        }
        editHeading={getMessage('offer.edit.heading')}
        tableProperties={tableProperties()}
        headerActions={HeaderActions}
        nextPrevButtonsEnabled={true}
        filters={{
          component: OfferFilter,
          options: {
            offerTypes: OfferTypes,
            offerMapping: OfferMapping,
            stores: this.state && this.state.stores,
            entityIds: EntityIds,
            buyKey: this.buyKey,
            defaultStatus: defaultStatus,
          },
          forceShow: true,
          transformSubmit: response => {
            const formData = JSON.parse(JSON.stringify(response))
            if (response.type === 'all') {
              formData.type = ''
            }
            if (response.entityType === 'PRODUCT') {
              formData.entityType = 'VARIANT'
            }
            formData.promoCode = (formData.promoCode || []).map(
              v => v && v.replace(/ /g, '')
            )
            delete formData.selectedEntity
            return formData
          },
        }}
        form={{
          component: OfferForm,
          options: {
            offerTypes: OfferTypes,
            offerMapping: OfferMapping,
            productOnlyOffers: ProductOnlyOffers,
            linkPayOffers: linkPayOffers,
            stores: this.state && this.state.stores,
            multiStoreEnabled: isExtensionEnabled('MultiStoreSupport'),
            entityIds: formEntityIds,
            discountTypes: DiscountTypes,
            storeOptions: StoreOptions,
            itemDiscountTypes: ItemDiscountTypes,
            buyKey: this.buyKey,
            pwpTagId: pwpTagId,
            activeIndex: this.state.activeIndex,
            changeTab: this.changeTab,
            isPayViaFpApp: isPayViaFpApp,
            isPinWallet: isPinWallet,
          },
          transformSubmit: formData => {
            formData.customerRedemptionLimit =
              formData.customerRedemptionLimit || null
            let data = JSON.parse(JSON.stringify(formData))
            if (data.tableAction) {
              delete data.tableAction
              return data
            }
            if (data?.metaData?.treatment && data.metaData.treatment === 'empty') {
              data.metaData.treatment = ''
            }
            if (data && data.userSet) {
              data = formatDataBeforeSubmit(data)
            }
            if (data && data.userSet && data.userSet.data) {
              if (data.userSet.data.length === 0) {
                data.userSet = null
                data.method !== 'edit' && delete data.userSet
              }
            } else if (data && data.userSet === null) {
              delete data.userSet
            }
            // Offer specific transforms
            const Offer = this.offerForm[data.type]
            const offer = new Offer()
            data = offer.submitHandler(data, this.buyKey)
            let idsString = ''
            data.storeId &&
              Array.isArray(data.storeId) &&
              data.storeId.forEach((id, i, arr) => {
                idsString += i !== arr.length - 1 ? id + ',' : id
              })
            data.storeId = idsString
            delete data.storeType
            if (data.validFrom && data.validFromTime) {
              data.offerValidFrom = `${data.validFrom} ${data.validFromTime}`
              delete data.validFrom
              delete data.validFromTime
            }
            if (data.validTill && data.validTillTime) {
              data.offerValidTill = `${data.validTill} ${data.validTillTime}`
            }

            if (data.offerValidTill === null) {
              delete data.offerValidTill
            }

            delete data.validTill
            delete data.validTillTime

            if (data.startDeliveryDate && data.startDeliveryDateTime) {
              data.startDeliveryDate += ` ${data.startDeliveryDateTime}`
              delete data.startDeliveryDateTime
            }
            if (data.endDeliveryDate && data.endDeliveryDateTime) {
              data.endDeliveryDate += ` ${data.endDeliveryDateTime}`
              delete data.endDeliveryDateTime
            }
            data.offerType = data.type
            delete data.entityType
            if (
              data &&
              data.rule &&
              data.rule.total &&
              data.rule.total.t &&
              data.rule.total.t === 'FREE'
            ) {
              data.rule.total.t = 'PERCENT_OFF'
              data.rule.total.v = 100
            }
            if (data.type === 'SFXGSD') {
              data.rule = data.rule[0]
              data.rule.shippingDiscount = data.rule.total
              delete data.rule.total
              delete data.entity
            }
            if (data.type === 'SFXGSFD') {
              data.rule = data.rule[0]
              data.rule.serviceFee = data.rule.total
              delete data.rule.total
              delete data.entity
            }
            if (data.type === 'SFXGCD') {
              data.rule =
                data.rule &&
                data.rule.map(rule => ({
                  cartDiscount: rule.total,
                  timeSlots: rule.timeSlots,
                  maxDiscount:
                    rule.maxDiscount === '' ? undefined : rule.maxDiscount,
                  cartPrice: rule.cartPrice,
                }))

              delete data.entity
            }
            if (data.type === 'PWP') {
              data.rule.get = data.rule.total
              delete data.rule.total
              delete data.entity
            }

            if (data.type === 'BMIN') {
              delete data.entity
              delete data.type
            }
            if (data.type === 'BFXATP') {
              const tags = JSON.parse(JSON.stringify(data.tags))
              const tagIds = tags.map(item => Number(item.id))
              data.rule.tagIds = tagIds
              delete data.tags
            }

            if (data && data.rule && data.rule.buy) {
              // eslint-disable-next-line no-extra-semi
              ;(Object.keys(data.rule.buy) || []).forEach(productId => {
                if (data.rule.buy[productId].t === 'FREE') {
                  data.rule.buy[productId].t = 'PERCENT_OFF'
                  data.rule.buy[productId].v = 100
                }
              })
            }
            if (data && data.rule && data.rule.get) {
              // eslint-disable-next-line no-extra-semi
              ;(Object.keys(data.rule.get) || []).forEach(productId => {
                if (data.rule.get[productId].t === 'FREE') {
                  data.rule.get[productId].t = 'PERCENT_OFF'
                  data.rule.get[productId].v = 100
                }
              })
            }
            if (data && data.rule && !data.rule.limit) {
              data.rule.limit = 0
            }

            if (data.type === 'SFXGCD') {
              delete data.pwpTagId
              delete data.rule.limit
            }

            if (isPayViaFpApp) {
              data.stackable = true
              delete data.storeId
            }
            data.promoCodeDetail = {
              title: data?.promoCodeDetail?.title,
              subTitle: data?.promoCodeDetail?.subTitle,
              tncUrl: data?.promoCodeDetail?.tncUrl || null,
              howToUseText: data?.promoCodeDetail?.howToUseText || null,
            }

            delete data.type
            delete data.method
            delete data.oldFormat
            return data
          },
          transformResponse: response => {
            const offer = response.data.offers
            offer.includes = false

            if (
              offer &&
              (offer.offerType === 'PT' ||
                offer.offerType === 'BMIN' ||
                offer.offerType === 'BMINXGFG') &&
              offer.rule &&
              offer.rule.entity
            ) {
              offer.includes = true
            }

            if (
              offer.offerType === 'PT' &&
              offer.rule &&
              offer.rule['non-entity']
            ) {
              offer.rule.entity = offer.rule['non-entity']
              delete offer.rule['non-entity']
            }

            offer.entityType =
              !offer.rule.entity || offer.rule.entity.type === 'VARIANT'
                ? 'PRODUCT'
                : offer.rule.entity.type

            if (
              (offer.offerType === 'BMIN' || offer.offerType === 'BMINXGFG') &&
              offer.rule &&
              offer.rule['non-entity']
            ) {
              offer.entityType =
                offer.rule['non-entity'].type === 'VARIANT'
                  ? 'PRODUCT'
                  : offer.rule['non-entity'].type
            }
            offer.itemDiscountType = offer.rule.total
              ? 'COMBO_DISCOUNT'
              : 'INDIVIDUAL_DISCOUNT'
            if (offer.rule && offer.offerType === 'BMINXATP') {
              offer.rule.itemDiscountType = offer.rule.isCombo
                ? 'COMBO_DISCOUNT'
                : 'INDIVIDUAL_DISCOUNT'
              if (!offer.rule.elementGroups) {
                offer.oldFormat = true
                offer.rule.elementGroups = [
                  {
                    total: offer.rule.total,
                    minQuantity: offer.rule.minQuantity,
                    maxDiscount: offer.rule.maxDiscount,
                  },
                ]

                delete offer.rule.total
                delete offer.rule.minQuantity
                delete offer.rule.maxDiscount
              }
            }
            if (offer.rule && offer.offerType === 'BMINXGFG') {
              offer.itemDiscountType =
                offer.rule.elementGroups &&
                offer.rule.elementGroups[0] &&
                offer.rule.elementGroups[0].total
                  ? 'COMBO_DISCOUNT'
                  : 'INDIVIDUAL_DISCOUNT'
              if (!offer.rule.elementGroups) {
                offer.rule.elementGroups = [
                  {
                    get: offer.rule.get,
                    minAmount: offer.rule.minAmount,
                  },
                ]
                if (offer.rule.total) {
                  offer.rule.elementGroups[0]['total'] = offer.rule.total
                }
                delete offer.rule.total
                delete offer.rule.minAmount
              }
            }
            if (offer.rule && offer.offerType === 'BMIN') {
              offer.rule.itemDiscountType = offer.rule.isCombo
                ? 'COMBO_DISCOUNT'
                : 'INDIVIDUAL_DISCOUNT'
              if (!offer.rule.elementGroups) {
                offer.rule.elementGroups = [
                  {
                    total: offer.rule.total,
                    minAmount: offer.rule.minAmount,
                    maxDiscount: offer.rule.maxDiscount,
                  },
                ]

                delete offer.rule.total
                delete offer.rule.minAmount
                delete offer.rule.maxDiscount
              }
            }
            const [unityOfferType] = OfferMapping.filter(
              type => type.isUnityOffer
            )
            offer.type =
              offer.offerType === BANYGLED
                ? unityOfferType.value
                : offer.offerType
            offer.rule.isUnityOffer = offer.offerType === BANYGLED
            const storeIdArray = []
            ;(offer.storeId || '').split(',').forEach(id => {
              id !== '' && storeIdArray.push(Number(id))
            })
            offer.storeId = storeIdArray
            offer.validFrom = offer.offerValidFrom
              ? offer.offerValidFrom.toString().split(' ')[0]
              : null
            offer.validFromTime = offer.offerValidFrom
              ? offer.offerValidFrom
                  .toString()
                  .split(' ')[1]
                  .split(':')
                  .slice(0, -1)
                  .join(':')
              : null
            offer.validTill = offer.offerValidTill
              ? offer.offerValidTill.toString().split(' ')[0]
              : null
            offer.validTillTime = offer.offerValidTill
              ? offer.offerValidTill
                  .toString()
                  .split(' ')[1]
                  .split(':')
                  .slice(0, -1)
                  .join(':')
              : null
            offer.validFrom = offer.offerValidFrom
              ? offer.offerValidFrom.split(' ')[0]
              : null
            offer.validFromTime = offer.offerValidFrom
              ? offer.offerValidFrom.split(' ')[1]
              : null
            offer.validTill = offer.offerValidTill
              ? offer.offerValidTill.split(' ')[0]
              : null
            offer.validTillTime = offer.offerValidTill
              ? offer.offerValidTill.split(' ')[1]
              : null
            const startDeliveryDate = offer.startDeliveryDate

            let split = startDeliveryDate && startDeliveryDate.split(' ')
            offer.startDeliveryDate =
              (Array.isArray(split) && split.length > 0 && split[0]) || null
            offer.startDeliveryDateTime =
              (Array.isArray(split) && split.length > 1 && split[1]) || null

            const endDeliveryDate = offer.endDeliveryDate
            split = endDeliveryDate && endDeliveryDate.split(' ')
            offer.endDeliveryDate =
              (Array.isArray(split) && split.length > 0 && split[0]) || null
            offer.endDeliveryDateTime =
              (Array.isArray(split) && split.length > 1 && split[1]) || null

            if (offer && offer.rule && offer.rule.shippingDiscount) {
              offer.rule.total = offer.rule.shippingDiscount
              delete offer.rule.shippingDiscount
            }
            if (offer && offer.rule && offer.rule.cartDiscount) {
              offer.rule.total = offer.rule.cartDiscount
              delete offer.rule.cartDiscount
            }
            if (
              offer &&
              ['SFXGCD', 'SFXGSD', 'SFXGSFD'].includes(offer.offerType)
            ) {
              if (!Array.isArray(offer.rule)) {
                offer.rule = [offer.rule]
              }
              offer.rule =
                offer.rule &&
                offer.rule.map(rule => ({
                  total:
                    rule.total ||
                    rule.cartDiscount ||
                    rule.shippingDiscount ||
                    rule.serviceFee,
                  timeSlots: rule.timeSlots,
                  maxDiscount: rule.maxDiscount,
                  cartPrice: rule.cartPrice,
                }))
            }
            if (offer && offer.rule && offer.rule.pwpTagId) {
              offer.rule.total = offer.rule.get
              delete offer.rule.get
            }
            if (offer && offer.rule && offer.rule.timeSlots) {
              offer.timeSlots = offer.rule.timeSlots
              delete offer.rule.timeSlots
            }

            if (offer && offer.rule && offer.rule.tagIds) {
              offer.tagIds = offer.rule.tagIds
              delete offer.rule.tagIds
            }

            offer.method = 'edit'
            offer.costCenter = offer?.metaData?.costCenter
            delete offer?.metaData?.costCenter
            return offer
          },
        }}
      />
    )
  }
}

export default Offer
