import React from 'react'
import { getMessage } from 'lib/translator'
import ListingPage from 'containers/ListingPage'
import VoucherCampaignTableProperties from './VoucherCampaignTableProperties'
import emptyIcon from 'icons/no-orders.svg'

const VoucherCenterCampaignsTable = ({ menu, history }) => {
  return (
    <ListingPage
      testid="voucher-campaign-listing"
      menu={menu}
      className="voucher-center-campaigns-page"
      title={getMessage('offer.campaigns.listing.heading')}
      api={{
        url: '/offer-service/campaigns',
        transform: (response) => response.data.campaign,
      }}
      tableDynamic
      primaryKey="id"
      tableProperties={VoucherCampaignTableProperties()}
      headerActions={() => (
        <button
          className="primary button"
          onClick={() =>
            history.push('/marketing/voucher-center-campaigns/new')
          }
        >{`+ ${getMessage('offer.campaigns.listing.add.offerCampaign')}`}</button>
      )}
      emptyState={{
        icon: emptyIcon,
        message: 'There is no voucher campaign at the moment.',
      }}
      nextPrevButtonsEnabled
      dontSavePagination
    />
  )
}

export default VoucherCenterCampaignsTable
