import React from 'react'
import moment from 'moment'
import { BaseForm, Toggle } from '../../../../components/Form'
import Tabs from '../../../../components/Tabs'
import { BANYGLED } from '../'
import BANYATP from './BANYATP'
import BMINXATP from './BMINXATP'
import BANYGYD from './BANYGYD'
import BXATP from './BXATP'
import BXGYD from './BXGYD'
import SF from './SF'
import SFXGSD from './SFXGSD'
import PWP from './PWP'
import BMIN from './BMIN'
import BFXATP from './BFXATP'
import BMINXGFG from './BMINXGFG'
import { withRouter } from 'react-router-dom'
import './style.css'

import { getMessage } from '../../../../lib/translator'
import API from '../../../../lib/api'
import { Dialog } from '../../../../components/Popup'
import { isExtensionEnabled } from '../../../../lib/auth'
import { getNestedValues, isAllRulesValid } from '../../../../lib/commonlyused'
import Promotion from './FormTabs/Promotion'
import Restrictions from './FormTabs/Restrictions'
import OverrideContent from './FormTabs/OverrideContent'
import { tabsList, iconTypes, treatmentTypes } from './settings'
import ConfirmationModal from './ConfirmationModal/ConfirmationModal'
import PinWallet from './PinWallet'

const noop = () => {
  return null
}

const DialogComponent = ({
  show,
  title,
  information,
  onClose,
  closeText,
  onOk,
  okText,
  className,
}) => (
  <Dialog
    show={show}
    className={`notification ${className || 'failure'}`}
    title={title}
    information={information}
    close={onClose}
    closeText={closeText}
    onOk={onOk}
    okText={okText}
  />
)

const EditButton = ({
  method,
  value,
  handleToggleClonePopup,
  handleCloneOffer,
}) => {
  return (
    method === 'edit' && (
      <div className="clone-buttons">
        {!value && value.status === 'DISABLED' && (
          <button className="primary" onClick={() => handleToggleClonePopup()}>
            {getMessage('offer.clone.disableNClone')}
          </button>
        )}
        <button className="primary" onClick={() => handleCloneOffer()}>
          {getMessage('offer.clone.copy')}
        </button>
      </div>
    )
  )
}

class OfferForm extends BaseForm {
  constructor(props) {
    super(props)
    this.isNewOffer = this.props.method === 'add'
    this.offerForm = {
      BANYATP: BANYATP,
      BMINXATP: BMINXATP,
      BANYGYD: BANYGYD,
      SFXGSD: SFXGSD,
      SFXGCD: SFXGSD,
      SFXGSFD: SFXGSD,
      BXATP: BXATP,
      BXGYD: BXGYD,
      SF: SF,
      PWP: PWP,
      BMIN: BMIN,
      BFXATP: BFXATP,
      BMINXGFG: BMINXGFG,
    }
    this.entityType = {
      PRODUCT: 'product',
      BRAND: 'brand',
      CATEGORY: 'category',
    }
    this.noMaxRedemption = [
      'SFXGSD',
      'SFXGSFD',
      'SFXGCD',
      'BMIN',
      'BFXATP',
      'BMINXGFG',
    ]
    this.buyKey = this.props.options.buyKey
    const entityType = this.state.values.entityType
    if (!entityType) {
      this.state.values.entityType = 'PRODUCT'
    }
    if (!this.state.values.itemDiscountType) {
      this.state.values.itemDiscountType = 'COMBO_DISCOUNT'
    }

    if (this.props.method === 'add' && this.state.values) {
      this.state.values.status = 'ENABLED'
      this.state.values.customerRedemptionLimit = 1
      this.state.values.totalRedemption = 10
    }
    this.freeDiscoutOffers = [
      'SF',
      'BXGYD',
      'BANYGYD',
      'SFXGSD',
      'SFXGSFD',
      'SFXGCD',
      'PWP',
    ] // offers that offer free pdts
    this.saveData = this.saveData.bind(this)
    this.addItem = this.addItem.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.addItemBuy = this.addItemBuy.bind(this)
    this.deleteBuyItem = this.deleteBuyItem.bind(this)
    this.handleEntityTypeOnchange = this.handleEntityTypeOnchange.bind(this)
    this.handleDiscountChange = this.handleDiscountChange.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handleOfferTypeOnchange = this.handleOfferTypeOnchange.bind(this)
    this.handleImageClick = this.handleImageClick.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
    this.noItemsOffers = ['SFXGSD', 'SFXGSFD', 'SFXGCD', 'PWP']
    this.handleUniquePromocodeValidations =
      this.handleUniquePromocodeValidations.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.readyForSubmission = this.readyForSubmission.bind(this)
    this.handleCloneOffer = this.handleCloneOffer.bind(this)
    this.handleToggleClonePopup = this.handleToggleClonePopup.bind(this)
    this.closeErrorDialog = this.closeErrorDialog.bind(this)
    this.closeSapErrorDialog = this.closeSapErrorDialog.bind(this)
    this.handleUpdateValues = this.handleUpdateValues.bind(this)
    this.handleUpdateValidations = this.handleUpdateValidations.bind(this)
    this.handleChangePromoCode = this.handleChangePromoCode.bind(this)
    this.addEligibleItems = this.addEligibleItems.bind(this)
    this.removeEligibleItems = this.removeEligibleItems.bind(this)
    this.setShowSubmitConfirmation = this.setShowSubmitConfirmation.bind(this)
    this.onConfirmSubmit = this.onConfirmSubmit.bind(this)
    this.onUpdateStores = this.onUpdateStores.bind(this)
    this.onUpdateStoreGroupings = this.onUpdateStoreGroupings.bind(this)
    this.closeRuleMinValueError = this.closeRuleMinValueError.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.options.pwpTagId !== this.props.options.pwpTagId ||
      this.state.values.pwpTagId !== this.props.options.pwpTagId
    ) {
      this.setState((prev) => ({
        values: {
          ...prev.values,
          pwpTagId: this.props.options.pwpTagId,
        },
      }))
    }
    if (
      this.state.values &&
      this.state.values.type === 'BMINXATP' &&
      this.state.values.stackable
    ) {
      let disableCheckbox = false
      disableCheckbox =
        this.state.values.rule &&
        this.state.values.rule.elementGroups &&
        this.state.values.rule.elementGroups.some(
          (group) => group.total && group.total.t === 'FIXED'
        )
      if (disableCheckbox) {
        this.setState({
          values: { ...this.state.values, stackable: false },
        })
      }
    } else {
      if (
        this.state.values &&
        this.state.values.itemDiscountType === 'COMBO_DISCOUNT'
      ) {
        if (
          this.state.values &&
          this.state.values.rule &&
          this.state.values.rule.total &&
          this.state.values.rule.total.t &&
          this.state.values.rule.total.t === 'FIXED' &&
          this.state.values.stackable
        ) {
          this.setState({
            values: { ...this.state.values, stackable: false },
          })
        }
      } else if (
        this.state.values &&
        this.state.values.itemDiscountType === 'INDIVIDUAL_DISCOUNT' &&
        this.state.values.stackable
      ) {
        const items = this.state.values.product || []
        let disableCheckbox = false
        items.length > 0 &&
          items.map((item) => {
            if (item.t === 'FIXED') {
              disableCheckbox = true
            }
            return null
          })

        if (disableCheckbox) {
          this.setState({
            values: { ...this.state.values, stackable: false },
          })
          disableCheckbox = false
        }
      }
    }
  }

  componentDidMount() {
    const { state, props } = this
    const { method } = props
    const { values } = state
    this.tabForms =
      method === 'add' ? [false, false, true] : ['true', true, true]
    this.fetchData()
    // This is for cloning an offer. From the offer details page,
    // the values are stored in sessionStorage and user is redirected to add offers page
    // and in this page hydrate the state from sessionStorage
    if (method === 'add') {
      let clonedOffer = JSON.parse(
        window.sessionStorage.getItem('clonedOffer') || '{}'
      )
      clonedOffer = { ...values, ...clonedOffer }
      this.setState({ values: clonedOffer })
    }
    if (method === 'edit') {
      this.setState({ currentTotalRedemption: props?.value?.totalRedemption })
    }
  }

  /* DO NOT REMOVE. It's used to overwrite the similar function in BaseForm. Removing it will run the method in BaseForm. */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options.activeIndex !== this.props.options.activeIndex) {
      // this.fetchData()
    }
  }

  componentWillUnmount() {
    if (this.props.method === 'add') {
      window.sessionStorage.removeItem('clonedOffer')
    }
    this.props.options.changeTab(0) // reset the tab when user naviagate to another page
  }

  readyForSubmission() {
    const activeIndex = this.props.options.activeIndex
    for (let i = 0; i < this.tabForms.length; i++) {
      if (activeIndex === i) {
        continue
      } else {
        if (!this.tabForms[i]) {
          return { val: false, nextTabIndex: i }
        }
      }
    }

    return { value: true, nextTabIndex: undefined }
  }

  /* istanbul ignore next */
  async fetchData() {
    this.hasOfferStarted =
      moment().diff(this.state.values.offerValidFrom) > 0 &&
      this.props.method === 'edit'
    const entityId =
      this.state.values.rule &&
      this.state.values.rule.entity &&
      this.state.values.rule.entity.id
    if (isExtensionEnabled('EntityMetaData')) {
      const metaDataApi = new API({ url: '/config-service/meta-data' })
      metaDataApi.get().then((response) => {
        const offerMetadata = response.data.config.entityMetaData.offer || {}
        this.setState({
          metadataFormTypes: offerMetadata,
        })
      })
    }

    if (this.props.method !== 'add') {
      const offerType = this.props.value.offerType
      const cartPrice =
        this.props.value &&
        this.props.value.rule &&
        this.props.value.rule.cartPrice
      const entityType = this.state.values.entityType.toLowerCase()
      let getParams = ''
      let multiTierGetParams = ''
      const multiTierGetPdts =
        offerType === 'BMINXGFG' &&
        (this.props.value.rule.elementGroups || []).map((group) => group.get)
      if (multiTierGetPdts) {
        multiTierGetPdts.map((singlePdt) => {
          const pdtsKeys = Object.keys(singlePdt) || []
          pdtsKeys.forEach((id) => {
            multiTierGetParams += `id=${Number(id)}&`
          })
          return null
        })
      }

      // set cost center value
      if (this.props.value?.metaData?.costCenter) {
        const values = Object.assign({}, this.state.values)
        values.costCenter = this.props.value?.metaData?.costCenter
        this.setState({ values })
      }

      const getPdts = this.props.value.rule.get
      if (getPdts) {
        if (offerType === 'PWP') {
          const rule = this.props.value.rule
          const id = rule && rule.get && rule.get.variantId
          getParams = `id=${id}`
        } else {
          const pdtsKeys = Object.keys(getPdts) || []
          pdtsKeys.forEach((id) => {
            getParams += `id=${Number(id)}&`
          })
        }
      }

      let tags = []
      if (this.state.values.tagIds && this.state.values.tagIds.length > 0) {
        let queryString = ''
        this.state.values.tagIds.map((id, index) => {
          if (index > 0) {
            queryString += `&id=${id}`
          } else {
            queryString += `id=${id}`
          }
          return null
        })
        const tagApi = new API({
          url: `/catalogue-service/tag/?${queryString}`,
        })
        await tagApi
          .get()
          .then((res) => {
            if (res.code === 200 && res.status === 'SUCCESS') {
              tags = res.data && res.data.tag
            }
          })
          .catch((err) => err)

        this.setState((prevState) => {
          return {
            values: { ...prevState.values, tags },
            tagsLoaded: tags.length > 0,
          }
        })
      }
      let formValues = Object.assign({}, { ...this.state.values, tags })
      if (getParams) {
        const getProductsAPI = new API({
          url: `/catalogue-service/product?${getParams}`,
        })
        await getProductsAPI.get().then((response) => {
          let products = response.data.product || []
          const productsFromProp = this.props.value.rule.get
          products = products.map((product) => {
            const newProduct = product
            const productFromProp =
              productsFromProp && productsFromProp[product.id]
            newProduct.q = productFromProp && productFromProp.q
            if (this.props.value.itemDiscountType === 'INDIVIDUAL_DISCOUNT') {
              newProduct.t = productFromProp && productFromProp.t
              newProduct.v = productFromProp && productFromProp.v
            }
            return newProduct
          })
          const values = formValues
          values.product = products
          if (cartPrice) {
            values.cartPrice = cartPrice
          }
          if (offerType === 'PWP') {
            const rule = values.rule
            rule.total = rule.get
            rule.total.variantId = products[0]
            values.rule = rule
          }
          formValues = { ...formValues, ...values }
        })
      }
      if (multiTierGetParams) {
        const getProductsAPI = new API({
          url: `/catalogue-service/product?${multiTierGetParams}`,
        })
        await getProductsAPI.get().then((response) => {
          const products = response.data.product || []
          const values = formValues
          const { rule } = values
          const { elementGroups } = rule
          const newElementGroups = elementGroups.map((group) => {
            group.product =
              group.get &&
              Object.keys(group.get)
                .map((getPdtId) => {
                  let newProduct =
                    products.find(
                      (pdt) => Number(pdt.id) === Number(getPdtId)
                    ) || null
                  if (newProduct) {
                    newProduct = {
                      ...newProduct,
                      q: group.get[getPdtId].q,
                    }
                  }
                  if (
                    newProduct &&
                    this.props.value.itemDiscountType === 'INDIVIDUAL_DISCOUNT'
                  ) {
                    newProduct = {
                      ...newProduct,
                      t: group.get[getPdtId].t,
                      v: group.get[getPdtId].v,
                    }
                  }
                  return newProduct
                })
                .filter(Boolean)
            delete group.get
            return group
          })
          rule.elementGroups = newElementGroups
          values.rule = rule
          this.setState((prevState) => ({
            values: { ...prevState.values, ...values },
          }))
        })
      }
      if (entityType === 'product') {
        const pdtIds = this.props.value.rule.variants || []
        let buyParams = ''
        let entityParams = ''
        pdtIds.forEach((id) => {
          buyParams += `id=${id}&`
        })
        const buyPdts = this.props.value.rule.buy
        let entityIds = []
        if (
          this.props.value.offerType === 'BMIN' ||
          this.props.value.offerType === 'BMINXGFG'
        ) {
          entityIds = this.props.value.rule.entityIds || []
        }

        if (buyPdts) {
          Object.keys(buyPdts).forEach((id) => {
            buyParams += `id=${Number(id)}&`
          })
        }

        if (entityIds.length > 0) {
          entityIds.map((id, index) => {
            if (index === 0) {
              entityParams += `id=${Number(id)}`
            } else {
              entityParams += `&id=${Number(id)}`
            }
            return null
          })
        }
        if (buyParams) {
          const buyProductsAPI = new API({
            url: `/catalogue-service/product?${buyParams}`,
          })
          await buyProductsAPI.get().then((response) => {
            let products = response.data.product || []
            products = products.map((product) => {
              const newProduct = product
              let buyProduct = buyPdts && buyPdts[product.id]
              buyProduct =
                typeof buyProduct === 'object' ? buyProduct : { q: buyProduct }
              if (buyProduct) {
                newProduct.q = buyProduct.q
                newProduct.t = buyProduct.t
                newProduct.v = buyProduct.v
              }
              return newProduct
            })
            const values = formValues
            if (
              this.props.value.offerType === 'BXATP' ||
              this.props.value.offerType === 'BMINXATP' ||
              this.props.value.offerType === 'BANYATP' ||
              this.props.value.offerType === BANYGLED ||
              this.props.value.offerType === 'PT'
            ) {
              values.product = products
            } else {
              values[`${this.props.options.buyKey}product`] = products
            }
            this.setState((prevState) => ({
              values: { ...prevState.values, ...values },
            }))
          })
        }
        if (entityIds.length > 0) {
          const entityProductsApi = new API({
            url: `/catalogue-service/product?${entityParams}`,
          })
          await entityProductsApi
            .get()
            .then((response) => {
              if (response.code === 200 && response.status === 'SUCCESS') {
                const values = JSON.parse(JSON.stringify(formValues))
                if (this.props.value.offerType === 'BMIN') {
                  values['product'] = response.data.product
                  this.setState((prevState) => ({
                    values: { ...prevState.values, ...values },
                  }))
                }
                if (this.props.value.offerType === 'BMINXGFG') {
                  let products = response.data.product || []
                  products = products.map((product) => {
                    const newProduct = product
                    let buyProduct = buyPdts && buyPdts[product.id]
                    buyProduct =
                      typeof buyProduct === 'object'
                        ? buyProduct
                        : { q: buyProduct }
                    if (buyProduct) {
                      newProduct.q = buyProduct.q
                      newProduct.t = buyProduct.t
                      newProduct.v = buyProduct.v
                    }
                    return newProduct
                  })

                  const newValues = formValues
                  newValues[`${this.props.options.buyKey}product`] = products
                  this.setState((prevState) => ({
                    values: { ...prevState.values, ...newValues },
                  }))
                }
              }
            })
            .catch((err) => err)
        }
      } else {
        const values = formValues
        if (entityType === 'category') {
          if (entityId) {
            this.api = new API({
              url: `/catalogue-service/category?id=${entityId}`,
            })
            this.api.get().then((response) => {
              values.category =
                (Array.isArray(response.data.category) &&
                  response.data.category[0]) ||
                null
              if (cartPrice) {
                values.cartPrice = cartPrice
              }
              this.setState((prevState) => ({
                values: { ...prevState.values, ...values },
              }))
            })
          } else {
            const entityIds = this.props.value.rule.entityIds || []
            if (entityIds.length > 0) {
              const entityParams = this.getEntityParams(entityIds)
              const entityCategoryApi = new API({
                url: `/catalogue-service/category?${entityParams}`,
              })
              entityCategoryApi
                .get()
                .then((response) => {
                  if (response.code === 200 && response.status === 'SUCCESS') {
                    const newValues = JSON.parse(JSON.stringify(formValues))
                    newValues['category'] = response.data.category
                    this.setState((prevState) => ({
                      values: { ...prevState.values, ...newValues },
                    }))
                  }
                })
                .catch((err) => err)
            }
          }
        } else if (entityType === 'brand') {
          if (entityId) {
            this.api = new API({
              url: `/catalogue-service/brand?id=${entityId}`,
            })
            this.api.get().then((response) => {
              values.brand =
                (Array.isArray(response.data.brand) &&
                  response.data.brand[0]) ||
                null
              if (cartPrice) {
                values.cartPrice = cartPrice
              }
              this.setState((prevState) => ({
                values: { ...prevState.values, ...values },
              }))
            })
          } else {
            const entityIds = this.props.value.rule.entityIds || []
            if (entityIds.length > 0) {
              const entityParams = this.getEntityParams(entityIds)
              const entityBrandsApi = new API({
                url: `/catalogue-service/brand?${entityParams}`,
              })
              entityBrandsApi
                .get()
                .then((response) => {
                  if (response.code === 200 && response.status === 'SUCCESS') {
                    const newValues = JSON.parse(JSON.stringify(formValues))
                    newValues['brand'] = response.data.brand
                    this.setState((prevState) => ({
                      values: { ...prevState.values, ...newValues },
                    }))
                  }
                })
                .catch((err) => err)
            }
          }
        }
      }

      // set total redemption count
      const totalRedemptionAPI = new API({
        url: `/offer-service/offer/${this.props.resourceId}/redemption?organizationId=2&status=AVAILED`,
      })

      await totalRedemptionAPI
        .get()
        .then((res) => {
          if (res.code === 200 && res.status === 'SUCCESS') {
            this.setState((prevState) => ({
              values: {
                ...prevState.values,
                redemptionCount: res.data?.redemptionCount,
              },
            }))
          }
        })
        .catch((err) => err)
    }
  }

  getEntityParams(entityIds) {
    let entityParams = ''
    if (entityIds.length > 0) {
      entityIds.map((id, index) => {
        if (index === 0) {
          entityParams += `id=${Number(id)}`
        } else {
          entityParams += `&id=${Number(id)}`
        }
        return null
      })
    }
    return entityParams
  }

  saveData(data) {
    const formData = { ...data, ...this.state.values }
    if (this.isFormValid()) {
      this.props.onSubmit(formData)
    }
  }

  deleteCategoryBrandsItem(index, entityType) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    const items = (values && values[entityType]) || []
    items.splice(index, 1)
    const validations = JSON.parse(JSON.stringify(this.state.validations))
    const itemsValidation = (validations && validations[entityType]) || []
    if (itemsValidation) {
      if (items.length > 0) {
        itemsValidation.valueMissing = true
        itemsValidation.valid = false
      } else {
        itemsValidation.valueMissing = undefined
        itemsValidation.valid = true
      }
    }
    this.setState({
      values,
      validations,
    })
  }

  addItem(newItem, nestedKeys) {
    if (!newItem) {
      return
    }
    const entityType = 'product'
    const values = JSON.parse(JSON.stringify(this.state.values))

    const valuesRef =
      values &&
      (nestedKeys !== undefined
        ? getNestedValues(values, ['rule', ...nestedKeys])
        : values)

    if (!valuesRef[entityType]) {
      valuesRef[entityType] = []
    }
    const index = valuesRef[entityType].filter(
      (item) => item.id === newItem.id
    ).length
    if (index > 0) {
      return
    }
    valuesRef[entityType].push(newItem)
    this.setState({
      values,
    })
  }

  addItemBuy(newItem) {
    if (!newItem) {
      return
    }
    const entityType = 'product'
    const values = Object.assign({}, this.state.values)
    const key = `${this.props.options.buyKey}${entityType}`
    if (!values[key]) {
      values[key] = []
    }
    const index = values[key].filter((item) => item.id === newItem.id).length
    if (index > 0) {
      return
    }
    values[key].push(newItem)
    this.setState({
      values,
    })
  }

  deleteBuyItem(index) {
    const values = Object.assign({}, this.state.values)
    const entityType = 'product'
    const key = `${this.props.options.buyKey}${entityType}`
    const items = values[key] || []
    items.splice(index, 1)
    const validations = Object.assign({}, this.state.validations)
    const itemsValidation = (validations && validations[key]) || []
    itemsValidation.splice(index, 1)
    this.setState({
      validations,
      values,
    })
  }

  deleteItem(index, nestedKeys) {
    const values = JSON.parse(JSON.stringify(this.state.values))
    const entityType = 'product'
    let items = !nestedKeys
      ? values && values[entityType]
      : getNestedValues(values, ['rule', ...nestedKeys, entityType])
    items = items || []

    items.splice(index, 1)
    const validations = JSON.parse(JSON.stringify(this.state.validations))
    let itemsValidation = !nestedKeys
      ? validations && validations[entityType]
      : getNestedValues(validations, ['rule', ...nestedKeys, entityType])
    itemsValidation = itemsValidation || []

    itemsValidation.splice(index, 1)
    this.setState({
      values,
      validations,
    })
  }

  addEligibleItems(newItem) {
    const values = Object.assign({}, this.state.values)
    const key = 'eligibleItems'
    if (!values[key]) {
      values[key] = []
    }
    const hasEligibleItems = values[key].some(
      (item) => item.sku === newItem.clientItemId
    )
    if (hasEligibleItems) {
      return
    }
    values[key].push({
      name: newItem.name,
      sku: newItem.clientItemId,
      slug: newItem.slug,
      displayUnit: newItem.metaData.DisplayUnit,
      image: newItem.images[0],
    })
    this.setState({
      values,
    })
  }
  removeEligibleItems(index) {
    const values = Object.assign({}, this.state.values)
    const items = values.eligibleItems
    items.splice(index, 1)
    this.setState({
      values,
    })
  }
  onUpdateStores(storeIds) {
    const values = Object.assign({}, this.state.values)
    values.storeId = storeIds
    this.setState({
      values,
      pressedSubmitWithCurrentData: false,
    })
  }
  onUpdateStoreGroupings(storeIds) {
    const values = Object.assign({}, this.state.values)
    values.clientStoreIds = storeIds
    this.setState({ values, pressedSubmitWithCurrentData: false })
  }

  handleDiscountChange(type, nestedKeys) {
    if (nestedKeys === undefined) {
      this.updateState(['rule', 'total', 't'])
    } else {
      this.updateState(['rule', ...nestedKeys, 'total', 't'])
    }

    this.setState((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      const ruleValue =
        newState &&
        newState.values &&
        (nestedKeys !== undefined
          ? getNestedValues(newState.values, ['rule', ...nestedKeys])
          : newState.values.rule)
      const ruleValidation =
        newState &&
        newState.validations &&
        (nestedKeys !== undefined
          ? getNestedValues(newState.validations, ['rule', ...nestedKeys])
          : newState.validations.rule)
      if (ruleValue) {
        if (
          type === 'FREE' &&
          ruleValidation &&
          ruleValidation.total &&
          ruleValidation.total.v &&
          !ruleValidation.total.v.valid
        ) {
          ruleValidation.total.v.valid = true
        } else if (
          type !== 'FREE' &&
          ruleValidation &&
          ruleValidation.total &&
          ruleValidation.total.v
        ) {
          ruleValidation.total.v.valid = false
        }
        ruleValue.total.t = type
        ruleValue.total.v = ''
      }
      return newState
    })
  }
  setShowSubmitConfirmation(showSubmitConfirmationValue) {
    this.setState({ showSubmitConfirmation: showSubmitConfirmationValue })
  }

  isValidSapReference(values) {
    if (values?.metaData?.sapReference && this.state.values.type === 'SFXGCD') {
      return values?.metaData?.sapReference?.startsWith('ZKP')
    }
    return true
  }

  isValidRules(values) {
    if (['BMIN', 'BMINXGFG'].indexOf(values.type) > -1) {
      return isAllRulesValid(values?.rule?.elementGroups, ['minAmount'])
    }
    if (['SFXGCD', 'SFXGSD', 'SFXGSFD'].indexOf(values.type) > -1) {
      return isAllRulesValid(values?.rule, ['cartPrice'])
    }
    if (values.type === 'PWP') {
      return !!values?.rule.cartPrice
    }
    return true
  }

  isValidStore(values) {
    return this.props.options.isPayViaFpApp || values?.storeId?.length > 0
  }

  beforeSubmit() {
    const data = Object.assign({}, this.state.values)
    const offerType = data.type
    const instance = new this.offerForm[offerType]()
    instance.validationHandler(this)
  }
  async _submitHandler(e) {
    e && e.preventDefault()
    await this.beforeSubmit()
    this.setState({
      pressedSubmitWithCurrentData: true,
    })
    const isValidSapRef = this.isValidSapReference(this.state.values)
    const isValidRules = this.isValidRules(this.state.values)
    const validationsObj = JSON.parse(JSON.stringify(this.state.validations))
    const activeIndex = this.props.options.activeIndex
    if (activeIndex === 1 && !this.isValidStore(this.state.values)) {
      validationsObj['storeId'] = {
        valid: false,
        valueMissing: true,
      }
    }

    const isValid = this.isFormValid(validationsObj)
    if (!isValidSapRef) {
      this.setState({ showSapRefError: true })
    }
    if (!isValidRules) {
      this.setState({ showRuleMinValueError: true })
    }
    if (isValid && isValidSapRef && isValidRules) {
      this.tabForms[activeIndex] = true
      const result = this.readyForSubmission()
      if (result.value) {
        this.setShowSubmitConfirmation(true)
      } else {
        this.changeTab(result.nextTabIndex)
      }
    } else {
      this.tabForms[activeIndex] = false
    }
  }

  onConfirmSubmit() {
    if (this.props.onSubmit) {
      const values = JSON.parse(JSON.stringify(this.state.values))
      /* istanbul ignore next */
      values.segmentationIds = values.segmentationIds
        ? values.segmentationIds.map((x) => x.id)
        : []
      this.props.onSubmit(values)
    } else {
      this.onSubmit(this.state.values)
    }
    this.setShowSubmitConfirmation(false)
  }

  handleEntityTypeOnchange(targetValue) {
    const { productOnlyOffers, offerMapping } = this.props.options
    const values = JSON.parse(JSON.stringify(this.state.values))
    const validations = JSON.parse(JSON.stringify(this.state.validations))
    let offersArray = [...offerMapping]
    offersArray = offersArray.filter((el) => {
      return productOnlyOffers.indexOf(el.value) < 0
    })
    const offerType = values.type
    if (
      targetValue !== 'PRODUCT' &&
      offersArray.findIndex((offer) => offer.value === offerType) === -1
    ) {
      values.type = null
    }
    values.entityType = targetValue
    if (values.entityType === 'PRODUCT') {
      delete values['brand']
      delete values['category']
      delete validations['brand']
      delete validations['category']
    }

    if (values.entityType === 'BRAND') {
      delete values['buyproduct']
      delete values['product']
      delete values['category']
      delete validations['product']
      delete validations['category']
    }

    if (values.entityType === 'CATEGORY') {
      delete values['buyproduct']
      delete values['product']
      delete values['brand']
      delete validations['product']
      delete validations['brand']
    }
    this.setState({ values, validations })
  }
  handleOfferTypeOnchange(type) {
    const values = {
      type: type,
      status: this.state.values.status,
      entityType: this.state.values.entityType,
      itemDiscountType: 'COMBO_DISCOUNT',
      pwpTagId: this.state.values.pwpTagId,
      customerRedemptionLimit: this.state.values.customerRedemptionLimit,
      totalRedemption: this.state.values.totalRedemption,
    }
    if (values.type === 'BMIN' || values.type === 'BMINXATP') {
      values.rule = { itemDiscountType: 'COMBO_DISCOUNT' }
    }
    const validations = { type: this.state.validations.type }
    this.setState({ values, validations, pressedSubmitWithCurrentData: false })
  }

  handleImageClick() {
    this.setState({
      showPromoDownloadPopup: true,
    })
    this.api = new API({
      url: `/offer-service/offer/${this.props.resourceId}/promocode`,
    })
    this.api
      .get()
      .then((res) => {
        const headers = ['offerId', 'promoCode', 'usedAt', 'expiresAt']
        let csvContent = 'data:text/csv;charset=utf-8,'
        headers.forEach((item) => {
          csvContent += item + ','
        })
        csvContent += '\r\n'
        res.data.forEach((rowItems) => {
          let row = ''
          headers.forEach((header) => {
            if (header === 'expiresAt' && rowItems[header]) {
              row += moment(rowItems[header]).format('DD-MM-YY hh:mm A')
            } else {
              row += rowItems[header] + ','
            }
          })
          csvContent += row + '\r\n'
        })

        const promoFileName = 'promo_codes_' + this.props.resourceId + '.csv'
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', promoFileName)
        document.body.appendChild(link)

        link.click()
      })
      .catch((error) => {
        if (error.code === 401 || error.code === 403) {
          throw error
        }
      })
  }

  onClosePopup() {
    this.setState({
      showPromoDownloadPopup: false,
    })
  }

  handleChangePromoCode(val = []) {
    var values = {
      ...this.state.values,
      promoCode: val.map((v = '') => v.replace(/ /g, '')),
    }

    if (!val || val.length === 0) {
      values.addToWallet = false
    }

    this.setState({
      values: values,
    })
  }

  handleUniquePromocodeValidations(val) {
    if (!val) {
      const validations = JSON.parse(JSON.stringify(this.state.validations))
      const values = JSON.parse(JSON.stringify(this.state.values))
      values['hasUniquePromocode'] = val
      delete validations.prefix
      delete validations.suffix
      delete validations.numberOfPromos
      this.setState({
        validations,
        values,
      })
    } else {
      const values = JSON.parse(JSON.stringify(this.state.values))
      values['hasUniquePromocode'] = val
      values.promoCode = []
      this.setState({
        values,
      })
    }
    return val
  }

  changeTab(val) {
    const activeIndex = this.props.options.activeIndex
    const validationsObj = this.state.validations
    if (activeIndex === 1 && !this.isValidStore(this.state.values)) {
      validationsObj['storeId'] = {
        valid: false,
        valueMissing: true,
      }
    }
    const isValid = this.isFormValid(validationsObj?.type || validationsObj)
    if (isValid) {
      this.tabForms[activeIndex] = true
    } else {
      this.tabForms[activeIndex] = false
    }
    const validations = { type: this.state.validations.type }
    this.setState({ validations }, () => this.props.options.changeTab(val))
  }

  // This method is for updating the values in state, whatever values
  // are being sent from the child component, the parent is gonna save it.
  // So, handle it with care inside child component
  handleUpdateValues(values) {
    this.setState({ values })
  }

  handleUpdateValidations(validations) {
    this.setState({ validations })
  }

  handleCloneOffer(onlyCopy = true) {
    const { state, props } = this
    const { values: valueState } = state || {}
    let values = valueState
    if (!onlyCopy) {
      this.disableOffer(values)
        .then(async () => {
          const redemptionValues = await this.fetchRedemptionCount(values.id)
          const totalRedemption = redemptionValues.redemptionLimit
          const redeemedCount = Number(redemptionValues.redemptionCount || 0)

          values = { ...values, totalRedemption, redeemedCount }

          this.cloneOffer(values, onlyCopy)
          props.history.push('/marketing/offers/add')
        })
        .catch((error) => {
          this.setState({
            showClonePopup: false,
            disableError: true,
            error: error,
          })
        })
    } else {
      values = {
        ...values,
        totalRedemption: null,
        redeemedCount: null,
        customerRedemptionLimit: null,
      }
      this.cloneOffer(values, onlyCopy)
      const createURL = this.props.options.isPayViaFpApp
        ? '/marketing/offers/pay-via-fp-app/add'
        : '/marketing/offers/add'
      props.history.push(createURL)
    }
  }

  handleToggleClonePopup() {
    this.setState({ showClonePopup: !this.state.showClonePopup })
  }

  disableOffer(values) {
    const { id } = values
    const url = `/offer-service/offer/${id}`
    const api = new API({ url })
    return api.put({ id, status: 'DISABLED' })
  }

  cloneOffer(state = {}, onlyCopy) {
    const { id, ...newOfferFromState } = state
    let newOfferState = newOfferFromState
    if (!onlyCopy) {
      newOfferState = { ...newOfferState, copyOf: id }
    }
    delete newOfferState.clientId
    window.sessionStorage.setItem('clonedOffer', JSON.stringify(newOfferState))
  }

  closeErrorDialog() {
    this.setState({ disableError: false })
  }

  closeSapErrorDialog() {
    this.setState({ showSapRefError: false })
  }

  closeRuleMinValueError() {
    this.setState({ showRuleMinValueError: false })
  }

  async fetchRedemptionCount(id) {
    const url = `/offer-service/offer/${id}/redemption`
    const api = new API({ url })
    const res = await api.get()
    const { data } = res
    const { redemptionCount, redemptionLimit } = data || {}

    return { redemptionCount, redemptionLimit }
  }

  render() {
    const { Form } = this.components
    const { SubmitButton, CancelButton } = this.buttons
    const { entityIds, stores, itemDiscountTypes } = this.props.options
    const {
      offerMapping: offerMappingProp,
      discountTypes,
      pwpTagId,
      productOnlyOffers,
      isPayViaFpApp,
      linkPayOffers,
      isPinWallet,
    } = this.props.options
    const { values } = this.state
    const { type, entityType, tags, oldFormat } = values
    const tagsLength = tags && tags.length > 0
    const offerType = values && values.type
    const redeemedCount = values && values.redeemedCount
    const FormComponent = this.offerForm[offerType] || noop
    const itemsCount =
      values &&
      entityType &&
      values[entityType.toLowerCase()] &&
      (Array.isArray(values[entityType.toLowerCase()])
        ? values[entityType.toLowerCase()].length
        : values[entityType.toLowerCase()])
    const activeIndex = this.props.options.activeIndex || 0
    const promomtionsTab = activeIndex === 0
    const restrictionsTab = activeIndex === 1
    const overrideTab = activeIndex === 2
    let offerMapping = offerMappingProp
    if (this.isNewOffer) {
      offerMapping = offerMapping.filter((offer) => offer.value !== 'SF')
    }
    let applicableOffers = offerMapping.slice()
    if (entityType !== 'PRODUCT') {
      // Not all offers are applicable on category and brand entities
      applicableOffers = applicableOffers.filter((el) => {
        return productOnlyOffers.indexOf(el.value) < 0
      })
    }
    let applicableDiscounts = discountTypes.slice()
    if (this.freeDiscoutOffers.indexOf(offerType) > -1) {
      applicableDiscounts.push({
        text: 'Free',
        value: 'FREE',
      })
    }
    if (isPayViaFpApp) {
      applicableDiscounts = applicableDiscounts.filter(
        (discount) => discount.value !== 'FIXED'
      )
      // Not all offers are applicable on linkpay offers
      applicableOffers = applicableOffers.filter(
        (el) => linkPayOffers.indexOf(el.value) >= 0
      )
    }

    let disableCheckbox = false
    if (values && values.type === 'BMINXGFG') {
      const product = values.product || []
      product.length > 0 &&
        product.map((item) => {
          if (item.t === 'FIXED') {
            disableCheckbox = true
          }
          return null
        })
    } else if (values && values.type !== 'BMINXGFG') {
      if (values.itemDiscountType === 'COMBO_DISCOUNT') {
        disableCheckbox =
          values &&
          values.rule &&
          values.rule.total &&
          values.rule.total.t &&
          values.rule.total.t === 'FIXED'
      } else {
        const product = values.product || []
        product.length > 0 &&
          product.map((item) => {
            if (item.t === 'FIXED') {
              disableCheckbox = true
            }
            return null
          })
      }
    }
    const isClonedOfferWithContinuation =
      redeemedCount !== null && redeemedCount !== undefined
    const showMaxRedemption = !this.noMaxRedemption.includes(type)
    return (
      <React.Fragment>
        {isPinWallet ? (
          <PinWallet _this={this} />
        ) : (
          <>
            <EditButton
              method={this.props.method}
              value={this.props.value}
              handleToggleClonePopup={this.handleToggleClonePopup}
              handleCloneOffer={this.handleCloneOffer}
            />
            <div className="tabs-offer">
              <Tabs
                items={tabsList.map((tab) => `${tab.text}`)}
                default={0}
                onClick={this.changeTab}
                active={this.props.options.activeIndex}
              />
            </div>
            <div className="offers-form offers-toggle">
              <Toggle
                name="offersStatus"
                value={this.getState(['status']) === 'ENABLED'}
                icons={false}
                onChange={() => {
                  const status =
                    this.getState(['status']) !== 'ENABLED'
                      ? 'ENABLED'
                      : 'DISABLED'
                  this.updateState(['status'], status)
                }}
              />
            </div>
            <Form className="offers-form">
              {promomtionsTab && (
                <>
                  <Promotion
                    isPayViaFpApp={isPayViaFpApp}
                    entityIds={entityIds}
                    entityType={entityType}
                    handleEntityTypeOnchange={this.handleEntityTypeOnchange}
                    isNewOffer={this.isNewOffer}
                    applicableOffers={applicableOffers}
                    handleOfferTypeOnchange={this.handleOfferTypeOnchange}
                    generateStateMappers={this.generateStateMappers}
                  />
                  <FormComponent
                    entityType={entityType}
                    discountTypes={applicableDiscounts}
                    save={this.saveData}
                    type={type}
                    cancel={this.props.onCancel}
                    entityTypeMap={this.entityType}
                    itemDiscountTypes={itemDiscountTypes}
                    _this={this}
                    buyKey={this.props.options.buyKey}
                    hasOfferStarted={this.hasOfferStarted}
                    isNewOffer={this.isNewOffer}
                    pwpTagId={pwpTagId}
                    method={this.props.method}
                    onUpdateValues={this.handleUpdateValues}
                    onUpdateValidations={this.handleUpdateValidations}
                    oldFormat={oldFormat}
                    isPayViaFpApp={isPayViaFpApp}
                  />
                </>
              )}
              {restrictionsTab && (
                <Restrictions
                  isPayViaFpApp={isPayViaFpApp}
                  generateStateMappers={this.generateStateMappers}
                  showMaxRedemption={showMaxRedemption}
                  hasOfferStarted={this.hasOfferStarted}
                  _this={this}
                  stores={stores}
                  disableCheckbox={disableCheckbox}
                  handleUniquePromocodeValidations={
                    this.handleUniquePromocodeValidations
                  }
                  values={this.state.values}
                  showPromoDownloadPopup={this.showPromoDownloadPopup}
                  method={this.props.method}
                  isClonedOfferWithContinuation={isClonedOfferWithContinuation}
                  redeemedCount={redeemedCount}
                  handleImageClick={this.handleImageClick}
                  onClosePopup={this.onClosePopup}
                  handleChangePromoCode={this.handleChangePromoCode}
                  currentTotalRedemption={this.state.currentTotalRedemption}
                  onUpdateStores={this.onUpdateStores}
                  onUpdateStoreGroupings={this.onUpdateStoreGroupings}
                  pressedSubmitWithCurrentData={
                    this.state.pressedSubmitWithCurrentData
                  }
                />
              )}
              {overrideTab && (
                <OverrideContent
                  isPayViaFpApp={isPayViaFpApp}
                  iconTypes={iconTypes}
                  generateStateMappers={this.generateStateMappers}
                  hasOfferStarted={this.hasOfferStarted}
                  _this={this}
                  metadataFormTypes={this.state.metadataFormTypes}
                  addEligibleItems={this.addEligibleItems}
                  removeEligibleItems={this.removeEligibleItems}
                  eligibleItems={values.eligibleItems}
                  treatmentTypes={treatmentTypes}
                  enablePlaceholderValue
                />
              )}
              {this.state.overSapReferenceLengthPopup && (
                <Dialog
                  show={this.state.overSapReferenceLengthPopup}
                  className="notification failure"
                  title={getMessage('offer.dialog.clone.label')}
                  information={getMessage('offer.dialog.sapreference.message')}
                  close={() =>
                    this.setState({ overSapReferenceLengthPopup: false })
                  }
                  closeText={getMessage('offer.dialog.disable.close')}
                />
              )}

              {((itemsCount ||
                this.noItemsOffers.includes(type) ||
                tagsLength) &&
                this.props.method === 'add') ||
              type === 'BMINXGFG' ||
              this.props.method === 'edit' ? (
                <div className="form-action">
                  <SubmitButton
                    disabled={
                      !(
                        itemsCount ||
                        this.noItemsOffers.includes(type) ||
                        tagsLength ||
                        (type === 'BMINXGFG' &&
                          values.rule &&
                          values.rule.elementGroups &&
                          values.rule.elementGroups.every(
                            (grp) =>
                              Array.isArray(grp.product) && grp.product.length
                          ))
                      )
                    }
                  >
                    {getMessage('offer.submit')}
                  </SubmitButton>
                  <CancelButton>{getMessage('offer.cancel')}</CancelButton>
                </div>
              ) : null}
            </Form>
            {this.state.showClonePopup && (
              <DialogComponent
                show={this.state.showClonePopup}
                title={getMessage('offer.dialog.clone.label')}
                information={getMessage('offer.dialog.clone.message')}
                onClose={this.handleToggleClonePopup}
                closeText={getMessage('offer.dialog.clone.close')}
                onOk={() => this.handleCloneOffer(false)}
                okText={getMessage('offer.dialog.clone.proceed')}
              />
            )}
            {this.state.disableError && (
              <DialogComponent
                show={this.state.disableError}
                title={getMessage('offer.dialog.disable.errorHead')}
                information={getMessage('offer.dialog.disable.errorText')}
                onClose={this.closeErrorDialog}
                closeText={getMessage('offer.dialog.disable.close')}
              />
            )}
            {this.state.showSapRefError && (
              <DialogComponent
                show={this.state.showSapRefError}
                title={getMessage('offer.dialog.sapref.errorHead')}
                information={getMessage('offer.dialog.sapref.errorText')}
                onClose={this.closeSapErrorDialog}
                closeText={getMessage('offer.dialog.sapref.close')}
              />
            )}
            {this.state.showRuleMinValueError && (
              <DialogComponent
                show={this.state.showRuleMinValueError}
                title={getMessage('offer.dialog.sapref.errorHead')}
                information={
                  ['BMIN', 'BMINXGFG'].indexOf(values.type) > -1
                    ? getMessage('offer.dialog.minAmount.errorText')
                    : getMessage('offer.dialog.cartprice.errorText')
                }
                onClose={this.closeRuleMinValueError}
                closeText={getMessage('offer.dialog.sapref.close')}
              />
            )}

            <ConfirmationModal
              show={this.state.showSubmitConfirmation}
              onEdit={() => this.setShowSubmitConfirmation(false)}
              onConfirm={this.onConfirmSubmit}
              values={values}
              stores={stores}
              isPayViaFpApp={isPayViaFpApp}
            />
          </>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(OfferForm)
