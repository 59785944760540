import React from 'react'
import { Input, VALIDATION_TYPES } from 'components/Form'
import Analytic from 'components/AppHome/Analytic'
import { ANALYTICS_PLACEHOLDER } from './utils'
import { getMessage } from 'lib/translator'

const PopupForm = ({
  method,
  trigger,
  errorFields,
  dismissErrors,
  generateStateMappers,
}) => {
  const storeInputPattern =
    trigger === 'scan-check-in' ? '^(?!.*fp-all-stores)[^\\s]*$' : '^[^\\s]*$'

  const storeInputValidationMsg =
    trigger === 'scan-check-in'
      ? 'Input cannot contain spaces or "fp-all-stores"'
      : 'Input cannot contain spaces'

  return (
    <>
      <h3>{getMessage('omni-journey.popup.header')}</h3>
      <Input
        name="title"
        label={getMessage('omni-journey.title-field.label')}
        placeholder={getMessage('omni-journey.title-field.placeholder')}
        {...generateStateMappers({
          stateKeys: ['popup', 'title'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        name="message"
        label={getMessage('omni-journey.message-field.label')}
        placeholder={getMessage('omni-journey.message-field.placeholder')}
        {...generateStateMappers({
          stateKeys: ['popup', 'message'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        name="delayMs"
        disabled={trigger === 'scan-product'}
        className="delayMs"
        type="number"
        label={getMessage('omni-journey.delay-field.label')}
        placeholder={getMessage('omni-journey.delay-field.placeholder')}
        step="1"
        min={1}
        max={10000000}
        prefix="milliseconds"
        {...generateStateMappers({
          stateKeys: ['popup', 'delayMs'],
          loseEmphasisOnFill: true,
        })}
      />
      <Input
        required
        name="store"
        label={getMessage('omni-journey.store-field.label')}
        placeholder={getMessage('omni-journey.store-field.placeholder')}
        disabled={method === 'edit'}
        pattern={storeInputPattern}
        {...generateStateMappers({
          stateKeys: ['popup', 'store'],
          loseEmphasisOnFill: true,
          validationType: VALIDATION_TYPES.ONSUBMIT,
        })}
        validationStrings={{
          patternMismatch: storeInputValidationMsg,
        }}
      />
      <Analytic
        type="cta"
        header="CTA Button"
        errorFields={errorFields}
        dismissErrors={dismissErrors}
        placeholder={ANALYTICS_PLACEHOLDER}
        generateStateMappers={generateStateMappers}
        stateKeys={['popup', 'cta']}
      />
      <Analytic
        type="closeBtnCta"
        header="Close Button"
        errorFields={errorFields}
        dismissErrors={dismissErrors}
        placeholder={ANALYTICS_PLACEHOLDER}
        generateStateMappers={generateStateMappers}
        stateKeys={['popup', 'closeBtnCta']}
      />
      <Input
        name="closeBtnText"
        label={getMessage('omni-journey.button.text.label')}
        placeholder={getMessage('omni-journey.button.text.placeholder')}
        {...generateStateMappers({
          stateKeys: ['popup', 'closeBtnText'],
          loseEmphasisOnFill: true,
        })}
      />
      <Analytic
        type="cancelBtnCta"
        header="Cancel Button"
        errorFields={errorFields}
        dismissErrors={dismissErrors}
        placeholder={ANALYTICS_PLACEHOLDER}
        generateStateMappers={generateStateMappers}
        stateKeys={['popup', 'cancelBtnCta']}
      />
    </>
  )
}

export default PopupForm
