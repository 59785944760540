import React from 'react'
import { BaseForm } from 'components/Form'
import { getMessage } from 'lib/translator'
import FilterStore from '../ScanToWin/FilterStore'
import { OMNI_JOURNEY_MENU } from '../utils'

class Filters extends BaseForm {
  constructor(props) {
    super(props)
  }
  render() {
    const { SubmitButton } = this.buttons
    const { Form } = this.components
    const { type } = this.props.options

    return (
      <Form>
        <div className="filters-section">
          {type === OMNI_JOURNEY_MENU[1] && (
            <FilterStore generateStateMappers={this.generateStateMappers} />
          )}
        </div>
        <SubmitButton testid="submit-filter">
          {getMessage('omni-journey.submit-button')}
        </SubmitButton>
      </Form>
    )
  }
}

export default Filters
