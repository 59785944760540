import React, { useState } from 'react'
import CustomDropdown from '../OfferSequenceLabel/CustomDropdown'
import ConfigCardList from '../OfferSequenceLabel/ConfigCardList'
import './style.css'
import AddNewConfig from '../OfferSequenceLabel/AddNewConfig'
import API from 'lib/api'
import { CAMPAIGN_LABELS_API } from 'config/app'
import InfoBanner from '../OfferSequenceLabel/InfoBanner'
import { isCatalogueProductReadOnly } from 'pages/catalogue/Products/utils'

function ConfigSetup() {
  const [selectedOption, setSelectedOption] = useState('offer-label-sequence')
  const [addNew, setAddNew] = useState(false)
  const [message, setMessage] = useState({})
  const isReadOnly = isCatalogueProductReadOnly()

  const onSave = async (data) => {
    try {
      const api = new API({
        url: `${CAMPAIGN_LABELS_API}/v1/configuration-management/${selectedOption}`,
      })

      const response = await api.post(data)
      setMessage({ message: response.message, status: 'success' })
      setAddNew(false)
    } catch (error) {
      /* istanbul ignore next */
      setMessage({ message: error.message, status: 'error' })
    }
  }

  const onCancel = () => {
    setAddNew(false)
  }

  return (
    <div>
      <div className="ConfigSetup">
        <h1>Configurations</h1>
        <div className="ConfigAdd">
          <CustomDropdown
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          />
          {!addNew && !isReadOnly && (
            <div className="ConfigNew">
              <h5
                onClick={() => {
                  setAddNew(true)
                }}
              >
                + Add New Config
              </h5>
            </div>
          )}
        </div>
      </div>

      <div className="ConfigContent">
        <InfoBanner message={message} />
        {addNew ? <AddNewConfig onCancel={onCancel} onSave={onSave} /> : ''}
        {!addNew ? <ConfigCardList selectedOption={selectedOption} /> : ''}
      </div>
    </div>
  )
}

export default ConfigSetup
