import React, { useState } from 'react'
import moment from 'moment'
import { Row, Cell } from 'components/Table'
import PopupModal from 'components/Popup/Modal'
import { getMessage } from 'lib/translator'
import Dialog from 'components/Popup/Dialog'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import API from 'lib/api'
import './style.scss'

function VoucherCampaignRow({
  id,
  name,
  status,
  validFrom,
  validTill,
  isExpiredCampaign,
}) {
  const [showStatusChangeDialog, setShowStatusChangeDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useState(null)

  const getDate = (dateTime) =>
    dateTime && moment(dateTime).format('D MMM YYYY')
  const getTime = (dateTime) => dateTime && moment(dateTime).format('hh:mm A')

  const changeCampaignStatus = () => {
    const api = new API({ url: `/offer-service/campaigns/${id}` })
    api
      .patch({ status: status === 'ENABLED' ? 'DISABLED' : 'ENABLED' })
      .then((res) => {
        if(res) {
          window.location.reload()
        }
      })
      .catch((err) => {
        if (err.code === 400 || err.code === 500) {
          setErrorDialog(err?.message || 'Server Error')
        }
      })
  }

  return (
    <>
      <Row className="voucher-center-table-row" key={`row-${id}`}>
        <Cell className="campaign-id">
          <Link to={`/marketing/voucher-center-campaigns/${id}`}>
            <div>{id}</div>
          </Link>
        </Cell>

        <Cell className="campaign-name">
          <Link to={`/marketing/voucher-center-campaigns/${id}`}>
            <div>{name}</div>
          </Link>
        </Cell>

        <Cell className="campaign-status">
          <div className="status text-muted">
            {isExpiredCampaign ? 'EXPIRED' : status}
          </div>
        </Cell>

        <Cell className="campaign-start-time">
          <div>{getDate(validFrom)}</div>
          <div className="time text-muted">{getTime(validFrom)}</div>
        </Cell>

        <Cell className="campaign-start-time">
          <div>{getDate(validTill)}</div>
          <div className="time text-muted">{getTime(validTill)}</div>
        </Cell>

        <Cell>
          <DropDown
            isDisable={isExpiredCampaign}
            icon={<img src={ICONS.VELLIP} alt="⋮" />}
          >
            <DropDownItem>
              <Link to={`voucher-center-campaigns/${id}`}>Edit</Link>
            </DropDownItem>

            {status === 'DISABLED' ? (
              <DropDownItem onClick={() => setShowStatusChangeDialog(true)}>
                Enable
              </DropDownItem>
            ) : (
              <DropDownItem onClick={() => setShowStatusChangeDialog(true)}>
                Disable
              </DropDownItem>
            )}
          </DropDown>
        </Cell>
      </Row>

      <PopupModal
        show={showStatusChangeDialog}
        close={() => setShowStatusChangeDialog(false)}
      >
        <div className="confirmation-popup">
          <div className="body-wrapper">
            <p className="title">Are you sure?</p>
            <p className="body">
              {status === 'ENABLED' ? 'Diable' : 'Enable'} this campaign #{id}?
            </p>
            <div className="buttons-wrapper">
              <button
                className="button secondary"
                onClick={() => setShowStatusChangeDialog(false)}
              >
                Cancel
              </button>
              <button
                className="button primary"
                onClick={() => changeCampaignStatus()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </PopupModal>

      {errorDialog && (
        <Dialog
          className="error-dialog"
          show={!!errorDialog}
          title="Error"
          information={errorDialog}
          close={() => setErrorDialog({ errorDialog: null })}
          closeText={getMessage('dialog.okText')}
        />
      )}
    </>
  )
}

export default VoucherCampaignRow
