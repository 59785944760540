import React from 'react';
import { VALIDATION_TYPES, Input, SingleDatePicker, TimePicker } from 'components/Form';
import { getMessage } from 'lib/translator';
import Button from 'components.new/button/Button'
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as BinIcon } from 'icons/delete.svg';
import moment from 'moment';

function OfferSchedulesInput({ formValues, generateStateMappers, addOfferSlot, removeOfferSlotByIndex, isExpiredCampaign }) {
  return(
    <>
      <div className="offer-slots-container">
      <p className='offer-slots-title'>{getMessage('offer.campaigns.form.offerSchedules.title')}</p>
      <div>
        {
          formValues?.schedules?.length > 0 && formValues.schedules.map((offerSlot, index) => (
            <div key={`offer-slot-${offerSlot.uid || offerSlot.id}`} className="single-slot-container">
              <div className="single-offer-slot-inputs" >
                <SingleDatePicker
                  name="scheduleStartDate"
                  required
                  label={getMessage('offer.campaigns.form.offerSchedules.startDate')}
                  allowAllDates
                  disabled={isExpiredCampaign}
                  {...generateStateMappers({
                    stateKeys: ['schedules', index, 'startDate'],
                    loseEmphasisOnFill: true,
                  })}
                />
                <SingleDatePicker
                  name="scheduleEndDate"
                  required
                  label={getMessage('offer.campaigns.form.offerSchedules.endDate')}
                  allowAllDates
                  disabled={isExpiredCampaign}
                  {...generateStateMappers({
                    stateKeys: ['schedules', index, 'endDate'],
                    loseEmphasisOnFill: true,
                  })}
                />
                <TimePicker
                  name="scheduleStartTime"
                  label={getMessage('offer.campaigns.form.offerSchedules.startTime')}
                  required
                  placeholder="time"
                  disabled={isExpiredCampaign}
                  {...generateStateMappers({
                    stateKeys: ['schedules', index, 'startTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
                <TimePicker
                  name="scheduleEndTime"
                  label={getMessage('offer.campaigns.form.offerSchedules.endTime')}
                  required
                  placeholder="time"
                  disabled={isExpiredCampaign}
                  {...generateStateMappers({
                    stateKeys: ['schedules', index, 'endTime'],
                    loseEmphasisOnFill: true,
                  })}
                />
                <Input
                  type="text"
                  placeholder={getMessage("offer.campaigns.form.offerSchedules.offerIds.placeholder")}
                  name="scheduleOfferIds"
                  className="offer-ids"
                  label={getMessage("offer.campaigns.form.offerSchedules.offerIds")}
                  required
                  disabled={isExpiredCampaign}
                  {...generateStateMappers({
                    stateKeys: ['schedules', index, 'offers'],
                    loseEmphasisOnFill: true,
                    validationType: VALIDATION_TYPES.ONSUBMIT,
                  })}
                />
                <div className="delete-icon">
                  {
                    formValues.schedules.length > 1 &&
                    <BinIcon width={16} height={16} onClick={(e) => {
                      e.preventDefault()
                      if (!isExpiredCampaign) {
                        removeOfferSlotByIndex(index)
                      }
                    }} />
                  }
                </div>
              </div>
              {
                offerSlot.startDate && offerSlot.endDate && offerSlot.startTime && offerSlot.endTime &&
                <span className="offer-schedule-subtext">
                  Offer is scheduled between {offerSlot.startTime} to {offerSlot.endTime} daily
                  from {moment(offerSlot.startDate).format("YYYY-MM-DD")} to {moment(offerSlot.endDate).format("YYYY-MM-DD")}
                </span>
              }
            </div>
          ))
        }
      </div>
      <Button
        className="button primary add-offer-slot-btn"
        disabled={isExpiredCampaign}
        onClick={addOfferSlot}>
        <PlusIcon />
        {getMessage('offer.campaigns.form.addSchedule')}
      </Button>
      </div>
    </>
  )
}

export default OfferSchedulesInput;
