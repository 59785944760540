import React, { Component, Fragment } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import AuthenticatedPage from '../../../containers/AuthenticatedPage'
import API from '../../../lib/api'
import Loader from '../../../components/Loader'
import Tabs from '../../../components/Tabs'
import {
  SingleDatePicker,
  Select,
  CategorySearch,
  BrandSearch,
  Searchable,
  Checkbox,
} from '../../../components/Form'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc'
import { getMessage } from '../../../lib/translator'
import { isExtensionEnabled, getSession } from '../../../lib/auth'
import { Link } from 'react-router-dom'
import { getTimes } from '../../../lib/datetime'
import moment from 'moment'
import { DropDown, DropDownItem } from '../../../components/DropDown'
import Layout from './layout'
import Banner from '../../../components/Layout/Banner'
import ProductCollection from '../../../components/Layout/ProductCollection'
import { Popup, Dialog } from '../../../components/Popup'
import './style.css'
import getLayout from './Layouts/getLayout'

import arrow from '../../../components/Form/Inputs/Select/dropdown arrow.svg'
import deleteIcon from './cx-delete-icon.svg'

import UpdatedLayout from './components/UpdatedLayout'
import O2OLayout from './components/O2OLayout'
import { uniqueId } from 'lodash'
import VoucherSwimLane from 'components/Layout/Voucher'

import { SplitContext } from 'containers/SplitContext'
import SPLIT_FEATURES from 'containers/SplitContext/features'
import { getLayoutUrl } from './components/ConfirmationDialog'

// serchable component for brand and category
const searchableComponent = {
  brand: BrandSearch,
  category: CategorySearch,
}

// Layout components mapping with their names
const availableComponents = {
  'E-commerce': {
    ProductCollection: ProductCollection,
    CategoryCollection: ProductCollection,
    BrandCollection: ProductCollection,
    SearchResults: ProductCollection,
  },
  Banners: {
    MarketingBanners: Banner,
  },
  Voucher: {
    VoucherSwimlane: VoucherSwimLane,
  },
}

const DragHandle = SortableHandle((props) => (
  <span className="sortable-handle">{props.children}</span>
))

const SortableItem = SortableElement((props) => {
  const { value, layoutIndex, onEditClick, onRemoveClick } = props
  let LayoutComponent = ''
  Object.keys(availableComponents).forEach(function (component) {
    if (availableComponents[component][value.name]) {
      LayoutComponent = availableComponents[component][value.name]
    }
  })
  let element = ''
  return (
    <div className="layoutItem">
      <h3>
        <DragHandle>{value.name.replace(/([A-Z])/g, ' $1').trim()}</DragHandle>
      </h3>
      <div
        className={value.name + ' editable layout'}
        ref={(el) => {
          element = el
        }}
      >
        {LayoutComponent && (
          <LayoutComponent
            data={value.data}
            status={value.status || 'DISABLED'}
          />
        )}
        <div className="toolbar">
          {value.name !== 'MarketingBanners' && (
            <button
              data-testid={`edit-${value.name}`}
              className="primary button"
              onClick={() => {
                onEditClick(layoutIndex, element.offsetTop)
              }}
            >
              Edit
            </button>
          )}
          <button
            className="button"
            onClick={() => {
              onRemoveClick(layoutIndex)
            }}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  )
})

const SortableList = SortableContainer((props) => {
  return (
    <div className="layoutsContainer">
      {Array.isArray(props.items) &&
        props.items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            layoutIndex={index} // for some reason index was not being sent as props
            {...props}
          />
        ))}
    </div>
  )
})
const Ecommerce = 'E-commerce'
class LayoutManagerComponent extends Component {
  constructor(props) {
    super(props)
    const user = getSession().user
    const layoutEditSplit =
      this.props.splits?.[SPLIT_FEATURES.DISCOVERY_LAYOUT_EDIT] || {}
    let isLayoutEditAllowed = true
    if (layoutEditSplit.treatment === 'on') {
      const allowedUsers = JSON.parse(layoutEditSplit.config)
      isLayoutEditAllowed = allowedUsers.includes(user.id)
    }
    this.state = {
      page: 0,
      // TODO change this to a better name
      loaded: false,
      editing: false,
      submitting: false,
      showDialog: false,
      showPrompt: false,
      category: '',
      brand: '',
      tag: '',
      categoriesDataLoaded: false,
      tagsDataLoaded: false,
      brandsDataLoaded: false,
      currentComponent: Ecommerce,
      timeLine: 'default',
      errors: {},
      showFilter: false,
      layoutMode: 0,
      view: 'collapsed',
      currentLayouts: [],
      showConfirmLayoutPopup: false,
      isLayoutEditAllowed,
    }
    this.pages = ['home', 'category', 'promotion', 'store']
    this.currentPages = [
      'home',
      'category',
      'promotion',
      'store',
      'tag',
      'brand',
      'search',
    ]
    this.isCustomizeEnabled =
      this.props.splits?.[SPLIT_FEATURES.BACK_GSR_WEBSITE_COLUMN_VIEW]
        ?.treatment === 'on'
    const productTagEnabled = isExtensionEnabled('ProductTagSupport')
    const brandsEnabled = isExtensionEnabled('MultiBrandSupport')

    if (productTagEnabled) {
      this.pages.push('tag')
    }
    if (brandsEnabled) {
      this.pages.push('brand')
    }

    this.pages.push('search')

    this.data = {} // Discuss: Didn't want to put this in state
    this.currentLayout = {}
    this.selectOptionsData = {
      categories: [],
      tags: [],
      brands: [],
    }
    this.listView = this.listView.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.onAddClick = this.onAddClick.bind(this)
    this.onEditClick = this.onEditClick.bind(this)
    this.onRemoveClick = this.onRemoveClick.bind(this)
    this.showPrompt = this.showPrompt.bind(this)
    this.updateLayouts = this.updateLayouts.bind(this)
    this.getSelectOptionsData = this.getSelectOptionsData.bind(this)
    this.getSelectElement = this.getSelectElement.bind(this)
    this.formatResponse = this.formatResponse.bind(this)
    this.reorderLayout = this.reorderLayout.bind(this)
    this.confirmSaveLayout = this.confirmSaveLayout.bind(this)
    this.cancelReordering = this.cancelReordering.bind(this)
    this.setLayoutSchedulerValues = this.setLayoutSchedulerValues.bind(this)
    this.setLayoutTimeline = this.setLayoutTimeline.bind(this)
    this.fetchScheduledLayout = this.fetchScheduledLayout.bind(this)
    this.handleDeleteSceduledLayout = this.handleDeleteSceduledLayout.bind(this)
    this.fetchWrapper = this.fetchWrapper.bind(this)
    this.getLayoutSpecificDetail = this.getLayoutSpecificDetail.bind(this)
    this.isEditablePage = this.isEditablePage.bind(this)
    this.getSpecficDetails = this.getSpecficDetails.bind(this)
    this.isHomeLayoutAPIEnabled = this.isHomeLayoutAPIEnabled.bind(this)
    this.isNewLayoutEnabled = this.isNewLayoutEnabled.bind(this)
    this.sendOfflineParam = this.sendOfflineParam.bind(this)
  }

  componentDidMount() {
    this.fetchWrapper()
  }

  // This method can be removed later once the scheduler api will be supported for all pages
  fetchWrapper() {
    this.fetchScheduledLayout()
  }

  formatResponse(response) {
    const newResponse = Object.assign([], response)
    const page = this.pages[this.state.page]
    newResponse.forEach((layout) => {
      if (
        layout.name === 'CategoryCollection' ||
        layout.name === 'BrandCollection'
      ) {
        const key = layout.name === 'CategoryCollection' ? 'category' : 'brand'
        let collection = (layout.value && layout.value.collection) || []
        collection = collection.map((val) => val[key])
        layout['data'][key] = collection
        layout.value && delete layout.value.collection
      }
      if (
        layout.name === 'CategoryCollection' &&
        layout.data &&
        layout.data.subcategories &&
        page === 'category'
      ) {
        layout.name = 'SubCategoryCollection'
      }
    })
    return newResponse
  }

  isNewLayoutEnabled() {
    const page = this.pages[this.state.page]
    return this.isHomeLayoutAPIEnabled() && page === 'home'
  }

  sendOfflineParam() {
    return this.isNewLayoutEnabled() ? 'offline' : 'o2o'
  }

  isHomeLayoutAPIEnabled() {
    return (
      this.props?.splits?.[SPLIT_FEATURES.BACK_DIS_WEBSITE_HOME_LAYOUT]
        ?.treatment === 'on'
    )
  }

  fetchScheduledLayout() {
    this.setState({
      loaded: false,
    })
    const page = this.pages[this.state.page]
    const timeline = this.state.timeLine
    let url = ''

    let params = {}

    if (this.isNewLayoutEnabled()) {
      url = getLayoutUrl('NEW_PAGE', page)
      params = {
        platform: this.state.layoutMode === 1 && 'offline',
      }
    } else {
      url = getLayoutUrl('OLD_URL')
      params = {
        id: page,
        platform: this.state.layoutMode === 0 ? undefined : 'o2o',
      }
    }

    if (timeline !== 'new') {
      params.validFrom = timeline === 'default' ? null : timeline.split('/')[0]
      params.validTill = timeline === 'default' ? null : timeline.split('/')[1]
    }

    if (this.state[page]) {
      params.url = this.state[page]
    }

    const api = new API({
      url: url,
    })
    api.get(params).then((response) => {
      if (response.status === 'ERROR') {
        console.error(`Error in fetching details for ${page}`) // Todo: Handle this error
        return
      }
      if (!response.data.page.length) {
        this.data = []
        this.setState({ loaded: true })
        return
      }
      if (page !== 'search') {
        delete availableComponents['E-commerce']['SearchResults']
      } else {
        availableComponents['E-commerce']['SearchResults'] = ProductCollection
      }

      if (page !== 'category') {
        delete availableComponents['E-commerce']['SubCategoryCollection']
      } else {
        availableComponents['E-commerce']['SubCategoryCollection'] =
          ProductCollection
      }
      const isProductionCollectionWithBannerEnabled =
        this.props.splits?.[
          SPLIT_FEATURES.DISCOVERY_PRODUCT_COLLECTION_WITH_BANNER_ENABLED
        ]?.treatment === 'on'
      if (page === 'promotion' && isProductionCollectionWithBannerEnabled) {
        availableComponents['E-commerce']['ProductCollectionWithBanner'] =
          ProductCollection
      } else {
        delete availableComponents['E-commerce']['ProductCollectionWithBanner']
      }
      this.pageId = response.data.page[0].pageId
      this.data = this.formatResponse(response.data.page[0].layouts)
      this.originalData = this.data

      const validFrom = response.data.page[0].validFrom
      const validTill = response.data.page[0].validTill

      if (!this.state.categoryDataLoaded) {
        this.getSelectOptionsData('category', 'categories')
      }
      if (this.pages.includes('tag')) {
        if (!this.state.tagDataLoaded) {
          this.getSelectOptionsData('tag', 'tags')
        }
      }
      if (this.pages.includes('brand')) {
        if (!this.state.brandDataLoaded) {
          this.getSelectOptionsData('brand', 'brands')
        }
      }
      this.setState(
        {
          loaded: true,
          fromDate: validFrom && moment(validFrom).format('YYYY-MM-DD'),
          fromTime: validFrom && moment(validFrom).format('HH:mm:ss'),
          toDate: validTill && moment(validTill).format('YYYY-MM-DD'),
          toTime: validTill && moment(validTill).format('HH:mm:ss'),
          showFilter: response.data.page[0].showFilter || false,
          currentLayouts: this.data,
        },
        () => {
          timeline !== 'new' &&
            isExtensionEnabled('LayoutSchedulingSupport') &&
            this.fetchLayoutTimeLine()
        }
      )
    })
  }

  fetchLayoutTimeLine() {
    const page = this.pages[this.state.page]
    const url = this.isNewLayoutEnabled()
      ? getLayoutUrl('NEW_DATE', page)
      : getLayoutUrl('OLD_DATE', page)

    let params
    if (this.isCustomizeEnabled) {
      params = this.state.view === 'collapsed' &&
        this.state[page] !== 'home' &&
        this.state[page] && { url: this.state[page] }
    } else {
      params = this.state[page] && {
        url: this.state[page],
      }
    }

    const api = new API({ url })

    const isO2OMode = this.state.layoutMode === 1

    api
      .get({
        ...params,
        platform: isO2OMode ? this.sendOfflineParam() : undefined,
      })
      .then((resp) => {
        let timeLines = resp.data.date
        timeLines =
          timeLines &&
          timeLines
            .filter((time) => time.validTill && time.validFrom)
            .map((time) => {
              const startDatetime = moment(time.validFrom)
              const endDatetime = moment(time.validTill)
              return {
                value: time.validFrom + '/' + time.validTill,
                text: (
                  <Fragment>
                    {startDatetime.format('Do MMM')}
                    <span className="layout-time">
                      {startDatetime.format('hh:mm A')}
                    </span>
                    {' - '}
                    &nbsp;
                    {endDatetime.format('Do MMM')}
                    <span className="layout-time">
                      {endDatetime.format('hh:mm A')}
                    </span>
                  </Fragment>
                ),
              }
            })

        timeLines = [
          { text: 'Default', value: 'default' },
          ...timeLines,
          { text: 'Add New', value: 'new' },
        ]

        this.setState({ timeLines })
      })
      .catch((error) => {
        this.setState({
          error: `Unable to fetch timeline dates: ${error.message}`,
        })
      })
  }

  getSelectOptionsData(selectField, dataFor) {
    const api = new API({
      url: `/catalogue-service/${selectField}`,
    })

    api.get().then((response) => {
      if (response.status === 'ERROR') {
        console.error(`Error in fetching details of ${dataFor}`)
        return
      }
      this.selectOptionsData[dataFor] = []
      if (!response.data[selectField]) {
        response.data[selectField] = []
      }
      this.selectOptionsData[dataFor] = response.data[selectField].map(
        function (field) {
          return { text: field.name, value: field.slug }
        }
      )
      this.selectOptionsData[dataFor].push(
        { text: `All ${dataFor}`, value: 'ALL' },
        { text: `Current ${selectField}`, value: 'CURRENT' }
      )
      const state = `${dataFor}DataLoaded`
      this.setState({
        loaded: true,
        [state]: true,
      })
    })
  }

  changePage(page) {
    this.setState(
      {
        page: page,
        timeLine: 'default',
        timeLines: [],
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
      },
      this.fetchWrapper
    )
  }

  // TODO update to a better name
  changeLayoutMode(page) {
    this.setState(
      {
        layoutMode: page,
        timeLine: 'default',
        timeLines: [],
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
      },
      this.fetchWrapper
    )
  }

  getSpecficDetails() {
    const selectedPage = this.pages[this.state.page]
    const { selectedlayoutType } = this.getLayoutSpecificDetail()
    const isTagBrandCategoryPage = ['tag', 'brand', 'category'].includes(
      selectedPage
    )
    const isDefaultView =
      selectedlayoutType === '' || selectedlayoutType === undefined
    return { isTagBrandCategoryPage, isDefaultView }
  }

  confirmSaveLayout() {
    const { isDefaultView, isTagBrandCategoryPage } = this.getSpecficDetails()
    const shouldShowPopup =
      !isTagBrandCategoryPage || (isTagBrandCategoryPage && isDefaultView)
    if (shouldShowPopup) {
      this.setState({
        showConfirmLayoutPopup: true,
      })
    } else {
      this.saveLayouts()
    }
  }

  reorderLayout() {
    this.saveLayouts()
  }

  cancelReordering() {
    this.data = this.originalData
    this.forceUpdate()
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.data = arrayMove(this.data, oldIndex, newIndex)
    this.forceUpdate()
  }

  saveLayouts(index, formData, isDelete) {
    const isO2OMode = this.state.layoutMode === 1

    const { fromDate, fromTime, toDate, toTime, timeLine } = this.state

    this.setState({
      submitting: true,
    })

    // Now save layout for all tabs[0,1,2,3,4,5,6] to get this selection view
    // Before that it is for Homepage only which is 0
    const pageForLayout = this.isCustomizeEnabled ? [0, 1, 2, 3, 4, 5, 6] : [0]
    if (pageForLayout.includes(this.state.page)) {
      this.data = this.state.currentLayouts
    }

    let data = this.data
    if (!formData) {
      data =
        index !== undefined
          ? this.data.filter((_, idx) => idx !== index)
          : this.data
    } else {
      data = data.filter((_, i) => i !== index)
      const layoutname = formData.name
      delete formData.name
      data.splice(index, 0, { name: layoutname, data: { ...formData } })
    }
    if (!index && index !== 0) {
      data = this.data
    }
    const page = this.pages[this.state.page]
    const url = this.isNewLayoutEnabled()
      ? getLayoutUrl('NEW_PAGE', page)
      : getLayoutUrl('OLD_PAGE', page)

    data &&
      data.forEach((layout, i) => {
        const layoutName = layout.name
        if (layoutName === 'SubCategoryCollection') {
          layout.name = 'CategoryCollection'
          layout.data.subcategories = true
        }

        if (layoutName === 'CategoryCollection') {
          if (formData && i === index) {
            layout.data.categoryIds =
              layout.data.category &&
              layout.data.category
                .map((category) => category.id)
                .filter(Boolean)
            delete layout.data.category
          }
        }
        if (layoutName === 'BrandCollection') {
          if (formData && i === index) {
            layout.data.brandIds =
              layout.data.brand &&
              layout.data.brand.map((brand) => brand.id).filter(Boolean)
          }
          delete layout.data.brand
        }
        if (
          layoutName === 'ProductCollection' ||
          layoutName === 'ProductCollectionWithBanner'
        ) {
          layout.data && delete layout.data.collection
          if (layout.data.hasImage === -1) {
            layout.data.hasImage = ''
          }
          if (layout.data.hasStock === -1) {
            layout.data.hasStock = ''
          }
          if (layout.data.hasOffers === -1) {
            layout.data.hasOffers = ''
          }
          if (
            layout.data.category &&
            typeof layout.data.category === 'object'
          ) {
            layout.data.category = layout.data.category.slug
          }
          if (layout.data.brand && typeof layout.data.brand === 'object') {
            layout.data.brand = layout.data.brand.slug
          }
          if (layout.data.tag && typeof layout.data.tag === 'object') {
            layout.data.tag = layout.data.tag.slug
          }
        }
        if (layout.name === 'ImageSlideShow') {
          layout.data &&
            layout.data.activeIndex &&
            delete layout.data.activeIndex
        }

        if (layout.data && layout.data.status) {
          layout.status = layout.data.status
          delete layout.data.status
        }
        if (!isDelete && isO2OMode && layout.status) {
          layout.status = 'ENABLED'
        }

        delete layout.value
        layout.data && delete layout.data.collection
      })

    const api = new API({
      url: url,
    })
    const params = {
      layouts: data,
      platform: isO2OMode ? this.sendOfflineParam() : undefined,
    }

    if (fromDate && fromTime && toDate && toTime) {
      const fromTimeStamp = moment(fromDate + fromTime, ['YYYY-MM-DDHH:mm:ss'])
        .utc()
        .format()
      let toTimeStamp = moment(toDate + toTime, ['YYYY-MM-DDHH:mm:ss'])
        .utc()
        .format()

      if (timeLine === 'new') {
        toTimeStamp = moment(toTimeStamp).subtract(1, 'second').utc().format()
      }

      params.validFrom = fromTimeStamp
      params.validTill = toTimeStamp
    }
    // if (page === 'tag' && !this.state.hideFilter) {
    //   params.hideFilter = true
    // }
    if (page === 'tag') {
      params.showFilter = this.state.showFilter
    }
    if (
      page !== 'home' &&
      this.state[page] !== '' &&
      this.state[page] !== 'ALL' &&
      this.state[page]
    ) {
      params.url = `${this.state[page]}`
    }
    if (
      timeLine !== 'new' ||
      (timeLine === 'new' && fromDate && fromTime && toDate && toTime)
    ) {
      api.put(params).then(
        (response) => {
          datadogRum.addAction('default test data changed', {
            value: '1',
            items: '2',
          })
          this.data = this.formatResponse(response.data.page.layouts)
          this.originalData = this.data
          const validFrom = response.data.page.validFrom
          const validTill = response.data.page.validTill

          const newFromDate =
            validFrom && moment(validFrom).format('YYYY-MM-DD')
          const newFromTime = validFrom && moment(validFrom).format('HH:mm:ss')
          const newToDate = validTill && moment(validTill).format('YYYY-MM-DD')
          const newToTime = validTill && moment(validTill).format('HH:mm:ss')
          const newTimeLine =
            validFrom && validTill ? validFrom + '/' + validTill : 'default'

          this.pageId = response.data.page.pageId

          this.setState(
            {
              editing: false,
              submitting: false,
              showDialog: true,
              error: false,
              fromDate: newFromDate,
              fromTime: newFromTime,
              toDate: newToDate,
              toTime: newToTime,
              timeLine: newTimeLine,
              currentLayouts: response.data.page.layouts,
              errors: {},
            },
            this.fetchLayoutTimeLine
          )
        },
        (error) => {
          this.setState({
            showDialog: true,
            error: error.message,
            submitting: false,
          })
        }
      )
    } else if (timeLine === 'new') {
      this.data = data
      window.scrollTo(0, 0)
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.editing = false
        newState.error = {}
        if (!newState.fromDate) {
          newState.errors.fromDate = true
        }
        if (!newState.toDate) {
          newState.errors.toDate = true
        }
        if (!newState.fromTime) {
          newState.errors.fromTime = true
        }
        if (!newState.toTime) {
          newState.errors.toTime = true
        }
        return newState
      })
    }
  }

  onAddClick() {
    this.setState({
      showLayoutPopup: true,
    })
  }

  addLayout(layoutName, data) {
    const layout = {
      name: layoutName,
      data: data,
      status: 'ENABLED',
    }
    this.data.push(layout)
    this.setState({
      showLayoutPopup: false,
    })
  }

  onEditClick(layoutIndex, top) {
    const layout = this.data[layoutIndex]
    Object.assign(this.currentLayout, layout)
    this.currentLayout.top = top
    this.setState({
      editing: true,
      layoutIndex,
    })
  }

  onRemoveClick(index) {
    this.saveLayouts(index, null, true)
    this.setState({
      showPrompt: false,
      deleteIndex: '',
    })
  }

  showPrompt(index) {
    this.setState({
      showPrompt: true,
      deleteIndex: index,
    })
  }

  updateLayouts(value) {
    const state = this.pages[this.state.page]
    this.setState(
      {
        [state]: value,
      },
      this.fetchScheduledLayout
    )
  }

  getRespectiveComponents(component) {
    this.setState({
      currentComponent: component,
    })
  }

  getSelectElement(selectField) {
    const SearchableComponent = searchableComponent[selectField]
    return selectField !== 'tag' ? (
      <SearchableComponent
        name={selectField}
        className="taxonomy-select"
        label={getMessage('themes.searchable.label')}
        placeholder={getMessage(`themes.${selectField}.show.layout`)}
        value={this.state[`${selectField}Object`]}
        dontShowChain={selectField === 'category'}
        onChange={(value) => {
          this.setState({ [`${selectField}Object`]: value })
          this.updateLayouts(value.slug)
        }}
      />
    ) : (
      <Searchable
        label={getMessage('themes.searchable.label')}
        placeholder={getMessage(`themes.${selectField}.show.layout`)}
        className="taxonomy-select"
        searchUrl="/catalogue-service/tag"
        valueKey="id"
        nameKey="name"
        searchKey="name"
        name="tag"
        expandMore={true}
        transformResponse={(response) => response.data.tag}
        value={this.state[`${selectField}Object`]}
        onChange={(value) => {
          this.setState({ [`${selectField}Object`]: value })
          this.updateLayouts(value.slug)
        }}
      />
    )
  }

  setLayoutSchedulerValues(key, value) {
    this.setState({
      [key]: value,
    })
  }

  handleDeleteSceduledLayout() {
    const pageId = this.pageId
    const { timeLine } = this.state
    const page = this.pages[this.state.page]
    const isO2OMode = this.state.layoutMode === 1
    let url = ''
    let params = {}
    if (this.isNewLayoutEnabled()) {
      url = getLayoutUrl('NEW_PAGE', pageId)
      params = {
        validFrom: timeLine === 'default' ? null : timeLine?.split('/')[0],
        validTill: timeLine === 'default' ? null : timeLine?.split('/')[1],
        platform: isO2OMode ? 'OFFLINE' : 'DELIVERY',
      }
    } else {
      url = getLayoutUrl('OLD_PAGE', page)
      params = {
        pageId: pageId,
        validFrom: timeLine === 'default' ? null : timeLine?.split('/')[0],
        validTill: timeLine === 'default' ? null : timeLine?.split('/')[1],
        platform: isO2OMode ? 'O2O' : 'DELIVERY',
      }
    }
    const api = new API({ url: url })
    this.setState({ loaded: false, showDeleteLayoutPopup: false }, () => {
      api
        .delete(params)
        .then(() => {
          this.setState(
            { loaded: true, timeLine: 'default' },
            this.fetchWrapper
          )
        })
        .catch((error) => {
          this.setState({ error: error.message })
        })
    })
  }

  setLayoutTimeline(value) {
    this.setState({ timeLine: value }, () => {
      if (value === 'new') {
        this.setState(
          {
            showNewLayoutForm: true,
            fromDate: '',
            fromTime: '',
            toDate: '',
            toTime: '',
          },
          this.fetchScheduledLayout
        )
      } else if (value !== 'default') {
        this.fetchScheduledLayout()
      } else {
        this.changePage(this.state.page)
      }
    })
  }

  getSchedulerForm() {
    return (
      <div
        className={
          'layout-scheduler-container' +
          (this.state.timeLine === 'new' ? ' bottom' : '')
        }
      >
        <div className="timeline-block">
          <div className="timeline-date">
            <label htmlFor="startDate">
              {getMessage('themes.layout.timeline.startDate.heading')}
            </label>
            <SingleDatePicker
              value={this.state.fromDate}
              id="startDate"
              onChange={(value) =>
                this.setLayoutSchedulerValues('fromDate', value)
              }
              enableToday
              required
              showError={this.state.errors.fromDate}
            />
          </div>
          <Select
            label={getMessage('themes.layout.timeline.startTime.heading')}
            value={this.state.fromTime}
            options={getTimes()}
            placeholder={getMessage(
              'themes.layout.timeline.startTime.placeholder'
            )}
            onChange={(value) =>
              this.setLayoutSchedulerValues('fromTime', value)
            }
            required
            showError={this.state.errors.fromTime}
          />
        </div>
        <div className="timeline-block">
          <div className="timeline-date">
            <label htmlFor="toDate">
              {getMessage('themes.layout.timeline.endDate.heading')}
            </label>
            <SingleDatePicker
              value={this.state.toDate}
              id="toDate"
              onChange={(value) =>
                this.setLayoutSchedulerValues('toDate', value)
              }
              enableToday
              required
              showError={this.state.errors.toDate}
            />
          </div>
          <Select
            label={getMessage('themes.layout.timeline.endTime.heading')}
            value={this.state.toTime}
            options={getTimes()}
            placeholder={getMessage(
              'themes.layout.timeline.endTime.placeholder'
            )}
            onChange={(value) => this.setLayoutSchedulerValues('toTime', value)}
            required
            showError={this.state.errors.toTime}
          />
        </div>
      </div>
    )
  }

  getTimelineSelector() {
    const { timeLine, timeLines } = this.state
    const value =
      timeLines &&
      timeLine &&
      (timeLines.filter((t) => t.value === timeLine)[0] || {}).text
    return (
      isExtensionEnabled('LayoutSchedulingSupport') && (
        <div className="layout-timeline-dropdown">
          <label>{getMessage('themes.layout.timeline.heading')}</label>
          <DropDown value={value} icon={<img src={arrow} alt="^" />}>
            {this.state.timeLines &&
              this.state.timeLines.map((t) => {
                return (
                  timeLine !== t.value && (
                    <DropDownItem
                      key={t.value}
                      onClick={() => this.setLayoutTimeline(t.value)}
                    >
                      <div>{t.text}</div>
                    </DropDownItem>
                  )
                )
              })}
          </DropDown>
        </div>
      )
    )
  }

  getDeleteButton() {
    return (
      <div className="layout-delete-btn">
        <button
          onClick={() => this.setState({ showDeleteLayoutPopup: true })}
          className="delete-btn"
          title="Delete Layout"
        >
          <img src={deleteIcon} alt="delete" />
        </button>
        <Dialog
          show={this.state.showDeleteLayoutPopup}
          title={getMessage('themes.dialog.promt.title')}
          okText={getMessage('themes.dialog.okText')}
          closeText={getMessage('themes.dialog.closeText')}
          information={getMessage('themes.layout.delete.warning')}
          close={() =>
            this.setState({
              showDeleteLayoutPopup: false,
            })
          }
          onOk={this.handleDeleteSceduledLayout}
        />
      </div>
    )
  }

  dialogs() {
    return (
      <>
        <Popup
          show={this.state.showLayoutPopup}
          heading={getMessage('themes.selection.heading')}
          className="add-new-layout-popup"
          close={() =>
            this.setState({
              showLayoutPopup: false,
            })
          }
        >
          {
            <div className="layout-main-content">
              <ul>
                {Object.keys(availableComponents).map((component, index) => {
                  return (
                    <li
                      key={`change-components-${index}`}
                      className={
                        this.state.currentComponent === component
                          ? 'active-layouts'
                          : ''
                      }
                      onClick={() => {
                        this.getRespectiveComponents(component)
                      }}
                    >
                      {component.replace(/([A-Z])/g, ' $1').trim()}
                    </li>
                  )
                })}
              </ul>
              <div
                className="ui-components"
                onClick={this.props.onComponentClick}
              >
                {Object.keys(
                  availableComponents[this.state.currentComponent]
                ).map((layout, index) => {
                  const Component =
                    availableComponents[this.state.currentComponent][layout]
                  const data = {}
                  if (layout === 'BannerWithButton') {
                    data.button = true
                  }
                  const categoryAndbrandCollection =
                    layout === 'SubCategoryCollection'
                  return (
                    <div key={`add-layout-${index}`}>
                      <h3>
                        {layout.replace(/([A-Z])/g, ' $1').trim()}{' '}
                        {categoryAndbrandCollection && <small>Beta</small>}
                      </h3>
                      <div className="layout-option">
                        <Component
                          data={data}
                          page={this.pages[this.state.page]}
                        />
                        <div className="toolbar">
                          <button
                            className="primary button"
                            onClick={() => {
                              if (layout === 'ProductCollectionWithBanner') {
                                data.layoutType = 'GRID'
                                data.loadMoreType = 'SEEALL'
                              }
                              this.addLayout(layout, data)
                            }}
                          >
                            {getMessage('themes.selection.add')}
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </Popup>
        <Dialog
          show={this.state.showDialog}
          className={this.state.error ? 'warn' : 'success'}
          title={
            this.state.error
              ? getMessage('themes.layout.save.title.error')
              : getMessage('themes.layout.save.title.success')
          }
          information={
            this.state.error
              ? getMessage('themes.error.' + this.state.error)
              : getMessage('themes.layout.save.success')
          }
          closeText={getMessage('themes.dialog.okText')}
          close={() => {
            this.setState({
              showDialog: false,
            })
          }}
        />
        <Dialog
          show={this.state.showPrompt}
          title={getMessage('themes.dialog.promt.title')}
          okText={getMessage('themes.dialog.okText')}
          closeText={getMessage('themes.dialog.closeText')}
          information={getMessage('themes.layout.delete.warning')}
          close={() =>
            this.setState({
              showPrompt: false,
              deleteIndex: '',
            })
          }
          onOk={() => this.onRemoveClick(this.state.deleteIndex)}
        />
      </>
    )
  }

  // The code below is for the old behavior which is rendered in other tabs
  classicSortableList() {
    return (
      <div>
        <SortableList
          items={this.data}
          onSortEnd={this.onSortEnd}
          lockAxis="y"
          helperClass="is-dragging"
          onEditClick={this.onEditClick}
          onRemoveClick={this.showPrompt}
        />

        {(this.state.isLayoutEditAllowed || this.isEditablePage()) &&
          this.addLayoutSection()}
        {this.dialogs()}
      </div>
    )
  }
  isEditablePage() {
    const { isDefaultView, isTagBrandCategoryPage } = this.getSpecficDetails()
    return !isTagBrandCategoryPage || (isTagBrandCategoryPage && !isDefaultView)
  }

  addLayoutSection() {
    return (
      <div className="add-layout-section">
        ——<span className="xs-hidden">———————</span>{' '}
        <button className="add-layout-btn" onClick={this.onAddClick}>
          {getMessage('themes.add.text')}
        </button>{' '}
        ——<span className="xs-hidden">——————</span>
      </div>
    )
  }

  getLayoutSpecificDetail() {
    const { category, brand, tag, page } = this.state
    const selectedPage = this.pages[page]
    let selectForCategoryTagBrandSelection = ''
    let selectedlayoutType = ''
    switch (selectedPage) {
      case 'category':
        selectForCategoryTagBrandSelection = this.getSelectElement(
          'category',
          'categories'
        )
        selectedlayoutType = category
        break
      case 'tag':
        selectForCategoryTagBrandSelection = this.getSelectElement(
          'tag',
          'tags'
        )
        selectedlayoutType = tag
        break
      case 'brand':
        selectForCategoryTagBrandSelection = this.getSelectElement(
          'brand',
          'brands'
        )
        selectedlayoutType = brand
        break
      default:
        selectForCategoryTagBrandSelection = ''
    }
    return { selectedlayoutType, selectForCategoryTagBrandSelection }
  }
  layoutTimelineView() {
    const { selectForCategoryTagBrandSelection, selectedlayoutType } =
      this.getLayoutSpecificDetail()
    const selectScheduler = this.getTimelineSelector()
    const datePicker =
      this.state.timeLine !== 'default' &&
      (this.state.timeLine === 'new'
        ? this.getSchedulerForm()
        : this.getDeleteButton())
    const selectedPage = this.pages[this.state.page]
    return (
      <Fragment>
        <div
          className={
            'layout-scheduler-controller' +
            (this.state.timeLine === 'new' ? ' column' : '')
          }
        >
          {this.pages[this.state.page] === 'tag' && (
            <div className="filter-checkbox">
              <Checkbox
                className="availability-status"
                inlineLabel={getMessage('themes.tag.filter.label')}
                name="status"
                value={this.state.showFilter}
                onChange={(value) => this.setState({ showFilter: value })}
              />
            </div>
          )}
          {datePicker}
          <div className="inline-block">
            {selectScheduler}
            {selectForCategoryTagBrandSelection}
          </div>
        </div>
        <h2 className="title">
          Now you are editing <strong>{selectedlayoutType || 'default'}</strong>{' '}
          layout of {selectedPage} page
        </h2>
      </Fragment>
    )
  }

  classicView() {
    // Enable classic view for the tabs
    // currentSubTab - pass current tab value
    // urlValueForSearch - override value
    const PAGES = this.isCustomizeEnabled ? [0, 1, 2, 3, 4, 5, 6] : [0]
    const View = () => {
      if (this.state.loaded) {
        if (PAGES.includes(this.state.page)) {
          return (
            <>
              <UpdatedLayout
                data={this.state.currentLayouts.map((item) => ({
                  ...item,
                  id: uniqueId('swimlane'),
                }))}
                onEditClick={(layoutIndex) => this.onEditClick(layoutIndex)}
                refetchLayout={this.fetchScheduledLayout}
                onRemoveClick={this.showPrompt}
                view={this.state.view}
                setView={(view) => this.setState({ view })}
                showDialog={() => this.setState({ showDialog: true })}
                timeline={this.state.timeLine}
                currentSubTab={this.currentPages[this.state.page]}
                urlValueForSearch={
                  this.state[this.currentPages[this.state.page]]
                }
                isCustomizeEnabled={this.isCustomizeEnabled}
                isLayoutEditAllowed={
                  this.isEditablePage() || this.state.isLayoutEditAllowed
                }
                onChangeLayout={(layouts) => {
                  this.setState({
                    currentLayouts: layouts,
                  })
                }}
              />
              {(this.state.isLayoutEditAllowed || this.isEditablePage()) &&
                this.addLayoutSection()}
              {this.dialogs()}
            </>
          )
        }

        return this.classicSortableList()
      }
      return <Loader />
    }

    return (
      <>
        <div
          style={{
            marginTop: 20,
          }}
        />
        <Tabs
          items={this.pages.map((page) => `${page} page`)}
          default={this.state.page}
          onClick={(index) => {
            this.changePage(index)
          }}
        />

        {this.layoutTimelineView()}
        <View />
        {(this.state.isLayoutEditAllowed || this.isEditablePage()) &&
          this.saveCancelButtons()}
      </>
    )
  }

  saveCancelButtons() {
    return (
      this.state.loaded && (
        <Fragment>
          <div className="button-wrapper">
            <button
              className="button button-white"
              type="button"
              onClick={this.cancelReordering}
            >
              {getMessage('order.form.cancel.text')}
            </button>
            <button
              className="button primary"
              type="button"
              onClick={this.confirmSaveLayout}
            >
              save
            </button>
          </div>
          <Popup
            show={this.state.showConfirmLayoutPopup}
            heading={'Confirm default layout change'}
            className="add-new-layout-popup"
            close={() =>
              this.setState({
                showConfirmLayoutPopup: false,
              })
            }
          >
            {
              <div className="popup popup-content">
                <p>
                  {' '}
                  You are going to change <strong>
                    default layout
                  </strong> for {this.pages[this.state.page]} Page which will
                  apply to all {this.pages[this.state.page]}. Do you wish to
                  continue?
                </p>
                <div className="button-wrapper">
                  <button
                    className="button button-white"
                    type="button"
                    onClick={() => {
                      this.setState({
                        showConfirmLayoutPopup: false,
                      })
                      this.cancelReordering()
                    }}
                  >
                    {getMessage('order.form.cancel.text')}
                  </button>
                  <button
                    className="button primary"
                    type="button"
                    onClick={() => {
                      this.setState({
                        showConfirmLayoutPopup: false,
                      })
                      this.reorderLayout()
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            }
          </Popup>
        </Fragment>
      )
    )
  }

  listView() {
    return (
      <div className="themes-customize">
        <div className="themes-customize-heading">
          <h1>{getMessage('themes.heading')}</h1>
          <Link to="/settings/themes/navigation" className="primary button">
            {getMessage('themes.customize.nav')}
          </Link>
        </div>
        <Tabs
          items={['Online', 'O2O']}
          default={this.state.layoutMode}
          onClick={(index) => {
            this.changePage(0)
            this.changeLayoutMode(index)
          }}
        />

        {this.state.layoutMode === 0 ? (
          this.classicView()
        ) : this.state.loaded ? (
          <>
            <O2OLayout
              items={this.state.currentLayouts.map((item) => ({
                ...item,
                id: uniqueId('swimlane'),
              }))}
              onEditClick={(layoutIndex) => this.onEditClick(layoutIndex)}
              refetchLayout={this.fetchScheduledLayout}
              view={this.state.view}
              setView={(view) => this.setState({ view })}
              timeline={this.state.timeLine}
              onRemoveClick={this.showPrompt}
              showDialog={() => this.setState({ showDialog: true })}
              onChangeLayout={(layouts) => {
                this.setState({
                  currentLayouts: layouts,
                })
              }}
              layoutPicker={this.layoutTimelineView()}
            />
            {(this.state.isLayoutEditAllowed || this.isEditablePage()) &&
              this.addLayoutSection()}
            {this.saveCancelButtons()}
            {this.dialogs()}
          </>
        ) : (
          <Loader />
        )}
      </div>
    )
  }

  render() {
    const { props } = this
    const { isDefaultView, isTagBrandCategoryPage } = this.getSpecficDetails()
    const View = () => {
      if (this.state.editing) {
        const layout = this.data[this.state.layoutIndex]
        const layoutInfo =
          getLayout(
            this.state.page,
            layout.name,
            this.pages,
            this.selectOptionsData
          ) || {}
        const top = this.currentLayout.top
        return (
          <Layout
            top={top}
            data={{
              ...layout.data,
              ...layout.value,
              name: layout.name,
            }}
            isTagBrandCategoryPage={isTagBrandCategoryPage}
            isDefaultView={isDefaultView}
            preview={layoutInfo.preview}
            fields={layoutInfo.fields}
            name={layout.name}
            status={layout.status || 'DISABLED'}
            page={this.pages[this.state.page]}
            onSubmit={(form) => {
              const newForm = form
              /* istanbul ignore if  */
              if (this.isNewLayoutEnabled() && 'userSegment' in newForm) {
                if (typeof newForm.userSegment === 'string') {
                  // this will filter any extra commas in the field and covert into integer
                  newForm.userSegment = newForm.userSegment
                    .split(',')
                    .map((num) => num.trim())
                    .filter((num) => num !== '')
                    .map(Number)
                }
              }
              this.saveLayouts(this.state.layoutIndex, newForm, false)
            }}
            onCancel={() => {
              this.setState({
                editing: false,
              })
            }}
            onRemove={() => {
              console.info('Remove this layout')
            }}
            sortable
          />
        )
      }
      return <this.listView />
    }
    return (
      <AuthenticatedPage
        menu={props.menu}
        className="website"
        from={props.location && props.location.pathname}
      >
        <View />
      </AuthenticatedPage>
    )
  }
}

const LayoutManager = (props) => {
  const splitConfig = React.useContext(SplitContext)

  return <LayoutManagerComponent {...props} splits={splitConfig?.splits} />
}

export default LayoutManager
