import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import isObject from 'lodash/isObject'
import AuthenticatedPage from '../AuthenticatedPage'
import WithoutMenuPage from '../WithoutMenuPage'
import { isNoResult, isFilterApplied } from '../../components/ListingPage/utils'
import Loader from '../../components/Loader'
import { Popup as Modal, Dialog } from '../../components/Popup'
import API from '../../lib/api'
import { getMessage } from '../../lib/translator'
import { get } from '../../lib/storage'
import { getDefaultStore } from '../StoreSelector'
import { isExtensionEnabled } from '../../lib/auth'
import { compareValues } from '../../lib/stateManagement'
import './style.css'
import TagsCSV from '../../pages/catalogue/Tags/TagsCSV'
import SPLIT_FEATURES from '../../containers/SplitContext/features'
import { SplitContext } from '../../containers/SplitContext'
import BulkTaggingModal from '../../pages/settings/CustomerTags/Table/Form/BulkTaggingmodal'
import { filterAppHomeListing } from 'components/AppHome/utils'
import Toast from 'components/Toaster/Toaster'
import {
  isRewardsPage,
  setOffsetForRewardsPage,
} from 'components/Rewards/utils'
import ListingPageView from './ListingPageView'
import { APP_HOME_PAGES, CONTENT_API_PAGES } from './constants'

export const TABLE_ACTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  DELETE: 'DELETE',
  TAGGING: 'TAGGING',
  FILTER: 'FILTER',
  REFRESH: 'REFRESH',
  UPDATE: 'UPDATE',
  UPDATE_CUSTOM: 'UPDATE_CUSTOM',
  SET_API_PARAMS: 'SET_API_PARAMS',
  IMPORT: 'IMPORT',
  DISABLE: 'DISABLE',
  UPDATE_EXPIRY: 'UPDATE_EXPIRY',
}

const DELIVERY_ORDER_TYPE = { type: 'DELIVERY' }
const ORDER_TYPE = { orderType: 'DELIVERY' }
const PATE_OPS_ORDERS = '/operations/orders'
const PAGE_OPS_DEL_ORDERS = '/operations/delivery-orders'
const ORDER_PAGES = [
  '/customer-support/delivery-orders',
  '/customer-support/orders',
  PATE_OPS_ORDERS,
  PAGE_OPS_DEL_ORDERS,
]
const MSG_DIALOG_OK_TEXT = getMessage('dialog.okText')

const NullComponent = () => null

const appHomePagingKey = {}

const getDefaultFilter = (props, storeId) => {
  const page = props.router.location.pathname

  if (page === '/customer-support/delivery-orders') {
    return DELIVERY_ORDER_TYPE
  }
  if (page === '/customer-support/orders') {
    return ORDER_TYPE
  }

  if (page === PATE_OPS_ORDERS || page === PAGE_OPS_DEL_ORDERS) {
    const flags = JSON.parse(get('SPLIT_FEATURE_FLAGS'))
    let storeIds = []
    if (flags?.[SPLIT_FEATURES.FILTER_BY_STORE_IDS]?.treatment === 'on') {
      const config = JSON.parse(
        flags[SPLIT_FEATURES.FILTER_BY_STORE_IDS]?.config
      )
      const { store_ids } = config
      storeIds = store_ids.split(',').map((id) => Number(id))
    }

    if (storeIds.includes(storeId)) {
      if (page === PAGE_OPS_DEL_ORDERS) {
        return DELIVERY_ORDER_TYPE
      }
      if (page === PATE_OPS_ORDERS) {
        return ORDER_TYPE
      }
    }
    return { orderType: undefined, type: undefined }
  }

  return {}
}

class ListingPage extends React.Component {
  constructor(props) {
    super(props)
    const searchParams = Object.assign(
      {},
      ...this.props.router.location.search
        .slice(1)
        .split('&')
        .filter(Boolean)
        .map((keystr) => {
          keystr = keystr.split('=')
          return {
            [keystr[0]]: decodeURIComponent(keystr[1]),
          }
        })
    )
    if (Object.keys(searchParams).length > 0) {
      const item = window.localStorage.getItem(this.props.className)
      if (!item) {
        window.localStorage.setItem(this.props.className, 1)
      }
    }
    let apiParams = {}
    if (props.api) {
      if (props.api.params) {
        apiParams = props.api.params
      }
      if (
        props.storeDependent &&
        isExtensionEnabled('MultiStoreSupport') &&
        (get('store') || getDefaultStore().storeId)
      ) {
        apiParams.storeId = Number(get('store') || getDefaultStore().storeId)
      }
    }
    const { location } = props.router || {}
    const { state = {} } = location
    this.state = {
      data: {
        items: null,
        paging: null,
        filters:
          {
            ...state,
            ...searchParams,
            ...getDefaultFilter(
              props,
              Number(get('store') || getDefaultStore().storeId)
            ),
          } || {}, // Corresponds to the filters applied to the API
        viewItems: null,
      },
      filters: {
        // Corresponds to the state of the 'filters' view
        shown:
          Object.keys(searchParams).length > 0 ||
          (this.props.filters && this.props.filters.showFiltersOnLoad),
      },
      apiParams: apiParams,
      loaders: {
        data: false,
        updatingApiParams: false,
      },
      form: {
        isTagSubmitted: false,
        shown: false,
        rowIndex: -1, // The row being edited
        data: null,
      },
      importDialog: {
        shown: false,
        data: {},
      },
      deleteDialog: {
        shown: false,
        data: {},
      },
      disableDialog: {
        shown: false,
        data: {},
      },
      updateDialog: {
        shown: false,
        data: {},
      },
      errorDialog: {
        shown: false,
        message: '',
        title: '',
      },
      firstLoadDone: false, // Indicate that we have gotten results from API at least once
      showBulkTagging: false,
      selectedTag: {},
      showToast: false,
      hideCustomerSupport:
        this.props.splits?.[SPLIT_FEATURES.LOYALTY_CUSTOMER_SUBMENU_TAB]
          ?.treatment === 'on',
      isCustomerPage: window.location.href.includes(
        'customer-support/customers'
      ),
    }

    this.primaryKey = this.props.primaryKey || 'id'

    if (props.api && props.api.url) {
      const { api } = props
      let queryString = ''
      if (api.defaultQuery && Object.keys(api.defaultQuery).length > 0) {
        queryString = Object.keys(api.defaultQuery)
          .map((key) => `${key}=${encodeURIComponent(api.defaultQuery[key])}`)
          .join('&')
      }
      const url = queryString ? `${api.url}?${queryString}` : api.url
      this.api = new API({ url })
    }

    /* Default hooks */
    // Method to extract data from the API call made
    this._transformResponse = (response) => response
    this._transformSubmit = (form) => form

    /* Hook overrides */
    if (props.api && props.api.transform) {
      this._transformResponse = props.api.transform
    }
    if (props.form && props.form.transformSubmit) {
      this._transformSubmit = props.form.transformSubmit
    }
    if (props.updateView) {
      this.updateView = this.updateView.bind(this)
    }

    this._showDataLoader = this._showDataLoader.bind(this)
    this._hideDataLoader = this._hideDataLoader.bind(this)
    this.changePage = this.changePage.bind(this)
    this._handleViewAction = this._handleViewAction.bind(this)
    this._handleUpdateExpiryAction = this._handleUpdateExpiryAction.bind(this)
    this._handleAddAction = this._handleAddAction.bind(this)
    this._handleDisableAction = this._handleDisableAction.bind(this)
    this._handleImportAction = this._handleImportAction.bind(this)
    this._handleEditAction = this._handleEditAction.bind(this)
    this._handleUpdateAction = this._handleUpdateAction.bind(this)
    this._handleUpdateCustomAction = this._handleUpdateCustomAction.bind(this)
    this._handleDeleteAction = this._handleDeleteAction.bind(this)
    this._handleFilterAction = this._handleFilterAction.bind(this)
    this._handleRefreshAction = this._handleRefreshAction.bind(this)
    this._handleSetApiParams = this._handleSetApiParams.bind(this)
    this._handleTaggingAction = this._handleTaggingAction.bind(this)
    this.performAction = this.performAction.bind(this)
    this._showForm = this._showForm.bind(this)
    this._hideForm = this._hideForm.bind(this)
    this._hideImportDialog = this._hideImportDialog.bind(this)
    this._showFilters = this._showFilters.bind(this)
    this._hideFilters = this._hideFilters.bind(this)
    this._toggleFilters = this._toggleFilters.bind(this)
    this.createResource = this.createResource.bind(this)
    this.modifyResource = this.modifyResource.bind(this)
    this.getResource = this.getResource.bind(this)
    this.applyFilters = this.applyFilters.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.throwError = this.throwError.bind(this)
    this._showDeleteDialog = this._showDeleteDialog.bind(this)
    this._showDisableDialog = this._showDisableDialog.bind(this)
    this._hideDeleteDialog = this._hideDeleteDialog.bind(this)
    this._showErrorDialog = this._showErrorDialog.bind(this)
    this._hideErrorDialog = this._hideErrorDialog.bind(this)
    this.setApiParam = this.setApiParam.bind(this)
    this.renderToast = this.renderToast.bind(this)
    this.showPaginationCounts = this.showPaginationCounts.bind(this)
    this.confirmUpdate = this.confirmUpdate.bind(this)
    this._hideUpdateDialog = this._hideUpdateDialog.bind(this)
    this.changeAppHomePage = this.changeAppHomePage.bind(this)
    this.handleToggleShowBulkTagging =
      this.handleToggleShowBulkTagging.bind(this)
  }

  // Some data lookup methods
  _getRow(data) {
    const result = {
      data: data ? data : null,
      index: -1,
    }
    if (!data && this.props.noId) {
      return result
    }
    const { items } = this.state.data
    for (let i = 0, len = items.length; i < len; i++) {
      const item = items[i]
      let matched = true
      for (const key in data) {
        if (!(key in item) || item[key] !== data[key]) {
          matched = false
          break
        }
      }
      if (matched) {
        result.data = item
        result.index = i
        break
      }
    }
    return result
  }
  // Some utility methods to manage states
  _showDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = true
      return newState
    })
  }
  _hideDataLoader() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.loaders.data = false
      newState.loaders.updatingApiParams = false
      return newState
    })
  }
  _showForm(rowIndex, data) {
    // If rowIndex is specified, then a particular row is being edited
    if (this.props.form && this.props.form.component) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.form.shown = true
        newState.form.rowIndex = isFinite(rowIndex) ? rowIndex : -1
        if (data) {
          newState.form.data = data
        }
        return newState
      })
    }
  }
  _showImportDialog(data) {
    // If rowIndex is specified, then a particular row is being edited
    if (this.props.importDialog && this.props.importDialog.component) {
      this.setState({
        importDialog: {
          shown: true,
          data: data,
        },
      })
    }
  }
  _hideForm() {
    // Clear the form and hide it
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.form.shown = false
      newState.form.rowIndex = -1
      newState.form.data = null
      newState.form.method = null
      newState.form.isTagSubmitted = false
      return newState
    })
  }
  _hideImportDialog() {
    // Clear the form and hide it
    this.setState({
      importDialog: {
        shown: false,
        data: {},
      },
    })
  }
  _showFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = true
      return newState
    })
  }
  _hideFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = false
      return newState
    })
  }
  _toggleFilters() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.filters.shown = !prevState.filters.shown
      return newState
    })
  }
  _hideDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = false
      newState.disableDialog.shown = false
      return newState
    })
  }
  _hideUpdateDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.updateDialog.shown = false
      return newState
    })
  }
  _showDeleteDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.shown = true
      return newState
    })
  }

  _showDisableDialog() {
    this.setState((previousState) => {
      const newState = Object.assign({}, previousState)
      newState.disableDialog.shown = true
      return newState
    })
  }

  _hideErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = false
      newState.errorDialog.message = ''
      newState.errorDialog.title = ''
      return newState
    })
  }
  _showErrorDialog() {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.errorDialog.shown = true
      return newState
    })
  }
  // Methods to manage data via API
  /**
   * Makes API call to create the resource
   * @param {object} data - Data to be created in DB
   * @return {Oject} newState - updated state [Immutated]
   */
  createResource(data) {
    const api = new API({
      url: this.props.api.url,
    })
    const params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams)
    if (params.storeId) {
      params.storeId = Number(params.storeId)
    }
    return api.post(params).then(
      (response) => {
        let transformedRes = this._transformResponse(response)
        /* Show new entry at top of the table */
        if (this.props.form && this.props.form.filterBeforeAdding) {
          const filterBeforeAdding = this.props.form.filterBeforeAdding
          transformedRes = filterBeforeAdding(transformedRes, this)
        }
        if (transformedRes) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            this.props.addNewItemToLast
              ? updatedItems.push(transformedRes)
              : updatedItems.unshift(transformedRes)
            newState.data.items = updatedItems
            const updatedPaging = newState.data.paging
            updatedPaging.count = (updatedPaging.count || 0) + 1
            newState.data.paging = updatedPaging
            return newState
          })
        }
        /* istanbul ignore else */
        if (this.props.api.url === '/account-service/employee') {
          if (this.props.updatePickerZones && transformedRes) {
            params.id = transformedRes.id
            this.props.updatePickerZones(params).then(
              () => {
                this._hideForm()
              },
              (error) => {
                this.throwError(error)
              }
            )
          }
        } else if (this.props.router.location.pathname === '/catalogue/tags') {
          this.setState({
            form: {
              ...this.state.form,
              isTagSubmitted: true,
            },
          })
        } else {
          this._hideForm()
        }
      },
      (error) => {
        this.throwError(error)
      }
    )
  }
  modifyResource(data, ignoreDefault = false) {
    // Make an API call to update the resource
    let x = 'X'
    if (!this.props.noId) {
      x = data[this.primaryKey]
    }
    const api = new API({
      url: `${this.props.api.url}/${x}`,
    })
    let params =
      this.props.form && this.props.form.overwriteWithApiParams === false
        ? Object.assign({}, this.state.apiParams, this._transformSubmit(data))
        : Object.assign({}, this._transformSubmit(data), this.state.apiParams)

    if (ignoreDefault) {
      params = data
    }
    const handleResponse = (response) => {
      /* Update the table row */
      if (this.props.form && this.props.form.filterBeforeAdding) {
        const filterBeforeAdding = this.props.form.filterBeforeAdding
        data = filterBeforeAdding(data, this)
      }
      const updatedRow = this._transformResponse(response)
      const row = this._getRow({
        [this.primaryKey]: updatedRow[this.primaryKey],
      })
      if (data) {
        if (row.index > -1) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            updatedItems.splice(row.index, 1, updatedRow)
            newState.data.items = updatedItems
            return newState
          })
        } else if (this.props.noId) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            this.props.addNewItemToLast
              ? updatedItems.push(updatedRow)
              : updatedItems.unshift(updatedRow)
            newState.data.items = updatedItems
            const updatedPaging = newState.data.paging
            updatedPaging.count = (updatedPaging.count || 0) + 1
            newState.data.paging = updatedPaging
            return newState
          })
        }
      } else {
        if (row.index > -1) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            updatedItems.splice(row.index, 1)
            newState.data.items = updatedItems
            const updatedPaging = newState.data.paging
            updatedPaging.count = updatedPaging.count - 1
            newState.data.paging = updatedPaging
            return newState
          })
        }
      }
      this.props.api &&
        this.props.api.afterSubmit &&
        this.props.api.afterSubmit(response)
      this._hideForm()
      this.fetchTableData()
    }

    switch (this.props.api.url) {
      case '/ef-customer-core/tags':
        return api.patch(params).then(handleResponse, (error) => {
          this.throwError(error)
        })
      case '/account-service/employee':
        return api.put(params).then(
          (response) => {
            if (this.props.updatePickerZones) {
              this.props.updatePickerZones(params).then(
                () => {
                  handleResponse(response)
                },
                (error) => {
                  this.throwError(error)
                }
              )
            } else {
              handleResponse(response)
            }
          },
          (error) => {
            this.throwError(error)
          }
        )
      default:
        return api.put(params).then(handleResponse, (error) => {
          this.throwError(error)
        })
    }
  }

  /**
   * Makes API call to delete the resource
   * @param {object} data - Data to be deleted from DB
   * @return {Oject} newState - updated state [Immutated]
   */
  deleteResource(data) {
    const api = new API({
      url: `${this.props.api.url}/${data[this.primaryKey]}`,
    })
    const params =
      this.props.api.overWriteDeleteParams === false
        ? Object.assign({}, this.state.apiParams, data)
        : Object.assign({}, data, this.state.apiParams)
    api
      .delete(params)
      .then(
        (res) => {
          /* Update the table row */
          const row = this._getRow({
            [this.primaryKey]: data[this.primaryKey],
          })
          if (row.index > -1) {
            this.setState((prevState) => {
              const newState = Object.assign({}, prevState)
              const updatedItems = newState.data.items
              // update status  only when status update required on delete
              // or remove complete row/item from list
              if (this.props.statusUpdateOnDelete) {
                updatedItems[row.index] = {
                  ...updatedItems[row.index],
                  status: res.data.status,
                }
              } else {
                updatedItems.splice(row.index, 1)
              }
              if (Object.values(appHomePagingKey).includes(row.data.id)) {
                const page = window.localStorage.getItem(this.props.className)
                appHomePagingKey[Number(page) + 1] =
                  updatedItems[updatedItems.length - 1].id
              }
              newState.data.items = updatedItems
              const updatedPaging = newState.data.paging
              updatedPaging.count = updatedPaging.count - 1
              newState.data.paging = updatedPaging
              return newState
            })
          }
        },
        (error) => {
          this.throwError(error, getMessage('errorDialog.delete.error.title'))
        }
      )
      .then(this._hideForm)
  }

  async disableResource(data) {
    try {
      const batchId = data.id
      const params = {
        status: 'DISABLED',
      }
      const api = new API({ url: `/batches/${batchId}` })
      await api.patch(params)
      this.handleUpdate(batchId, true)
      this.setState({ showToast: true })
    } catch (error) {
      this.throwError(error, error.message)
    }
  }

  getResource(data) {
    const api = new API({
      url: `${this.props.api.url}/${data[this.primaryKey]}`,
    })
    const params = Object.assign(
      {},
      this._transformSubmit(data),
      this.state.apiParams
    )
    api.get(params).then(
      (response) => {
        /* Update the table row */
        const updatedRow = this._transformResponse(response)
        const row = this._getRow({
          [this.primaryKey]: updatedRow[this.primaryKey],
        })
        if (row.index > -1) {
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            updatedItems.splice(row.index, 1, updatedRow)
            newState.data.items = updatedItems
            return newState
          })
        }
        this.props.api &&
          this.props.api.afterSubmit &&
          this.props.api.afterSubmit(response)
        this._hideForm()
      },
      (error) => {
        this.throwError(error)
      }
    )
  }

  isDeliveryOrderListing(url) {
    return url && url.endsWith('/v3/search/deliveryOrders')
  }

  fetchTableData(params = {}) {
    if (!this.api) {
      return null
    }
    let filters = Object.keys(params).reduce((acc, key) => {
      if (isObject(params[key])) {
        return acc
      }
      return { ...acc, [key]: params[key] }
    }, {})
    this._showDataLoader()
    if (Object.keys(filters).length > 0) {
      filters = Object.assign({}, this.state.apiParams, filters)
    } else {
      filters = Object.assign({}, filters, this.state.apiParams)
    }
    if (this.props.storeDependent && isExtensionEnabled('MultiStoreSupport')) {
      if (!filters.storeId) {
        return null
      }
    }

    if (
      this.api.url &&
      this.api.url.includes('/catalogue-service/product-ranking')
    ) {
      const isDiscoverSearchOverrideEnabled =
        this.props.splits?.[SPLIT_FEATURES.DISCOVERY_SEARCH_OVERRIDE]
          ?.treatment === 'on'
      if (isDiscoverSearchOverrideEnabled) {
        filters.bypasscache = new Date().getTime()
      } else {
        filters.activeNow = true
      }
    }
    if (this.api.url && this.api.url.includes('/account-service/store')) {
      filters.bypasscache = new Date().getTime()
    }
    return this.api
      .get(filters)
      .then((response) => {
        this.setState((prevState) => {
          const newState = Object.assign({}, prevState)
          newState.data.items = this._transformResponse(response)
          if (this.props.updateView) {
            newState.data.viewItems = this._transformResponse(response)
          }
          if (this.isDeliveryOrderListing(this.api.url)) {
            response.data = response.paging
          }
          if (APP_HOME_PAGES.includes(this.props.className)) {
            response.data = {
              ...response.data,
              count: 10,
              limit: 10,
              offset: 0,
            }
            const page = window.localStorage.getItem(this.props.className)
            if (response.lastEvaluatedKey) {
              appHomePagingKey[Number(page) + 1] = response.lastEvaluatedKey
            }
            if (response.data.pagination) {
              appHomePagingKey[Number(page) + 1] = {
                ...response.data.pagination,
              }
            }
            newState.data.pagination = {
              lastEvaluatedSK: response.lastEvaluatedKey,
              ...response.data.pagination,
            }
          }
          newState.data.paging = (({ count, limit, offset }) => ({
            count,
            limit,
            offset,
          }))(response.data)
          newState.firstLoadDone = true
          return newState
        })
      })
      .then(this._hideDataLoader)
      .catch((error) => {
        this._hideDataLoader()
        if (error.message !== 'cancelled') {
          throw error
        }
      })
  }
  changePage(page) {
    window.localStorage.setItem(this.props.className, page) // necessary for pagination
    if (Object.keys(this.state.data.filters).length) {
      this.applyFilters(this.state.data.filters, page)
    } else {
      const { updatePageParams } = this.props.api
      let pageParams
      if (isRewardsPage(this.props.className) && page) {
        pageParams = setOffsetForRewardsPage(page)
      } else if (this.props.className === 'voucher-center-campaigns-page' && page) {
        pageParams = { offset: (page - 1) * 20 }
      } else {
        pageParams = updatePageParams ? updatePageParams({ page }) : { page }
      }

      this.fetchTableData(pageParams)
    }
  }

  changeAppHomePage(page) {
    let params
    const { className } = this.props
    window.localStorage.setItem(className, page)
    const filters = filterAppHomeListing(this.state.data.filters)
    if (CONTENT_API_PAGES.includes(className)) {
      const { lastEvaluatedPK, lastEvaluatedSK } = this.state.data.pagination
      const [contentType, key] = lastEvaluatedPK.split('#', 2)
      params = {
        contentType,
        key,
        lastEvaluatedPK,
        lastEvaluatedSK,
      }
    } else {
      params = {
        id: appHomePagingKey[page],
      }
    }
    this.fetchTableData({
      ...params,
      ...filters,
    })
  }

  applyFilters(filtersData, page = 1) {
    const { location, history } = this.props.router
    window.localStorage.setItem(this.props.className, page) // necessary for pagination
    const { filters: propFilters } = this.props
    const { data } = this.state

    let filters = { ...filtersData }
    if (ORDER_PAGES.includes(location.pathname)) {
      if (filters.referenceNumber) {
        filters = {
          referenceNumber: filters.referenceNumber,
        }
      }
    }

    let transformedFilters = filters
    if (this.props.filters && this.props.filters.transformSubmit) {
      transformedFilters = this.props.filters.transformSubmit(filters)
    }
    let params = Object.assign({}, transformedFilters, {
      page,
    })
    if (isRewardsPage(this.props.className)) {
      params = setOffsetForRewardsPage(page)
    }
    if (APP_HOME_PAGES.includes(this.props.className)) {
      params = filterAppHomeListing(filters)
    }
    const urlSearchParams = new URLSearchParams(params)
    const querystring = urlSearchParams.toString()
    history.push(`${location.pathname}?${querystring}`, {
      ...filters,
    })

    this.setState({
      data: { ...data, filters: { ...filters } },
    })

    if (propFilters?.transformFilter) {
      this.fetchTableData({
        ...propFilters?.transformFilter(filters),
        ...params,
      })
    } else {
      this.fetchTableData({ ...filters, ...params })
    }
  }
  clearFilters() {
    const { location, history } = this.props.router
    window.localStorage.setItem(this.props.className, 1)
    const { onClear } = this.props
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.data.filters = {}
      newState.filters = { shown: true }
      return newState
    })

    if (onClear) {
      onClear()
      return
    }

    history.push(location.pathname)
    this.fetchTableData()
  }
  confirmDelete() {
    this.deleteResource(this.state.deleteDialog.data)
    this._hideDeleteDialog()
  }

  confirmDisable() {
    this.disableResource(this.state.disableDialog.data)
    this.setState((prevState) => {
      const newStates = Object.assign({}, prevState)
      newStates.disableDialog.shown = false
      return newStates
    })
  }

  confirmUpdate() {
    this.modifyResource(this.state.updateDialog.data, true)
    this._hideUpdateDialog()
    this._showDataLoader()
  }

  throwError(error, title) {
    if (error.code >= 400 && error.code < 500) {
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.errorDialog.message =
          error.message.split(':').slice(1).join(':') || error.message // if string does not have a colon
        newState.errorDialog.title = title || ''
        return newState
      }, this._showErrorDialog())
    } else {
      throw error
    }
  }

  handleToggleShowBulkTagging(show) {
    this.setState({ showBulkTagging: show })
  }
  _handleViewAction(data) {
    if (this.props.form) {
      const getRow = this._getRow(data)
      if (getRow.index >= 0) {
        this.setState({ form: { method: 'view' } })
        this._showForm(getRow.index)
      }
    }
  }
  _handleUpdateExpiryAction(data) {
    if (this.props.form) {
      const getRow = this._getRow(data)
      if (getRow.index >= 0) {
        this.setState(
          (prevState) => {
            const newState = Object.assign({}, prevState)
            newState.form.method = 'update_expiry'
          },
          () => {
            this.props.router.history.push({
              pathname: `/marketing/evouchers/update_expiry/${data.id}`,
            })
            localStorage.setItem(
              'updateExpiry',
              JSON.stringify(
                this.state.data.items.find(({ id }) => id === data.id)
              )
            )
          }
        )
      }
    }
  }
  _handleAddAction(data) {
    if (this.props.form) {
      this._showForm(-1, data)
    }
  }
  _handleDisableAction(data) {
    this.setState((prevStates) => {
      const newState = Object.assign({}, prevStates)
      newState.disableDialog.data = data
      return newState
    }, this._showDisableDialog())
  }
  _handleImportAction(data) {
    if (this.props.importDialog) {
      this._showImportDialog(data)
    }
  }
  _handleEditAction(data, updates) {
    if (this.props.form) {
      // Lookup row number for given data and send that row number
      const row = this._getRow(data)
      if (row.index >= 0) {
        if (updates) {
          // If there are updates, just update row instead of showing form
          this.setState((prevState) => {
            const newState = Object.assign({}, prevState)
            const updatedItems = newState.data.items
            updatedItems.splice(
              row.index,
              1,
              Object.assign({}, row.data, updates)
            )
            newState.data.items = updatedItems
            return newState
          })
        } else {
          this._showForm(row.index)
        }
      } else {
        if (this.props.noId && row.index >= 0) {
          this._showForm(row.index, data)
        } else if (this.props.noId) {
          this._showForm(-1, data)
        }
      }
    }
  }
  _handleUpdateAction(data, updates) {
    const row = this._getRow(data)
    if (row.index >= 0 && updates) {
      const params = Object.assign({}, data, updates)
      this.modifyResource(params)
    }
  }
  _handleUpdateCustomAction(data, updates) {
    const rowData = this._getRow(data)
    if (rowData.index >= 0 && updates) {
      const params = Object.assign({}, data, updates)
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        newState.updateDialog.shown = true
        newState.updateDialog.data = params
        return newState
      })
    }
  }
  _handleDeleteAction(data) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState)
      newState.deleteDialog.data = data
      return newState
    })
    this._showDeleteDialog()
  }
  _handleFilterAction(data) {
    // To the user, search & filters are mutually exclusive, but internally,
    // both are implemented as filters
    this.applyFilters(data)
  }
  _handleRefreshAction(data, updates, deleteRow) {
    let params = {}
    if (data && deleteRow) {
      // If data and delete row are not empty, simply delete the row from the view
      const row = this._getRow(data)
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        const updatedItems = newState.data.items.slice()
        updatedItems.splice(row.index, 1)
        newState.data.items = updatedItems
        return newState
      })
    } else if (data && updates) {
      // If 'data' and 'updates' are not empty, simply update the view by pushing 'updates' into the state
      const row = this._getRow(data)
      this.setState((prevState) => {
        const newState = Object.assign({}, prevState)
        const updatedItems = newState.data.items
        updatedItems.splice(row.index, 1, Object.assign({}, row.data, updates))
        newState.data.items = updatedItems
        return newState
      })
    } else if (data) {
      // TODO: If 'data' is not empty, fetch details for that row and update the data for that row
      this.getResource(data)
    } else if (this.state.data && this.state.data.filters) {
      // if filters are applied on the table then refresh the page with filters
      params = Object.assign(
        {
          offset: 0, // Reset page to 1 if applying filters
        },
        this.state.data.filters
      )
    }
    this.fetchTableData(params)
    return null
  }
  _handleSetApiParams(data) {
    this.setState((prevState) => {
      return {
        apiParams: Object.assign({}, prevState.apiParams, data),
      }
    }, this.fetchTableData)
  }
  _handleTaggingAction(data) {
    const getRow = this._getRow(data)
    this.setState({ selectedTag: getRow }, () => {
      this.handleToggleShowBulkTagging(true)
    })
  }
  performAction(action, data, updates, deleteRow = false) {
    // action: (string) Action to perform
    // data: The data needed to search a specific a row
    // updates: The data to update the row with
    // deleteRow: delete the row from view without making api call
    if (action in TABLE_ACTIONS) {
      switch (action) {
        case TABLE_ACTIONS.VIEW:
          this._handleViewAction(data)
          break
        case TABLE_ACTIONS.UPDATE_EXPIRY:
          this._handleUpdateExpiryAction(data)
          break
        case TABLE_ACTIONS.ADD:
          this._handleAddAction(data)
          break
        case TABLE_ACTIONS.DISABLE:
          this._handleDisableAction(data)
          break
        case TABLE_ACTIONS.IMPORT:
          this._handleImportAction(data)
          break
        case TABLE_ACTIONS.EDIT:
          this._handleEditAction(data, updates)
          break
        case TABLE_ACTIONS.UPDATE: {
          this._handleUpdateAction(data, updates)
          break
        }
        case TABLE_ACTIONS.UPDATE_CUSTOM: {
          this._handleUpdateCustomAction(data, updates)
          break
        }
        case TABLE_ACTIONS.DELETE: {
          this._handleDeleteAction(data)
          break
        }
        case TABLE_ACTIONS.FILTER: {
          this._handleFilterAction(data)
          break
        }
        case TABLE_ACTIONS.REFRESH: {
          this._handleRefreshAction(data, updates, deleteRow)
          break
        }
        case TABLE_ACTIONS.SET_API_PARAMS: {
          this._handleSetApiParams(data)
          break
        }
        case TABLE_ACTIONS.TAGGING: {
          this._handleTaggingAction(data)
          break
        }
        default:
          break
      }
    } else if (
      this.props.tableProperties.actions &&
      action in this.props.tableProperties.actions
    ) {
      // Perform custom action
    }
    return null
  }
  componentDidMount() {
    this.props.dontSavePagination &&
      window.localStorage.setItem(this.props.className, 1)

    if (this.props.dontFetchOnMount) {
      return
    }

    let configs = { page: window.localStorage.getItem(this.props.className) }
    if (isRewardsPage(this.props.className) && configs.page) {
      const { page } = configs
      const pageNo = parseInt(page)
      if (this.props.router?.location?.search) {
        configs = setOffsetForRewardsPage(pageNo)
      } else {
        window.localStorage.setItem(this.props.className, 1)
        configs = { offset: 0 }
      }
    }

    if (
      this.props.filters &&
      this.props.filters.transformFilter &&
      this.state.data.filters
    ) {
      const transformedFilters = this.props.filters.transformFilter(
        this.state.data.filters
      )
      this.fetchTableData({ ...transformedFilters, ...configs })
    } else {
      this.fetchTableData({ ...this.state.data.filters, ...configs })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !compareValues(this.props.api.params, nextProps.api.params) &&
      this.props.api &&
      this.props.api.updateApiParams
    ) {
      const loaders = Object.assign({}, this.state.loaders)
      loaders.updatingApiParams = true
      this.setState({
        loaders,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const api = this.props.api
    if (api && api.updateApiParams) {
      const updates = api.updateApiParams(
        prevProps.api.params,
        this.props.api.params,
        prevState.apiParams,
        this.state.apiParams
      )
      if (updates && updates.shouldUpdate) {
        // an option to reset pagination during API params updates
        if (updates.clearPagination) {
          window.localStorage.setItem(this.props.className, 1)
        }

        this.setState(
          (previousState) => {
            const loaders = Object.assign({}, previousState.loaders)
            loaders.updatingApiParams = true
            return {
              loaders,
            }
          },
          () => {
            this.performAction(TABLE_ACTIONS.SET_API_PARAMS, updates.params)
          }
        )
      }
    }
    if (
      this.state.apiParams.storeId &&
      prevState.apiParams.storeId !== this.state.apiParams.storeId
    ) {
      this.fetchTableData()
    }
  }

  componentWillUnmount() {
    this.api && this.api.cancel()
  }

  handleUpdate = (batchId, isDisable) => {
    if (batchId) {
      const newData = Object.assign({}, this.state.data)
      let updatedList = []
      if (isDisable) {
        updatedList = this.state.data.items.map((item) =>
          item.id === batchId ? { ...item, voucherStatus: 'DISABLED' } : item
        )
      }
      newData.items = updatedList
      this.setState({
        data: newData,
      })
    }
  }

  updateView(data, key) {
    if (this.props.updateView) {
      const newView = this.props.updateView(data, key)
      const newData = Object.assign({}, this.state.data)
      newData.viewItems = newView
      this.setState({
        data: newData,
      })
    }
  }
  setApiParam(storeId) {
    const apiParams = JSON.parse(JSON.stringify(this.state.apiParams))
    if (
      !apiParams.storeId &&
      this.props.storeDependent &&
      isExtensionEnabled('MultiStoreSupport')
    ) {
      apiParams.storeId = Number(storeId)
      this.setState({ apiParams })
    }
  }

  renderToast() {
    if (this.props.haveToast === 'approved') {
      return (
        <div className="changes-saved">
          {`${getMessage('order.details.heading')} ${
            this.props.reference
          } ${getMessage('customerSupport.egifting.header.save.success')}`}
        </div>
      )
    }
    if (this.props.haveToast === 'rejected') {
      return (
        <div className="changes-saved">
          {`${getMessage('order.details.heading')} ${this.props.reference} ${getMessage(
            'customerSupport.egifting.header.save.reject'
          )}`}
        </div>
      )
    }
    return ''
  }

  showPaginationCounts(count, limit, offset) {
    if (this.props.nextPrevButtonsEnabled) {
      const currentPage = Math.floor(offset / limit + 1)
      return `Page ${currentPage} -${getMessage('pagination.text')} ${count}`
    }

    return `${getMessage('pagination.text')} ${offset + 1} - ${
      limit > 0 && offset >= 0 ? Math.min(offset + limit, count) : count
    } ${getMessage('pagination.helperText')} ${count}`
  }

  render() {
    // TODO: Add support for replacing default messages with localized strings
    const { props } = this
    const { router = {} } = props
    const { location = {} } = router
    const data = this.state.data

    const filtersApplied = isFilterApplied({ data })
    const noResult = isNoResult({
      api: this.api,
      data,
    })

    const emptyStateShown = noResult && !filtersApplied

    let HeaderActions = this.props.headerActions || NullComponent
    const Form = props.form
      ? props.form.component || NullComponent
      : NullComponent
    const Filters = props.filters
      ? props.filters.component || NullComponent
      : NullComponent
    const allowDelete = props.form && props.form.allowDelete // to allow delete action from inside the form
    if (emptyStateShown) {
      HeaderActions =
        props.emptyState && props.emptyState.actions
          ? props.emptyState.actions
          : NullComponent
    }
    const WrapperComponent = this.props.menu
      ? AuthenticatedPage
      : WithoutMenuPage
    const ImportDialog =
      (this.props.importDialog && this.props.importDialog.component) ||
      NullComponent

    return (
      <WrapperComponent
        setApiParam={this.setApiParam}
        menu={props.menu}
        showLanguageDropDown={props.showLanguageDropDown}
        className={'listing-page ' + props.className}
        storeDependent={props.storeDependent}
        onChange={() => {
          this.performAction(TABLE_ACTIONS.SET_API_PARAMS, {
            storeId: Number(get('store')),
            ...getDefaultFilter(props, Number(get('store'))),
          })
          this.props.api &&
            this.props.api.onUpdateStore &&
            this.props.api.onUpdateStore()
        }}
        from={location.pathname}
      >
        <div className="header-container">
          {props.title && <h1 className="title">{props.title}</h1>}
          {(!emptyStateShown && this.state.firstLoadDone) ||
          (this.props.filters && this.props.filters.forceShow) ? (
            <div className="header-actions-wrapper">
              {this.props.noSearch ? null : this.props.filters ? (
                <div className="search-button-wrapper">
                  <button
                    className={
                      'search-button' +
                      (this.state.filters.shown ? ' active' : '')
                    }
                    data-testid="search-button"
                    onClick={this._toggleFilters}
                  />
                </div>
              ) : null}
              <HeaderActions
                apiParams={this.state.apiParams}
                onAction={this.performAction}
                data={this.state.data}
              />
            </div>
          ) : (
            emptyStateShown && (
              <div className="header-actions-wrapper">
                <HeaderActions
                  apiParams={this.state.apiParams}
                  onAction={this.performAction}
                />
              </div>
            )
          )}
        </div>
        {props.notificationMessage ? props.notificationMessage : null}
        {this.props.additionalViews
          ? this.props.additionalViews.map((View, index) => (
              <View
                key={index}
                data={this.props.updateView ? this.state.data.items : null}
                updateView={this.props.updateView ? this.updateView : null}
              />
            ))
          : null}
        <div
          className={
            'filters-wrapper' +
            (this.props.noSearch
              ? ''
              : this.state.filters.shown
                ? ''
                : ' hidden')
          }
        >
          <Filters
            value={this.state.data.filters}
            onClear={this.clearFilters}
            onSubmit={this.applyFilters}
            options={props.filters ? props.filters.options : null}
          />
        </div>
        {this.state.showToast && (
          <Toast
            message={getMessage('eVoucher.disable.message')}
            onClose={() => this.setState({ showToast: false })}
          />
        )}
        {this.state.form.shown ? (
          <Modal
            heading={
              ['view', 'update_expiry'].includes(this.state.form.method)
                ? this.props.viewHeading
                : this.state.form.rowIndex > -1
                  ? this.props.editHeading ||
                    (this.props.getEditHeading &&
                      this.props.getEditHeading(
                        this.state.data.items[this.state.form.rowIndex]
                      ))
                  : this.props.addHeading ||
                    (this.props.getAddHeading &&
                      this.props.getAddHeading(this.state.form.data))
            }
            className={
              this.props.modalClassName ||
              (this.props.getModalClassName &&
                ((this.state.form.data &&
                  this.props.getModalClassName(this.state.form.data)) ||
                  (this.state.form.rowIndex > -1 &&
                    this.props.getModalClassName(
                      this.state.data.items[this.state.form.rowIndex]
                    )))) ||
              ''
            }
            show={this.state.form.shown}
            close={this._hideForm}
          >
            {!this.state.form.isTagSubmitted && (
              <Form
                value={
                  this.state.form.rowIndex > -1
                    ? this.state.data.items[this.state.form.rowIndex]
                    : this.state.form.data
                }
                onSubmit={
                  this.state.form.rowIndex > -1
                    ? this.modifyResource
                    : this.props.noId
                      ? this.modifyResource
                      : this.createResource
                }
                onCancel={this._hideForm}
                method={
                  this.state.form.method === 'view'
                    ? 'view'
                    : this.state.form.method === 'update_expiry'
                      ? 'update_expiry'
                      : this.state.form.rowIndex > -1
                        ? 'edit'
                        : 'add'
                }
                options={this.props.form && this.props.form.options}
                onDelete={
                  allowDelete
                    ? () =>
                        this.performAction(
                          'DELETE',
                          this.state.data.items[this.state.form.rowIndex]
                        )
                    : null
                }
              />
            )}
            {this.state.form.isTagSubmitted &&
              this.state.data.items &&
              this.state.data.items.length &&
              this.props.router.location.pathname === '/catalogue/tags' && (
                <TagsCSV
                  data={this.state.data}
                  hideForm={this._hideForm}
                  {...this.props}
                />
              )}
          </Modal>
        ) : null}
        {this.state.importDialog.shown && (
          <Modal
            heading={this.props.importHeading}
            className={this.props.modalClassName}
            show={this.state.importDialog.shown}
            close={this._hideImportDialog}
          >
            <ImportDialog />
          </Modal>
        )}

        {(this.state.deleteDialog.shown || this.state.disableDialog.shown) && (
          <Dialog
            show={
              this.state.deleteDialog.shown || this.state.disableDialog.shown
            }
            title={getMessage('deleteDialog.title')}
            information={getMessage('deleteDialog.information')}
            onOk={() =>
              this.state.disableDialog.shown
                ? this.confirmDisable()
                : this.confirmDelete()
            }
            close={this._hideDeleteDialog}
            closeText={getMessage('dialog.cancelText')}
            okText={MSG_DIALOG_OK_TEXT}
          />
        )}
        {this.state.updateDialog.shown && (
          <Dialog
            show={this.state.updateDialog.shown}
            title={getMessage('updateDialog.title')}
            information={getMessage('updateDialog.information')}
            onOk={this.confirmUpdate}
            close={this._hideUpdateDialog}
            closeText={getMessage('dialog.cancelText')}
            okText={MSG_DIALOG_OK_TEXT}
          />
        )}
        {this.state.errorDialog.shown && (
          <Dialog
            show={this.state.errorDialog.shown}
            title={this.state.errorDialog.title}
            information={getMessage(this.state.errorDialog.message)}
            close={this._hideErrorDialog}
            closeText={MSG_DIALOG_OK_TEXT}
          />
        )}
        {this.state.loaders.data ? (
          <Loader />
        ) : (
          <ListingPageView
            data={this.state.data}
            isCustomerPage={this.state.isCustomerPage}
            hideCustomerSupport={this.state.hideCustomerSupport}
            updatingApiParams={this.state.loaders.updatingApiParams}
            apiParams={this.state.apiParams}
            isRewardsTab={props.isRewardsTab}
            emptyState={props.emptyState}
            helpItems={props.helpItems}
            tableProperties={props.tableProperties}
            className={props.className}
            updateView={props.updateView}
            testid={props.testid}
            tableDynamic={props.tableDynamic}
            addToTable={props.addToTable}
            nextPrevButtonsEnabled={props.nextPrevButtonsEnabled}
            api={this.api}
            renderToast={this.renderToast}
            primaryKey={this.primaryKey}
            performAction={this.performAction}
            changePage={this.changePage}
            changeAppHomePage={this.changeAppHomePage}
            showPaginationCounts={this.showPaginationCounts}
          />
        )}
        {!emptyStateShown && this.props.additionalViewsBottom
          ? this.props.additionalViewsBottom.map((View, index) => (
              <View key={index} values={this.state.data} />
            ))
          : null}
        {this.state.showBulkTagging && (
          <BulkTaggingModal
            show={this.state.showBulkTagging}
            handleClosePopup={this.handleToggleShowBulkTagging}
            value={this.state.selectedTag}
          />
        )}
      </WrapperComponent>
    )
  }
}

const ListingPageWrapper = (props) => {
  const splitConfig = useContext(SplitContext)
  const { splits } = splitConfig
  return <ListingPage splits={splits} {...props} />
}

ListingPageWrapper.propTypes = {
  title: PropTypes.string,
  api: PropTypes.shape({
    url: PropTypes.string.isRequired,
    defaultQuery: PropTypes.object,
    transform: PropTypes.func,
  }),
}

export default withRouter(({ location, history, match, ...props }) => (
  <ListingPageWrapper router={{ location, history, match }} {...props} />
))
