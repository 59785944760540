import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import './style.scss'

const Screen = ({ components }) => {
  return (
    <div className="screen">
      {components.map(({ key, url, position }) => {
        const isAnimation = url && url.endsWith('json')
        return (
          <div key={key} className={position}>
            {isAnimation && <Player autoplay loop src={url} />}
            {!isAnimation && <img src={url} alt={key} />}
          </div>
        )
      })}
    </div>
  )
}

export default Screen
