import React from 'react'
import { Cell, Row } from 'components/Table'
import { formatIsoDate } from 'lib/datetime'
import { Link } from 'react-router-dom'
import { DropDown, DropDownItem, ICONS } from 'components/DropDown'
import { getMessage } from 'lib/translator'

export const TableProperties = () => {
  return {
    headers: [
      getMessage('gift.card.print.orders.status'),
      getMessage('gift.card.print.orders.vendors.email'),
      getMessage('gift.card.print.orders.distinct.design.codes'),
      getMessage('gift.card.print.orders.created.by'),
      getMessage('gift.card.print.orders.create.time'),
      getMessage('gift.card.print.orders.actions'),
    ],
    row: ({
      status,
      vendorEmail,
      distinctDesignCodes,
      createdBy,
      createTime,
    }) => (
      <Row>
        <Cell className="rewards-page-actions column-batch-id">{status}</Cell>
        <Cell className="rewards-page-actions column-entity-name">
          {vendorEmail}
        </Cell>
        <Cell className="rewards-page-actions column-total-amount">
          <Link to={`/gift-card/print-orders/${distinctDesignCodes}`}>
            {distinctDesignCodes}
          </Link>
        </Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {createdBy}
        </Cell>
        <Cell className="rewards-page-actions column-creation-time">
          {formatIsoDate(createTime)}
        </Cell>
        <Cell className="category-actions">
          <DropDown icon={<img src={ICONS.VELLIP} alt="⋮" />}>
            <DropDownItem>
              <Link to={`/gift-card/print-orders/${distinctDesignCodes}`}>
                {' '}
                {getMessage('gift.card.print.orders.actions.resend.order')}
              </Link>
            </DropDownItem>
            <DropDownItem>
              <Link to={`/gift-card/print-orders/${distinctDesignCodes}`}>
                {' '}
                {getMessage('gift.card.print.orders.actions.mark.as.printed')}
              </Link>
            </DropDownItem>
          </DropDown>
        </Cell>
      </Row>
    ),
  }
}
