import React, { useEffect, useState } from 'react'
import Form, { Textarea } from 'components/Form'
import { getMessage } from 'lib/translator'
import API from 'lib/api'

import '../style.css'

const PinWallet = (props) => {
  const [loading, setLoading] = useState(false)
  const [showToaster, setShowToaster] = useState(false)
  const { _this } = props
  const { SubmitButton } = _this.buttons

  useEffect(() => {
    const fetchWalletPinOffers = () => {
      new API({
        url: '/offer-service/monitored-offers/wallet-pinned-offers',
      })
        .get()
        .then(({ data: { offers = [] } }) => {
          _this.setState({ values: { offerIds: offers.join('\n') } })
        })
    }
    fetchWalletPinOffers()
  }, [])

  const _submitHandler = (e) => {
    setLoading(true)
    e.preventDefault()
    const { values } = _this.state

    const api = new API({
      url: '/offer-service/monitored-offers/wallet-pinned-offers/add',
    })

    const offerIdsArray = values.offerIds.split('\n').map(Number)

    api
      .post({ offerIds: offerIdsArray })
      .then(() => {
        setShowToaster(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div className="container-pin-wallet">
      {showToaster && (
        <div className="search-success-pin-wallet" id="succcess-dialog">
          {getMessage('offer.pin.wallet.toast.text')}
          <strong
            data-testid="ok-strng-btn"
            onClick={() => setShowToaster(false)}
            className="strong-btn"
          >
            {getMessage('rewardPage.dialogs.disable.okText')}
          </strong>
        </div>
      )}
      <Form onSubmit={(e) => _submitHandler(e)}>
        <Textarea
          className="text-area-pin-wallet"
          type="text"
          name="campaign-winners"
          key="campaign-winners"
          testid="campaign-winners"
          label={getMessage('offer.pin.wallet')}
          placeholder={getMessage(
            'offer.campaigns.form.offerSchedules.offerIds.placeholder'
          )}
          {..._this.generateStateMappers({
            stateKeys: ['offerIds'],
            loseEmphasisOnFill: true,
          })}
        />

        <SubmitButton disabled={loading}>
          {getMessage('offer.submit')}
        </SubmitButton>
      </Form>
    </div>
  )
}
export default PinWallet
