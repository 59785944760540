import React from 'react'
import ListingPageWithRoutes from 'containers/ListingPage/listingRouter'

import { TableProperties } from './TableProperties'
import Actions from './Actions'
import PrintFilter from '../PrintFilter'

const dummyEmail = 'admin@email.com'

const PrintOrdersTable = (props) => {
  return (
    <ListingPageWithRoutes
      testid="print-orders-listing"
      title="Print Orders"
      menu={props.menu}
      className="rewards-page"
      api={{
        url: `/offer-service/offer`,
        params: { ...props.params },
        transform: () => [
          {
            status: 'PLACED',
            distinctDesignCodes: '1',
            vendorEmail: dummyEmail,
            createdBy: dummyEmail,
            createTime: '2025-01-01T00:00:00+08:00',
          },
          {
            status: 'PRINTED',
            distinctDesignCodes: '2',
            vendorEmail: dummyEmail,
            createdBy: dummyEmail,
            createTime: '2025-01-01T00:00:00+08:00',
          },
        ],
      }}
      headerActions={() => <Actions />}
      filters={{
        component: PrintFilter,
        forceShow: true,
        options: {
          type: 'store',
        },
      }}
      form={{}}
      tableProperties={TableProperties()}
      viewHeading={'Test'}
      modalClassName="resend-popup"
      tableDynamic
    />
  )
}

export default PrintOrdersTable
