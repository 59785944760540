import { getMessage } from 'lib/translator'
import _set from 'lodash/set'
import _get from 'lodash/get'

const ANALYTIC_PLACEHOLDER = `Enter analytics in JSON format. Example:\n{\n  "cta.ga": {\n  },\n  "cta.amp": {\n  },\n  "ga_impression": {\n      "asset_type": "omni",\n      "event_name": "voucher clicked",\n  },\n}`

const ANALYTIC_SCHEMA = {
  id: '/AnalyticSchema',
  type: 'object',
  additionalProperties: true,
  properties: {
    ga: { type: ['object', 'null'] },
    amp: { type: ['object', 'null'] },
    'cta.ga': { type: ['object', 'null'] },
    'cta.amp': { type: ['object', 'null'] },
    ga_impression: { type: ['object', 'null'] },
  },
}

const POPUP_HEADING = {
  'edit-module': getMessage(`app.layout.module.edit`),
  'create-module': getMessage(`app.layout.popup.heading.create-module`),
  'create-profile': getMessage(`app.layout.popup.heading.create-profile`),
  'module-type': getMessage(`app.layout.popup.heading.module-type`),
}

const IMG_PLACEHOLDER = {
  'product-swimlane':
    'https://preprod-media.nedigital.sg/fairprice/images/956e7f76-d991-4640-a0f4-a137b624ff4d/product-card-swimlane.png',
  'card-swimlane':
    'https://preprod-media.nedigital.sg/fairprice/images/66f98ae2-accc-4b9a-ae61-5220ed35c0ec/generic-card-swimlane.png',
  banner:
    'https://preprod-media.nedigital.sg/fairprice/images/36ef3cb1-5e0e-42e2-b61f-c00b60eba196/banner.png',
  default:
    'https://preprod-media.nedigital.sg/fairprice/images/39f82ec7-e46a-43aa-8d85-8c118b62bd65/placeholder.png',
}

// Is datasource configurable
const isDsConfigurable = (datasource) =>
  datasource === 'r4u' || datasource === 'webservice'

const formatFormData = (data) => {
  _set(
    data,
    'mConfig.default.analytic.content',
    JSON.stringify(data.mConfig.default.analytic?.content, null, 2)
  )

  if (!isDsConfigurable(data.mDataSource)) {
    return data
  }

  const urlParams = _get(data, 'mConfig.default.apiConfig.urlParams') || {}
  _set(
    data,
    'mConfig.default.apiConfig.urlParams',
    Object.entries(urlParams).reduce((accumulator, item, index) => {
      accumulator[index] = {
        id: crypto.randomUUID(),
        key: item[0],
        val: item[1],
      }
      return accumulator
    }, {})
  )
  return data
}

const getConfig = (data) => {
  if (!isDsConfigurable(data.mDataSource)) {
    return data.mConfig
  }

  return {
    default: {
      ...data.mConfig.default,
      apiConfig: {
        urlParams: Object.values(
          data.mConfig.default.apiConfig.urlParams
        ).reduce((accumulator, item) => {
          if (item.key) {
            accumulator[item.key] = item.val
          }
          return accumulator
        }, {}),
      },
    },
  }
}

const isCardSwimlane = (type) => type === MODULE_TYPES[2].type

const MODULE_TYPES = [
  {
    type: 'banner',
    text: 'app.layout.module.type.banner',
  },
  {
    type: 'product-swimlane',
    text: 'app.layout.module.type.productSwimlane',
  },
  {
    type: 'card-swimlane',
    text: 'app.layout.module.type.cardSwimlane',
  },
  {
    type: 'placeholder',
    text: 'app.layout.module.type.placeholder',
  },
]

export {
  isDsConfigurable,
  isCardSwimlane,
  formatFormData,
  getConfig,
  IMG_PLACEHOLDER,
  POPUP_HEADING,
  ANALYTIC_SCHEMA,
  ANALYTIC_PLACEHOLDER,
  MODULE_TYPES,
}
