import React from 'react'
import './style.scss'

const PreviewTrigger = ({ src = null, text1 = '', text2 = '' }) => {
  return (
    <div className="trigger-wrapper">
      <div className="trigger-content">
        <span className="trigger-text1">{text1}</span>
        <div className="trigger-image">
          <img src={src} alt="Product Image" />
        </div>
        <span className="trigger-text2">{text2}</span>
      </div>
    </div>
  )
}

export default PreviewTrigger
